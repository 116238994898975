//scss
import "./assets/scss/socialv.scss"
import './assets/scss/custom.scss'
import "./assets/scss/customizer.scss"
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import "choices.js/public/assets/styles/choices.min.css";
import "flatpickr/dist/flatpickr.css";
import { ToastContainer } from 'react-toastify';


// Redux Selector / Action
import { useDispatch } from 'react-redux';

// import state selectors
import { setSetting } from './store/setting/actions'
import "./assets/custom/scss/socialv-custom.scss"
import InactivityHandler from "./components/InactivityHandler"

function App({ children }) {
  const dispatch = useDispatch()
  dispatch(setSetting())


  return (
    <>
      <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
      <div className="App">
        <ToastContainer />
        <InactivityHandler />
        {/* <IndexRouters /> */}
        {children}
      </div>
    </>
  );
}

export default App;
