import CryptoJS from 'crypto-js';

// Chave secreta para a criptografia (mantenha isso seguro e fora do código-fonte)
const SECRET_KEY = 'gcVnW@hG3%eiz6T&7p^V9E'; // Pode ser definida em variáveis de ambiente

// Função para criptografar o token
export function encryptToken(token) {
  return CryptoJS.AES.encrypt(token, SECRET_KEY).toString();
}

// Função para descriptografar o token
export function decryptToken(ciphertext) {
  const bytes = CryptoJS.AES.decrypt(ciphertext, SECRET_KEY);
  return bytes.toString(CryptoJS.enc.Utf8);
}
