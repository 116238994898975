import React, { useRef, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import api from '../../services/api';
import { Container, Row, Col, Card } from "react-bootstrap";

const VodPlayer = () => {
    const { vodId } = useParams();
    const [vodData, setVodData] = useState(null);
    const vimeoIframeRef = useRef(null);
    const [isFullscreen, setIsFullscreen] = useState(false);

    useEffect(() => {
        const fetchVod = async () => {
            try {
                const response = await api.get(`/api/ondemand/show/${vodId}`);
                setVodData(response.data);
            } catch (error) {
                console.error('Erro ao carregar o VOD', error);
            }
        };

        fetchVod();
    }, [vodId]);

    useEffect(() => {
        const handleFullscreenChange = () => {
            setIsFullscreen(document.fullscreenElement === vimeoIframeRef?.current?.querySelector('div')?.querySelector('iframe'));
        };

        document.addEventListener('fullscreenchange', handleFullscreenChange);

        return () => {
            document.removeEventListener('fullscreenchange', handleFullscreenChange);
        };
    }, []);

    if (!vodData) {
        return <div>Carregando...</div>;
    }

    return (
        <>
            <div id='content-page' className='content-inner'>
                <Container>
                    <Row>
                        <Col sm="12">
                            <Card
                                className="position-relative inner-page-bg bg-primary p-5"

                            >
                                <div className="inner-page-title">
                                    <h3 className="text-white">{vodData.name}</h3>
                                </div>
                            </Card>
                        </Col>
                        <div className='user-post mt-4 streaming-container'>
                            <div className='ratio ratio-16x9' style={{ height: '100%' }}>
                                <div ref={vimeoIframeRef} dangerouslySetInnerHTML={{ __html: vodData.iframe }} style={{ height: '100%' }} />
                            </div>
                        </div>
                    </Row>
                </Container>
            </div>
        </>
    )
};

export default VodPlayer;
