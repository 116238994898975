import React, { useState, useEffect } from 'react'
import { Row, Col, Container, Button, Card } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import { Link } from 'react-router-dom'
import api from '../../services/api'
import { toast } from 'react-toastify'
import { Pie, Bar } from 'react-chartjs-2'
import {
    Chart as ChartJS,
    BarElement,
    CategoryScale,
    LinearScale,
    Title,
    ArcElement,
    Tooltip,
    Legend,
  } from 'chart.js'
  
const Chart = () => {
    ChartJS.register(CategoryScale, BarElement, LinearScale, Title, ArcElement, Tooltip, Legend)
    const customId = 'success-toast-id'
    let { id } = useParams()
    const [answers, setAnswers] = useState([])
    const [percentage, setPercentage] = useState([])
    const [quantities, setQuantities] = useState([])
    const [showPie, setShowPie] = useState(true)
    const [showBar, setShowBar] = useState(false)

    const data = {
        labels: answers,
        datasets: [
            {
            label: 'Quiz',
            data: percentage,
            backgroundColor: [
                'rgba(255, 99, 132, 0.6)',
                'rgba(54, 162, 235, 0.6)',
                'rgba(255, 206, 86, 0.6)',
                'rgba(75, 192, 192, 0.6)',
                'rgba(153, 102, 255, 0.6)',
                'rgba(255, 159, 64, 0.6)',
            ],
            borderColor: [
                'rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(255, 206, 86, 1)',
                'rgba(75, 192, 192, 1)',
                'rgba(153, 102, 255, 1)',
                'rgba(255, 159, 64, 1)',
            ],
            borderWidth: 1,
            },
        ],
    }
        
    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            tooltip: {
            callbacks: {
                label: function (tooltipItem) {
                    return `${tooltipItem.label}: ${tooltipItem.raw}%`
                },
            },
            },
        },
    }

    const dataBar = {
        labels: answers,
        datasets: [
            {
                label: "Respostas",
                data: quantities,
                backgroundColor: "rgba(75, 192, 192, 0.6)",
                borderColor: "rgba(75, 192, 192, 1)",
                borderWidth: 1,
            },
        ],
    };
    
    const optionsBar = {
        responsive: true,
        plugins: {
            legend: {
                position: "top",
            },
            tooltip: {
                callbacks: {
                    label: (context) => `${context.dataset.label}: ${context.raw}`,
                },
            },
        },
        scales: {
            x: {
                title: {
                    display: true,
                    text: "Opções",
                },
            },
            y: {
                beginAtZero: true,
                ticks: {
                    stepSize: 1,
                    callback: function (value) {
                        return Number.isInteger(value) ? value : null;
                    },
                },
                title: {
                    display: true,
                    text: "Respostas",
                },
            },
        },
    };

    useEffect(() => {
        let cancel = false

        async function fetchData() {
            try {
                const response = await api.get(`/api/quiz/chart/${id}`)

                if (!cancel) {
                    setAnswers(response.data.answers)
                    setPercentage(response.data.percentages)
                    setQuantities(response.data.quantities)
                }
            } catch (error) {
                console.error('Failed to fetch chart data:', error)
                toast.error('Erro ao recuperar dados do gráfico', {
                    toastId: customId,
                    autoClose: 1000,
                })
            }
        }

        fetchData()

        return () => {
            cancel = true
        }
    }, [])

    return (
        <>
            <div id='content-page' className='content-inner'>
                <Container>
                    <Row>
                        <Col sm='12'>
                            <Card className='position-relative inner-page-bg bg-primary p-5'>
                                <div className='inner-page-title' style={{ display: 'flex', justifyContent: 'space-between', width: '-webkit-fill-available' }}>
                                    <h3 className='text-white'>Quiz</h3>
                    
                                    <Link to='/quiz/index'>
                                        <Button><i className='icon material-symbols-outlined d-flex'>
                                            arrow_back
                                        </i></Button>
                                    </Link>
                                </div>
                            </Card>
                        </Col>
                        <Col sm='12' lg='12'>
                            <Col sm='12' lg='12'>
                                <Card>
                                    <Card.Header className='d-flex justify-content-between'>
                                        <div className='header-title d-flex'>
                                            <h4 className='card-title'>Respostas de quiz</h4>
                                            <Button style={{ marginRight: "5px", marginLeft: '8px' }} onClick={() => {
                                                setShowPie(true)
                                                setShowBar(false)
                                            }}>
                                                <i className='material-symbols-outlined d-flex'> pie_chart </i>
                                            </Button>

                                            <Button style={{ marginRight: "5px" }} onClick={() => {
                                                setShowPie(false)
                                                setShowBar(true)
                                            }}>
                                                <i className='material-symbols-outlined d-flex'> bar_chart </i>
                                            </Button>
                                        </div>
                                    </Card.Header>
                                    <Card.Body className='pt-0' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        { showPie ? (<Pie data={data} options={options} style={{ maxWidth: '60vw', maxHeight: '60vh' }} />) : '' }
                                        { showBar ? (<Bar data={dataBar} options={optionsBar} style={{ maxWidth: '60vw', maxHeight: '60vw' }} />) : '' }
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default Chart