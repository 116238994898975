import React, { useEffect, useState, useCallback, useMemo } from 'react'
import { Container, Row, Col, Card, Button, Form, Modal } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import Cookies from 'js-cookie'
import api from '../../services/api'
import { toast } from 'react-toastify'
import { FaSearch } from 'react-icons/fa'
import { useInView } from 'react-intersection-observer'
import loader from '../../assets/images/page-img/page-load-loader.gif'
import { debounce } from 'lodash'
import io from 'socket.io-client'
import AnimationWarningLottie from '../../components/AnimationWarningDeleteConfim/AnimationWarningLottie'

const MembersList = () => {
  const token = Cookies.get('token')
  const loged_user = Cookies.get('userId')
  const [members, setMembers] = useState([])
  const [admins, setAdmins] = useState([])
  const [search, setSearch] = useState('')
  const [page, setPage] = useState(1)
  const [hasMore, setHasMore] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [isAdm, setIsAdm] = useState(false)
  const [modalShow, setModalShow] = useState(false)
  const [memberToRemove, setMemberToRemove] = useState(null)
  let { id } = useParams()
  const { ref, inView } = useInView({
    threshold: 0.1,
  })

  const socket = io(process.env.REACT_APP_API_SOCKET_URL, {
    autoConnect: true,
    withCredentials: true,
  })

  const fetchData = useCallback(async (resetPage = false) => {
    setIsLoading(true)
    const currentPage = resetPage ? 1 : page
    try {
      const response = await api.post(`/api/groupMembers/${id}`, {
        loged_user,
        token,
        search,
        page: currentPage,
        limit: 10
      })
      const data = response.data

      if (resetPage) {
        setMembers(data.members.data)
        setAdmins(data.admins_list)
        setIsAdm(data.is_adm)
        setPage(2)
      } else {
        setMembers(prev => [...prev, ...data.members.data])
        setIsAdm(data.is_adm)
        setPage(currentPage + 1)
      }
      setHasMore(data.length === 10)
    } catch (error) {
      console.error('Failed to fetch user data:', error)
    } finally {
      setIsLoading(false)
    }
  }, [search, page, loged_user, token])

  const handleSearchChange = useCallback(debounce((newSearch) => {
    setSearch(newSearch)
    fetchData(true)
  }, 300), [])

  useEffect(() => {
    fetchData(true)
  }, [search])

  useEffect(() => {
    if (inView && hasMore && !isLoading) {
      fetchData(false)
    }
  }, [inView, hasMore, isLoading])

  const removeGroupMember = async (member_id) => {
    try {
      await api.post(`/api/removeGroupMember`, { member_id, group_id: id }, {
        headers: { Authorization: `Bearer ${token}` },
      }).then(() => {
        toast.success('Membro removido com sucesso!', { autoClose: 1000 })
        setMembers(prev => prev.filter(m => m.id !== member_id))
        setModalShow(false)
      })
    } catch (error) {
      toast.error('Erro ao remover membro do grupo', { autoClose: 1000 })
      setModalShow(false)
      console.error('Failed to remove member:', error)
    }
  }

  const toggleMemberAdmin = async (member_id) => {
    try {
        await api.post(`/api/toggleMemberAdmin`, { member_id, group_id: id }, {
          headers: { Authorization: `Bearer ${token}` },
        }).then((res) => {
          setAdmins(res.data.admins_list)
        })
      } catch (error) {
        toast.error('Erro ao alterar membro do grupo', { autoClose: 1000 })
        console.error('Failed to update group member:', error)
      }
  }

  function DeleteMemberConfirmationModal(props) {
    return (
        <Modal
            {...props}
            aria-labelledby='contained-modal-title-vcenter'
            centered
        >
            <Modal.Header id='modalHeader' closeButton>
                <div id='divModalTitle'>
                    <Modal.Title id='modalTitle'>
                        Exclusão de membro
                    </Modal.Title>
                </div>
            </Modal.Header>
            <Modal.Body id='modalBody'>
                <div className='d-flex justify-content-center'>
                    <AnimationWarningLottie />
                </div>
                <div className='d-flex justify-content-center'>
                    <p>
                        Tem certeza de que deseja remover esse membro ?
                    </p>
                </div>
            </Modal.Body>
            <Modal.Footer id='modalFooter'>
                <Button id='yesButton' onClick={() => removeGroupMember(memberToRemove)}>Sim</Button>
                <Button className='btn-danger' id='noButton' onClick={props.onHide}>Não</Button>
            </Modal.Footer>
        </Modal>
    )
  }

  const filteredMembers = useMemo(() => {
    return search ? members.filter(f => f.name.toLowerCase().includes(search.toLowerCase())) : members
  }, [search, members])

  return (
    <>
      <div id='content-page' className='content-inner'>
        <DeleteMemberConfirmationModal
            show={modalShow}
            onHide={() => setModalShow(false)}
        />

        <Container>
          <Row>
            <Col sm='12'>
              <div className='card-header d-flex justify-content-between px-0 pb-4 mx-5'>
                <div className='header-title'>
                  <h5 className='fw-semibold'>Membros</h5>
                </div>
              </div>
            </Col>
            <div className='mb-4 mt-4'>
              <Form.Group className='input-group'>
                <span className='input-group-text' id='basic-addon1'><FaSearch /></span>
                <Form.Control
                  placeholder='Pesquisar'
                  aria-label='Pesquisar'
                  aria-describedby='search'
                  onChange={(e) => handleSearchChange(e.target.value)}
                />
              </Form.Group>
            </div>
            {filteredMembers.map((member, index) => (
              <Col md={6} key={index} ref={index === filteredMembers.length - 1 ? ref : null}>
                <Card className='card-block card-stretch card-height'>
                  <Card.Body className='profile-page p-0'>
                    <div className='profile-header-image'>
                      <div className='cover-container' style={{ 'height': '9vh' }}>
                        <img src={member.user_about.background_image} alt='profile-bg' className='rounded img-fluid w-100' style={{ 'height': '100%' }} />
                      </div>
                      <div className='profile-info p-4'>
                        <div className='user-detail'>
                          <div className='d-flex flex-wrap justify-content-between align-items-start'>
                            <div className='profile-detail d-flex'>
                              <div className='profile-img pe-lg-4' style={{ 'height': '12vh' }}>
                                <img src={member.user_about.image_profile} alt='profile-img' className='avatar-130 img-fluid' style={{ 'borderRadius': '50%', 'height': '100%' }} />
                              </div>
                              <div className='user-data-block mt-md-0 mt-2'>
                                <h4>{member.name}</h4>
                              </div>
                            </div>
                            <div className='d-flex' style={{ 'flexDirection': 'column' }}>
															{
																isAdm && loged_user != member.id ? (
																	<Button className='btn btn-danger mb-3' onClick={() => {
																		setMemberToRemove(member.id)
																		setModalShow(true)
																	}}>remover membro</Button>
																) : ''
															}

															{
																isAdm && loged_user != member.id && !admins.includes(member.id) ? (
																	<Button className='btn btn-primary' onClick={() => toggleMemberAdmin(member.id)}>tornar admin</Button>
																) : isAdm && loged_user != member.id && admins.includes(member.id) ? (
																	<Button className='btn btn-danger' onClick={() => toggleMemberAdmin(member.id)}>remover admin</Button>
																) : ''
															}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            ))}
            {isLoading && <div className='col-sm-12 text-center'>
              <img src={loader} alt='loader' style={{ height: '100px' }} />
            </div>}
          </Row>
        </Container>
      </div>
    </>
  )
}

export default MembersList
