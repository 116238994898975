import React, { useEffect, useState, useMemo } from 'react'
import { Container, Row, Col, Card, Button, Modal, Form } from "react-bootstrap";
import { useNavigate } from 'react-router-dom'
import { Link } from "react-router-dom";
import api from '../../services/api'
import { toast } from 'react-toastify'
import AnimationWarningLottie from '../../components/AnimationWarningDeleteConfim/AnimationWarningLottie'
import { FaSearch } from 'react-icons/fa'
import placeholderIMG from '../../assets/images/placeholder.png'
import '../../assets/scss/events/index.scss'
const EventsIndex = () => {
    const history = useNavigate()

    const [events, setEvents] = useState([])
    const [modalShow, setModalShow] = useState(false)
    const [eventIdToDelete, setEventIdToDelete] = useState('')
    const [eventNameToDelete, setEventNameToDelete] = useState('')
    const [search, setSearch] = useState('')
    const [currentPage, setCurrentPage] = useState(1)
    const [eventsPerPage] = useState(10)
    const indexOfLastEvent = currentPage * eventsPerPage
    const indexOfFirstEvent = indexOfLastEvent - eventsPerPage
    const currentEvents = events.slice(indexOfFirstEvent, indexOfLastEvent)
    const nPages = Math.ceil(events.length / eventsPerPage)


    const Pagination = ({ nPages, currentPage, setCurrentPage }) => {
        if (nPages <= 1) return null;

        const range = (start, end) => {
            return Array(end - start + 1)
                .fill()
                .map((_, idx) => start + idx);
        };

        let pageNumbers = [];
        if (currentPage <= 3) {
            pageNumbers = [...range(1, Math.min(5, nPages))];
            if (nPages > 5) pageNumbers.push('...', nPages);
        } else if (currentPage > 3 && currentPage < nPages - 2) {
            pageNumbers = [1, '...', ...range(currentPage - 1, currentPage + 1), '...', nPages];
        } else {
            pageNumbers = [1, '...'];
            pageNumbers.push(...range(nPages - 4, nPages));
        }

        return (

            <nav className='d-flex justify-content-center align-items-center'>
                <ul className="pagination mb-4">
                    <li className="page-item"><Link className="page-link" onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}>
                        <i className="icon material-symbols-outlined d-flex">
                            arrow_back
                        </i></Link></li>
                    {pageNumbers.map((number, index) => (
                        <li key={index} className={`page-item ${currentPage === number ? 'active' : ''}`}>
                            <Link className="page-link" onClick={() => typeof number === 'number' && setCurrentPage(number)}> {number}</Link></li>
                    ))}
                    <li className="page-item"><Link className="page-link" onClick={() => setCurrentPage(prev => Math.min(prev + 1, nPages))}>
                        <i className="icon material-symbols-outlined d-flex">
                            arrow_forward
                        </i></Link></li>
                </ul>
            </nav>
        );
    };

    const getEvents = async () => {

        try {
            const response = await api.post('/api/events/index')
            setEvents(response.data.events)

        } catch (error) {
            console.error('Erro')
            toast.error('Erro ao carregar dados.', { autoClose: 3000 });
        }

    }

    const showEditEventPage = (eventId) => {
        history(`/events/edit/${eventId}`)
    }

    const showCreateEventPage = () => {
        history(`/events/create`)
    }


    const showDeleteEventConfirmationModal = (eventId, eventName) => {
        setModalShow(true)
        setEventIdToDelete(eventId)
        setEventNameToDelete(eventName)
    }

    const deleteEvent = async (eventId) => {
        await api.delete(`/api/event/destroy/${eventId}`).then(() => {
            getEvents()
            setModalShow(false)
            toast.success('Evento deletado com sucesso!', { autoClose: 3000 })
        })
    }

    function DeleteEventConfirmationModal(props) {

        return (
            <Modal
                {...props}
                aria-labelledby='contained-modal-title-vcenter'
                centered
            >
                <Modal.Header id='modalHeader' closeButton>
                    <div id='divModalTitle'>
                        <Modal.Title id='modalTitle'>
                            Exclusão de Evento
                        </Modal.Title>
                    </div>
                </Modal.Header>
                <Modal.Body id='modalBody'>
                    <div className="d-flex justify-content-center">
                        <AnimationWarningLottie />
                    </div>
                    <div className="d-flex justify-content-center">
                        <p>
                            Você tem certeza de que deseja remover o evento "{props.username}" ?
                        </p>
                    </div>
                </Modal.Body>
                <Modal.Footer id='modalFooter'>
                    <Button id='yesButton' onClick={() => deleteEvent(props.userid)}>Sim</Button>
                    <Button id='noButton' onClick={props.onHide}>Não</Button>
                </Modal.Footer>
            </Modal>
        )
    }

    useEffect(() => {
        getEvents()
    }, [])

    const filteredEvents = useMemo(() => {
        const lowerSearch = search.toLowerCase()

        if (search === '') {
            return currentEvents
        } else {
            return events.filter((event) => event.name.toLowerCase().includes(lowerSearch) || event.id.toString().toLowerCase().includes(lowerSearch))
        }
    }, [search, events, currentEvents])

    const formatDate = (dateTime) => {
        const date = new Date(dateTime);
        const formattedDate = date.toLocaleDateString('pt-BR');
        const time = date.toLocaleTimeString('pt-BR');
        return (
            <>
                <div className='d-flex align-items-center gap-1'>
                    <span className="material-symbols-outlined fs-5">calendar_today</span> {formattedDate}
                </div>
                <div className='d-flex align-items-center gap-1'>
                    <span className="material-symbols-outlined fs-5">schedule</span> {time}
                </div>
            </>
        );
    };

    return (
        <>
            <div id='content-page' className='content-inner'>
                <Container>
                    <DeleteEventConfirmationModal
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                        userid={eventIdToDelete}
                        username={eventNameToDelete}
                    />
                    <Row>
                        <Col sm="12">
                            <Card
                                className="position-relative inner-page-bg bg-primary p-5"

                            >
                                <div className="inner-page-title">
                                    <h3 className="text-white">Eventos</h3>
                                </div>
                            </Card>
                        </Col>
                        <Col sm="12" lg="12">
                            <Card>
                                <Card.Body className="pt-0">
                                    <div className="mb-4 mt-4">
                                        <Form.Group className="input-group">
                                            <span className="input-group-text" id="basic-addon1">
                                                <FaSearch />
                                            </span>
                                            <Form.Control
                                                placeholder='Pesquisar'
                                                aria-label='pesquisar'
                                                aria-describedby='search'
                                                onChange={(e) => setSearch(e.target.value)}
                                            />
                                            <Button onClick={() => showCreateEventPage()} id='buttonNew' className='borderbtnCreateEdit'>
                                                <i className="icon material-symbols-outlined d-flex">
                                                    add
                                                </i>
                                            </Button>
                                        </Form.Group>
                                    </div>
                                    <div className='w-100'>
                                        <Col className='gridCard'>
                                            {filteredEvents.map((event, index) => (
                                                <Card style={{ width: '20rem', height: "13rem" }} className='border-1 border customCard' key={`event_${event.id}`}>
                                                    <Card.Img
                                                        style={{ maxHeight: '10rem' }}
                                                        className='rounded-top-2'
                                                        variant="top"
                                                        src={event.image_capa || placeholderIMG}
                                                        alt='imagem evento'
                                                    />
                                                    <Card.Title className='d-flex justify-content-between ms-3 mt-2'>{event.name}</Card.Title>
                                                    <Card.Body className='cardBodyContent'>
                                                        <div className='hoverMoreInfos'>
                                                            <div className='d-flex justify-content-between'>
                                                                <div>
                                                                    <span>Data Inicio</span>
                                                                    <div className='fw-bold'>{formatDate(event.data_inicio)}</div>
                                                                </div>
                                                                <div>
                                                                    <span style={{ color: 'orangered' }}>Data Fim</span>
                                                                    <div className='fw-bold'>{formatDate(event.data_fim)}</div>
                                                                </div>
                                                            </div>
                                                            <div className='w-100 d-flex flex-wrap justify-content-end'>
                                                                <div key={index}>
                                                                    <Button style={{ marginRight: "5px" }} onClick={() => showEditEventPage(event.id)}>
                                                                        <i onClick={() => showEditEventPage(event.id)} className="material-symbols-outlined d-flex"> mode_edit </i>
                                                                    </Button>
                                                                    <Button onClick={() => showDeleteEventConfirmationModal(event.id, event.name)}>
                                                                        <i className="material-symbols-outlined d-flex"> delete </i>
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Card.Body>
                                                </Card>
                                            ))}
                                        </Col>
                                    </div>
                                </Card.Body>
                            </Card>
                            <Pagination
                                nPages={nPages}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                            />
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default EventsIndex