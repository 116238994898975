import React, { useState, useEffect } from 'react';
import { OrganizationChart } from 'primereact/organizationchart';
import api from '../../services/api';
import placeholderIMG from '../../assets/images/placeholder.png';
import { Container, Row, Col, Card, } from 'react-bootstrap';
import './style-organorama.css'

const OrganogramaIndex = () => {
    const [data, setData] = useState(false);

    useEffect(() => {
        api.get('/api/organograma/index')
            .then(response => {
                setData(response.data);
            })
            .catch(error => {
                console.error('Erro ao carregar o organograma', error);
            });
    }, []);

    const nodeTemplate = (node) => {
        if (node.type === 'person') {
            return (
                <div className="d-flex flex-column">
                    <div className="d-flex flex-column align-items-center">
                        <a href={`/users/profile/${node.data.userId}`} className="btn btn-primary btn-sm rounded rounded-3" target="_blank" rel="noopener noreferrer">
                            <img
                                alt={node.data.name}
                                src={node.data.image ? node.data.image : placeholderIMG}
                                style={{ width: "3rem", height: "3rem", borderRadius: "50%" }}
                            />
                        </a>
                        <span className="fw-normal mb-2">{node.data.name}</span>
                        <span className='fw-bold'>{node.data.title}</span>
                    </div>
                </div>
            );
        }

        return node.label;
    };
    return (
        <>
            <div id='content-page' className='content-inner'>
                <Container>
                    <Row>
                        <Col sm="12">
                            <Card
                                className="position-relative inner-page-bg bg-primary p-5"
                            >
                                <div className="inner-page-title">
                                    <h3 className="text-white">Organograma</h3>
                                </div>
                            </Card>
                        </Col>
                        <Col sm="12" lg="12">
                            <Card>
                                <Card.Body>
                                    <div className='border border-1 p-3 rounded rounded-2'>
                                        <div className='organization-chart-container'>
                                            {data ? (<OrganizationChart value={data} nodeTemplate={nodeTemplate} />) : ''}
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default OrganogramaIndex;
