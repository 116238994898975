import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Button, Card } from "react-bootstrap";
import { useNavigate, useParams } from 'react-router-dom'
import { Link } from "react-router-dom";
import Cookies from 'js-cookie'
import api from '../../services/api'
import { ToastContainer, toast } from 'react-toastify'
import Select from 'react-select'

const RolesEdit = () => {
    const history = useNavigate()
    const [name, setName] = useState('')
    const [permissions, setPermissions] = useState([])
    const [permissionsToAssociate, setPermissionsToAssociate] = useState([])
    const customId = 'success-toast-id'
    let { id } = useParams()

    const editRole = async (e) => {
        e.preventDefault()

        if (name === '') {
            toast.error('Por favor preencha todos os campos.', { autoClose: 3000 })
        } else {
            await api.put(`/api/role/${id}`, {
                name,
                permissionsToAssociate
            }).then(() => {
                setName('')
                toast.success('Perfil editado com sucesso!', { toastId: customId, autoClose: 3000, onClose: history('/roles/index') })
            })
        }
    }

    useEffect(() => {
        let cancel = false

        async function fetchData() {
            await api.get(`api/role/${id}`).then((res) => {
                if (cancel) return

                setName(res.data.role.name)
            })

            await api.get(`api/permission/index`).then(res => {
                if (cancel) return

                let permissionsData = []

                res.data.permissions.forEach(permission => {
                    permissionsData.push({
                        value: permission.id,
                        label: permission.name
                    })
                })

                setPermissions(permissionsData)
            })

            await api.get(`api/permissions/role/${id}`).then(res => {
                if (cancel) return

                let permissionsData = []

                res.data.permissionsArray.forEach(permission => {
                    permissionsData.push({
                        value: permission.id,
                        label: permission.name
                    })
                })

                setPermissionsToAssociate(permissionsData)
            })
        }

        fetchData()

        return () => {
            cancel = true
        }
    }, [id])


    return (
        <>
            <ToastContainer />

            <div id='content-page' className='content-inner'>
                <Container>
                    <Row>
                        <Col sm="12">
                            <Card
                                className="position-relative inner-page-bg bg-primary p-5"
                            >
                                <div className="inner-page-title" style={{ display: "flex", justifyContent: "space-between", width: "-webkit-fill-available" }}>
                                    <h3 className="text-white">Roles</h3>
                                    <Link to='/roles/index'>
                                        <Button><i className="icon material-symbols-outlined d-flex">
                                            arrow_back
                                        </i></Button>
                                    </Link>
                                </div>
                            </Card>
                        </Col>
                        <Col sm="12" lg="12">
                            <Col sm="12" lg="12">
                                <Card>
                                    <Card.Header className="d-flex justify-content-between">
                                        <div className="header-title">
                                            <h4 className="card-title">Editar role</h4>
                                        </div>
                                    </Card.Header>
                                    <Card.Body className="pt-0">
                                        <Form noValidate onSubmit={editRole} className="row g-3">
                                            <Col md="6" className="">
                                                <Form.Label
                                                    className="form-label"
                                                    htmlFor="validationCustom01"
                                                >
                                                    Nome
                                                </Form.Label>
                                                <Form.Control className='input-group-edit' type='text' placeholder='Nome' value={name} onChange={(e) => setName(e.target.value)} aria-describedby="inputGroupPrepend" id="validationCustom01" required />
                                                <Form.Control.Feedback>
                                                    Correto!
                                                </Form.Control.Feedback>
                                                <Form.Control.Feedback type="invalid">
                                                    Please choose a username.
                                                </Form.Control.Feedback>
                                            </Col>
                                            <Col md="6" className="">
                                                <Form.Label
                                                    className="form-label"
                                                    htmlFor="validationCustom02"
                                                >
                                                    E-Permissões
                                                </Form.Label>
                                                <Select className='input-group-edit' value={permissionsToAssociate} onChange={(e) => setPermissionsToAssociate(e)} options={permissions} isMulti={true} />
                                                <Form.Control.Feedback>
                                                    Correto!
                                                </Form.Control.Feedback>
                                            </Col>
                                            <div className="col-12">
                                                <Button type="submit" variant="primary">Editar</Button>
                                            </div>
                                        </Form>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default RolesEdit