import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import ProtectedRoute from './components/ProtectedRoute';
import api from './services/api';

// Router
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { FriendRequestProvider } from './context/FriendRequestContext.js';
import { FriendStatusProvider } from './context/FriendStatusContext.js';
import { NotificationProvider } from './context/NotificationContext.js';
import { UserAboutProvider } from './context/UserAboutContext.js';
import { MemberRequestProvider } from './context/MemberRequestContext';

// Store
import { Provider } from 'react-redux';
import { store } from './store';

// Routes
import { IndexRouters } from './router';
import { AuthRouter } from './router/auth-router';
import { ChatRouter } from './router/chat-router';

// Criação do roteador com ProtectedRoute aplicado onde necessário
const router = createBrowserRouter([
  ...IndexRouters.map(route => ({
    ...route,
    element: (
      <>
      <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
      <ProtectedRoute>
        <UserAboutProvider>
          <FriendStatusProvider>
            <FriendRequestProvider>
              <NotificationProvider>
                <MemberRequestProvider>
                  {route.element}
                </MemberRequestProvider>
              </NotificationProvider>
            </FriendRequestProvider>
          </FriendStatusProvider>
        </UserAboutProvider>
      </ProtectedRoute>
      </>
    ),
    children: route.children?.map(child => ({
      ...child,
      element: (
        <ProtectedRoute>
          {child.element}
        </ProtectedRoute>
      )
    }))
  })),
  ...AuthRouter,
  ...ChatRouter
], { basename: process.env.PUBLIC_URL });

ReactDOM.createRoot(document.getElementById('root')).render(
  <StrictMode>
    <Provider store={store}>
      <App>
        <RouterProvider router={router} />
      </App>
    </Provider>
  </StrictMode>
);
