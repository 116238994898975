import React, { useEffect, useState, useMemo, useRef } from "react"
import { Card, Form, Nav, Tab, Button, Dropdown, DropdownButton } from "react-bootstrap"
import api from "../../../../services/api"
import Cookies from 'js-cookie'
import { Link, useNavigate } from "react-router-dom"
import { toast } from 'react-toastify'
import EmojiPicker from 'emoji-picker-react';
import { MdEmojiEmotions } from "react-icons/md"
import { FaArrowDown } from "react-icons/fa";
import { io } from 'socket.io-client'
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { set_selected_friend } from "../../../../store/setting/actions"
import { set_selected_group } from "../../../../store/setting/actions"
import { set_friend_messages } from "../../../../store/setting/actions"
import { set_group_messages } from "../../../../store/setting/actions"
import { set_active_users } from "../../../../store/setting/actions"
import { set_receive_new_messages } from "../../../../store/setting/actions"
import { IoCheckmarkDoneSharp } from "react-icons/io5"
import { IoMdClose, IoIosSettings } from "react-icons/io";
import { MdAssistant } from "react-icons/md";

import './styles.css'
import { PhotoProvider, PhotoView } from 'react-photo-view';
import 'react-photo-view/dist/react-photo-view.css';
import { saveAs } from 'file-saver';

//image
import userGenericImage from '../../../../assets/images/generic_user.png'
import logoAtmoAi from '../../../../assets/images/ATMO_AI.png'

const RightSidebar = () => {
  const dispatch = useDispatch();
  const history = useNavigate()
  const customId = 'success-toast-id'
  const token = Cookies.get('token')
  const loged_user = Cookies.get('userId')
  const userStatus = Cookies.get('userStatus')
  const chatBodyRef = useRef(null)
  const groupChatBodyRef = useRef(null)
  const socket = useRef(null)
  const [showScrollButton, setShowScrollButton] = useState(false);
  const [showGroupScrollButton, setShowGroupScrollButton] = useState(false);
  const [friends, setFriends] = useState([])
  const [newFiends, setNewFriends] = useState([])
  const [groups, setGroups] = useState([])
  const [textMessage, setTextMessage] = useState('')
  const [groupTextMessage, setGroupTextMessage] = useState('')
  const [search, setSearch] = useState('')
  const [showPicker, setShowPicker] = useState(false)
  const [showGroupPicker, setShowGroupPicker] = useState(false)
  const [deletedMessage, setDeletedMessage] = useState([])
  const [editedMessages, setEditedMessages] = useState([])
  const selectedFriend = useSelector((state) => state.setting.setting.selected_friend);
  const selectedGroup = useSelector((state) => state.setting.setting.selected_group);
  const messages = useSelector((state) => state.setting.setting.friend_messages);
  const groupMessages = useSelector((state) => state.setting.setting.group_messages);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedMode, setSelectedMode] = useState('text');
  const [wasBlocked, setwasBlocked] = useState(selectedFriend?.someone_blocked)
  const [isKeyboardOpen, setIsKeyboardOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 575);

  const handleSelect = (mode) => {
    setSelectedMode(mode);
  };

  const checkIfUserWasBlocked = async (userId) => {
    try {
      const response = await api.get(`/api/chat/checkIfUserWasBlocked/${userId}`)

      setwasBlocked(response.data.someone_blocked)
    } catch (error) {
      toast.error('Erro ao checar se usuário foi bloqueado', {
        toastId: customId,
        autoClose: 3000,
      })
    }
  }

  const marcarComoLidas = (currentMessages) => {
    if (Array.isArray(currentMessages)) {
      const mensagensAtualizadas = currentMessages.map(message => {
        if (!message.readed) {
          return { ...message, readed: true };
        }
        return message;
      });

      dispatch(set_friend_messages(mensagensAtualizadas))
    }
  };

  const readFriendMessages = (friendId, friends) => {
    if (Array.isArray(friends)) {
      const updatedFriends = friends.map(friend => {
        if (friend.id == friendId) {
          return { ...friend, unread_messages: 0 };
        }
        return friend;
      });

      setFriends(updatedFriends)
    }
  }

  const readGroupMessages = (groupId, groups) => {
    if (Array.isArray(groups)) {
      const updatedGroups = groups.map(group => {
        if (group.id == groupId) {
          return { ...group, unread_messages: 0 };
        }
        return group;
      });

      setGroups(updatedGroups)
    }
  }

  const changeStatus = (friendId, friends, status) => {
    if (Array.isArray(friends)) {
      const updatedFriends = friends.map(friend => {
        if (friend.id == friendId) {
          let newFriend = { ...friend, chat_status: status };

          if (selectedFriend && selectedFriend.id == friendId) {
            dispatch(set_selected_friend(newFriend))
          }

          return newFriend;
        }
        return friend;
      });

      setFriends(updatedFriends)
    }
  }

  const setLastMessage = (message, chats, isFirstMessage = false, sending = false) => {
    if (Array.isArray(chats)) {
      const moveToTop = (updatedChats, index) => {
        const chatToMove = updatedChats.splice(index, 1)[0];
        return [chatToMove, ...updatedChats];
      };

      if (message.group_id) {
        if (sending) {
          const updatedChats = chats.map(chat => {
            if (chat.id == message.group_id) {
              return { ...chat, latest_message: message.message, latest_message_time: 'Agora' };
            }
            return chat;
          });

          const chatIndex = updatedChats.findIndex(chat => chat.id == message.group_id);
          const reorderedChats = moveToTop(updatedChats, chatIndex);

          setGroups(reorderedChats);
        } else {
          const updatedChats = chats.map(chat => {
            if (chat.id == message.group_id) {
              return { ...chat, latest_message: message.message, latest_message_time: 'Agora', unread_messages: chat.unread_messages + 1 };
            }
            return chat;
          });

          const chatIndex = updatedChats.findIndex(chat => chat.id == message.group_id);
          const reorderedChats = moveToTop(updatedChats, chatIndex);

          setGroups(reorderedChats);
        }

      } else if (!isFirstMessage) {
        if (sending) {
          const updatedChats = chats.map(chat => {
            if (chat.id == message.sender_id || chat.id == message.receiver_id) {
              return { ...chat, latest_message: message.message, latest_message_time: 'Agora' };
            }
            return chat;
          });

          const chatIndex = updatedChats.findIndex(chat =>
            chat.id == message.sender_id || chat.id == message.receiver_id
          );
          const reorderedChats = moveToTop(updatedChats, chatIndex);

          setFriends(reorderedChats);
        } else {
          const updatedChats = chats.map(chat => {
            if (chat.id == message.sender_id || chat.id == message.receiver_id) {
              return { ...chat, latest_message: message.message, latest_message_time: 'Agora', unread_messages: chat.unread_messages + 1 };
            }
            return chat;
          });

          const chatIndex = updatedChats.findIndex(chat =>
            chat.id == message.sender_id || chat.id == message.receiver_id
          );
          const reorderedChats = moveToTop(updatedChats, chatIndex);

          setFriends(reorderedChats);
        }
      }
    }
  }

  const minirightsidebar = () => {
    getFriends()
    getGroups()
    getNewFriends()
    document.getElementById("rightSidebar").classList.toggle("right-sidebar")
    document.body.classList.toggle("right-sidebar-close")
  }

  const handleScroll = () => {
    const chatBody = chatBodyRef.current;
    if (chatBody.scrollTop < chatBody.scrollHeight - chatBody.clientHeight - 100) {
      setShowScrollButton(true);
    } else {
      setShowScrollButton(false);
    }
  };

  const handleScrollGroup = () => {
    const groupChatBody = groupChatBodyRef.current;
    if (groupChatBody.scrollTop < groupChatBody.scrollHeight - groupChatBody.clientHeight) {
      setShowGroupScrollButton(true);
    } else {
      setShowGroupScrollButton(false);
    }
  };

  const getFriends = async () => {
    try {
      const response = await api.post(`/api/friends/show`, {
        loged_user,
        token,
      });

      const data = response.data;
      setFriends(data)
    } catch (error) {
      console.error('Failed to fetch user data:', error);

      toast.error('Erro ao listar amigos', {
        toastId: customId,
        autoClose: 3000,
      })
    }
  }

  const getNewFriends = async () => {
    try {
      const response = await api.post(`/api/newFriends/show`, {
        loged_user,
        token,
      });

      const data = response.data;
      setNewFriends(data)
    } catch (error) {
      console.error('Failed to fetch user data:', error);

      toast.error('Erro ao listar novos contatos', {
        toastId: customId,
        autoClose: 3000,
      })
    }
  }

  const getGroups = async () => {
    try {
      const response = await api.get(`/api/user/groups/${loged_user}`);

      const data = response.data;
      setGroups(data.groups)
    } catch (error) {
      console.error('Failed to fetch groups data:', error);

      toast.error('Erro ao listar grupos', {
        toastId: customId,
        autoClose: 3000,
      })
    }
  }

  const readMessages = async (friend) => {
    try {
      await api.post(`/api/chat/readMessages`, {
        logged_user: loged_user,
        friend: friend,
      });
    } catch (error) {
      console.error('Failed to read friend messages:', error);

      toast.error('Erro ao ler mensagens', {
        toastId: customId,
        autoClose: 3000,
      })
    }
  }

  const getActiveUsers = async () => {
    try {
      const response = await api.post(`/api/chat/getActiveFriends`);

      const data = response.data;
      dispatch(set_active_users(data))
    } catch (error) {
      console.error('Failed to fetch active users data:', error);

      toast.error('Erro ao listar amigos ativos', {
        toastId: customId,
        autoClose: 3000,
      })
    }
  }

  const sendMessage = async (e, groupId = null) => {
    e.preventDefault()

    if (selectedFriend?.id === '0') {
        try {
        const messageToSend = textMessage; // Captura a mensagem do state
        sendMessageToChatGpt(messageToSend); // Envia a mensagem para a rota da API
        setTextMessage(''); // Limpa o campo de texto após o envio
    } catch (error) {
        console.error('Failed to send message:', error);
  
        toast.error('Erro ao enviar mensagem', {
          toastId: customId,
          autoClose: 3000,
        })
      }
    } else {
        try {
            if (textMessage !== '' || groupTextMessage !== '') {
              const response = await api.post(`/api/chat/store`, {
                message: groupId ? groupTextMessage : textMessage,
                type: 'text',
                sender_id: loged_user,
                receiver_id: groupId ? null : selectedFriend?.id,
                group_id: groupId,
                is_first_message: messages.length === 0 ? true : false,
              });
      
              if (groupId) {
                setGroupTextMessage('')
                dispatch(set_group_messages([...groupMessages, response.data.message]))
      
                setLastMessage(response.data.message, groups, false, true)
              } else {
                if (messages.length === 0) {
                  getFriends()
                }
      
                setTextMessage('')
                dispatch(set_friend_messages([...messages, response.data.message]))
      
                setLastMessage(response.data.message, friends, messages.length === 0, true)
              }
            }
      
        } catch (error) {
          console.error('Failed to send message:', error);

          if (error.response.data.error === 'Você foi bloqueado por este usuário') {
            toast.error(error.response.data.error, {
              toastId: customId,
              autoClose: 3000,
            })
          } else {
            toast.error('Erro ao enviar mensagem', {
              toastId: customId,
              autoClose: 3000,
            })
          }
        }
    }
  }

  const getMessages = async (friendId) => {
    try {
      const response = await api.get(`/api/chat/listMessagesByFriend/${loged_user}/${friendId}`);

      dispatch(set_friend_messages(response.data.messages))

    } catch (error) {
      console.error('Failed to get messages:', error);

      toast.error('Erro ao listar mensagens', {
        toastId: customId,
        autoClose: 3000,
      })
    }
  }

  const getGroupMessages = async (groupId) => {
    try {
      const response = await api.get(`/api/chat/listMessagesByGroup/${groupId}`);

      dispatch(set_group_messages(response.data.messages))

    } catch (error) {
      console.error('Failed to get group messages:', error);

      toast.error('Erro ao listar mensagens de grupo', {
        toastId: customId,
        autoClose: 3000,
      })
    }
  }

  const onEmojiClick = (emojiObject, event) => {
    setTextMessage((prevInput) => prevInput + emojiObject.emoji);
    setShowPicker(false);
  }

  const onGroupEmojiClick = (emojiObject, event) => {
    setGroupTextMessage((prevInput) => prevInput + emojiObject.emoji);
    setShowGroupPicker(false);
  }

  const scrollToBottom = () => {
    if (chatBodyRef.current) {
      chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
    }
  }

  const scrollToBottomGroup = () => {
    if (groupChatBodyRef.current) {
      groupChatBodyRef.current.scrollTop = groupChatBodyRef.current.scrollHeight;
    }
  }

  function updateEditedMessages(newItem) {
    setEditedMessages((prevItems) => {
      const existingItemIndex = prevItems.findIndex((item) => item.id === newItem.id);

      if (existingItemIndex !== -1) {
        return prevItems.map((item, index) =>
          index === existingItemIndex ? { ...item, text: newItem.text } : item
        );
      } else {
        return [...prevItems, newItem];
      }
    });
  }

  useEffect(() => {
    let clickableElements = document.querySelectorAll(
      '[data-target="chat-popup-modal"]'
    )

    clickableElements.forEach(function (clickableElement) {
      clickableElement.addEventListener("click", function () {
        let targetId = clickableElement.getAttribute("data-target")
        let targetElement = document.getElementById(targetId)
        if (targetElement) {
          targetElement.classList.add("show")
        }
      })
    })

    let closeBtn = document.querySelector(".chat-popup-modal-close")

    if (closeBtn) {
      closeBtn.addEventListener("click", function () {
        let chatModal = document.getElementById("chat-popup-modal")
        if (chatModal.classList.contains("show")) {
          chatModal.classList.remove("show")
        }
      })
    }
  })

  useEffect(() => {
    let clickableElements = document.querySelectorAll(
      '[data-target="group-chat-popup-modal"]'
    )

    clickableElements.forEach(function (clickableElement) {
      clickableElement.addEventListener("click", function () {
        let targetId = clickableElement.getAttribute("data-target")
        let targetElement = document.getElementById(targetId)
        if (targetElement) {
          targetElement.classList.add("show")
        }
      })
    })

    let closeBtn = document.querySelector(".group-chat-popup-modal-close")

    if (closeBtn) {
      closeBtn.addEventListener("click", function () {
        let chatModal = document.getElementById("group-chat-popup-modal")
        if (chatModal.classList.contains("show")) {
          chatModal.classList.remove("show")
        }
      })
    }
  })

  useEffect(() => {
    scrollToBottom()
  }, [messages])

  useEffect(() => {
    scrollToBottomGroup()
  }, [groupMessages])

  useEffect(() => {
    getFriends()
    getGroups()
    getNewFriends()
  }, [])

  useEffect(() => {
    socket.current = io(process.env.REACT_APP_CHAT_SOCKET_URL, {
      transports: ['polling', 'websocket'],
      withCredentials: true,
      query: {
        token: token,
        loged_user: loged_user,
        userStatus: userStatus
      },
    });

    socket.current.on('connect', () => {
      console.log('Connected to WebSocket');
      socket.current.emit('subscribe', `chat_workplace_user_${loged_user}`);
    });

    socket.current.on('disconnect', () => {
      console.log('Disconnected from WebSocket');
    });

    return () => {
      if (socket.current) {
        socket.current.disconnect();
      }
    };
  }, [socket, loged_user]);

  const sendMessageToChatGpt = async (message) => {
    setIsLoading(true);
    try {
      if (selectedMode === 'text') {

        const response = await api.post('/api/chatgpt', { message });

        const newMessage = await api.post(`/api/chat/store`, {
          message: textMessage,
          type: 'text',
          sender_id: loged_user,
          receiver_id: 0,
          is_first_message: messages.length === 0 ? true : false,
          readed: true, // Simulando que foi lida
        });

        const chatStore = await api.post(`/api/chat/store`, {
          message: response.data.message,
          type: 'text',
          sender_id: 0,
          receiver_id: loged_user,
          is_first_message: messages.length === 0 ? true : false,
          readed: true, // Simulando que foi lida
        });
        getMessages(0)

      } else if (selectedMode === 'image') {

        const response = await api.post('/api/chatgpt/generateImage', { prompt: message });

        const newMessage = await api.post(`/api/chat/store`, {
          message: textMessage,
          type: 'text',
          sender_id: loged_user,
          receiver_id: 0,
          is_first_message: messages.length === 0 ? true : false,
          readed: true, // Simulando que foi lida
        });

        await api.post('/api/chat/store', {
          message: response.data.image_url,
          type: 'image', // Define como imagem
          sender_id: 0,
          receiver_id: loged_user,
          is_first_message: false,
          readed: true,
        });
        getMessages(0)

      }
    } catch (error) {
      console.error('Failed to send message:', error);
    }
    setIsLoading(false);
  }

  const handleDownload = (src) => {
    saveAs(src, 'image.png');
  };

  const handleChatSelection = (friend) => {
    if (friend.name === "Chat GPT") {
      // Defina a lógica para o chat com a IA
      dispatch(set_selected_friend({ id: '0', name: 'Atmo AI' }));
      dispatch(set_friend_messages([])); // Limpa as mensagens anteriores ou pode carregar um histórico se necessário
    }
    setIsLoading(false)
  }

  useEffect(() => {
    const handleMessage = ({ friend_id, message, groupId, isFirstMessage }) => {
      console.log('mensagem recebida')
      dispatch(set_receive_new_messages(true))

      const selectedFriendId = selectedFriend ? String(selectedFriend.id) : null;
      const selectedGroupId = selectedGroup ? String(selectedGroup.id) : null;
      const messageFriendId = String(friend_id);
      const messageGroupId = String(groupId);

      if (groupId) {
        if (selectedGroupId === messageGroupId) {
          dispatch(set_group_messages([...groupMessages, message]))
        }

        setLastMessage(message, groups)
      } else if (!isFirstMessage) {
        if (selectedFriendId === messageFriendId) {
          readMessages(messageFriendId)
          dispatch(set_friend_messages([...messages, message]))
        }

        setLastMessage(message, friends)
      } else if (isFirstMessage) {
        dispatch(set_friend_messages([...messages, message]))
        getFriends()
      }
    }

    if (socket.current) {
      socket.current.on('newMessage', handleMessage);
    }

    return () => {
      if (socket.current) {
        socket.current.off('newMessage', handleMessage);
      }
    };
  }, [selectedFriend, messages, selectedGroup, groupMessages, friends, groups])

  useEffect(() => {
    const handleReadMessage = () => {
      marcarComoLidas(messages)
      console.log('mensagens lidas')
    }

    if (socket.current) {
      socket.current.on('readedMessage', handleReadMessage);
    }

    return () => {
      if (socket.current) {
        socket.current.off('readedMessage', handleReadMessage);
      }
    };
  }, [messages])

  useEffect(() => {
    const handleNewStatus = ({ friend_id, status }) => {

      changeStatus(friend_id, friends, status)

      getActiveUsers()
    }

    if (socket.current) {
      socket.current.on('newStatus', handleNewStatus);
    }

    return () => {
      if (socket.current) {
        socket.current.off('newStatus', handleNewStatus);
      }
    };
  }, [friends])

  useEffect(() => {
    const handleRemoveMessage = ({ message_id, is_group, chat_id }) => {
      setDeletedMessage([...deletedMessage, message_id])

      if (is_group) {
        const updatedChats = groups.map(chat => {
          if (chat.id == chat_id) {
            return { ...chat, latest_message: 'Mensagem apagada' };
          }
          return chat;
        });

        setGroups(updatedChats)
      } else {
        const updatedChats = friends.map(chat => {
          if (chat.id == chat_id) {
            return { ...chat, latest_message: 'Mensagem apagada' };
          }
          return chat;
        });

        setFriends(updatedChats)
      }
    }

    if (socket.current) {
      socket.current.on('removedMessage', handleRemoveMessage);
    }

    return () => {
      if (socket.current) {
        socket.current.off('removedMessage', handleRemoveMessage);
      }
    };
  }, [deletedMessage, friends, groups])

  useEffect(() => {
    const handleEditMessage = ({ message_id, new_message, is_group, chat_id }) => {
      updateEditedMessages({
        id: message_id,
        text: new_message
      })

      if (is_group) {
        const updatedChats = groups.map(chat => {
          if (chat.id == chat_id) {
            return { ...chat, latest_message: new_message };
          }
          return chat;
        });

        setGroups(updatedChats)
      } else {
        const updatedChats = friends.map(chat => {
          if (chat.id == chat_id) {
            return { ...chat, latest_message: new_message };
          }
          return chat;
        });

        setFriends(updatedChats)
      }
    }

    if (socket.current) {
      socket.current.on('editedMessage', handleEditMessage);
    }

    return () => {
      if (socket.current) {
        socket.current.off('editedMessage', handleEditMessage);
      }
    };
  }, [editedMessages, friends, groups])

  useEffect(() => {
    const handleUserWasBlocked = ({ blocker_id }) => {
      if (selectedFriend?.id === blocker_id) {
        checkIfUserWasBlocked(blocker_id)
      }
    }

    if (socket.current) {
      socket.current.on('wasBlocked', handleUserWasBlocked);
    }

    return () => {
      if (socket.current) {
        socket.current.off('wasBlocked', handleUserWasBlocked);
      }
    };
  }, [wasBlocked, selectedFriend])

  useEffect(() => {
    const chatBody = chatBodyRef.current;
    chatBody.addEventListener("scroll", handleScroll);

    const groupChatBody = groupChatBodyRef.current;
    groupChatBody.addEventListener("scroll", handleScrollGroup);
    return () => {
      chatBody.removeEventListener("scroll", handleScroll);
      groupChatBody.removeEventListener("scroll", handleScrollGroup);
    };
  }, []);

  const filteredFriends = useMemo(() => {
    const lowerSearch = search.toLowerCase()

    if (search === '') {
      return friends
    } else {
      return friends.filter((friend) => friend.name.toLowerCase().includes(lowerSearch) || friend.email.toLowerCase().includes(lowerSearch) || friend.id.toString().toLowerCase().includes(lowerSearch))
    }
  }, [search, friends])

  const filteredNewFriends = useMemo(() => {
    const lowerSearch = search.toLowerCase()

    if (search === '') {
      return newFiends
    } else {
      return newFiends.filter((newFriend) => newFriend.name.toLowerCase().includes(lowerSearch) || newFriend.email.toLowerCase().includes(lowerSearch) || newFriend.id.toString().toLowerCase().includes(lowerSearch))
    }
  }, [search, newFiends])

  const filteredGroups = useMemo(() => {
    const lowerSearch = search.toLowerCase()

    if (search === '') {
      return groups
    } else {
      return groups.filter((group) => group.name.toLowerCase().includes(lowerSearch))
    }
  }, [search, groups])



  const handleFocus = () => {
    setIsKeyboardOpen(true);
  };

  const handleBlur = () => {
    setIsKeyboardOpen(false);
  };

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 575);
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>
      <div className="right-sidebar-mini" id="rightSidebar">
        <div className="right-sidebar-panel p-0">
          <Card className="shadow-none m-0 h-100">
            <Card.Body className="px-0 pt-0">
              <div className="p-4">
                <div className="mt-4 iq-search-bar device-search div-btn-and-search">
                  <Button className="hide-btn-desk" onClick={minirightsidebar}>
                    <IoMdClose className="fs-3" />
                  </Button>
                  <Form action="#" className="searchbox position-relative">
                    <Link className="search-link" to="#">
                      <svg
                        width="16"
                        height="17"
                        viewBox="0 0 16 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          cx="7.82491"
                          cy="7.82495"
                          r="6.74142"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M12.5137 12.8638L15.1567 15.5"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </Link>
                    <Form.Control
                      type="text"
                      className="text search-input  bg-light-subtle"
                      placeholder="Procure pessoas ou grupos..."
                      onChange={(e) => setSearch(e.target.value)}
                    />
                  </Form>
                </div>
              </div>
              <Tab.Container defaultActiveKey="first">
                {/* <div className='tabs'> */}
                <Nav
                  className="nav-tabs right-sidebar-tabs"
                  id="right-sidebar-tabs"
                  role="tablist"
                >
                  <Nav.Link
                    eventKey={"first"}
                    className="text-center"
                    id="nav-friends-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-friends"
                    type="button"
                    role="tab"
                    aria-controls="nav-friends"
                    aria-selected="true"
                    style={{ 'display': 'flex', 'flexDirection': 'column' }}
                  >
                    <span className="material-symbols-outlined" style={{ color: "var(--bs-body-color)" }}>
                      sms
                    </span>{" "}
                    <span className="h6 font-size-14">Conversas</span>
                  </Nav.Link>

                  <Nav.Link
                    eventKey={"second"}
                    className="text-center"
                    id="nav-groups-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-groups"
                    type="button"
                    role="tab"
                    aria-controls="nav-groups"
                    aria-selected="false"
                    style={{ 'display': 'flex', 'flexDirection': 'column' }}
                  >
                    <span className="material-symbols-outlined" style={{ color: "var(--bs-body-color)" }}>
                      forum
                    </span>{" "}
                    <span className="h6 font-size-14">Grupos</span>
                  </Nav.Link>

                  <Nav.Link
                    eventKey={"third"}
                    className="text-center"
                    id="nav-groups-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-groups"
                    type="button"
                    role="tab"
                    aria-controls="nav-groups"
                    aria-selected="false"
                    style={{ 'display': 'flex', 'flexDirection': 'column' }}
                  >
                    <span className="material-symbols-outlined" style={{ color: "var(--bs-body-color)" }}>
                      chat_add_on
                    </span>{" "}
                    <span className="h6 font-size-14">Novos</span>
                  </Nav.Link>
                </Nav>
                {/* </div> */}
                <div className="media-height" data-scrollbar="init">
                  <Tab.Content className="right-sidebar-tabs-content">
                    <Tab.Pane eventKey="first">
                      <div
                        key={'chat_gpt-0'}
                        onClick={() => {
                          handleChatSelection({ id: '0', name: 'Chat GPT' })
                          setTextMessage('')
                          getMessages(0)
                          setwasBlocked(false)
                        }}
                        className="d-flex align-items-center justify-content-between chat-tabs-content border-bottom contact"
                        data-target="chat-popup-modal"
                      >
                        <div className="d-flex align-items-center gap-3">
                          <div className={`iq-profile-avatar status-online`}>
                            <img
                              className="rounded-circle avatar-50"
                              src={logoAtmoAi}
                              alt="user-img"
                              loading="lazy"
                            />
                          </div>
                          <div>
                            <h6 className="font-size-14 mb-0 fw-semibold">
                              Atmo AI   <MdAssistant style={{ fontSize: "20px", color: " RGBA(var(--bs-primary-rgb), var(--bs-bg-opacity, 1))" }} />
                            </h6>
                          </div>
                        </div>
                      </div>
                      {
                        filteredFriends.map(friend => {
                          let currentUserStatus = friend.chat_status

                          return (
                            <div
                              key={friend.id}
                              className="d-flex align-items-center justify-content-between chat-tabs-content border-bottom contact"
                              data-target="chat-popup-modal"
                              onClick={() => {
                                dispatch(set_selected_friend(friend))
                                checkIfUserWasBlocked(friend.id)
                                setTextMessage('')
                                getMessages(friend.id)
                                readMessages(friend.id)
                                readFriendMessages(friend.id, friends)
                              }}
                            >
                              <div className="d-flex align-items-center gap-3">
                                <div className={`iq-profile-avatar ${currentUserStatus === 'online' ? 'status-online' : currentUserStatus === 'away' ? 'status-away' : 'status-offline'}`}>
                                  <img
                                    className="rounded-circle avatar-50"
                                    src={friend.user_about.image_profile ?? userGenericImage}
                                    alt="user-img"
                                    loading="lazy"
                                  />
                                </div>
                                <div>
                                  <h6 className="font-size-14 mb-0 fw-semibold">
                                    {friend.name}
                                  </h6>
                                  <p className="mb-0 font-size-12 fw-medium">
                                    {friend.latest_message && /\.(jpg|jpeg|png|gif|bmp|webp)$/i.test(friend.latest_message) ? (
                                      <span className="image-text fst-italic d-flex align-items-center">
                                        <span class="material-symbols-outlined fs-6 me-1">
                                          image
                                        </span> Imagem
                                      </span>
                                    ) : (
                                      friend.latest_message ?? "Nova conversa..."
                                    )}
                                  </p>
                                </div>
                              </div>
                              <span className="font-size-12 fw-medium messages-counter-span">
                                {friend.latest_message_time ?? ''}
                                {
                                  friend.unread_messages > 0 ? (
                                    <div className="counter-container bg-primary">
                                      <span className="counter-number">{friend.unread_messages}</span>
                                    </div>
                                  ) : ''
                                }
                              </span>
                            </div>
                          )
                        })
                      }
                    </Tab.Pane>

                    <Tab.Pane eventKey={"second"}>
                      {
                        filteredGroups.map(group => (
                          <div
                            key={group.id}
                            className="d-flex align-items-center justify-content-between chat-tabs-content border-bottom"
                            data-target="group-chat-popup-modal"
                            onClick={() => {
                              dispatch(set_selected_group(group))
                              setwasBlocked(false)
                              setGroupTextMessage('')
                              getGroupMessages(group.id)
                              readGroupMessages(group.id, groups)
                            }}
                          >
                            <div className="d-flex align-items-center gap-3">
                              <div className="flex-shrink-0">
                                <img
                                  className="rounded-circle avatar-50"
                                  src={group.photo ?? userGenericImage}
                                  alt="user-img"
                                  loading="lazy"
                                />
                              </div>
                              <div>
                                <h6 className="font-size-14 mb-0 fw-semibold">
                                  {group.name}
                                </h6>
                                <p className="mb-0 font-size-12 fw-medium">
                                  {group.latest_message ?? "Nova conversa..."}
                                </p>
                              </div>
                            </div>
                            <span className="font-size-12 fw-medium messages-counter-span">
                              {group.latest_message_time ?? ''}
                              {
                                group.unread_messages > 0 ? (
                                  <div className="counter-container bg-primary">
                                    <span className="counter-number">{group.unread_messages}</span>
                                  </div>
                                ) : ''
                              }
                            </span>
                          </div>
                        ))
                      }
                    </Tab.Pane>

                    <Tab.Pane eventKey={"third"}>
                      {
                        filteredNewFriends.map(friend => {
                          let currentUserStatus = friend.chat_status

                          return (
                            <div
                              key={friend.id}
                              className="d-flex align-items-center justify-content-between chat-tabs-content border-bottom contact"
                              data-target="chat-popup-modal"
                              onClick={() => {
                                dispatch(set_selected_friend(friend))
                                setTextMessage('')
                                getMessages(friend.id)
                                readMessages(friend.id)
                                readFriendMessages(friend.id, friends)
                              }}
                            >
                              <div className="d-flex align-items-center gap-3">
                                <div className={`iq-profile-avatar ${currentUserStatus === 'online' ? 'status-online' : currentUserStatus === 'away' ? 'status-away' : 'status-offline'}`}>
                                  <img
                                    className="rounded-circle avatar-50"
                                    src={friend.user_about.image_profile ?? userGenericImage}
                                    alt="user-img"
                                    loading="lazy"
                                  />
                                </div>
                                <div>
                                  <h6 className="font-size-14 mb-0 fw-semibold">
                                    {friend.name}
                                  </h6>
                                </div>
                              </div>
                            </div>
                          )
                        })
                      }
                    </Tab.Pane>
                  </Tab.Content>
                </div>
              </Tab.Container>
              <div className="right-sidebar-toggle bg-primary text-white mt-3 d-flex">
                <span className="material-symbols-outlined">chat</span>
              </div>
              <div className="conversion-button">
                <Button
                  onClick={() => history('/chat/index')}
                  as="a"
                  className="btn btn-primary w-100 py-3 d-block rounded-0"
                >
                  Ver todas as conversas
                </Button>
              </div>
              <div className="right-sidebar-toggle bg-primary text-white mt-3 d-flex" onClick={minirightsidebar}>
                <span className="material-symbols-outlined">chat</span>
              </div>
            </Card.Body>
          </Card>
        </div>
      </div>

      <div className="chat-popup-modal div-chat-onmobile"
        id="chat-popup-modal"
        style={{
          height: isMobile ? (isKeyboardOpen ? '55%' : '100%') : '39rem',
        }}
      >
        <div className="bg-primary p-3 d-flex align-items-center justify-content-between gap-3">
          <div className="d-flex align-items-center gap-3">
            <div className="image flex-shrink-0">
              {selectedFriend && selectedFriend.id == '0' ?
                <img
                  src={selectedFriend?.user_about?.image_profile ?? logoAtmoAi}
                  alt="img"
                  className="img-fluid avatar-45 rounded-circle object-cover"
                /> : (
                  <img
                    src={selectedFriend?.user_about?.image_profile ?? userGenericImage}
                    alt="img"
                    className="img-fluid avatar-45 rounded-circle object-cover"
                  />
                )}
            </div>
            <div className="content">
              <h6 className="mb-0 font-size-14 text-white">{selectedFriend ? selectedFriend.name : ''}</h6>
              {selectedFriend && selectedFriend.id !== '0' &&
                <span className="d-inline-block lh-1 font-size-12 text-white">
                  <span className={`d-inline-block rounded-circle border-5 p-1 align-baseline me-1 ${selectedFriend && selectedFriend.chat_status === 'online' ? 'bg-success' : selectedFriend && selectedFriend.chat_status === 'away' ? 'bg-danger' : 'bg-gray'}`}></span>
                  {selectedFriend && selectedFriend.chat_status === 'online' ? 'Online' : selectedFriend && selectedFriend.chat_status === 'away' ? 'Ocupado' : 'Offline'}
                </span>
              }
              {selectedFriend && selectedFriend.id == '0' &&
                <span className="d-inline-block lh-1 font-size-12 text-white">
                  <span className={`d-inline-block rounded-circle border-5 p-1 align-baseline me-1 bg-success`}></span>
                  {'Online'}
                </span>
              }
            </div>
          </div>
          {selectedFriend && selectedFriend.id == '0' &&
            <div className='chat-header-icons d-inline-flex ms-auto'>
              <Dropdown
                onSelect={handleSelect}
                className='bg-primary-subtle d-flex align-items-center justify-content-center'
                as='span'
              >
                <Dropdown.Toggle as='svg' bsPrefix=' '
                  className='icon-20 nav-hide-arrow cursor-pointer pe-0'
                  id='dropdownMenuButton-09'
                  data-bs-toggle='dropdown'
                  aria-haspopup='true'
                  aria-expanded='false'
                  role='menu'
                  width='24'
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 24 24'
                  fill='none'
                >
                  <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M10 20.6788C10 21.9595 11.0378 23 12.3113 23C13.5868 23 14.6265 21.9595 14.6265 20.6788C14.6265 19.3981 13.5868 18.3576 12.3113 18.3576C11.0378 18.3576 10 19.3981 10 20.6788ZM10 12.0005C10 13.2812 11.0378 14.3217 12.3113 14.3217C13.5868 14.3217 14.6265 13.2812 14.6265 12.0005C14.6265 10.7198 13.5868 9.67929 12.3113 9.67929C11.0378 9.67929 10 10.7198 10 12.0005ZM12.3113 5.64239C11.0378 5.64239 10 4.60192 10 3.3212C10 2.04047 11.0378 1 12.3113 1C13.5868 1 14.6265 2.04047 14.6265 3.3212C14.6265 4.60192 13.5868 5.64239 12.3113 5.64239Z'
                    fill='currentColor'
                  ></path>
                </Dropdown.Toggle>
                <Dropdown.Menu
                  className='dropdown-menu-right'
                  aria-labelledby='dropdownMenuButton-09'
                >
                  <Dropdown.Item eventKey="text">Tipo de chat: Texto</Dropdown.Item>
                  <Dropdown.Item eventKey="image">Tipo de chat: Imagem</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          }

          <div className="chat-popup-modal-close lh-1" type="button" onClick={() => dispatch(set_selected_friend(null))}>
            <span className="material-symbols-outlined font-size-18 text-white">
              close
            </span>
          </div>
        </div>

        <div className="chat-popup-body p-3 border-bottom div-body-chat-onmobile"
          style={{
            height: isKeyboardOpen ? '100%' : '80%', // Reduz a altura do chat se o teclado está aberto
            overflowY: 'auto',
          }}
        >
          <ul className="ul-content-onmobile list-unstyled" ref={chatBodyRef}
            style={{
              height: isMobile ? (isKeyboardOpen ? '40vh' : '100%') : '28rem',
              overflowY: isMobile ? 'auto' : 'auto',
            }}
          >
            {messages?.map((msg) => {
              if (msg.sender_id == loged_user) {
                return (
                  <li className="mt-3 message-box" key={`message_${msg.id}`}>
                    <div className="text-end">
                      {
                        (msg.user_removed || deletedMessage.includes(msg.id)) ? (
                          <div className="d-inline-block py-2 px-3 bg-primary-subtle chat-popup-message message-right font-size-12 fw-medium" style={{ marginRight: '5px' }}>
                            <i className='mr-2 mb-0'>
                              Mensagem apagada
                            </i>
                          </div>
                        ) : editedMessages && editedMessages.some(message => message.id === msg.id) ? (
                          <div className="d-inline-block py-2 px-3 bg-primary-subtle chat-popup-message message-right font-size-12 fw-medium" style={{ marginRight: '5px' }}>
                            {editedMessages.find(message => message.id === msg.id).text}
                          </div>
                        ) : ( msg.type === 'text' ? (

                            <p className='mr-2 mb-0'>
                              {msg.message}
                            </p>
                          ) : (
                            <div className="d-inline-block py-2 px-3 bg-light chat-popup-message message-right font-size-12 fw-medium" style={{ marginRight: '5px' }}>
                            <PhotoProvider toolbarRender={({ rotate, onRotate, scale, onScale }) => (
                                    <div>
                                        <Button onClick={() => onRotate(rotate + 90)} style={{ cursor: 'pointer',  marginLeft: '10px', display: 'contents' }}>
                                            <span className="material-symbols-outlined font-size-18 icon-rtl" style={{ margin: '10px' }}>
                                            rotate_right
                                            </span>
                                        </Button>

                                        <Button onClick={() => onRotate(rotate - 90)} style={{ cursor: 'pointer',  marginLeft: '10px', display: 'contents' }}>
                                            <span className="material-symbols-outlined font-size-18 icon-rtl" style={{ margin: '10px' }} >
                                            rotate_left
                                            </span>
                                        </Button>

                                        <Button className="d-contents" onClick={() => onScale(scale - 0.1)} style={{ marginLeft: '10px', display: 'contents' }}>
                                        <span className="material-symbols-outlined font-size-18 icon-rtl" style={{ margin: '10px' }}>
                                            zoom_out
                                            </span>
                                        </Button>

                                        <Button onClick={() => onScale(scale + 0.1)} style={{ marginLeft: '10px', display: 'contents' }}>
                                        <span className="material-symbols-outlined font-size-18 icon-rtl" style={{ margin: '10px' }}>
                                            zoom_in
                                            </span>
                                        </Button>

                                        <Button className="d-contents" onClick={() => handleDownload(msg.message)} style={{ marginLeft: '10px', marginRight: '10px', display: 'contents' }}>
                                        <span className="material-symbols-outlined font-size-18 icon-rtl" style={{ margin: '10px' }}>
                                            file_download
                                            </span>
                                        </Button>
                                    </div>
                            )}>
                                <PhotoView src={msg.message} >
                                    <img src={msg.message} style={{ maxWidth: '100%', maxHeight: '200px', borderRadius: '8px' }} alt="" />
                                </PhotoView>
                                </PhotoProvider>
                        </div>
                          ))}

                      <IoCheckmarkDoneSharp style={{ color: msg.readed ? '#50B5FF' : '' }} />
                      <span className="mt-1 d-block time font-size-10 fst-italic">
                        {msg.formatted_created_at}
                      </span>
                    </div>
                  </li>
                );
              } else {
                return (
                  <li className="mt-2" key={`message_${msg.id}`}>
                    <div className="text-start">
                      {
                        (msg.user_removed || deletedMessage.includes(msg.id)) ? (
                          <div className="d-inline-block py-2 px-3 bg-gray-subtle chat-popup-message font-size-12 fw-medium">
                            <i className="mr-2 mb-0">Mensagem apagada</i>
                          </div>
                        ) : (editedMessages && editedMessages.some(message => message.id === msg.id)) ? (
                          <div className="d-inline-block py-2 px-3 bg-gray-subtle chat-popup-message font-size-12 fw-medium">
                            {editedMessages.find(message => message.id === msg.id).text}
                          </div>
                        ) : (
                          msg.type === 'text' && (
                            <div
                              className="d-inline-block py-2 px-3 chat-popup-message message-right font-size-12 fw-medium"
                              style={{ marginRight: '5px', backgroundColor: "grey", color: "white" }}
                            >
                              {msg.message}
                            </div>
                          )
                        )
                      }
                      {msg.type === 'image' && (
                        <div className="d-inline-block py-2 px-3 bg-light chat-popup-message message-right font-size-12 fw-medium" style={{ marginRight: '5px' }}>
                          {/* <img src={msg.message} alt="Generated" style={{ maxWidth: '100%', maxHeight: '200px', borderRadius: '8px' }} /> */}
                          <PhotoProvider toolbarRender={({ rotate, onRotate, scale, onScale }) => (
                            <div>
                              <Button onClick={() => onRotate(rotate + 90)} style={{ cursor: 'pointer', marginLeft: '10px', display: 'contents' }}>
                                <span className="material-symbols-outlined font-size-18 icon-rtl" style={{ margin: '10px' }}>
                                  rotate_right
                                </span>
                              </Button>

                              <Button onClick={() => onRotate(rotate - 90)} style={{ cursor: 'pointer', marginLeft: '10px', display: 'contents' }}>
                                <span className="material-symbols-outlined font-size-18 icon-rtl" style={{ margin: '10px' }} >
                                  rotate_left
                                </span>
                              </Button>

                              <Button className="d-contents" onClick={() => onScale(scale - 0.1)} style={{ marginLeft: '10px', display: 'contents' }}>
                                <span className="material-symbols-outlined font-size-18 icon-rtl" style={{ margin: '10px' }}>
                                  zoom_out
                                </span>
                              </Button>

                              <Button onClick={() => onScale(scale + 0.1)} style={{ marginLeft: '10px', display: 'contents' }}>
                                <span className="material-symbols-outlined font-size-18 icon-rtl" style={{ margin: '10px' }}>
                                  zoom_in
                                </span>
                              </Button>

                              <Button className="d-contents" onClick={() => handleDownload(msg.message)} style={{ marginLeft: '10px', marginRight: '10px', display: 'contents' }}>
                                <span className="material-symbols-outlined font-size-18 icon-rtl" style={{ margin: '10px' }}>
                                  file_download
                                </span>
                              </Button>
                            </div>
                          )}>
                            <PhotoView src={msg.message} >
                              <img src={msg.message} style={{ maxWidth: '100%', maxHeight: '200px', borderRadius: '8px' }} alt="" />
                            </PhotoView>
                          </PhotoProvider>
                        </div>
                      )}
                      <span className="mt-1 d-block time font-size-10 fst-italic">
                        {msg.formatted_created_at}
                      </span>
                    </div>
                  </li>
                );
              }
            })}
            {isLoading && (
              <li className="loading-message">
                <div className="text-primary" role="status">
                  <span className="sr-only">Carregando...</span>
                </div>
              </li>
            )}
          </ul>

          <button
            className={`scroll-to-bottom-btn btn btn-primary ${showScrollButton ? 'visible' : 'invisible'}`}
            onClick={scrollToBottom}
          >
            <FaArrowDown />
          </button>
        </div>

        <div className="chat-popup-footer p-3 chat-input-footer-onmobile">
          {showPicker && (
            <EmojiPicker style={{ position: 'absolute', bottom: '6rem', width: '90%', height: '400px' }} onEmojiClick={onEmojiClick} />
          )}
          <div className="chat-popup-form">
            <form
              className="d-flex"
              onSubmit={(e) => {
                e.preventDefault();
                sendMessage(e);
              }}
            >
              <button
                type="button"
                className="btn btn-primary hidebtnEmoji"
                onClick={(e) => {
                  e.preventDefault();
                  setShowPicker((val) => !val);
                }}
              >
                <MdEmojiEmotions />
              </button>
              <input
                type="text"
                className="form-control"
                disabled={wasBlocked}
                placeholder={
                  selectedFriend?.id === '0'
                    ? (selectedMode === "text"
                      ? "Mensagem Atmo Ai.."
                      : selectedMode === "image"
                        ? "Crie uma imagem.."
                        : "Digite aqui...")
                    : "Digite aqui..."
                }
                onChange={(e) => setTextMessage(e.target.value)}
                value={textMessage}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    sendMessage(e);
                  }
                }}
                onFocus={handleFocus} // Define foco para detectar teclado aberto
                onBlur={handleBlur}   // Define blur para detectar teclado fechado
              />
              <button
                type="submit"
                disabled={wasBlocked}
                className="chat-popup-form-button btn btn-primary"
              >
                <span className="material-symbols-outlined font-size-18 icon-rtl">
                  send
                </span>
              </button>
            </form>
          </div>
        </div>
      </div>

      <div className="group-chat-popup-modal div-chat-onmobile"
        id="group-chat-popup-modal"
        style={{
          height: isMobile ? (isKeyboardOpen ? '55%' : '100%') : '39rem',
        }}
      >
        <div className="bg-primary p-3 d-flex align-items-center justify-content-between gap-3">
          <div className="d-flex align-items-center gap-3">
            <div className="image flex-shrink-0">
              <img
                src={selectedGroup?.photo ?? userGenericImage}
                alt="img"
                className="img-fluid avatar-45 rounded-circle object-cover"
              />
            </div>
            <div className="content">
              <h6 className="mb-0 font-size-14 text-white">{selectedGroup ? selectedGroup.name : ''}</h6>
            </div>
          </div>
          <div className="group-chat-popup-modal-close lh-1" type="button" onClick={() => dispatch(set_selected_group(null))}>
            <span className="material-symbols-outlined font-size-18 text-white">
              close
            </span>
          </div>
        </div>

        <div className="chat-popup-body p-2 border-bottom div-body-chat-onmobile"
          style={{
            height: isKeyboardOpen ? '100%' : '80%', // Reduz a altura do chat se o teclado está aberto
            overflowY: 'auto',
          }}>
          <ul className="ul-content-onmobile list-unstyled" ref={groupChatBodyRef}
            style={{
              height: isMobile ? (isKeyboardOpen ? '40vh' : '100%') : '28rem',
              overflowY: isMobile ? 'auto' : 'auto',
            }}
          >
            {groupMessages?.map((msg) => {
              if (msg.sender_id == loged_user) {
                return (
                  <li className="mt-3 message-box" key={`message_${msg.id}`}>
                    <div className="text-end">
                      {
                        (msg.user_removed || deletedMessage.includes(msg.id)) ? (
                          <div className="d-inline-block py-2 px-3 bg-primary-subtle chat-popup-message message-right font-size-12 fw-medium" style={{ marginRight: '5px' }}>
                            <i className='mr-2 mb-0'>
                              Mensagem apagada
                            </i>
                          </div>
                        ) : editedMessages && editedMessages.some(message => message.id === msg.id) ? (
                          <div className="d-inline-block py-2 px-3 bg-primary-subtle chat-popup-message message-right font-size-12 fw-medium" style={{ marginRight: '5px' }}>
                            {editedMessages.find(message => message.id === msg.id).text}
                          </div>
                        ) : (
                          <div className="d-inline-block py-2 px-3 bg-primary-subtle chat-popup-message message-right font-size-12 fw-medium" style={{ marginRight: '5px' }}>
                            {msg.message}
                          </div>
                        )
                      }

                      <span className="mt-1 d-block time font-size-10 fst-italic">
                        {msg.formatted_created_at}
                      </span>
                    </div>
                  </li>
                );
              } else {
                return (
                  <li className="mt-2" key={`message_${msg.id}`}>
                    <div className="text-start">
                      {
                        (msg.user_removed || deletedMessage.includes(msg.id)) ? (
                          <div className="d-inline-block py-2 px-3 bg-gray-subtle chat-popup-message font-size-12 fw-medium">
                            <i className='mr-2 mb-0'>
                              Mensagem apagada
                            </i>
                          </div>
                        ) : editedMessages && editedMessages.some(message => message.id === msg.id) ? (
                          <div className="d-inline-block py-2 px-3 bg-gray-subtle chat-popup-message font-size-12 fw-medium">
                            {editedMessages.find(message => message.id === msg.id).text}
                          </div>
                        ) : (
                          <div className="d-inline-block py-2 px-3 bg-gray-subtle chat-popup-message font-size-12 fw-medium">
                            {msg.message}
                          </div>
                        )
                      }

                      <span className="mt-1 d-block time font-size-10 fst-italic">
                        {msg.name} - {msg.formatted_created_at}
                      </span>
                    </div>
                  </li>
                );
              }
            })}
          </ul>

          <button
            className={`scroll-to-bottom-btn btn btn-primary ${showGroupScrollButton ? 'visible' : 'invisible'}`}
            onClick={scrollToBottomGroup}
          >
            <FaArrowDown />
          </button>
        </div>

        <div className="chat-popup-footer p-3 chat-input-footer-onmobile">
          {showGroupPicker && (
            <EmojiPicker style={{ position: 'absolute', bottom: '6rem', width: '90%', height: '400px' }} onEmojiClick={onGroupEmojiClick} />
          )}
          <div className="chat-popup-form">
            <form
              className="d-flex"
              onSubmit={(e) => {
                e.preventDefault();
                sendMessage(e, selectedGroup?.id);
              }}
            >
              <button
                type="button"
                className="btn btn-primary hidebtnEmoji"
                onClick={(e) => {
                  e.preventDefault();
                  setShowGroupPicker((val) => !val);
                }}
              >
                <MdEmojiEmotions />
              </button>
              <input
                type="text"
                className="form-control"
                placeholder="Digite aqui..."
                onChange={(e) => setGroupTextMessage(e.target.value)}
                value={groupTextMessage}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    sendMessage(e, selectedGroup?.id);
                  }
                }}
                onFocus={handleFocus} // Define foco para detectar teclado aberto
                onBlur={handleBlur}   // Define blur para detectar teclado fechado
              />
              <button
                type="submit"
                className="chat-popup-form-button btn btn-primary"
              >
                <span className="material-symbols-outlined font-size-18 icon-rtl">
                  send
                </span>
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

export default RightSidebar
