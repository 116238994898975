import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Button, Card } from "react-bootstrap";
import { useNavigate } from 'react-router-dom'
import { Link } from "react-router-dom";
import api from '../../services/api'
import { toast } from 'react-toastify'
import Select from 'react-select'

const UsersCreate = () => {

    const [validated, setValidated] = useState(false);
    const customId = 'success-toast-id'
    const history = useNavigate()
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [password_confirmation, setPasswordConfirmation] = useState('')
    const [roles, setRoles] = useState([])
    const [rolesToAssociate, setRolesToAssociate] = useState([])


    const handleSubmit = async (e) => {
        e.preventDefault()

        const form = e.currentTarget;

        if (name === '' || email === '' || password === '' || password_confirmation === '') {
            toast.error('Por favor preencha todos os campos.', { autoClose: 3000 })
        } else if (email.indexOf('@') === -1) {
            toast.error('O campo email deve conter o caractere @.', { autoClose: 3000 })
        } else if (password !== password_confirmation) {
            toast.error('Senha e confirmação de senha não conferem!', { autoClose: 3000 })

            setPassword('')
            setPasswordConfirmation('')
        } else if (password && password.length < 6) {
            toast.error('A senha deve possuir no mínimo 6 caracteres.', { autoClose: 3000 })

            setPassword('')
            setPasswordConfirmation('')
        } else {
            setValidated(true);

            await api.post(`/api/user/store`, {
                name,
                email,
                password,
                password_confirmation,
                rolesToAssociate,

            }).then(() => {

                toast.success('Usuário Criado com sucesso!', {
                    toastId: customId,
                    autoClose: 300,
                    onClose: () => {
                        history('/users/index')
                    }
                });

            })
        }
    }

    useEffect(() => {
        let cancel = false

        async function fetchData() {

            await api.get(`api/role/index`).then(res => {
                if (cancel) return

                let rolesData = []

                res.data.roles.forEach(role => {
                    rolesData.push({
                        value: role.id,
                        label: role.name
                    })
                })

                setRoles(rolesData)
            })
        }

        fetchData()

        return () => {
            cancel = true
        }
    }, [])

    return (
        <>

            <div id='content-page' className='content-inner'>
                <Container>
                    <Row>
                        <Col sm="12">
                            <Card
                                className="position-relative inner-page-bg bg-primary p-5"
                            >
                                <div className="inner-page-title" style={{ display: "flex", justifyContent: "space-between", width: "-webkit-fill-available" }}>
                                    <h3 className="text-white">Usuários</h3>
                                    <Link to='/users/index'>
                                        <Button><i className="icon material-symbols-outlined d-flex">
                                            arrow_back
                                        </i></Button>
                                    </Link>
                                </div>
                            </Card>
                        </Col>
                        <Col sm="12" lg="12">
                            <Col sm="12" lg="12">
                                <Card>
                                    <Card.Header className="d-flex justify-content-between">
                                        <div className="header-title">
                                            <h4 className="card-title">Criar usuário</h4>
                                        </div>
                                    </Card.Header>
                                    <Card.Body className="pt-0">
                                        <Form noValidate onSubmit={handleSubmit} validated={validated} className="row g-3">
                                            <Col md="6" className="">
                                                <Form.Label
                                                    className="form-label"
                                                    htmlFor="validationCustom01"
                                                >
                                                    Nome
                                                </Form.Label>
                                                <Form.Control className='input-group-edit' type='text' placeholder='Nome' value={name} onChange={(e) => setName(e.target.value)} aria-describedby="inputGroupPrepend" id="validationCustom01" required />
                                                <Form.Control.Feedback>
                                                    Correto!
                                                </Form.Control.Feedback>
                                                <Form.Control.Feedback type="invalid">
                                                    Please choose a username.
                                                </Form.Control.Feedback>
                                            </Col>
                                            <Col md="6" className="">
                                                <Form.Label
                                                    className="form-label"
                                                    htmlFor="validationCustom02"
                                                >
                                                    E-mail
                                                </Form.Label>
                                                <Form.Control className='input-group-edit' type='email' placeholder='E-mail' value={email} onChange={(e) => setEmail(e.target.value)} id="validationCustom02" required />
                                                <Form.Control.Feedback>
                                                    Correto!
                                                </Form.Control.Feedback>
                                            </Col>
                                            <Col md="6" className="">
                                                <Form.Label
                                                    className="form-label"
                                                >
                                                    Senha
                                                </Form.Label>
                                                <div className="input-group">
                                                    <Form.Control className='input-group-edit' type='password' placeholder='Senha' value={password} onChange={(e) => setPassword(e.target.value)} />
                                                </div>
                                            </Col>
                                            <Col md="6" className="">
                                                <Form.Label
                                                    className="form-label"
                                                >
                                                    Confirmação de Senha
                                                </Form.Label>
                                                <div className="input-group">
                                                    <Form.Control className='input-group-edit' type='password' placeholder='Confirmar senha' value={password_confirmation} onChange={(e) => setPasswordConfirmation(e.target.value)} />
                                                </div>
                                            </Col>
                                            <Col md="6" className="">
                                                <Form.Label
                                                    className="form-label"
                                                >
                                                    Perfis
                                                </Form.Label>
                                                <div className="input-group">
                                                <Select className='input-group-edit w-100' value={rolesToAssociate} onChange={(e) => setRolesToAssociate(e)} options={roles} isMulti={true} />
                                                </div>
                                            </Col>

                                            <div className="col-12">
                                                <Button type="submit" variant="primary">Criar</Button>
                                            </div>
                                        </Form>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default UsersCreate