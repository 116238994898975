import React, { useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Col, Modal, Row, Dropdown } from "react-bootstrap";
import Plyr from "plyr-react"
import "plyr-react/plyr.css"
import { Link } from 'react-router-dom'
import api from '../services/api'
import { toast } from 'react-toastify'
import { set_handle_posts } from '../store/setting/actions'
import { formatDistanceToNow, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import Cookies from 'js-cookie'

const ShareOffcanvasNew = (props) => {

    const dispatch = useDispatch()
    const customId = 'success-toast-id'
    const [text, setText] = useState('')
    const [showSpinner, setShowSpinner] = useState(false)
    const [privacy, setPrivacy] = useState('public')
    const handlePosts = useSelector((state) => state.setting.setting.handle_posts)
    const logged_user = Cookies.get('userId')

    const handleSubmit = async (e) => {
        e.preventDefault()
        setShowSpinner(true)

        if (text === '') {
            setShowSpinner(false)
            toast.error('Por favor escreva um texto para a postagem.', { autoClose: 3000 })
        } else {
            const formData = new FormData()
            formData.append('text', text)
            formData.append('privacy', privacy)
            formData.append('post_id', props.post_id)
            formData.append('logged_user', logged_user)
            if (props.group_id) formData.append('group_id', props.group_id)

            await api.post(`/api/posts/share/store`, formData).then(() => {
                setShowSpinner(false)
                setText('')

                dispatch(set_handle_posts(!handlePosts))
                props.onHide()

                toast.success('Postagem criada com sucesso!', {
                    toastId: customId,
                    autoClose: 300,
                })
            }).catch(err => {
                setShowSpinner(false)
                toast.error('Erro ao publicar postagem.', { autoClose: 3000 })
            })
        }
    }

    const playerRef = useRef(null);
    const plyrProps = useMemo(() => ({
        source: {
            type: "video",
            sources: [
                {
                    src: props.new_media,
                    type: "video/mp4",
                }
            ]
        }
    }), [props.new_media]);

    return (
        <>
            <Modal
                {...props}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                size="lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <h3 className="modal-title" id="share-btnLabel">
                            Compartilhar
                        </h3>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="mt-4">
                    <Row className="gy-3">
                        <Col sm={12} className='special-post'>
                            <div id='post-modal-data' className={`card ${props.class}`}>
                                <div className='card-body'>
                                    <div className='d-flex align-items-center mb-5'>
                                        <form className='post-text w-100'>
                                            <input
                                                type='text'
                                                className='form-control rounded px-0'
                                                placeholder='Escreva e compartilhe sua postagem com seus amigos...'
                                                style={{ border: 'none' }}
                                                value={text}
                                                onChange={(e) => setText(e.target.value)}
                                            />
                                        </form>
                                    </div>
                                </div>
                                <div className='card card-block card-stretch card-height' style={{ border: '2px solid grey' }}>
                                    <div className='card-body'>
                                        <div className='user-post-data'>
                                            <div className='d-flex justify-content-between align-items-center'>
                                                <div className='me-3 flex-shrik-0'>
                                                    <img
                                                        className='border border-2 rounded-circle user-post-profile'
                                                        src={props.user_image}
                                                        alt=''
                                                    />
                                                </div>
                                                <div className='w-100'>
                                                    <div className='d-flex justify-content-between align-items-center'>
                                                        <div>
                                                            <h6 className='mb-0 d-inline-block'>{props.user_name}</h6>{' '}
                                                            <p className='mb-0'>{formatDistanceToNow(parseISO(props.post_time), { addSuffix: true, locale: ptBR })}</p>
                                                        </div>

                                                        <div className='card-post-toolbar d-flex'>
                                                            {
                                                                props.new_privacy === 'public' ? (
                                                                    <span className='material-symbols-outlined' style={{ 'marginRight': '10px' }}>
                                                                        public
                                                                    </span>
                                                                ) : props.new_privacy === 'friends' ? (
                                                                    <span className='material-symbols-outlined' style={{ 'marginRight': '10px' }}>
                                                                        group
                                                                    </span>
                                                                ) : ''
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='mt-4'>
                                                <p className='m-0'>
                                                    {props.text}
                                                </p>
                                            </div>
                                        </div>


                                        {props.new_type === 'image' ? (
                                            <div className='user-post mt-4 live-container'>
                                                <div className='ratio ratio-16x9' style={{ height: '100%' }}>
                                                    <img
                                                        src={props.new_media}
                                                        alt='post-images'
                                                        className='img-fluid rounded w-100'
                                                        loading='lazy'
                                                    />
                                                </div>
                                            </div>
                                        ) : props.new_type === 'video' ? (
                                            <div className='user-post mt-4 live-container'>
                                                <div className='ratio ratio-16x9' style={{ height: '100%' }}>
                                                    <div className='d-flex'>
                                                        <Plyr ref={playerRef} {...plyrProps} />
                                                    </div>
                                                </div>
                                            </div>

                                        ) : props.live_iframe ? (
                                            <div className=''>
                                                <div className='' style={{ height: '100%' }}>
                                                    <div dangerouslySetInnerHTML={{ __html: props.live_iframe }} style={{ height: '100%' }} />
                                                </div>
                                            </div>
                                        ) : ''
                                        }
                                    </div>
                                </div>
                                <div className='card-body bg-primary-subtle rounded-bottom-3'>
                                    <div className='d-flex align-items-center justify-content-between flex-wrap gap-3'>
                                        <div className='create-post-data'>
                                            <ul className='list-inline m-0 p-0 d-flex align-items-center gap-4'>
                                                {
                                                    !props.group_id ? (
                                                        <li className="icon-item">
                                                            <Dropdown>
                                                                <Dropdown.Toggle id='post-option' as='span' >
                                                                    {
                                                                        privacy === 'public' ? (
                                                                            <span className='material-symbols-outlined' style={{ 'fontSize': 'large', 'verticalAlign': 'middle' }}>
                                                                                public
                                                                            </span>
                                                                        ) : privacy === 'friends' ? (
                                                                            <span className='material-symbols-outlined' style={{ 'fontSize': 'large', 'verticalAlign': 'middle' }}>
                                                                                group
                                                                            </span>
                                                                        ) : ''
                                                                    }
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu className='m-0 p-0'>
                                                                    <Dropdown.Item className=' p-3' to='#' onClick={() => setPrivacy('public')}>
                                                                        <div className='d-flex align-items-top'>
                                                                            <span className='material-symbols-outlined' style={{ 'fontSize': 'large', 'verticalAlign': 'middle' }}>
                                                                                public
                                                                            </span>
                                                                            <div className='data ms-2'>
                                                                                <h6>pública</h6>
                                                                            </div>
                                                                        </div>
                                                                    </Dropdown.Item>

                                                                    <Dropdown.Item className=' p-3' to='#' onClick={() => setPrivacy('friends')}>
                                                                        <div className='d-flex align-items-top'>
                                                                            <span className='material-symbols-outlined' style={{ 'fontSize': 'large', 'verticalAlign': 'middle' }}>
                                                                                group
                                                                            </span>
                                                                            <div className='data ms-2'>
                                                                                <h6>amigos</h6>
                                                                            </div>
                                                                        </div>
                                                                    </Dropdown.Item>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </li>
                                                    ) : ''
                                                }
                                            </ul>

                                        </div>
                                        <div>
                                            <ul className='list-inline m-0 p-0 d-flex align-items-center gap-4'>
                                                <li>
                                                    <button disabled={showSpinner} type='button' className='btn btn-primary px-4' onClick={(e) => handleSubmit(e)}>
                                                        {showSpinner ? <div className="spinner-border spinner-border-sm" role="status"></div> : <div className='fw-semibold'>Publicar</div>}
                                                    </button>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </>
    );
};
export default ShareOffcanvasNew;
