import React from 'react'
import Index from '../views/index'
import PostDetail from "../views/posts/post-detail";

//app
import UserIndex from '../views/users/index'
import UserEdit from '../views/users/edit'
import UserCreate from '../views/users/create'
import MyProfile from '../views/users/my-profile'
import UserProfile from '../views/users/user-profile'
import UserPrivacySetting from '../views/users/user-privacy-setting'
import UserAccountSetting from '../views/users/user-account-setting'
import UserProfileEdit from '../views/users/user-profile-edit'
import FriendRequest from '../views/friends/friend-request'
import FriendList from '../views/friends/friend-list'
import MembersList from '../views/groups/members-list'
import FriendOfFriendList from '../views/friends/friend-of-friend-list'
import Groups from '../views/groups/groups'
import GroupDetail from '../views/groups/group-detail'
import GroupAdd from '../views/groups/group-add'
import Notification from '../views/notifications/notification'
import KnowledgeIndex from '../views/knowledge/index'
import KnowledgeList from '../views/knowledge/list'
import KnowledgeCreate from '../views/knowledge/create'
import KnowledgeEdit from '../views/knowledge/edit'
import KnowledgeShow from '../views/knowledge/show'
import StreamingsIndex from '../views/lives/index'
import StreamingsCreate from '../views/lives/create'
import StreamingsEdit from '../views/lives/edit'
import StreamingChart from  '../views/lives/chart'
import MemberRequest from '../views/groups/member-request'
import OrganogramaIndex from "../views/organograma";
import EquipesIndex from "../views/equipes/index";
import EquipesCreate from "../views/equipes/create";
import EquipesEdit from "../views/equipes/edit";
import PermissionIndex from "../views/permissions/index";
import PermissionsCreate from "../views/permissions/create";
import RoleIndex from "../views/roles/index";
import RoleCreate from "../views/roles/create";
import RoleEdit from "../views/roles/edit";
import EventIndex from "../views/events/index";
import EventCreate from "../views/events/create";
import EventEdit from "../views/events/edit";
import ActivityLog from "../views/activitylogs/index";
import Chat from '../views/chat/chat';
import QuizIndex from '../views/quiz/index';
import QuizCreate from '../views/quiz/create';
import PollIndex from '../views/polls';
import PollCreate from '../views/polls/create';
import PollEdit from '../views/polls/edit';
import PollChart from '../views/polls/chart';
import QuizEdit from '../views/quiz/edit';
import QuizChart from '../views/quiz/chart'
import VodsIndex from '../views/vods/index';
import VodsShow from '../views/vods/show';

export const DefaultRouter = [
  {
    path: '/',
    element: <Index />,
  },
  {
    path: "/posts/post-detail/:id",
    element: <PostDetail />,
  },
  {
    path: "/posts/post-detail/:id",
    element: <PostDetail />,
  },
  {
    path: 'users/my-profile',
    element: <MyProfile />,
  },
  {
    path: 'chat/index',
    element: <Chat />,
  },
  {
    path: 'users/profile/:id',
    element: <UserProfile />,
  },
  {
    path: 'users/index',
    element: <UserIndex />,
  },
  {
    path: 'users/edit/:id',
    element: <UserEdit />,
  },
  {
    path: 'users/create',
    element: <UserCreate />,
  },
  {
    path: 'users/user-privacy-setting',
    element: <UserPrivacySetting />,
  },
  {
    path: 'notifications/notification',
    element: <Notification />,
  },
  {
    path: 'friends/friend-request',
    element: <FriendRequest />,
  },
  {
    path: 'users/user-account-setting',
    element: <UserAccountSetting />,
  },
  {
    path: 'users/user-profile-edit',
    element: <UserProfileEdit />,
  },
  {
    path: 'groups/groups',
    element: <Groups />,
  },
  {
    path: 'groups/members/:id',
    element: <MembersList />,
  },
  {
    path: 'groups/membersRequests/:id',
    element: <MemberRequest />,
  },
  {
    path: 'groups/group-detail/:id',
    element: <GroupDetail />,
  },
  {
    path: 'group/create',
    element: <GroupAdd />,
  },
  {
    path: 'friends/friend-list',
    element: <FriendList />,
  },
  {
    path: 'friends/friend-of-friend-list/:id',
    element: <FriendOfFriendList />,
  },
  {
    path: 'knowledge/index',
    element: <KnowledgeIndex />,
  },
  {
    path: 'knowledge/list',
    element: <KnowledgeList />,
  },
  {
    path: 'knowledge/create',
    element: <KnowledgeCreate />,
  },
  {
    path: 'knowledge/edit/:id',
    element: <KnowledgeEdit />,
  },
  {
    path: 'knowledge/show/:id',
    element: <KnowledgeShow />,
  },
  {
    path: 'streaming/index',
    element: <StreamingsIndex />,
  },
  {
    path: 'streaming/create',
    element: <StreamingsCreate />,
  },
  {
    path: 'streaming/edit/:id',
    element: <StreamingsEdit />,
  },
  {
    path: 'streaming/chart/:id',
    element: <StreamingChart />,
  },
  {
    path: "organograma/index",
    element: <OrganogramaIndex />,
  },
  {
    path: "equipes/index",
    element: <EquipesIndex />,
  },
  {
    path: "equipes/create",
    element: <EquipesCreate />,
  },
  {
    path: "equipes/edit/:id",
    element: <EquipesEdit />,
  },
  {
    path: "permissions/index",
    element: <PermissionIndex />,
  },
  {
    path: "permissions/create",
    element: <PermissionsCreate />,
  },
  {
    path: "roles/index",
    element: <RoleIndex />,
  },
  {
    path: "roles/create",
    element: <RoleCreate />,
  },
  {
    path: "roles/edit/:id",
    element: <RoleEdit />,
  },
  {
    path: "events/index",
    element: <EventIndex />,
  },
  {
    path: "events/create",
    element: <EventCreate />,
  },
  {
    path: "events/edit/:id",
    element: <EventEdit />,
  },
  {
    path: "activitylogs/index",
    element: <ActivityLog />,
  },
  {
    path: "quiz/index",
    element: <QuizIndex />,
  },
  {
    path: "quiz/create",
    element: <QuizCreate />,
  },  
  {
    path: "polls/index",
    element: <PollIndex />,
  },
  {
    path: "polls/create",
    element: <PollCreate />,
  },
  {
    path: "polls/edit/:poll_id",
    element: <PollEdit />,
  },
  {
    path: "poll/chart/:id",
    element: <PollChart />,
  },
  {
    path: "quiz/edit/:id",
    element: <QuizEdit />,
  },
  {
    path: "quiz/chart/:id",
    element: <QuizChart />,
  },
  {
    path: "vods/index",
    element: <VodsIndex />,
  },
  {
    path: "vods/show/:vodId",
    element: <VodsShow />,
  },
];
