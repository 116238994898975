import React, { useState } from 'react'
import { Row, Col, Container, Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom"
import Cookies from 'js-cookie'
import api from '../../services/api'
import { TbLockX } from "react-icons/tb";
import { Alert } from 'reactstrap'
import { encryptToken } from '../../helpers/cryptoHelpers';
import './style-sign-in.css'
//swiper
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore from "swiper";
import { Navigation, Autoplay } from "swiper/modules";

// Import selectors & action from setting store
import * as SettingSelector from "../../store/setting/selectors";
// Redux Selector / Action
import { useSelector } from "react-redux";


//img
import atmoLogo from '../../assets/images/atmo logo.png'
import atmoLogo2 from '../../assets/images/atmo_midia_logo_branco.png'


// install Swiper modules
SwiperCore.use([Navigation, Autoplay]);


export function setAuthToken(token) {
  if (token) {
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    delete api.defaults.headers.common['Authorization'];
  }
}


const SignIn = () => {
  const appName = useSelector(SettingSelector.app_name);

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [showSpinner, setShowSpinner] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate()

  const handleLogin = async (e) => {
    e.preventDefault()
    setShowSpinner(true);

    try {
        const response = await api.post('/api/auth/login', {
            email,
            password,
        })

        const token = response.data.token
        const encryptedToken = encryptToken(token);

        Cookies.set("token", encryptedToken, { expires: 7, secure: true, sameSite: "None" });
        Cookies.set("userId", response.data.user.id, { expires: 7, secure: true, sameSite: "None" });
        Cookies.set("userName", response.data.user.name, { expires: 7, secure: true, sameSite: "None" });
        Cookies.set("userStatus", 'online', { expires: 7, secure: true, sameSite: "None" });
        

        await api.put(`/api/users/changeStatus`, {
          logged_user: response.data.user.id,
          status: 'online',
        })

        navigate("/")

        if ('serviceWorker' in navigator) {
          navigator.serviceWorker.register('/service-worker.js')
              .then(function (registration) {
                  console.log('Service Worker registrado com sucesso com escopo:', registration.scope);
                  Notification.requestPermission().then(permission => {
                      if (permission === "granted") {
                          console.log('Permissão concedida para notificações');
                          registration.pushManager.getSubscription().then(subscription => {
                              if (!subscription) {
                                  registration.pushManager.subscribe({
                                      userVisibleOnly: true,
                                      applicationServerKey: 'BNmnE6lb-4s8eKpZYLCjMRXh38RqNuzC2CG9-6sx1Rck7urYJg_WLc86ObpzSskVrlWsvSR6tyALSm_6JRm0yMs'
                                  }).then(subscription => {
                                      console.log('Subscrição obtida:', subscription);
                                      // Enviar a subscrição para o servidor
                                      api('/api/save-subscription', {
                                          method: 'POST',
                                          headers: {
                                              'Content-Type': 'application/json'
                                          },
                                          data: subscription
                                      })
                                          .then(response => response)
                                          .then(data => console.log('Resposta do servidor:', data))
                                          .catch(error => console.error('Erro ao enviar subscrição:', error));
                                  }).catch(error => console.error('Erro ao registrar para push:', error));
                              }
                          }).catch(error => console.error('Erro ao verificar subscrição existente:', error));
                      } else {
                          console.log('Permissão para notificações não concedida');
                      }
                  });
              })
              .catch(error => {
                  console.log('Falha no registro do Service Worker:', error);
              });
        } else {
          console.log('Service Worker não é suportado neste navegador.');
        }

    } catch (error) {
        if (error.response && error.response.status === 401) {
            setError("E-mail ou senha incorretos.");
        } else {
            setError("Erro ao fazer login.");
        }
        console.error('Erro ao fazer login:', error)
    } finally {
        setShowSpinner(false);
    }
}
  

  return (
    <>
      <section className="sign-in-page">
        <Container fluid>
          <Row className="align-items-center">
            <Col md={6} className="overflow-hidden position-relative col-hide-onMobile">
              <div className="bg-primary w-100 h-100 position-absolute top-0 bottom-0 start-0 end-0"></div>
              <div className="container-inside z-1">
                <div className="main-circle circle-small"></div>
                <div className="main-circle circle-medium"></div>
                <div className="main-circle circle-large"></div>
                <div className="main-circle circle-xlarge"></div>
                <div className="main-circle circle-xxlarge"></div>
              </div>
              <div className="sign-in-detail container-inside-top">
              <Swiper
                  className="list-inline m-0 p-0 "
                  spaceBetween={30}
                  centeredSlides={true}
                  loop={true}
                  autoplay={{
                    delay: 5000,
                    disableOnInteraction: false,
                  }}
                >
                  <ul className="swiper-wrapper list-inline m-0 p-0 ">
                    <SwiperSlide>
                    <img
                     style={{ width:"250px" }}
                        src={atmoLogo2}
                        className="img-fluid mb-2"
                        alt="images"
                      />
                      <p className="mt-3 text-white fw-semibold">
                        Bem-vindo à plataforma que conecta talentos, transforma a comunicação e fortalece sua empresa.
                      </p>
                    </SwiperSlide>
                    <SwiperSlide>
                    <img
                     style={{ width:"250px" }}
                        src={atmoLogo2}
                        className="img-fluid mb-2"
                        alt="images"
                      />
                      <p className="mt-3 text-white fw-semibold">
                        Conecte-se com sua equipe, colabore em tempo real e compartilhe ideias que impulsionam a produtividade.
                      </p>
                    </SwiperSlide>
                    <SwiperSlide>
                    <img style={{ width:"250px" }}
                        src={atmoLogo2}
                        className="img-fluid mb-2"
                        alt="images"
                      />
                      <p className="mt-3 text-white fw-semibold">
                        A rede social corporativa que une equipes, projetos e pessoas em um só lugar.
                      </p>
                    </SwiperSlide>
                  </ul>
                </Swiper>
              </div>
            </Col>
            <Col md={6}>
              <div className="sign-in-from text-center">
                <Link
                  to="/auth/sign-in"
                  className="d-inline-flex align-items-center justify-content-center gap-2"
                >
                  <img style={{ width:50 }} src={atmoLogo} alt='Logo Atmo' ></img>
                  <h2 className="logo-title" data-setting="app_name">
                    {appName}
                  </h2>
                </Link>
                <p className="mt-3 font-size-16">
                  Bem vindo ao {appName}.
                </p>
                {error && <Alert className='alertClass d-flex align-items-center' color="danger"><TbLockX className='fs-5 marginAlertClass' />{error}</Alert>}
                <Form onSubmit={handleLogin} className="mt-5">
                  <Form.Group className="form-group text-start">
                    <h6 className="form-label fw-bold">
                      E-mail
                    </h6>
                    <Form.Control
                      type="email"
                      className="form-control mb-0"
                      placeholder="E-mail"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="form-group text-start">
                    <h6 className="form-label fw-bold">Senha</h6>
                    <Form.Control
                      type="password"
                      className="form-control mb-0"
                      placeholder="Senha"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </Form.Group>
                  <div className="d-flex align-items-center justify-content-between">
                    <Link to="/auth/recoverpw" className="font-italic">
                      Esqueceu a senha?
                    </Link>
                  </div>
                  {/* <Link type="button" className="btn btn-primary mt-4 fw-semibold text-uppercase w-100" to="#">sign in</Link> */}
                  <Button
                    variant="primary"
                    type="submit"
                    className="btn btn-primary mt-4 fw-semibold text-uppercase w-100"
                    disabled={showSpinner}
                  >
                   {showSpinner ? <div className="spinner-border spinner-border-sm" role="status"></div> : <div className='fw-semibold'>Entrar</div>}
                  </Button>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default SignIn;
