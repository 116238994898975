import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Form, Container, Button, Card } from "react-bootstrap";
import { useNavigate, Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import api from '../../services/api'
import Quill from 'quill';
import 'quill/dist/quill.snow.css';
import ImageResize from 'quill-image-resize';
Quill.register('modules/imageResize', ImageResize);


const KnowledgeEdit = () => {

    const [editor, setEditor] = useState(null);
    const editorRef = useRef(null);
    const editorCreated = useRef(false);
    const [formData, setFormData] = useState({
        title: '',
        content: ''
    });
    let { id } = useParams();
    const history = useNavigate();


    useEffect(() => {
        if (editorRef.current && !editorCreated.current) {
            const quill = new Quill(editorRef.current, {
                theme: 'snow',
                modules: {
                    toolbar: [
                        ['bold', 'italic', 'underline', 'strike'],      
                        ['blockquote', 'code-block'],
                        ['link', 'image', 'video'],

                        [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'list': 'check' },  { align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],

                        [{ 'size': [] }],  

                        [{ 'color': [] }, { 'background': [] }],      
                        [{ 'font': [] }],

                        ['clean']                                      
                    ],
                    imageResize: {
                        modules: ['Resize', 'DisplaySize', 'Toolbar'],
                        handleStyles: {
                            backgroundColor: 'black',
                            border: 'none',
                            color: 'white',
                            width: '10px',
                            height: '10px'
                        },
                        displayStyles: {
                            backgroundColor: 'gray',
                            border: '1px dashed black'
                        },
                        resizeMode: 'cover',
                        parchment: Quill.import('parchment')
                    }
                }
            });
            editorCreated.current = true;
            setEditor(quill);
        }
    }, []);




    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await api.post(`/api/knowledge/edit/${id}`)
                const { title, content } = response.data.knowledge;
                setFormData({ title, content });
                if (editor) {
                    editor.clipboard.dangerouslyPasteHTML(content);
                }
            } catch (error) {
                console.error('Failed to fetch knowledge data:', error);
            }
        };
        fetchData()
    }, [editor]);

    const handleChange = e => {
        const { name, value } = e.target;
        setFormData(prev => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const content = editor.root.innerHTML;
        const data = { ...formData, content };

        try {
            await api.post(`/api/knowledge/update/${id}`, data);
            toast.success('Conhecimento editado com sucesso!');
            history('/knowledge/index');
        } catch (error) {
            toast.error('Erro ao editar conhecimento!');
            console.error('Erro ao enviar dados:', error);
        }
    };

    return (
        <>
            <div id='content-page' className='content-inner'>
                <Container>
                    <Row>
                        <Col sm="12">
                            <Card className="position-relative inner-page-bg bg-primary p-5">
                                <div className="inner-page-title" style={{ display: "flex", justifyContent: "space-between", width: "-webkit-fill-available" }}>
                                    <h3 className="text-white">Conhecimentoteca</h3>
                                    <Link to='/knowledge/index'>
                                        <Button><i className="icon material-symbols-outlined d-flex">
                                            arrow_back
                                        </i></Button>
                                    </Link>
                                </div>
                            </Card>
                        </Col>
                        <Col sm="12" lg="12">
                            <Card>
                                <Card.Header className="d-flex justify-content-between">
                                    <h4 className="card-title">Editar biblioteca de Conhecimento</h4>
                                </Card.Header>
                                <Card.Body className="pt-0">
                                    <Form noValidate onSubmit={handleSubmit} className="row g-3">
                                        <Col md="12">
                                            <Form.Label className="form-label" htmlFor="title">Título</Form.Label>
                                            <Form.Control name='title' className='input-group-edit' value={formData.title} type='text' placeholder='Título' onChange={handleChange} />
                                        </Col>
                                        <Col md="12">
                                            <Form.Label className="form-label">Conteúdo</Form.Label>
                                            <div style={{ height: '10rem' }} ref={editorRef}/>
                                        </Col>
                                        <div className="col-12">
                                            <Button type="submit" variant="primary">Salvar</Button>
                                        </div>
                                    </Form>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default KnowledgeEdit;