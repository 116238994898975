import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'

// img
import error404Light from "../../assets/images/atmo logo.png";
import error404 from "../../assets/images/atmo logo.png";



const Error404 = () => {

   return (
      <>
         <Container className="p-0">
            <Row className=" no-gutters vh-100">
               <Col className="col-12 text-center align-self-center">
                  <div className="iq-error position-relative mt-5">
                     <img src={error404Light} style={{ width: '250px' }} className="img-fluid iq-error-img img-light center" alt="light-img" />
                     <img src={error404} style={{ width: '250px' }} className="img-fluid iq-error-img img-dark center" alt="dark-img" />
                     <h2 className="mb-0 mt-5 text-center">Ops! Esta página não foi encontrada.</h2>
                     <p className="text-center">A página solicitada não existe.</p>
                  </div>
               </Col>
            </Row>
         </Container>
      </>
   )
}

export default Error404;
