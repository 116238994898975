import React, { useState } from 'react'
import Cookies from 'js-cookie'
import api from '../../services/api'
import { Dropdown, Button, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { toast } from 'react-toastify'
import AnimationWarningLottie from '../AnimationWarningDeleteConfim/AnimationWarningLottie'
import user3 from '../../assets/images/user/03.jpg'
import { formatDistanceToNow, parseISO } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import like_btn from '../../assets/images/icon/like_btn.png'
import love_btn from '../../assets/images/icon/love_btn.png'
import haha_btn from '../../assets/images/icon/haha_btn.png'
import wow_btn from '../../assets/images/icon/wow_btn.png'
import angry_btn from '../../assets/images/icon/angry_btn.png'
import sad_btn from '../../assets/images/icon/sad_btn.png'
import { useDispatch } from 'react-redux'
import { set_handle_comments } from '../../store/setting/actions'
import ShowReactionCommentsModal from '../../components/Reactions/modalShowReactionsComments';

const Index = ({ comment }) => {
  const dispatch = useDispatch()
  const [modalCommentShow, setModalCommentShow] = useState(false)
  const logged_user = Cookies.get('userId')
  const [isEditingComment, setIsEditingComment] = useState(null)
  const [editedCommentText, setEditedCommentText] = useState(comment.text)
  const [newReaction, setNewReaction] = useState(comment.user_reaction)
  const [newReactionsCounter, setNewReactionsCounter] = useState(comment.reaction_counter)
  const [reactionsModalShow, setReactionsModalShow] = useState(false)

  const commentDelete = async (comment_id) => {
    await api.post(`/api/comments/delete/${comment_id}`).then(() => {
      setModalCommentShow(false)
      dispatch(set_handle_comments(comment.post_id))
      toast.success('Comentario deletado com sucesso.', { autoClose: 1000 })
    }).catch(err => toast.error('Erro ao deletar comentario', { autoClose: 3000 }))
  }

  const startEditingComment = (comment) => {
    setIsEditingComment(comment.id)
  }

  const updateComment = async (comment_id) => {
    await api.post(`/api/comments/update/${comment_id}`, {
      newComment: editedCommentText,
      logged_user,
    }).then(() => {
      toast.success('Comentário editado com sucesso.', { autoClose: 1000 })
      setIsEditingComment(null)
    }).catch(err => toast.error('Erro ao editar comentário', { autoClose: 3000 }))
  }

  function DeleteCommentConfirmationModal(props) {
    return (
      <Modal
        {...props}
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header id='modalHeader' closeButton>
          <div id='divModalTitle'>
            <Modal.Title id='modalTitle'>
              Exclusão de comentário
            </Modal.Title>
          </div>
        </Modal.Header>
        <Modal.Body id='modalBody'>
          <div className='d-flex justify-content-center'>
            <AnimationWarningLottie />
          </div>
          <div className='d-flex justify-content-center'>
            <p>
              Você tem certeza de que deseja remover esse comentário ?
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer id='modalFooter'>
          <Button id='yesButton' onClick={() => commentDelete(props.show)}>Sim</Button>
          <Button id='noButton' onClick={props.onHide}>Não</Button>
        </Modal.Footer>
      </Modal>
    )
  }

  const sendReaction = async (reaction) => {
    await api.post(`/api/reactionComment/store`, {
      comment_id: comment.id,
      reaction
    }).catch(err => toast.error('Erro ao reagir post', { autoClose: 3000 }))
  }

  const removeReaction = async () => {
    setNewReactionsCounter(prev => prev - 1)
    setNewReaction('')
    await api.delete(`/api/reactionComment/destroy/${comment.id}`).catch(err => toast.error('Erro ao deletar reação', { autoClose: 3000 }))
  }

  const handleReaction = (reaction) => {
    if (!newReaction) {
      setNewReactionsCounter(prev => prev + 1)
    }

    setNewReaction(reaction)
    sendReaction(reaction)
  }

  const openModalReaction = () => {
    setReactionsModalShow(true);
  };

  return (
    <>
      <DeleteCommentConfirmationModal
        show={modalCommentShow}
        onHide={() => setModalCommentShow(false)}
      />

      <ShowReactionCommentsModal
        comment_id={comment.id}
        showModalReactionComments={reactionsModalShow}
        setShowModalReactionComments={setReactionsModalShow}
        onHide={() => setReactionsModalShow(false)}
      />

      <ul className='list-inline m-o p-0 comment-list'>
        <li className='mb-3'>
          <div className='comment-list-block'>
            <div className='d-flex align-items-center gap-3'>
              <div className='comment-list-user-img flex-shrink-0'>
                <img
                  src={comment.image_profile ?? user3}
                  alt='userimg'
                  className='avatar-48 rounded-circle img-fluid'
                  loading='lazy'
                />
              </div>
              <div className='comment-list-user-data'>
                <div className='d-inline-flex align-items-center gap-1 flex-wrap'>
                  <h6 className='m-0'>{comment.name}</h6>
                  <span className='d-inline-block text-primary'>
                    <svg
                      className='align-text-bottom'
                      xmlns='http://www.w3.org/2000/svg'
                      width='17'
                      height='17'
                      viewBox='0 0 17 17'
                      fill='none'
                    >
                      <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M12.2483 0.216553H4.75081C2.13805 0.216553 0.5 2.0665 0.5 4.68444V11.7487C0.5 14.3666 2.13027 16.2166 4.75081 16.2166H12.2475C14.8689 16.2166 16.5 14.3666 16.5 11.7487V4.68444C16.5 2.0665 14.8689 0.216553 12.2483 0.216553Z'
                        fill='currentColor'
                      />
                      <path
                        d='M5.5 8.21627L7.50056 10.216L11.5 6.21655'
                        stroke='white'
                        strokeWidth='1.5'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                    </svg>
                  </span>
                  <span className='fw-medium small text-capitalize'>
                    {formatDistanceToNow(parseISO(comment.created_at), { addSuffix: true, locale: ptBR })}
                  </span>

                  <div className='card-post-toolbar'>
                    {
                      comment.user_id == logged_user ? (
                        <Dropdown>
                          <Dropdown.Toggle id='post-option' as='span' >
                            <span className='material-symbols-outlined'>more_horiz</span>
                          </Dropdown.Toggle>
                          <Dropdown.Menu className='m-0 p-0'>
                            <Dropdown.Item className=' p-3' to='#' onClick={() => startEditingComment(comment)}>
                              <div className='d-flex align-items-top'>
                                <span className='material-symbols-outlined'>
                                  edit
                                </span>
                                <div className='data ms-2'>
                                  <h6>Editar</h6>
                                </div>
                              </div>
                            </Dropdown.Item>
                            <Dropdown.Item className=' p-3' to='#' onClick={() => setModalCommentShow(comment.id)}>
                              <div className='d-flex align-items-top'>
                                <span className='material-symbols-outlined'>
                                  delete
                                </span>
                                <div className='data ms-2'>
                                  <h6>Deletar</h6>
                                </div>
                              </div>
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      ) : ''
                    }
                  </div>

                  <span
                    className='text-capitalize font-size-14 fw-medium'
                    type='button'
                    data-bs-toggle='modal'
                    data-bs-target='#likemodal'
                  >
                    {
                      newReactionsCounter > 0 ? (
                        <div>
                          <img src={like_btn} className='img-fluid me-2' alt='' style={{ 'height': '25px', 'width': '25px' }} onClick={openModalReaction} /> {newReactionsCounter}
                        </div>
                      ) : ''
                    }
                  </span>
                </div>
              </div>
            </div>
            <div className='comment-list-user-comment'>
              {isEditingComment === comment.id ? (
                <div>
                  <input
                    type='text'
                    className='form-control'
                    value={editedCommentText}
                    onChange={(e) => setEditedCommentText(e.target.value)}
                  />
                  <Button className='btn btn-primary mt-2' style={{ marginRight: '5px' }} onClick={() => updateComment(comment.id)}>Salvar</Button>
                  <Button className='btn btn-danger mt-2' onClick={() => {
                    setIsEditingComment(null)
                    setEditedCommentText(comment.text)
                  }}>Cancelar</Button>
                </div>
              ) : (
                <div className='comment-list-comment'>
                  {editedCommentText}
                </div>
              )}

              <div className='comment-list-action mt-2'>
                <ul className='list-inline m-0 p-0 d-flex align-items-center gap-2'>
                  <li>
                    <div className='like-block position-relative d-flex align-items-center flex-shrink-0'>
                      <div className='like-data'>
                        <div className='dropdown'>
                          <span
                            className='dropdown-toggle'
                            data-bs-toggle='dropdown'
                            aria-haspopup='true'
                            aria-expanded='false'
                            role='button'
                          >
                            {
                              !newReaction ? (
                                <span className='material-symbols-outlined align-text-top font-size-20'>
                                  thumb_up
                                </span>
                              ) : (
                                <img src={newReaction === 'like' ? like_btn : newReaction === 'love' ? love_btn : newReaction === 'haha' ? haha_btn : newReaction === 'wow' ? wow_btn : newReaction === 'sad' ? sad_btn : newReaction === 'angry' ? angry_btn : ''} className='img-fluid me-2' alt='' onClick={() => removeReaction()} style={{ 'height': '35px', 'width': '35px' }} />
                              )
                            }

                            {' '}
                            <span className='fw-medium'>{
                              newReaction === 'like' ? 'Gostei' : newReaction === 'love' ? 'Amei' : newReaction === 'haha' ? 'Haha' : newReaction === 'wow' ? 'Uau' : newReaction === 'sad' ? 'Triste' : newReaction === 'angry' ? 'Grr' : 'Reagir'
                            }</span>
                          </span>
                          <div className='dropdown-menu py-2 shadow'>
                            <OverlayTrigger
                              placement='top'
                              overlay={<Tooltip>Gostei</Tooltip>}
                              className='ms-2 me-2'
                            >
                              <img src={like_btn} className='img-fluid me-2' alt='' onClick={() => handleReaction('like')} style={{ 'height': '35px', 'width': '35px' }} />
                            </OverlayTrigger>
                            <OverlayTrigger
                              placement='top'
                              overlay={<Tooltip>Amei</Tooltip>}
                              className='me-2'
                            >
                              <img src={love_btn} className='img-fluid me-2' alt='' onClick={() => handleReaction('love')} style={{ 'height': '35px', 'width': '35px' }} />
                            </OverlayTrigger>
                            <OverlayTrigger
                              placement='top'
                              overlay={<Tooltip>HaHa</Tooltip>}
                              className='me-2'
                            >
                              <img src={haha_btn} className='img-fluid me-2' alt='' onClick={() => handleReaction('haha')} style={{ 'height': '35px', 'width': '35px' }} />
                            </OverlayTrigger>
                            <OverlayTrigger
                              placement='top'
                              overlay={<Tooltip>Uau</Tooltip>}
                              className='me-2'
                            >
                              <img src={wow_btn} className='img-fluid me-2' alt='' onClick={() => handleReaction('wow')} style={{ 'height': '35px', 'width': '35px' }} />
                            </OverlayTrigger>
                            <OverlayTrigger
                              placement='top'
                              overlay={<Tooltip>Triste</Tooltip>}
                              className='me-2'
                            >
                              <img src={sad_btn} className='img-fluid me-2' alt='' onClick={() => handleReaction('sad')} style={{ 'height': '35px', 'width': '35px' }} />
                            </OverlayTrigger>
                            <OverlayTrigger
                              placement='top'
                              overlay={<Tooltip>Grr</Tooltip>}
                              className='me-2'
                            >
                              <img src={angry_btn} className='img-fluid me-2' alt='' onClick={() => handleReaction('angry')} style={{ 'height': '35px', 'width': '35px' }} />
                            </OverlayTrigger>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </>
  )
}

export default Index