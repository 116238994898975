import React, { useState, useEffect } from 'react';
import api from '../../services/api';
import { Container, Row, Col, Card, Button, Form, Modal } from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { FaSearch } from 'react-icons/fa';
import { ToastContainer, toast } from 'react-toastify';
import Select from 'react-select'
import AnimationWarningLottie from '../../components/AnimationWarningDeleteConfim/AnimationWarningLottie'
import { useNavigate } from 'react-router-dom'

const EquipesIndex = () => {
    const history = useNavigate()
    const [teams, setTeams] = useState([]);

    const getTeams = async () => {
        try {
            const response = await api.post('/api/teams/index');
            setTeams(response.data.teams);
        } catch (error) {
            console.error('Erro');
            toast.error('Erro ao carregar dados.', { autoClose: 3000 });
        }
    };

    useEffect(() => {
        getTeams();
    }, []);

    const handleDeleteTeam = async (teamId) => {
        try {
            await api.delete(`/api/teams/destroy/${teamId}`);
            toast.success('Equipe excluida com sucesso!', { autoClose: 3000 });
            getTeams();

        } catch (error) {
            console.error('Erro ao excluir equipe');
            toast.error('Erro ao excluir equipe.', { autoClose: 3000 });
        }
    };

    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [teamToDelete, setTeamToDelete] = useState(null);
    const [teamNameToDelete, setTeamNameToDelete] = useState('');

    const handleConfirmDelete = () => {
        if (teamToDelete) {
            handleDeleteTeam(teamToDelete);
        }
        setShowDeleteModal(false);
    };

    const handleOpenDeleteModal = (teamId, teamName) => {
        setTeamToDelete(teamId);
        setTeamNameToDelete(teamName);
        setShowDeleteModal(true);
    };

    const showCreateEquipePage = () => {
        history(`/equipes/create`)
    }

    const showEditEquipePage = (teamId) => {
        history(`/equipes/edit/${teamId}`)
    }

    function OpenDeleteModal(props) {
        return (
            <Modal
                show={props.show}
                onHide={props.onHide}
                aria-labelledby='contained-modal-title-vcenter'
                centered>
                <Modal.Header closeButton>
                    <Modal.Title>Confirmar exclusão de equipe</Modal.Title>
                </Modal.Header>
                <Modal.Body id='modalBody'>
                    <div className="d-flex justify-content-center">
                        <AnimationWarningLottie />
                    </div>
                    <div className="d-flex justify-content-center">
                        <p>
                            Tem certeza que deseja excluir "{props.teamName}" ?
                        </p>
                    </div>
                </Modal.Body>
                <Modal.Footer id='modalFooter'>
                    <Button id='yesButton' onClick={props.onConfirm}>Sim</Button>
                    <Button id='noButton' onClick={props.onHide} >Não</Button>
                </Modal.Footer>
            </Modal>
        );
    }

    return (
        <>
            <div id='content-page' className='content-inner'>
                <Container>
                    <OpenDeleteModal
                        show={showDeleteModal}
                        onHide={() => setShowDeleteModal(false)}
                        onConfirm={handleConfirmDelete}
                        teamName={teamNameToDelete}
                    />
                    <Row>
                        <Col sm="12">
                            <Card className="position-relative inner-page-bg bg-primary p-5">
                                <div className="inner-page-title">
                                    <h3 className="text-white">Equipes de trabalho</h3>
                                </div>
                            </Card>
                        </Col>
                        <Col sm="12" lg="12">
                            <Card>
                                <Card.Body>
                                    <Tabs defaultActiveKey="profile" id="uncontrolled-tab-example" className="mb-3">
                                        <Tab eventKey="profile" title="Equipes de trabalho">
                                            <div className="mb-4 mt-4">
                                                <Form.Group className="input-group">
                                                    <span className="input-group-text" id="basic-addon1">
                                                        <FaSearch />
                                                    </span>
                                                    <Form.Control placeholder='Pesquisar' aria-label='pesquisar' aria-describedby='search' />
                                                    <Button onClick={() => showCreateEquipePage()} id='buttonNew' className='borderbtnCreateEdit'>
                                                        <i className="icon material-symbols-outlined d-flex">
                                                            group_add
                                                        </i>
                                                    </Button>

                                                </Form.Group>
                                            </div>
                                            <table className="table d-table">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Equipes</th>
                                                        <th scope="col">Membros</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {teams.map(team => (
                                                        <tr key={team.id}>
                                                            <td>{team.nome}</td>
                                                            <td>
                                                                {team.membros.length > 0 && (
                                                                    <span>{team.membros[0].name}</span>
                                                                )}
                                                                {team.membros.length > 1 && (
                                                                    <span> e mais {team.membros.length - 1} {team.membros.length - 1 === 1 ? 'pessoa' : 'pessoas'}</span>
                                                                )}
                                                            </td>
                                                            <td>
                                                                <Button style={{ marginRight: "5px" }} onClick={() => showEditEquipePage(team.id)}>
                                                                    <i className="material-symbols-outlined d-flex"> mode_edit </i>
                                                                </Button>
                                                                <Button onClick={() => handleOpenDeleteModal(team.id, team.nome)}>
                                                                    <i className="material-symbols-outlined d-flex"> delete </i>
                                                                </Button>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </Tab>
                                    </Tabs>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default EquipesIndex;
