import React, { useEffect, useState, useRef, useCallback, useLayoutEffect } from "react";
import { Container, Row, Col, Nav, Tab, Button, Tooltip, OverlayTrigger } from "react-bootstrap";
import Card from "../../components/Card";
import { useParams } from 'react-router-dom'
import { useFriendStatus } from '../../context/FriendStatusContext';
import loader from "../../assets/images/page-img/page-load-loader.gif";
import TeamLeaderInfo from "./team-leader-info"
import { Link } from "react-router-dom";
import ReactFsLightbox from "fslightbox-react";
import Cookies from 'js-cookie'
import api from '../../services/api'
import { toast } from 'react-toastify'
import LivePost from '../../components/LivePost'
import PollPost from '../../components/PollPost'
import Post from '../../components/Post'
import EventPost from '../../components/EventPost'
import QuizPost from '../../components/QuizPost'
import { useInView } from 'react-intersection-observer'

// images
import img1 from "../../assets/images/page-img/profile-bg1.jpg";
import user1 from "../../assets/images/user/11.png";

// Fslightbox plugin
const FsLightbox = ReactFsLightbox.default
  ? ReactFsLightbox.default
  : ReactFsLightbox;

const FriendProfile = () => {

  const [imageController, setImageController] = useState({
    toggler: false,
    slide: 1,
  });

  function imageOnSlide(index) {
    setImageController({
      toggler: !imageController.toggler,
      slide: index + 1,
    });
  }

  let { id } = useParams()
  const token = Cookies.get('token');
  const loged_user = Cookies.get('userId');
  const [userName, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [aboutMe, setAboutMe] = useState('');
  const [address, setAddress] = useState('');
  const [birthDate, setBirthDate] = useState('');
  const [birthPlace, setBirthPlace] = useState('');
  const [gender, setGender] = useState('');
  const [interestedIn, setInterestedIn] = useState('');
  const [language, setLanguage] = useState('');
  const [livesIn, setLivesIn] = useState('');
  const [phone, setPhone] = useState('');
  const [socialLink, setSocialLink] = useState('');
  const [status, setStatus] = useState('');
  const [imageSrc, setImageSrc] = useState(user1);
  const [BackgroundSrc, setBackgroundSrc] = useState(img1);
  const [Friends, setFriends] = useState([])
  const [friendsCount, setFriendsCount] = useState([])
  const [postImages, setPostImages] = useState([])
  const [isLoading, setIsLoading] = useState(false);
  const { friendStatus, friendStatusCanAccept, fetchFriendStatus } = useFriendStatus();
  const [position, setPosition] = useState('');
  const [organization, setOrganization] = useState('');
  const [workTeam, setWorkTeam] = useState('');
  const [employmentStartDate, setEmploymentStartDate] = useState('');
  const [companyLocation, setCompanyLocation] = useState('');
  const [professionalEmail, setProfessionalEmail] = useState('');
  const [isTeamLeader, setIsTeamLeader] = useState(false);
  const [posts, setPosts] = useState([]);
  const [postCount, setPostCount] = useState(0);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true)
  const loadingRef = useRef(false)
  const { ref, inView } = useInView({
    threshold: 0.1,
    triggerOnce: false,
    skip: !hasMore || isLoading
  })

  useEffect(() => {
    setIsLoading(true);
    let cancel = false;

    async function fetchData() {
      try {
        const response = await api.get(`/api/users/profile/${id}`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        if (!cancel) {
          const data = response.data.user;
          const friends = response.data.friends
          const friendsCount = response.data.friendsCount
          const postImage = response.data.post_images

          setUsername(data.name);
          setEmail(data.email);
          setAboutMe(data.about_me);
          setAddress(data.address);
          setBirthDate(data.birth_date);
          setBirthPlace(data.birth_place);
          setGender(data.gender);
          setInterestedIn(data.interested_in);
          setLanguage(data.language);
          setLivesIn(data.lives_in);
          setPhone(data.phone);
          setSocialLink(data.social_link);
          setStatus(data.status);
          setImageSrc(data.image_profile);
          setBackgroundSrc(data.background_image);
          setFriends(friends);
          setFriendsCount(friendsCount);
          setPostImages(postImage);

        }
      } catch (error) {
        console.error('Failed to fetch user data:', error);
      }
    }

    fetchData();
    setIsLoading(false);

    return () => {
      cancel = true;
    };
  }, [id, token]);

  useEffect(() => {
    let cancel = false;

    async function fetchData() {
      try {
        const response = await api.post(`/api/user/about/`, {
          userId: id,
          token,
        });

        if (!cancel) {
          const data = response.data[0];

          // Função para tratar valores nulos, indefinidos ou objetos inesperados
          const handleEmptyField = (field) => {
            return field && typeof field === 'string' && field.trim() !== '' ? field : '';
          };

          const handleDateField = (date) => {
            if (date && typeof date === 'string' && date.trim() !== '') {
              const [year, month, day] = date.split('-');
              const localDate = new Date(year, month - 1, day);
              return localDate.toLocaleDateString();
            }
            return '';
          };

          setPosition(handleEmptyField(data.user.position));
          setOrganization(handleEmptyField(data.user.organization));
          setWorkTeam(data.team_name);
          setBirthDate(handleDateField(data.user.birth_date))
          setEmploymentStartDate(handleDateField(data.user.employment_start_date));
          setCompanyLocation(handleEmptyField(data.user.company_location));
          setProfessionalEmail(handleEmptyField(data.user.professional_email));

          if (data.user.team_id) {
            setIsTeamLeader(true);
          } else {
            setIsTeamLeader(false);
          }
        }
      } catch (error) {
        console.error('Failed to fetch user data:', error);
      }
    }

    fetchData();

    return () => {
      cancel = true;
    };
  }, [id, token]);

  useEffect(() => {
    setIsLoading(true);
    fetchFriendStatus(id).then(() => {
      setIsLoading(false);
    }).catch(() => {
      setIsLoading(false);
    });
  }, [id, fetchFriendStatus]);

  const handleFriendAction = async (action, friendId) => {

    switch (action) {
      case 'add':
        try {
          await api.post(`/api/friends/add`, { friendId, loged_user: loged_user }, {
            headers: { Authorization: `Bearer ${token}` }
          });
          fetchFriendStatus(friendId)
          toast.success('Pedido de amizade enviado com sucesso!', {
            autoClose: 1000,
          });
        } catch (error) {
          console.error('Failed to add friend:', error);
          toast.error('Falha ao enviar pedido de amizade.');
        }
        break;
      case 'cancel':
        try {
          await api.post(`/api/friends/cancelRequest`, { friendId, loged_user: loged_user }, {
            headers: { Authorization: `Bearer ${token}` }
          });
          fetchFriendStatus(friendId);
          toast.success('Pedido de amizade cancelado!', {
            autoClose: 1000,
          });
        } catch (error) {
          console.error('Failed to cancel friend request:', error);
          toast.error('Falha ao cancelar pedido de amizade.');
        }
        break;
      case 'accept':
        try {
          await api.post(`/api/friends/accept`, { friendId, loged_user: loged_user }, {
            headers: { Authorization: `Bearer ${token}` }
          });
          fetchFriendStatus(friendId);
          toast.success('Pedido de amizade aceito com sucesso!', {
            autoClose: 1000,
          });
        } catch (error) {
          console.error('Failed to accept friend request:', error);
          toast.error('Falha ao aceitar pedido de amizade.');
        }
        break;
      case 'remove':
        try {
          await api.post(`/api/friends/remove`, { friendId, loged_user: loged_user }, {
            headers: { Authorization: `Bearer ${token}` }
          });
          fetchFriendStatus(friendId);
          toast.success('Amigo removido com sucesso!', {
            autoClose: 1000,
          });
        } catch (error) {
          console.error('Failed to remove friend:', error);
          toast.error('Falha ao remover amigo.');
        }
        break;
      default:
        console.log('Ação desconhecida');
    }
  };

  const fetchPostsData = useCallback(async (resetPage = false) => {
    if (loadingRef.current) return;
    loadingRef.current = true;
    setIsLoading(true);

    const currentPage = resetPage ? 1 : page;
    try {
      const response = await api.get(`/api/users/${id}/posts`, {
        params: {
          page: currentPage,
          limit: 2
        }
      });
      const data = response.data.posts;

      if (data.length === 0) {
        setHasMore(false);
        return;
      }

      setPostCount(response.data.total_posts);

      if (resetPage) {
        setPosts(data);
        setPage(2);
      } else {
        setPosts(prev => {
          const newPosts = data.filter(post => !prev.some(prevPost => prevPost.id === post.id));
          return [...prev, ...newPosts];
        });
        setPage(currentPage + 1);
      }
    } catch (error) {
      console.error('Failed to fetch user posts:', error);
    } finally {
      setIsLoading(false);
      loadingRef.current = false;
    }
  }, [page, id]);

  useEffect(() => {
    fetchPostsData(true);
  }, [id]);

  useLayoutEffect(() => {
    if (inView && hasMore && !loadingRef.current) {
      fetchPostsData(false);
    }
  }, [inView, hasMore]);

  return (
    <>
      <FsLightbox
        toggler={imageController.toggler}
        sources={postImages.map(img => img.image)}
        slide={imageController.slide}
      />
      <div id="content-page" className="content-inner">
        <Container>
          <Row>
            <Col sm={12}>
              <Card>
                <Card.Body className=" profile-page p-0">
                  <div className="profile-header profile-info">
                    <div className="cover-container">
                      <img
                        loading="lazy"
                        src={BackgroundSrc}
                        alt="profile-bg"
                        className="rounded"
                        width={'100%'}
                        height={'300vh'}
                      />
                      <ul className="header-nav d-flex flex-wrap justify-end p-0 m-0">
                        {isLoading ? (
                          <div className="col-sm-12 text-center">
                            <img src={loader} alt="loader" style={{ height: "100px" }} />
                          </div>
                        ) : (
                          <div style={{ zIndex: "1" }}>
                            {loged_user !== id && !friendStatus && !friendStatusCanAccept === true && (
                              <OverlayTrigger placement="left" overlay={<Tooltip>Enviar pedido de amizade</Tooltip>}>
                                <Button className="btn btn-primary m-2" onClick={() => handleFriendAction('add', id)}>
                                  <span className="material-symbols-outlined d-flex">
                                    person_add_alt
                                  </span>
                                </Button>
                              </OverlayTrigger>
                            )}
                            {friendStatus === 'pending' && !friendStatusCanAccept === true && (
                              <OverlayTrigger placement="left" overlay={<Tooltip>Cancelar pedido de amizade</Tooltip>}>
                                <Button className="btn btn-primary m-2" onClick={() => handleFriendAction('cancel', id)}>
                                  <span className="material-symbols-outlined d-flex">
                                    hourglass_bottom
                                  </span>
                                </Button>
                              </OverlayTrigger>
                            )}
                            {friendStatusCanAccept === true && (
                              <OverlayTrigger placement="left" overlay={<Tooltip>Aceitar pedido de amizade</Tooltip>}>
                                <Button className="btn btn-primary m-2" onClick={() => handleFriendAction('accept', id)}>
                                  <span className="material-symbols-outlined d-flex">
                                    how_to_reg
                                  </span>
                                </Button>
                              </OverlayTrigger>
                            )}
                            {friendStatus === 'accepted' && !friendStatusCanAccept === true && (
                              <OverlayTrigger placement="left" overlay={<Tooltip>Remover Amigo</Tooltip>}>
                                <Button className="btn btn-primary m-2" onClick={() => handleFriendAction('remove', id)}>
                                  <span className="material-symbols-outlined d-flex">
                                    person_remove
                                  </span>
                                </Button>
                              </OverlayTrigger>
                            )}
                          </div>
                        )}
                      </ul>
                    </div>
                    <div className="user-detail text-center mb-3">
                      <div className="profile-img">
                        <img
                          loading="lazy"
                          src={imageSrc}
                          alt="profile-img"
                          className="avatar-130 img-fluid"
                        />
                      </div>
                      <div className="profile-detail">
                        <h3>{userName}</h3>
                      </div>
                    </div>
                    <div className="profile-info py-5 px-md-5 px-3 d-flex align-items-center justify-content-between position-relative">
                      <div className="social-links">
                        {/* <ul className="social-data-block d-flex align-items-center justify-content-between list-inline p-0 m-0">
                          <li className="text-center pe-3">
                            <Link to="#">
                              <BsFacebook />
                            </Link>
                          </li>
                          <li className="text-center pe-3">
                            <Link to="#">
                              <BsTwitter />
                            </Link>
                          </li>
                          <li className="text-center pe-3">
                            <Link to="#">
                              <BsInstagram />
                            </Link>
                          </li>
                          <li className="text-center md-pe-3 pe-0">
                            <Link to="#">
                              <BsLinkedin />
                            </Link>
                          </li>
                        </ul> */}
                      </div>
                      <div className="social-info">
                        <ul className="social-data-block social-user-meta-list d-flex align-items-center justify-content-center list-inline p-0 m-0 gap-1">
                          <li className="text-center">
                            <p className="mb-0">{postCount}</p>
                            <h6>Posts</h6>
                          </li>
                          <li className="text-center">
                            <p className="mb-0">{friendsCount.length}</p>
                            <h6>Amigos</h6>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Row>
              <Col lg={4}>
                <Tab.Container defaultActiveKey="f1">
                  <div className="fixed-md-suggestion mb-0 mb-md-4">
                    <Card>
                      <Card.Body className="p-0">
                        <div className="tab-bottom-bordered">
                          <Nav as='ul'
                            variant="pills"
                            // className="d-flex align-items-center justify-content-center profile-feed-items p-0 m-0"
                            className="mb-0 nav nav-tabs rounded-top border-0 d-flex align-items-center justify-content-center profile-feed-items p-0 m-0"
                          >

                            <Col sm="4" as="li" className="nav-item col-12 p-0">
                              <Nav.Link eventKey="f1" href="#Abouts" className="border-0 px-md-0">
                                Sobre
                              </Nav.Link>
                            </Col>
                            <Col sm="4" as="li" className="nav-item col-12 p-0">
                              <Nav.Link eventKey="f2" href="#Friends" className="border-0 px-md-0">
                                Amigos
                              </Nav.Link>
                            </Col>
                            <Col sm="4" as="li" className="nav-item col-12 p-0">
                              <Nav.Link eventKey="f3" href="#Photos" className="border-0 px-md-0">
                                Fotos
                              </Nav.Link>
                            </Col>
                          </Nav>
                        </div>
                      </Card.Body>
                    </Card>
                    <Tab.Content>
                      <Tab.Pane
                        eventKey="f1"
                        className="fade show"
                        id="Photos"
                        role="tabpanel"
                      >
                        <Card>
                          {(interestedIn || birthDate) && (
                            <div>
                              <Card.Header className="border-bottom">
                                <div className="header-title">
                                  <h4 className="card-title">Visão Geral</h4>
                                </div>
                              </Card.Header>
                              <Card.Body>
                                <ul className="list-inline p-0 m-0">
                                  {interestedIn && (
                                    <li className="d-flex flex-column">
                                      <div className="d-flex gap-1">
                                        <span className="material-symbols-outlined md-18">gpp_good</span>
                                        <span>Interessado em</span>
                                      </div>
                                      <p className="ms-5 fw-bolder fs-6">{interestedIn}</p>
                                    </li>
                                  )}
                                  {birthDate && (
                                    <li className="d-flex flex-column">
                                      <div className="d-flex gap-1">
                                        <span className="material-symbols-outlined md-18">cake</span>
                                        <span>Data de aniversário</span>
                                      </div>
                                      <p className="ms-5 fw-bolder fs-6">{birthDate}</p>
                                    </li>
                                  )}
                                </ul>
                              </Card.Body>
                            </div>
                          )}
                          {(position || organization || workTeam) && (
                            <div>
                              <Card.Header className="border-bottom">
                                <div className="header-title">
                                  <h4 className="card-title">Função</h4>
                                </div>
                              </Card.Header>
                              <Card.Body>
                                <ul className="list-inline p-0 m-0">
                                  {position && (
                                    <li className="d-flex flex-column">
                                      <div className="d-flex gap-1">
                                        <span className="material-symbols-outlined md-18">business_center</span>
                                        <span>Cargo</span>
                                      </div>
                                      <p className="ms-5 fw-bolder fs-6">{position}</p>
                                    </li>
                                  )}
                                  {organization && (
                                    <li className="d-flex flex-column">
                                      <div className="d-flex gap-1">
                                        <span className="material-symbols-outlined md-18">hub</span>
                                        <span>Organização</span>
                                      </div>
                                      <p className="ms-5 fw-bolder fs-6">{organization}</p>
                                    </li>
                                  )}
                                  {workTeam && (
                                    <li className="d-flex flex-column">
                                      <div className="d-flex gap-1">
                                        <span className="material-symbols-outlined md-18">group_work</span>
                                        <span>Equipe de trabalho</span>
                                      </div>
                                      <p className="ms-5 fw-bolder fs-6"
                                      >{workTeam}</p>
                                    </li>
                                  )}
                                </ul>
                              </Card.Body>
                            </div>
                          )}
                          {(employmentStartDate || companyLocation || professionalEmail) && (
                            <div>
                              <Card.Header className="border-bottom">
                                <div className="header-title">
                                  <h4 className="card-title">Informações de Contato</h4>
                                </div>
                              </Card.Header>
                              <Card.Body>
                                <ul className="list-inline p-0 m-0">
                                  {employmentStartDate && (
                                    <li className="d-flex flex-column">
                                      <div className="d-flex gap-1">
                                        <span className="material-symbols-outlined md-18">event_upcoming</span>
                                        <span>Data de início</span>
                                      </div>
                                      <p className="ms-5 fw-bolder fs-6">{employmentStartDate}</p>
                                    </li>
                                  )}
                                  {companyLocation && (
                                    <li className="d-flex flex-column">
                                      <div className="d-flex gap-1">
                                        <span className="material-symbols-outlined md-18">location_on</span>
                                        <span>Localização da empresa</span>
                                      </div>
                                      <p className="ms-5 fw-bolder fs-6">{companyLocation}</p>
                                    </li>
                                  )}
                                  {professionalEmail && (
                                    <li className="d-flex flex-column">
                                      <div className="d-flex gap-1">
                                        <span className="material-symbols-outlined md-18">mail</span>
                                        <span>e-mail corporativo</span>
                                      </div>
                                      <p className="ms-5 fw-bolder fs-6">{professionalEmail}</p>
                                    </li>
                                  )}
                                </ul>
                              </Card.Body>
                            </div>
                          )}
                          <Card.Header className="border-bottom">
                            <div className="header-title">
                              <h4 className="card-title">Organograma</h4>
                            </div>
                          </Card.Header>
                          <Card.Body>
                            {isTeamLeader && <TeamLeaderInfo user_id={id} />}
                          </Card.Body>
                        </Card>
                      </Tab.Pane>
                      <Tab.Pane
                        eventKey="f2"
                        className="fade show"
                        id="Abouts"
                        role="tabpanel"
                      >
                        <Card>
                          <Card.Header className="border-bottom">
                            <div className="header-title">
                              <h4 className="card-title">Amigos</h4>
                            </div>
                            <div className="card-header-toolbar d-flex align-items-center">
                              <p className="m-0">
                                <Link to={`/friends/friend-of-friend-list/${id}`}>Ver todos </Link>
                              </p>
                            </div>
                          </Card.Header>
                          <Card.Body>
                            <ul className="request-list list-inline m-0 p-0">
                              {Friends.map((friend, index) => (
                                <li className="d-flex align-items-center  flex-wrap" key={index}>
                                  <div className="user-img img-fluid flex-shrink-0">
                                    <img
                                      loading="lazy"
                                      src={friend.user_about.image_profile}
                                      alt="story-img"
                                      className="rounded-circle avatar-40"
                                    />
                                  </div>
                                  <div className="flex-grow-1 ms-3">
                                    <Link to={`/users/profile/${friend.id}`} >
                                      {friend.name}
                                    </Link>
                                  </div>
                                </li>
                              ))}
                            </ul>
                          </Card.Body>
                        </Card>
                      </Tab.Pane>
                      <Tab.Pane
                        eventKey="f3"
                        className="fade show"
                        id="Friends"
                        role="tabpanel"
                      >
                        <Card>
                          <Card.Header className="border-bottom">
                            <div className="header-title">
                              <h4 className="card-title">Fotos</h4>
                            </div>
                          </Card.Header>
                          <Card.Body>
                            <ul className="profile-img-gallary p-0 m-0 list-unstyled">
                              {postImages.map((image, index) => (
                                <li className="" key={index}>
                                  <Link to="#" onClick={() => imageOnSlide(index)}>
                                    <img
                                      loading="lazy"
                                      src={image.image}
                                      alt="gallary-img"
                                      style={{ width: '100px', height: '100px' }}
                                      className="img-fluid"
                                    />
                                  </Link>
                                </li>
                              ))}
                            </ul>
                          </Card.Body>
                        </Card>
                      </Tab.Pane>
                    </Tab.Content>
                  </div>
                </Tab.Container>
              </Col>
              <Col lg={8}>
                {
                  posts.map((post, index) => (
                    <div ref={index === posts.length - 1 ? ref : null} key={`post_${post.id}`}>
                      {
                        post.live_id && !post.event_id ? (
                          <LivePost user_name={post.user_name} user_image={post.user_image} live_iframe={post.live_iframe} live_name={post.live_name} post_time={post.created_at} privacy={post.privacy} post_id={post.id} user_reaction={post.user_reaction} reactions={post.reaction_counter} share_id={post.share_id} share_posts={post.sharePosts} />
                        ) : post.event_id ? (
                          <EventPost event_id={post.event_id} event_place={post.event_place} event_external_link={post.event_external_link} event_start_date={post.event_start_date} event_end_date={post.event_end_date} event_name={post.event_name} user_id={post.user_id} post_id={post.id} user_name={post.user_name} user_image={post.user_image} type={post.event_image ? 'image' : 'text'} media={post.event_image} post_time={post.created_at} text={post.event_description} privacy={post.privacy} group_id={post.group_id} is_admin={false} user_reaction={post.user_reaction} reactions={post.reaction_counter} share_id={post.share_id} share_posts={post.sharePosts} />
                        ) : post.quiz_id ? (
                          <QuizPost user_answer={post.user_answer} user_id={post.user_id} post_id={post.id} user_name={post.user_name} user_image={post.user_image} type={post.image ? 'image' : post.video ? 'video' : 'text'} media={post.image ?? post.video} post_time={post.created_at} text={post.text} privacy={post.privacy} group_id={post.group_id} is_admin={false} user_reaction={post.user_reaction} reactions={post.reaction_counter} share_id={post.share_id} share_posts={post.sharePosts} view_count={post.view_count} options={post.quiz_options} right_answer={post.quiz_right_answer} />
                        ) : post.poll_id ? (
                          <PollPost poll_id={post.poll_id} group_name={post.group_name} user_id={post.user_id} post_id={post.id} user_name={post.user_name} user_image={post.user_image} type={post.image ? 'image' : post.video ? 'video' : 'text'} media={post.image ?? post.video} post_time={post.created_at} text={post.text} privacy={post.privacy} group_id={post.group_id} is_admin={false} user_reaction={post.user_reaction} reactions={post.reaction_counter} share_id={post.share_id} share_posts={post.sharePosts} view_count={post.view_count} />
                        ) : (
                          <Post user_id={post.user_id} post_id={post.id} user_name={post.user_name} user_image={post.user_image} type={post.image ? 'image' : post.video ? 'video' : 'text'} media={post.image ?? post.video} post_time={post.created_at} text={post.text} privacy={post.privacy} group_id={post.group_id} is_admin={false} user_reaction={post.user_reaction} reactions={post.reaction_counter} share_id={post.share_id} share_posts={post.sharePosts} view_count={post.view_count}/>
                        )
                      }
                    </div>
                  ))
                }

                {isLoading && (
                  <div className='col-sm-12 text-center'>
                    <img src={loader} alt='loader' style={{ height: '100px' }} />
                  </div>
                )}
              </Col>
            </Row>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default FriendProfile;