import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import api from '../../services/api'
import LivePost from '../../components/LivePost'
import Post from '../../components/Post'
import QuizPost from '../../components/QuizPost'
import loader from '../../assets/images/page-img/page-load-loader.gif'

import {
    Row,
    Col,
    Container
} from 'react-bootstrap'

const Index = () => {

    const [posts, setPosts] = useState([])
    const { id } = useParams()
    const [isLoading, setIsLoading] = useState(true)


    const getDetailsPost = async () => {
        const response = await api.get(`/api/post/${id}/show`)
        setPosts(response.data)
        setIsLoading(false)
    }

    useEffect(() => {
        setPosts([])
        getDetailsPost()
    }, [id]);

    return (
        <>
            {isLoading && <div className='col-sm-12 text-center'>
                <img src={loader} alt='loader' style={{ height: '100px' }} />
            </div>
            }
            <div id='content-page' className='content-inner'>
                <Container>
                    <Row className='gx-4'>
                        <Col lg={8}>
                            <div id='content'>

                                {
                                    posts.map((post, index) => (
                                        <div key={index}>
                                            {
                                                post.live_id ? (
                                                    <LivePost event_id={post.event_id} user_name={post.user_name} user_image={post.user_image} live_iframe={post.live_iframe} live_name={post.live_name} post_time={post.created_at} post_id={post.id} share_id={post.share_id} share_posts={post.sharePosts} privacy={post.privacy} user_reaction={post.user_reaction} reactions={post.reaction_counter} />
                                                ) : post.quiz_id ? (
                                                    <QuizPost user_answer={post.user_answer} user_id={post.user_id} post_id={post.id} user_name={post.user_name} user_image={post.user_image} type={post.image ? 'image' : post.video ? 'video' : 'text'} media={post.image ?? post.video} post_time={post.created_at} text={post.text} user_reaction={post.user_reaction} reactions={post.reaction_counter} share_id={post.share_id} share_posts={post.sharePosts} privacy={post.privacy} view_count={post.view_count} options={post.quiz_options} right_answer={post.quiz_right_answer} />
                                                ) : (
                                                    <Post user_id={post.user_id} post_id={post.id} user_name={post.user_name} user_image={post.user_image} type={post.image ? 'image' : post.video ? 'video' : 'text'} media={post.image ?? post.video} post_time={post.created_at} text={post.text} user_reaction={post.user_reaction} reactions={post.reaction_counter} share_id={post.share_id} share_posts={post.sharePosts} privacy={post.privacy} view_count={post.view_count}/>
                                                )
                                            }
                                        </div>
                                    ))
                                }
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default Index
