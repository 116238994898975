import React, { useState, useEffect } from 'react'
import { Row, Col, Container, Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useNavigate, useParams } from "react-router-dom"
import api from '../../services/api'

//swiper
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore from "swiper";
import { Navigation, Autoplay } from "swiper/modules";
import { TbLockX } from "react-icons/tb";
import { Alert } from 'reactstrap'

//img
import atmoLogo from '../../assets/images/atmo logo.png'
import atmoLogo2 from '../../assets/images/atmo_midia_logo_branco.png'
import loader from "../../assets/images/page-img/page-load-loader.gif";



// Import selectors & action from setting store
import * as SettingSelector from "../../store/setting/selectors";
// Redux Selector / Action
import { useSelector } from "react-redux";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// install Swiper modules
SwiperCore.use([Navigation, Autoplay]);

const SignUp = () => {
  const appName = useSelector(SettingSelector.app_name);

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [password_confirmation, setPasswordConfirmation] = useState('')
  const [showSpinner, setShowSpinner] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate()
  let { token } = useParams()
  const [isLoading, setIsLoading] = useState(false);


  const validateToken = async () => {

    try {
       await api.post(`/api/auth/validateToken/${token}`)

    } catch (error) {
      if(error.response.status == 404){
        toast.error(error.response.data.message, { autoClose: 3000 });
        navigate('/errors/error404')
      }

      if(error.response.status == 400){
        // toast.error(error.response.data.message, { autoClose: 3000 });
        navigate('/errors/error500')
      }
    
    }

}


useEffect(() => {
    setIsLoading(true);
    validateToken().then(() => {
      setIsLoading(false);
    }).catch(() => {
      setIsLoading(false);
    });
}, []); 


  const handleRegister = async (e) => {
    e.preventDefault()
    setShowSpinner(true);

    try {
      await api.post('/api/auth/register', {
        name,
        email,
        password,
        password_confirmation,
      }).then(() => {

        toast.success('Registro realizado com sucesso!', {
          autoClose: 2000,
          onClose: () => {
            navigate('/auth/sign-in')
          }
        });
      })

    } catch (error) {
      if (error.response && error.response.status === 422) {
        setError("Verifique os campos preenchidos.");
      } else {
        setError("Erro ao fazer registros.");
      }
      console.error('Erro ao fazer registro:', error)
    } finally {
      setShowSpinner(false);
    }
  }


  return (
    <>
    {isLoading ? (
                    <div className="col-sm-12 text-center">
                      <img src={loader} alt="loader" style={{ height: "100px" }} />
                    </div>
                  ) : (
      <section className="sign-in-page">
        <Container fluid>
          <Row className="align-items-center">
            <Col md={6} className="overflow-hidden position-relative">
              <div className="bg-primary w-100 h-100 position-absolute top-0 bottom-0 start-0 end-0"></div>
              <div className="container-inside z-1">
                <div className="main-circle circle-small"></div>
                <div className="main-circle circle-medium"></div>
                <div className="main-circle circle-large"></div>
                <div className="main-circle circle-xlarge"></div>
                <div className="main-circle circle-xxlarge"></div>
              </div>
              <div className="sign-in-detail container-inside-top">
                <Swiper
                  className="list-inline m-0 p-0 "
                  spaceBetween={30}
                  centeredSlides={true}
                  loop={true}
                  autoplay={{
                    delay: 5000,
                    disableOnInteraction: false,
                  }}
                >
                  <ul className="swiper-wrapper list-inline m-0 p-0 ">
                    <SwiperSlide>
                    <img
                     style={{ width:"250px" }}
                        src={atmoLogo2}
                        className="img-fluid mb-2"
                        alt="images"
                      />
                      <p className="mt-3 text-white fw-semibold">
                        Bem-vindo à plataforma que conecta talentos, transforma a comunicação e fortalece sua empresa.
                      </p>
                    </SwiperSlide>
                    <SwiperSlide>
                    <img
                     style={{ width:"250px" }}
                        src={atmoLogo2}
                        className="img-fluid mb-2"
                        alt="images"
                      />
                      <p className="mt-3 text-white fw-semibold">
                        Conecte-se com sua equipe, colabore em tempo real e compartilhe ideias que impulsionam a produtividade.
                      </p>
                    </SwiperSlide>
                    <SwiperSlide>
                    <img style={{ width:"250px" }}
                        src={atmoLogo2}
                        className="img-fluid mb-2"
                        alt="images"
                      />
                      <p className="mt-3 text-white fw-semibold">
                        A rede social corporativa que une equipes, projetos e pessoas em um só lugar.
                      </p>
                    </SwiperSlide>
                  </ul>
                </Swiper>
              </div>
            </Col>
            <Col md={6}>
              <div className="sign-in-from text-center">
                <Link
                  to="/"
                  className="d-inline-flex align-items-center justify-content-center gap-2"
                >
                  <img style={{ width: 50 }} src={atmoLogo} alt='Logo Atmo' ></img>
                  <h2 className="logo-title" data-setting="app_name">
                    {appName}
                  </h2>
                </Link>
                <p className="mt-3 font-size-16">
                  Bem-vindo ao Atmo Workplace.
                </p>
                {error && <Alert className='alertClass d-flex align-items-center' color="danger"><TbLockX className='fs-5 marginAlertClass' />{error}</Alert>}
                <Form onSubmit={handleRegister} className="mt-5">
                  <Form.Group className="form-group text-start">
                    <h6 className="form-label fw-bold">Nome</h6>
                    <Form.Control
                      type="text"
                      className="form-control mb-0"
                      placeholder="Nome completo"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="form-group text-start">
                    <h6 className="form-label fw-bold">E-mail</h6>
                    <Form.Control
                      type="email"
                      className="form-control mb-0"
                      placeholder="E-mail"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="form-group text-start">
                    <h6 className="form-label fw-bold">Senha</h6>
                    <Form.Control
                      type="password"
                      className="form-control mb-0"
                      placeholder="Senha"
                      required
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="form-group text-start">
                    <h6 className="form-label fw-bold">Confirmar senha</h6>
                    <Form.Control
                      type="password"
                      required
                      className="form-control mb-0"
                      placeholder="Confirmar senha"
                      value={password_confirmation}
                      onChange={(e) => setPasswordConfirmation(e.target.value)}

                    />
                  </Form.Group>
                  <Button
                    variant="primary"
                    type="submit"
                    className="btn btn-primary mt-4 fw-semibold text-uppercase w-100"
                    disabled={showSpinner}
                  >
                    {showSpinner ? <div className="spinner-border spinner-border-sm" role="status"></div> : <div className='fw-semibold'>Criar conta</div>}
                  </Button>
                  <h6 className="mt-5">
                    Já tem uma conta? {" "}
                    <Link to={"/auth/sign-in"}>Conecte-se</Link>
                  </h6>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      )}
    </>
  );
};

export default SignUp;
