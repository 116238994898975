import React, { useState, useRef, useMemo } from 'react'
import api from '../services/api'
import { toast } from 'react-toastify'
import { FaFileUpload } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { set_handle_posts } from '../store/setting/actions'
import Plyr from "plyr-react"
import "plyr-react/plyr.css"


//router
import { Link } from 'react-router-dom'

//react bootstrap
import { Dropdown, Button, Modal, CloseButton, Form, Col } from 'react-bootstrap'

//images
import user1 from '../assets/images/user/1.jpg'

const CreatePost = (props) => {
  const dispatch = useDispatch()
  const customId = 'success-toast-id'
  const [show, setShow] = useState(false)
  const [text, setText] = useState('')
  const [image, setImage] = useState(null)
  const [video, setVideo] = useState(null)
  const [showMediaInput, setShowMediaInput] = useState(false)
  const [showSpinner, setShowSpinner] = useState(false)
  const [privacy, setPrivacy] = useState('public')
  const [important, setImportant] = useState(0)
  const [dataImportant, setDataImportant] = useState('')
  const [horaImportant, setHoraImportant] = useState('')
  const handlePosts = useSelector((state) => state.setting.setting.handle_posts)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const handleSubmit = async (e) => {
    e.preventDefault()
    setShowSpinner(true)

    if (text === '') {
      setShowSpinner(false)
      toast.error('Por favor escreva um texto para a postagem.', { autoClose: 3000 })
    } else if ((important && dataImportant === '') || (important && horaImportant === '')) {
      setShowSpinner(false)
      toast.error('Por favor preencha corretamente a data limite do aviso importante', { autoClose: 3000 })
    } else {
        const formData = new FormData()
        formData.append('text', text)
        formData.append('privacy', privacy)
        formData.append('important', important)
        formData.append('data_important', dataImportant)
        formData.append('hora_important', horaImportant)
        if (image) formData.append('image', image)
        if (video) formData.append('video', video)
        if (props.group_id) formData.append('group_id', props.group_id)

        await api.post(`/api/posts/store`, formData).then(() => {
          setShowSpinner(false)
          setText('')
          setImage(null)
          setVideo(null)
          setImportant(0)
          setDataImportant('')
          setHoraImportant('')

          dispatch(set_handle_posts(!handlePosts))

          toast.success('Postagem criada com sucesso!', {
            toastId: customId,
            autoClose: 300,
          })
        }).catch(err => {
          setShowSpinner(false)
          toast.error('Erro ao publicar postagem.', { autoClose: 3000 })
        })
    }
  }

  const handleMediaClick = () => {
    if (image || video) {
      setImage(null)
      setVideo(null)
      setShowMediaInput(false)
    } else {
      setShowMediaInput(!showMediaInput)
    }
  }

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const fileType = file.type.split('/')[0];
      if (fileType === 'image') {
        setImage(file);
        setShowMediaInput(false);
      } else if (fileType === 'video') {
        setVideo(file);
        setShowMediaInput(false);
      }
    }
  }

  const removeImage = () => {
    setImage(null)
    setShowMediaInput(true)
  }

  const removeVideo = () => {
    setVideo(null)
    setShowMediaInput(true)
  }

  const playerRef = useRef(null);

  const plyrProps = useMemo(() => ({
    source: {
      type: "video",
      sources: [
        {
          src: video ? URL.createObjectURL(video) : "",
          type: "video/mp4",
        }
      ]
    }
  }), [video ? URL.createObjectURL(video) : ""]); 


  return (
    <>
      <div id='post-modal-data' className={`card ${props.class}`}>
        <div className='card-header d-flex justify-content-between border-bottom'>
          <div className='header-title'>
            <h5 className='card-title'>Adicionar Post</h5>
          </div>
          <Dropdown >
            <Dropdown.Toggle className='lh-1' id='post-option' as='div' bsPrefix=' '>
              {/* <span className='material-symbols-outlined'>more_horiz</span> */}
            </Dropdown.Toggle>
            <Dropdown.Menu
              variant='right'
              aria-labelledby='post-option'
              style={{ position: 'absolute', inset: 'auto auto 0px 0px', margin: '0px', transform: 'translate(0px, -27px)' }}
            >
              <Dropdown.Item
                href='#'
                onClick={handleShow}
              >
                Check in
              </Dropdown.Item>
              <Dropdown.Item
                href='#'
                onClick={handleShow}
              >
                Live Video
              </Dropdown.Item>
              <Dropdown.Item
                href='#'
                onClick={handleShow}
              >
                GIF
              </Dropdown.Item>
              <Dropdown.Item
                href='#'
                onClick={handleShow}
              >
                Watch Party
              </Dropdown.Item>
              <Dropdown.Item
                href='#'
                onClick={handleShow}
              >
                Play with Friend
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className='card-body'>
          <div className='d-flex align-items-center mb-5'>
            <form className='post-text w-100'>
              <input
                type='text'
                className='form-control rounded px-0'
                placeholder='Escreva e compartilhe sua postagem com seus amigos...'
                style={{ border: 'none' }}
                value={text}
                onChange={(e) => setText(e.target.value)}
              />

              <div className="mt-5">
                {image && (
                  <div className="position-relative d-inline-block">
                    <img
                      src={URL.createObjectURL(image)}
                      alt="Imagem escolhida"
                      style={{ maxWidth: '100%', objectFit: 'cover' }}
                    />
                    <CloseButton
                      onClick={removeImage}
                      style={{ position: 'absolute', top: '10px', right: '10px', backgroundColor:'white', color: '#ff0000' }}
                    />
                  </div>
                )}
                {video && (
                  <div className='position-relative d-inline-block'>
                        <div className='d-flex'>
                            <Plyr ref={playerRef} {...plyrProps} />
                        </div>
                    <CloseButton
                      onClick={removeVideo}
                      style={{ position: 'absolute', top: '10px', right: '10px', backgroundColor:'white', color: '#ff0000' }}
                    />
                  </div>
                )}
              </div>

              {showMediaInput && (
                <div className="upload-area mt-3 position-relative" style={{ border: '1px solid #ccc', padding: '20px', borderRadius: '5px', textAlign: 'center' }}>
                  <CloseButton
                    onClick={handleMediaClick}
                    style={{ zIndex: 99, position: 'absolute', top: '10px', right: '10px', color: '#ff0000', backgroundColor: '#fff' }}
                  />
                  <FaFileUpload size={48} style={{ color: '#ccc' }} />
                  <p className="mt-2">Adicionar Fotos/Vídeos</p>
                  <p className="text-muted">ou arraste e solte</p>
                  <Form.Control
                    type="file"
                    accept="image/*,video/*"
                    onChange={handleFileUpload}
                    className="position-absolute w-100 h-100 opacity-0"
                    style={{ top: 0, left: 0, cursor: 'pointer' }}
                  />
                </div>
              )}
            </form>
          </div>
        </div>
        <div className='card-body bg-primary-subtle rounded-bottom-3'>
          <div className='d-flex align-items-center justify-content-between flex-wrap gap-3'>
            <div className='create-post-data'>
              <ul className='list-inline m-0 p-0 d-flex align-items-center gap-4'>
                <li className="icon-item">
                  <Link to='#' className='text-body' onClick={handleMediaClick}>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='18'
                      height='16'
                      viewBox='0 0 18 16'
                      fill='none'
                      style={{ verticalAlign: 'middle' }} // Alinha verticalmente o SVG
                    >
                      <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M11.5334 1.3761C12.3751 1.7111 12.6326 2.87776 12.9767 3.25276C13.3209 3.62776 13.8134 3.75526 14.0859 3.75526C15.5342 3.75526 16.7084 4.92943 16.7084 6.37693V11.2061C16.7084 13.1478 15.1334 14.7228 13.1917 14.7228H4.80841C2.86591 14.7228 1.29175 13.1478 1.29175 11.2061V6.37693C1.29175 4.92943 2.46591 3.75526 3.91425 3.75526C4.18591 3.75526 4.67841 3.62776 5.02341 3.25276C5.36758 2.87776 5.62425 1.7111 6.46591 1.3761C7.30841 1.0411 10.6917 1.0411 11.5334 1.3761Z'
                        stroke='currentColor'
                        strokeWidth='1.5'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                      <path
                        d='M13.5794 5.91667H13.5869'
                        stroke='currentColor'
                        strokeWidth='2'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                      <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M11.6489 8.94C11.6489 7.47667 10.4631 6.29083 8.99975 6.29083C7.53642 6.29083 6.35059 7.47667 6.35059 8.94C6.35059 10.4033 7.53642 11.5892 8.99975 11.5892C10.4631 11.5892 11.6489 10.4033 11.6489 8.94Z'
                        stroke='currentColor'
                        strokeWidth='1.5'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                    </svg>
                  </Link>
                </li>

                {
                  !props.group_id ? (
                    <li className="icon-item">
                      <Dropdown>
                        <Dropdown.Toggle id='post-option' as='span' >
                          {
                            privacy === 'public' && !important ? (
                              <span className='material-symbols-outlined' style={{ 'fontSize': 'large', 'verticalAlign': 'middle' }}>
                                public
                              </span>
                            ) : privacy === 'friends' ? (
                              <span className='material-symbols-outlined' style={{ 'fontSize': 'large', 'verticalAlign': 'middle' }}>
                                group
                              </span>
                            ) : (
                              <span className='material-symbols-outlined' style={{ 'fontSize': 'large', 'verticalAlign': 'middle' }}>
                                keep
                              </span>
                            )
                          }
                        </Dropdown.Toggle>
                        <Dropdown.Menu className='m-0 p-0'>
                          <Dropdown.Item className=' p-3' to='#' onClick={() => {
                            setPrivacy('public')
                            setImportant(0)
                          }}>
                            <div className='d-flex align-items-top'>
                              <span className='material-symbols-outlined' style={{ 'fontSize': 'large', 'verticalAlign': 'middle' }}>
                                public
                              </span>
                              <div className='data ms-2'>
                                <h6>pública</h6>
                              </div>
                            </div>
                          </Dropdown.Item>

                          <Dropdown.Item className=' p-3' to='#'  onClick={() => {
                            setPrivacy('friends')
                            setImportant(0)
                          }}>
                            <div className='d-flex align-items-top'>
                              <span className='material-symbols-outlined' style={{ 'fontSize': 'large', 'verticalAlign': 'middle' }}>
                                group
                              </span>
                              <div className='data ms-2'>
                                <h6>amigos</h6>
                              </div>
                            </div>
                          </Dropdown.Item>

                          <Dropdown.Item className=' p-3' to='#'  onClick={() => {
                            setPrivacy('public')
                            setImportant(1)
                          }}>
                            <div className='d-flex align-items-top'>
                              <span className='material-symbols-outlined' style={{ 'fontSize': 'large', 'verticalAlign': 'middle' }}>
                                keep
                              </span>
                              <div className='data ms-2'>
                                <h6>aviso importante</h6>
                              </div>
                            </div>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </li>
                  ) : ''
                }

                {
                  !props.group_id && important ? (
                    <li className='d-flex' style={{ width: '20rem' }}>
                      <Col md='6' style={{ marginRight: '3px' }}>
                          <Form.Label
                              className='form-label'
                              htmlFor='validationCustom03'
                          >
                              Data limite
                          </Form.Label>
                          <Form.Control className='input-group-edit' placeholder='Data início' type='date' value={dataImportant} onChange={(e) => setDataImportant(e.target.value)} aria-describedby='inputGroupPrepend' id='validationCustom03' required />
                      </Col>

                      <Col md='6' className=''>
                          <Form.Label
                              className='form-label'
                              htmlFor='validationCustom04'
                          >
                              Hora limite
                          </Form.Label>
                          <Form.Control className='input-group-edit' placeholder='Hora início' type='time' value={horaImportant} onChange={(e) => setHoraImportant(e.target.value)} aria-describedby='inputGroupPrepend' id='validationCustom04' required />
                      </Col>
                    </li>
                  ) : ''
                }
              </ul>

            </div>
            <div>
              <ul className='list-inline m-0 p-0 d-flex align-items-center gap-4'>
                <li>
                  <button disabled={showSpinner} type='button' className='btn btn-primary px-4' onClick={(e) => handleSubmit(e)}>
                    {showSpinner ? <div className="spinner-border spinner-border-sm" role="status"></div> : <div className='fw-semibold'>Publicar</div>}
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <Modal
          show={show}
          onHide={handleClose}
          centered
          id='custom-post-modal'
        >
          <div className='modal-header d-flex justify-content-between'>
            <h5 className='modal-title' id='post-modalLabel'>
              Create Post
            </h5>
            <Link to='#' className='lh-1' onClick={handleClose}>
              <span className='material-symbols-outlined'>close</span>
            </Link>
          </div>
          <Modal.Body>
            <div className='d-flex align-items-center'>
              <div className='user-img'>
                <img
                  loading='lazy'
                  src={user1}
                  alt='userimg'
                  className='avatar-60 rounded-circle img-fluid'
                />
              </div>
              <form className='post-text ms-3 w-100 '>
                <input
                  type='text'
                  className='form-control rounded'
                  placeholder='Write something here...'
                  style={{ border: 'none' }}
                />
              </form>
            </div>
            <hr />
            <ul className='d-flex flex-wrap align-items-center list-inline m-0 p-0'>
              <li className='col-md-6 mb-3'>
                <div className='bg-primary-subtle rounded p-2 pointer me-3'>
                  <Link to='#' className='custom-link-color d-inline-block fw-medium text-body'>
                    <span className='material-symbols-outlined align-middle font-size-20 me-1'>
                      add_a_photo
                    </span>{' '}
                    Photo/Video
                  </Link>
                </div>
              </li>
              <li className='col-md-6 mb-3'>
                <div className='bg-primary-subtle rounded p-2 pointer me-3'>
                  <Link to='#' className='custom-link-color d-inline-block fw-medium text-body'>
                    <span className='material-symbols-outlined align-middle font-size-20 me-1'>
                      group
                    </span>{' '}
                    Tag Friend
                  </Link>
                </div>
              </li>
              <li className='col-md-6 mb-3'>
                <div className='bg-primary-subtle rounded p-2 pointer me-3'>
                  <Link to='#' className='custom-link-color d-inline-block fw-medium text-body'>
                    <span className='material-symbols-outlined align-middle font-size-20 me-1'>
                      sentiment_satisfied
                    </span>{' '}
                    Feeling/Activity
                  </Link>
                </div>
              </li>
              <li className='col-md-6 mb-3'>
                <div className='bg-primary-subtle rounded p-2 pointer me-3'>
                  <Link to='#' className='custom-link-color d-inline-block fw-medium text-body'>
                    <span className='material-symbols-outlined align-middle font-size-20 me-1'>
                      location_on
                    </span>{' '}
                    Check in
                  </Link>
                </div>
              </li>
              <li className='col-md-6 mb-3'>
                <div className='bg-primary-subtle rounded p-2 pointer me-3'>
                  <Link to='#' className='custom-link-color d-inline-block fw-medium text-body'>
                    <span className='material-symbols-outlined align-middle font-size-20 me-1'>
                      live_tv
                    </span>{' '}
                    Live Video
                  </Link>
                </div>
              </li>
              <li className='col-md-6 mb-3'>
                <div className='bg-primary-subtle rounded p-2 pointer me-3'>
                  <Link to='#' className='custom-link-color d-inline-block fw-medium text-body'>
                    <span className='material-symbols-outlined align-middle font-size-20 me-1'>
                      gif_box
                    </span>{' '}
                    GIF
                  </Link>
                </div>
              </li>
              <li className='col-md-6 mb-3'>
                <div className='bg-primary-subtle rounded p-2 pointer me-3'>
                  <Link to='#' className='custom-link-color d-inline-block fw-medium text-body'>
                    <span className='material-symbols-outlined align-middle font-size-20 me-1'>
                      celebration
                    </span>{' '}
                    Watch Party
                  </Link>
                </div>
              </li>
              <li className='col-md-6 mb-3'>
                <div className='bg-primary-subtle rounded p-2 pointer me-3'>
                  <Link to='#' className='custom-link-color d-inline-block fw-medium text-body'>
                    <span className='material-symbols-outlined align-middle font-size-20 me-1'>
                      sports_esports
                    </span>{' '}
                    Play with Friends
                  </Link>
                </div>
              </li>
            </ul>
            <hr />
            <div className='other-option'>
              <div className='d-flex align-items-center justify-content-between'>
                <div className='d-flex align-items-center'>
                  <div className='user-img me-3'>
                    <img
                      loading='lazy'
                      src={user1}
                      alt='userimg'
                      className='avatar-60 rounded-circle img-fluid'
                    />
                  </div>
                  <h6>Your Story</h6>
                </div>
                <div className='card-post-toolbar'>
                  <Dropdown>
                    <Dropdown.Toggle
                      className='btn btn-primary'
                      data-bs-toggle='dropdown'
                      aria-haspopup='true'
                      aria-expanded='false'
                      role='button'
                    >
                      Friend
                      {/* <span className='btn btn-primary'>Friend</span> */}
                    </Dropdown.Toggle>
                    <Dropdown.Menu className=' m-0 p-0'>
                      <Dropdown.Item className='p-3' href='#'>
                        <div className='d-flex align-items-top'>
                          <span className='material-symbols-outlined'>save</span>
                          <div className='data ms-2'>
                            <h6>Public</h6>
                            <p className='mb-0'>Anyone on or off Facebook</p>
                          </div>
                        </div>
                      </Dropdown.Item>
                      <Dropdown.Item className='p-3' href='#'>
                        <div className='d-flex align-items-top'>
                          <span className='material-symbols-outlined'>cancel</span>
                          <div className='data ms-2'>
                            <h6>Friends</h6>
                            <p className='mb-0'>Your Friend on facebook</p>
                          </div>
                        </div>
                      </Dropdown.Item>
                      <Dropdown.Item className='p-3' href='#'>
                        <div className='d-flex align-items-top'>
                          <span className='material-symbols-outlined'>person_remove</span>
                          <div className='data ms-2'>
                            <h6>Friends except</h6>
                            <p className='mb-0'>Don't show to some friends</p>
                          </div>
                        </div>
                      </Dropdown.Item>
                      <Dropdown.Item className='p-3' href='#'>
                        <div className='d-flex align-items-top'>
                          <span className='material-symbols-outlined'>notifications</span>
                          <div className='data ms-2'>
                            <h6>Only Me</h6>
                            <p className='mb-0'>Only me</p>
                          </div>
                        </div>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </div>
            <Button variant='primary' className='d-block w-100 mt-3'>
              Post
            </Button>
          </Modal.Body>
        </Modal>
      </div>
      <div
        className={`modal-backdrop fade ${show ? 'show' : 'd-none'}`}
        onClick={handleClose}
      ></div>
    </>
  )
}

export default CreatePost
