import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Card, Button, Modal } from 'react-bootstrap'
import { Link, useParams } from 'react-router-dom'
import Cookies from 'js-cookie'
import api from '../../services/api'
import { toast } from 'react-toastify'
import AnimationWarningLottie from '../../components/AnimationWarningDeleteConfim/AnimationWarningLottie'
import AnimationWarningApproveLottie from '../../components/AnimationWarningApproveConfim/AnimationWarningApproveConfimLottie'
import { useMemberRequests } from '../../context/MemberRequestContext'
import loader from '../../assets/images/page-img/page-load-loader.gif'
import io from 'socket.io-client'

const MemberRequest = () => {
  let { id } = useParams()
  const token = Cookies.get('token')
  const loged_user = Cookies.get('userId')
  const {memberRequests, fetchMemberRequests} = useMemberRequests()
  const [modalShow, setModalShow] = useState(false)
  const [modalAcceptShow, setModalAccpetShow] = useState(false)
  const [userIdToAction, setUserIdToAction] = useState('')
  const [userNameToAction, setUserNameToAction] = useState('')
  const [usersPerPage] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const nPages = Math.ceil(memberRequests.total / usersPerPage)
  const [isLoading, setIsLoading] = useState(false)

  const socket = io(process.env.REACT_APP_API_SOCKET_URL, {
    autoConnect: true,
    withCredentials: true,
  })

  useEffect(() => {
    setIsLoading(true)
    fetchMemberRequests(currentPage, usersPerPage, id).then(() => {
      setIsLoading(false)
    }).catch(() => {
      setIsLoading(false)
    })
  }, [currentPage, usersPerPage, fetchMemberRequests])

  const handleMemberAction = async (memberId, event) => {
    event.preventDefault()
    try {
      await api.post(`/api/acceptMemberRequest`, { member_id: memberId, loged_user, group_id: id }, {
        headers: { Authorization: `Bearer ${token}` },
      }).then(() => {
        toast.success('Membro adicionado', { autoClose: 1000 })
        setModalAccpetShow(false)
        fetchMemberRequests(1, 10, id)
      })
    } catch (error) {
      console.error('Falha ao adicionar membro', error)
      toast.error('Falha ao adicionar membro.')
    }
  }

  const showDeleteRequestConfirmationModal = (userId, userName) => {
    setModalShow(true)
    setUserIdToAction(userId)
    setUserNameToAction(userName)
  }

  const deleteRequest = async (memberId) => {
    try {
      await api.post(`/api/blockMemberRequest`, { member_id: memberId, loged_user, group_id: id }, {
        headers: { Authorization: `Bearer ${token}` },
      }).then(() => {
        toast.success('Pedido de membro bloqueado com sucesso!', { autoClose: 1000 })
        fetchMemberRequests(1, 10, id)
        setModalShow(false)
      })
    } catch (error) {
      console.error('Falha ao bloquear membro', error)
      toast.error('Falha ao bloquear membro.')
    }
  }

  function DeleteRequestConfirmationModal(props) {

    return (
      <Modal
        {...props}
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header id='modalHeader' closeButton>
          <div id='divModalTitle'>
            <Modal.Title id='modalTitle'>
              Recusar pedido de membro
            </Modal.Title>
          </div>
        </Modal.Header>
        <Modal.Body id='modalBody'>
          <div className='d-flex justify-content-center m-4'>
            <AnimationWarningLottie />
          </div>
          <div className='d-flex'>
            <p>
              Você tem certeza de que deseja recusar o pedido de membro de {props.username} ?
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer id='modalFooter'>
          <Button id='yesButton' onClick={() => deleteRequest(props.userid)}>Sim</Button>
          <Button id='noButton' onClick={props.onHide}>Não</Button>
        </Modal.Footer>
      </Modal>
    )
  }

  const showAcceptRequestConfirmationModal = (userId, userName, e) => {
    setModalAccpetShow(true)
    setUserIdToAction(userId)
    setUserNameToAction(userName)
  }

  function AcceptRequestConfirmationModal(props) {

    return (
      <Modal
        {...props}
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header id='modalHeader' closeButton>
          <div id='divModalTitle'>
            <Modal.Title id='modalTitle'>
              Aceitar pedido de membro
            </Modal.Title>
          </div>
        </Modal.Header>
        <Modal.Body id='modalBody'>
          <div className='d-flex justify-content-center m-4'>
            <AnimationWarningApproveLottie />
          </div>
          <div className='d-flex'>
            <p>
              Deseja aceitar o pedido de membro de {props.username} ?
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer id='modalFooter'>
          <Button id='yesButton' onClick={(e) => handleMemberAction(props.userid, e)}>Sim</Button>
          <Button id='noButton' onClick={props.onHide}>Não</Button>
        </Modal.Footer>
      </Modal>
    )
  }

  const Pagination = () => {
    const pageNumbers = []
    if (nPages <= 1) return null

    for (let i = 1; i <= Math.ceil(memberRequests.total / usersPerPage); i++) {
      pageNumbers.push(i)
    }

    return (
      <nav className='d-flex justify-content-center align-items-center'>
        <ul className='pagination mb-4'>
          <li className='page-item'><Link className='page-link' onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}>
            <i className='icon material-symbols-outlined d-flex'>
              arrow_back
            </i></Link></li>
          {pageNumbers.map((number, index) => (
            <li key={index} className={`page-item ${currentPage === number ? 'active' : ''}`}>
              <Link className='page-link' onClick={() => typeof number === 'number' && setCurrentPage(number)}> {number}</Link></li>
          ))}
          <li className='page-item'><Link className='page-link' onClick={() => setCurrentPage(prev => Math.min(prev + 1, nPages))}>
            <i className='icon material-symbols-outlined d-flex'>
              arrow_forward
            </i></Link></li>
        </ul>
      </nav>
    )
  }

  return (
    <>
      <div id='content-page' className='content-inner'>
        <Container>
          <Row>
            <Col sm='12'>
              <Card>
                <Card.Header>
                  <div className='header-title'>
                    <h4 className='card-title'>Solicitações de entrada</h4>
                  </div>
                </Card.Header>
                <Card.Body className='pt-0'>
                  {isLoading ? (
                    <div className='col-sm-12 text-center'>
                      <img src={loader} alt='loader' style={{ height: '100px' }} />
                    </div>
                  ) : memberRequests.data && memberRequests.data.length > 0 ? (
                    <ul className='request-list list-inline m-0 p-0'>
                      {memberRequests.data.map((member, index) => (
                        <li className='d-flex align-items-center justify-content-between' key={index}>
                          <div className='d-flex align-items-center'>
                            <div className='user-img img-fluid flex-shrink-0'>
                              <img
                                src={member.image_profile}
                                alt='story-img'
                                className='rounded-circle avatar-40'
                              />
                            </div>
                            <div className='flex-grow-1 ms-3'>
                              <Link to={`/users/profile/${member.user_id}`} >
                                {member.name}
                              </Link>
                            </div>
                          </div>
                          <div className='d-flex align-items-center mt-2 mt-md-0'>
                            <div className='confirm-click-btn'>
                              <Button onClick={() => showAcceptRequestConfirmationModal(member.id_user, member.name)} className='me-2 btn btn-success-subtle rounded confirm-btn p-1 lh-1'>
                                <i className='material-symbols-outlined font-size-14'>
                                  done
                                </i>
                              </Button>
                            </div>
                            <div className='confirm-click-btn'>
                              <Button onClick={() => showDeleteRequestConfirmationModal(member.id_user, member.name)} className='me-2 btn btn-danger-subtle rounded confirm-btn p-1 lh-1'>
                                <i className='material-symbols-outlined font-size-14'>
                                  close
                                </i>
                              </Button>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <div className='text-center'>
                      <p>Não há solicitações de entrada no momento.</p>
                    </div>
                  )}
                  <Pagination
                    nPages={nPages}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                  />
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <DeleteRequestConfirmationModal
            show={modalShow}
            onHide={() => setModalShow(false)}
            userid={userIdToAction}
            username={userNameToAction}
          />

          <AcceptRequestConfirmationModal
            show={modalAcceptShow}
            onHide={() => setModalAccpetShow(false)}
            userid={userIdToAction}
            username={userNameToAction}
          />
        </Container>
      </div>
    </>
  )
}

export default MemberRequest
