import React, { useEffect, useRef, useState } from 'react';
import api from '../services/api';
import Cookies from 'js-cookie';

const InactivityHandler = () => {
    const timeoutRef = useRef(null);
    const inactivityTime = 10 * 60 * 1000;
    const [isAway, setIsAway] = useState(false);
    const loged_user = Cookies.get('userId');
    const debounceRef = useRef(false);

    const handleUserInactive = async () => {
        const userStatus = Cookies.get("userStatus");
        if (userStatus === "invisible") {
            return;
        }

        try {
            await api.put('/api/users/changeStatus', {
                logged_user: loged_user,
                status: 'away'
            });
            setIsAway(true);
            Cookies.set("userStatus", 'away', { expires: 7, secure: true, sameSite: "None" });
        } catch (error) {
            console.error('Erro ao alterar status para ausente:', error);
        }
    };

    const handleUserActive = async () => {
        const userStatus = Cookies.get("userStatus");
        if (userStatus === "invisible") {
            return;
        }

        if (isAway && !debounceRef.current) {
            debounceRef.current = true;

            try {
                await api.put('/api/users/changeStatus', {
                    logged_user: loged_user,
                    status: 'online'
                });
                setIsAway(false);
                Cookies.set("userStatus", 'online', { expires: 7, secure: true, sameSite: "None" });
            } catch (error) {
                console.error('Erro ao alterar status para online:', error);
            } finally {
                debounceRef.current = false;
            }
        }
        resetInactivityTimeout();
    };

    const resetInactivityTimeout = () => {
        const userStatus = Cookies.get("userStatus");
        if (!isAway && userStatus !== "invisible") {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
            timeoutRef.current = setTimeout(handleUserInactive, inactivityTime);
        }
    };

    useEffect(() => {
        const handleActivity = () => {
            handleUserActive();
        };

        window.addEventListener('mousemove', handleActivity);
        window.addEventListener('keydown', handleActivity);
        window.addEventListener('mousedown', handleActivity);

        resetInactivityTimeout();

        return () => {
            window.removeEventListener('mousemove', handleActivity);
            window.removeEventListener('keydown', handleActivity);
            window.removeEventListener('mousedown', handleActivity);
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, [isAway]);

    return null;
};

export default InactivityHandler;
