import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Button, Table, Card } from "react-bootstrap";
import { Link, useParams, useNavigate } from "react-router-dom";
import api from "../../services/api";
import { toast } from "react-toastify";
import InputGroup from 'react-bootstrap/InputGroup';
import { IoHourglass } from "react-icons/io5";
import Cookies from 'js-cookie';

const PollEdit = ({}) => {
    const [validated, setValidated] = useState(false);
    const { poll_id } = useParams(); // Captura o ID da enquete da URL
    const userId = Cookies.get('userId')
    const [question, setQuestion] = useState("");
    const history = useNavigate();
    const [options, setOptions] = useState(["", ""]);
    const [dataInicio, setDataInicio] = useState('');
    const [horaInicio, setHoraInicio] = useState('')
    const [dataFim, setDataFim] = useState('');
    const [horaFim, setHoraFim] = useState('')
    const [fixed, setFixed] = useState(false)
    const [fixedLimitDate, setFixedLimitDate] = useState('')
    const [fixedLimitTime, setFixedLimitTime] = useState('')
    const [active, setActive] = useState(false)
    const [showSpinner, setShowSpinner] = useState(false)
    const [groups, setGroups] = useState([])
    const [selectedGroup, setSelectedGroup] = useState('Sem grupo')

    // Carrega os dados da enquete ao montar o componente
    useEffect(() => {
        const fetchPoll = async () => {
            try {
                const response = await api.get(`/api/polls/edit/${poll_id}`);

                if (response.data) {
                    setQuestion(response.data.question);
                    setOptions(response.data.options || []);
                    setFixed(response.data.post?.important);
                    setSelectedGroup(response.data.post?.group_id ?? 'Sem grupo');

                    if (response.data.post?.important_limit_date) {
                        setFixedLimitDate(response.data.post?.important_limit_date?.split(' ')[0])
                        setFixedLimitTime(response.data.post?.important_limit_date?.split(' ')[1])
                    }

                    setActive(response.data.active)

                    setDataInicio(response.data.start_date.split(' ')[0]);
                    setHoraInicio(response.data.start_date.split(' ')[1]);
                    setDataFim(response.data.end_date ? response.data.end_date.split(' ')[0] : '');
                    setHoraFim(response.data.end_date ? response.data.end_date.split(' ')[1] : '');
                } else {
                    toast.error("Erro ao carregar a enquete.");
                }
            } catch (error) {
                console.error("Erro ao carregar a enquete:", error);
                toast.error("Erro ao buscar dados da enquete.");
            }
        };
        fetchPoll();
    }, [poll_id]);

    useEffect(() => {
        let cancel = false

        async function fetchData() {
            try {
                const response = await api.get(`/api/groupsByUser/${userId}`)

                if (!cancel) {
                    const data = response.data
                    setGroups(data.groups)
                }
            } catch (error) {
                console.error('Failed to fetch user groups:', error)
                toast.error('Erro ao recuperar grupos', { autoClose: 3000 })
            }
        }

        fetchData()

        return () => {
            cancel = true
        }
    }, [])

    // Adiciona uma nova opção
    const addOption = () => {
        setOptions([...options, ""]);
    };

    // Atualiza o valor de uma opção
    const handleOptionChange = (index, value) => {
        const updatedOptions = [...options];
        updatedOptions[index] = value;
        setOptions(updatedOptions);
    };

    // Remove uma opção
    const removeOption = (index) => {
        const updatedOptions = options.filter((_, i) => i !== index);
        setOptions(updatedOptions);
    };

    // Envia as alterações ao back-end
    const handleSubmit = async (e) => {
        e.preventDefault();

        setShowSpinner(true)

        const filledOptions = options.filter((option) => option.trim() !== "");
        if (question.trim() === "" || filledOptions.length < 2) {
            toast.error("A enquete deve ter uma pergunta e pelo menos duas opções válidas.");
            return;
        }

        if (question === '') {
            setShowSpinner(false)
            toast.error('Por favor preencha a pergunta.', { autoClose: 3000 })
        } else if (fixed && fixedLimitDate === '') {
            setShowSpinner(false)
            toast.error('Por favor selecione a data limite pra manter a enquete fixada no feed.', { autoClose: 3000 })
        } else if (fixed && fixedLimitTime === '') {
            setShowSpinner(false)
            toast.error('Por favor selecione a hora limite pra manter a enquete fixada no feed.', { autoClose: 3000 })
        } else {
            setValidated(true)

            const pollData = {
                question,
                options: filledOptions,
                start_date: `${dataInicio} ${horaInicio}`,
                end_date: `${dataFim} ${horaFim}`,
                active: active,
                fixed: fixed,
                fixed_limit_date: fixedLimitDate,
                fixed_limit_time: fixedLimitTime,
                group_id: selectedGroup && selectedGroup !== 'Sem grupo' ? selectedGroup : null
            };

            try {
                const response = await api.put(`/api/polls/update/${poll_id}`, pollData);
                if (response.data.error) {
                    toast.error(response.data.error);
                } else {
                    setShowSpinner(false)
                    toast.success("Enquete atualizada com sucesso!", {
                        autoClose: 300,
                        onClose: () => {
                            history('/polls/index')
                        }
                    })
                }
            } catch (error) {
                console.error("Erro ao atualizar a enquete:", error);
                toast.error("Erro ao atualizar a enquete.");
            }
        }
    };

    const handleDataInicioChange = (e) => {
        setDataInicio(e.target.value)
    }

    const handleHoraInicioChange = (e) => {
        setHoraInicio(e.target.value)
    }

    const handleDataFimChange = (e) => {
        setDataFim(e.target.value)
    }

    const handleHoraFimChange = (e) => {
        setHoraFim(e.target.value)
    }


    return (
        <div id="content-page" className="content-inner">
            <Container>
                <Row>
                    <Col sm="12">
                        <Card className="position-relative inner-page-bg bg-primary p-5">
                            <div
                                className="inner-page-title"
                                style={{ display: "flex", justifyContent: "space-between", width: "-webkit-fill-available" }}
                            >
                                <h3 className="text-white">Editar Enquete</h3>
                                <Link to="/polls/index">
                                    <Button>
                                        <i className="icon material-symbols-outlined d-flex">arrow_back</i>
                                    </Button>
                                </Link>
                            </div>
                        </Card>
                    </Col>
                    <Col sm="12" lg="12">
                        <Card>
                            <Card.Header className="d-flex justify-content-between">
                                <div className="header-title">
                                    <h4 className="card-title">Editar enquete</h4>
                                </div>
                            </Card.Header>
                            <Card.Body className="pt-0 d-flex flex-wrap gap-2">
                                <Col>
                                    <Form onSubmit={handleSubmit} validated={validated}>
                                        <Form.Group controlId="formQuestion" className="mb-1">
                                            <div className="d-flex gap-2">
                                                <div className="w-50">
                                                    <Form.Label>Pergunta</Form.Label>
                                                    <Form.Control
                                                        className="my-2"
                                                        type="text"
                                                        placeholder="Digite sua pergunta"
                                                        value={question}
                                                        onChange={(e) => setQuestion(e.target.value)}
                                                        required
                                                    />
                                                </div>
                                                <div className="w-50">
                                                    <label htmlFor='groups' className='form-label'>Grupo</label>
                                                    <select
                                                        id='groups'
                                                        value={selectedGroup}
                                                        onChange={(e) => setSelectedGroup(e.target.value)}
                                                        className='form-control my-2'
                                                    >
                                                        {
                                                            groups.map((group, index) => (
                                                                <option value={group.value} key={`group_${index}`}>
                                                                    {group.label}
                                                                </option>
                                                            ))
                                                        }
                                                    </select>

                                                </div>
                                            </div>
                                            <Button variant="secondary" onClick={addOption}>
                                                Adicionar Opção
                                            </Button>
                                        </Form.Group>

                                        <Table striped bordered className='shadowCustom'>
                                            <thead className='bg-dark'>
                                                <tr>
                                                    <th colSpan={2}>
                                                        <h6 className="text-white text-center">
                                                            <IoHourglass className='mb-1' /> Periodo
                                                        </h6>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr className='rowGenForm'>
                                                    <td>
                                                        <Col>
                                                            <h5 className="bg-success text-white p-1 mb-3 rounded text-center">
                                                                Inicio
                                                            </h5>
                                                            <InputGroup>
                                                                <InputGroup.Text className='bg-dark text-white' id="basic-addon1">Data</InputGroup.Text>
                                                                <input
                                                                    type="date"
                                                                    name="data_inicio"
                                                                    className="text-center form-control valid fw-semibold"
                                                                    id="data_inicio"
                                                                    aria-describedby="data_inicio"
                                                                    aria-invalid="false"
                                                                    value={dataInicio}
                                                                    onChange={handleDataInicioChange}
                                                                    required
                                                                />
                                                            </InputGroup>
                                                            <InputGroup className='mt-3'>
                                                                <InputGroup.Text className='bg-dark text-white' id="basic-addon1">Hora</InputGroup.Text>
                                                                <input
                                                                    type="time"
                                                                    name="hora_inicio"
                                                                    step="1"
                                                                    className="text-center form-control inteiro fw-semibold"
                                                                    id="hora_inicio"
                                                                    aria-describedby="hora_inicio"
                                                                    value={horaInicio}
                                                                    onChange={handleHoraInicioChange}
                                                                    required
                                                                />
                                                            </InputGroup>
                                                        </Col>
                                                    </td>
                                                    <td>
                                                        <Col>
                                                            <h5 className="bg-danger text-white p-1 mb-3 rounded text-center">
                                                                Final
                                                            </h5>
                                                            <InputGroup>
                                                                <InputGroup.Text className='bg-dark text-white' id="basic-addon1">Data</InputGroup.Text>
                                                                <input
                                                                    type="date"
                                                                    name="data_fim"
                                                                    className="text-center form-control valid fw-semibold"
                                                                    id="data_fim"
                                                                    aria-describedby="data_fim"
                                                                    aria-invalid="false"
                                                                    value={dataFim}
                                                                    onChange={handleDataFimChange}
                                                                    required
                                                                />
                                                            </InputGroup>
                                                            <InputGroup className='mt-3'>
                                                                <InputGroup.Text className='bg-dark text-white' id="basic-addon1">Hora</InputGroup.Text>
                                                                <input
                                                                    type="time"
                                                                    name="hora_fim"
                                                                    step="1"
                                                                    className="text-center form-control inteiro fw-semibold"
                                                                    id="hora_fim"
                                                                    value={horaFim}
                                                                    onChange={handleHoraFimChange}
                                                                    aria-describedby="hora_fim"
                                                                />
                                                            </InputGroup>
                                                        </Col>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </Table>

                                        <Form.Label className="mt-3">Opções</Form.Label>
                                        <div className="d-flex flex-wrap gap-2">
                                            {options.map((option, index) => (
                                                <div key={index} className="d-flex col-md-5 mb-2">
                                                    <Form.Control
                                                        type="text"
                                                        placeholder={`Opção ${index + 1}`}
                                                        value={option}
                                                        onChange={(e) => handleOptionChange(index, e.target.value)}
                                                        required
                                                    />
                                                    <Button
                                                        type="button"
                                                        variant="danger"
                                                        onClick={() => removeOption(index)}
                                                        disabled={options.length <= 2}
                                                        className="ms-2 rounded"
                                                    >
                                                        <span className='material-symbols-outlined' style={{ 'fontSize': 'large', 'verticalAlign': 'middle' }}>
                                                            delete
                                                        </span>
                                                    </Button>
                                                </div>
                                            ))}
                                        </div>


                                        <Col md='12' className=''>
                                            <Form.Check
                                                type='switch'
                                                label='Fixar no feed'
                                                checked={fixed}
                                                onChange={(e) => setFixed(e.target.checked)}

                                            />
                                        </Col>

                                        {
                                            fixed ? (
                                                <>
                                                    <Col md='6' className=''>
                                                        <Form.Label
                                                            className='form-label'
                                                        >
                                                            Data limite
                                                        </Form.Label>
                                                        <Form.Control className='input-group-edit' placeholder='Data limite' type='date' value={fixedLimitDate} onChange={(e) => setFixedLimitDate(e.target.value)} />
                                                    </Col>

                                                    <Col md='6' className=''>
                                                        <Form.Label
                                                            className='form-label'
                                                        >
                                                            Hora limite
                                                        </Form.Label>
                                                        <Form.Control className='input-group-edit' placeholder='Hora limite' type='time' value={fixedLimitTime} onChange={(e) => setFixedLimitTime(e.target.value)} />
                                                    </Col>
                                                </>
                                            ) : ''
                                        }

                                        <Col md='12' className=''>
                                            <Form.Check
                                                type='switch'
                                                label='Ativo'
                                                checked={active}
                                                onChange={(e) => setActive(e.target.checked)}

                                            />
                                        </Col>

                                        <div className="d-flex justify-content-between mt-3">
                                            <Button disabled={showSpinner} variant="primary" type="submit">
                                                {showSpinner ? <div className="spinner-border spinner-border-sm" role="status"></div> : 'Salvar'}
                                            </Button>
                                        </div>


                                    </Form>
                                </Col>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default PollEdit;
