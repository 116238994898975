import React, { useEffect, useState } from "react";
import { Row, Col, Container, Button, Card } from "react-bootstrap";
import { Link, useParams } from 'react-router-dom';
import api from '../../services/api'
import loader from "../../assets/images/page-img/page-load-loader.gif";
import './style.css'


const KnowledgeEdit = () => {
    const [formData, setFormData] = useState(false);
    let { id } = useParams();
    const [isLoading, setIsLoading] = useState(false);

    const fetchData = async () => {
        try {
            const response = await api.post(`/api/knowledge/show/${id}`)
            const {  title, content } = response.data.knowledge;
            setFormData({ title, content });
        } catch (error) {
            console.error('Failed to fetch knowledge data:', error);
        }
    };

    useEffect(() => {
        setIsLoading(true);
        fetchData().then(() => {
            setIsLoading(false);
        }).catch(() => {
            setIsLoading(false);
        });
    }, []);


    return (
        <>
            <div id='content-page' className='content-inner'>
                <Container>
                    <Row>
                        <Col sm="12" lg="12">
                            <Card>
                                <Card.Header className="d-flex justify-content-between">
                                    <h4 className="card-title">{formData.title}</h4>
                                    <Link to='/knowledge/list'>
                                        <Button><i className="icon material-symbols-outlined d-flex">
                                            arrow_back
                                        </i></Button>
                                    </Link>
                                </Card.Header>
                                <Card.Body className="pt-0">
                                    {isLoading ? (
                                        <div className="col-sm-12 text-center">
                                            <img src={loader} alt="loader" style={{ height: "100px" }} />
                                        </div>
                                    ) : (
                                        <div
                                            className="content-html"
                                            dangerouslySetInnerHTML={{ __html: formData.content }}
                                        />
                                    )
                                }
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default KnowledgeEdit;