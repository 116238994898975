import React, { useEffect, useState, useMemo, useRef } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Form, Tab, Nav, Dropdown, DropdownButton, Button, CloseButton, Row } from 'react-bootstrap'
import { Swiper, SwiperSlide } from 'swiper/react'
import { toast } from 'react-toastify'
import api from '../../services/api'
import Cookies from 'js-cookie'
import { io } from 'socket.io-client'
import { useDispatch } from 'react-redux'
import { set_receive_new_messages } from "../../store/setting/actions"
import EmojiPicker from 'emoji-picker-react'
import { IoCheckmarkDoneSharp } from "react-icons/io5"
import { FaArrowDown } from "react-icons/fa"
import { PhotoProvider, PhotoView } from 'react-photo-view';
import { MdAssistant } from "react-icons/md";
import 'react-photo-view/dist/react-photo-view.css';
import { saveAs } from 'file-saver';
import './chat.css'
import Table from 'react-bootstrap/Table';
import ButtonGroup from 'react-bootstrap/ButtonGroup';

//img
import userGenericImage from '../../assets/images/generic_user.png'
import logoAtmoAi from '../../assets/images/ATMO_AI.png'

//scrollbar
import Scrollbar from 'smooth-scrollbar'

import { useInView } from 'react-intersection-observer'


const Chat = () => {
  useEffect(() => {
    Scrollbar.init(document.querySelector('.data-scrollbar'))
  })

  const { ref, inView } = useInView({
    threshold: 0.1,
  })

  const customId = 'success-toast-id'
  const token = Cookies.get('token')
  const userStatus = Cookies.get('userStatus')
  const socket = useRef(null)
  const chatBodyRef = useRef(null)
  const chatBodyGroupsRef = useRef(null)
  const history = useNavigate()
  const dispatch = useDispatch()
  const loged_user = Cookies.get('userId')
  const [active, setActive] = useState('first')
  const [search, setSearch] = useState('')
  const [friends, setFriends] = useState([])
  const [groups, setGroups] = useState([])
  const [newText, setNewText] = useState('')
  const [friendMessages, setFriendMessages] = useState([])
  const [groupMessages, setGroupMessages] = useState([])
  const [selectedFriend, setSelectedFriend] = useState('')
  const [selectedGroup, setSelectedGroup] = useState('')
  const [textMessage, setTextMessage] = useState('')
  const [showPicker, setShowPicker] = useState(false)
  const [showScrollButton, setShowScrollButton] = useState(false)
  const [deletedMessage, setDeletedMessage] = useState([])
  const [editedMessages, setEditedMessages] = useState([])
  const [showFriends, setShowFriends] = useState(true)
  const [showGroups, setShowGroups] = useState(true)
  const [showEdit, setShowEdit] = useState(false)
  const [blocked, setBlocked] = useState(false)
  const [wasBlocked, setwasBlocked] = useState(selectedFriend?.someone_blocked)
  const [editedChatId, setEditedChatId] = useState('')
  const [isLoading, setIsLoading] = useState(false);
  const [selectedMode, setSelectedMode] = useState('text');
  
  //drag&touch
  const [isVisible, setIsVisible] = useState(false);
  const [startX, setStartX] = useState(0);
  const [translateX, setTranslateX] = useState(0); // Controla a posição da animação

  const handleDragStart = (e) => {
    setStartX(e.clientX || e.touches[0].clientX); // Ponto inicial do arrasto
  };

  const handleDrag = (e) => {
    const currentX = e.clientX || (e.touches && e.touches[0].clientX);
    if (currentX) {
      const distance = currentX - startX;
      setTranslateX(distance); // Atualiza a posição da `div` durante o arrasto
    }
  };

  const handleDragEndOtherUser = (e) => {
    const endX = e.clientX || (e.changedTouches && e.changedTouches[0].clientX);
    const distance = endX - startX;

    if (distance > 15) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }

    setTranslateX(0);
  };

  const handleDragEnd = (e) => {
    const endX = e.clientX || (e.changedTouches && e.changedTouches[0].clientX);
    const distance = startX - endX;

    if (distance > 15) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }

    setTranslateX(0);
  };

  const toggleVisibility = () => {
    setIsVisible((prevState) => !prevState); // Alterna o estado de visibilidade
  };

  const handleSelect = (mode) => {
    setSelectedMode(mode);
  };

  const readMessages = async (friend) => {
    try {
      await api.post(`/api/chat/readMessages`, {
        logged_user: loged_user,
        friend: friend,
      });
    } catch (error) {
      console.error('Failed to read friend messages:', error);

      toast.error('Erro ao ler mensagens', {
        toastId: customId,
        autoClose: 3000,
      })
    }
  }

  const checkIfUserWasBlocked = async (userId) => {
    try {
      const response = await api.get(`/api/chat/checkIfUserWasBlocked/${userId}`)

      setBlocked(response.data.was_blocked)
      setwasBlocked(response.data.someone_blocked)
    } catch (error) {
      toast.error('Erro ao checar se usuário foi bloqueado', {
        toastId: customId,
        autoClose: 3000,
      })
    }
  }

  function updateEditedMessages(newItem) {
    setEditedMessages((prevItems) => {
      const existingItemIndex = prevItems.findIndex((item) => item.id === newItem.id);
      
      if (existingItemIndex !== -1) {
        return prevItems.map((item, index) =>
          index === existingItemIndex ? { ...item, text: newItem.text } : item
        );
      } else {
        return [...prevItems, newItem];
      }
    });
  }

  async function copyMessage(texto) {
    console.log(texto)
      if (navigator.clipboard && window.isSecureContext) {
          return navigator.clipboard.writeText(texto).then(() => {
              console.log("Texto copiado com sucesso!");
          }).catch(err => {
              console.error("Erro ao copiar o texto: ", err);
          });
      } else {
          let textArea = document.createElement("textarea");
          textArea.value = texto;

          textArea.style.position = "fixed";
          textArea.style.left = "-9999px";

          document.body.appendChild(textArea);
          textArea.select();

          try {
              document.execCommand("copy");
              console.log("Texto copiado com sucesso!");
          } catch (err) {
              console.error("Erro ao copiar o texto: ", err);
          }

          document.body.removeChild(textArea);
      }
  }

  const handleScroll = () => {
    const chatBody = chatBodyRef.current;
    const chatBodyGroup = chatBodyGroupsRef.current;

    if ((chatBody.scrollTop < chatBody.scrollHeight - chatBody.clientHeight - 100) || (chatBodyGroup.scrollTop < chatBodyGroup.scrollHeight - chatBodyGroup.clientHeight - 100)) {
      setShowScrollButton(true);
    } else {
      setShowScrollButton(false);
    }
  };

  const scrollToBottom = () => {
    if (chatBodyRef.current) {
      chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
    }

    if (chatBodyGroupsRef.current) {
      chatBodyGroupsRef.current.scrollTop = chatBodyGroupsRef.current.scrollHeight;
    }
  }

  const readFriendMessages = (friendId, friends) => {
    if (Array.isArray(friends)) {
      const updatedFriends = friends.map(friend => {
        if (friend.id == friendId) {
          return { ...friend, unread_messages: 0 };
        }
        return friend;
      });

      setFriends(updatedFriends)
    }
  }

  const onEmojiClick = (emojiObject, event) => {
    setTextMessage((prevInput) => prevInput + emojiObject.emoji);
    setShowPicker(false);
  }

  const getFriends = async () => {
    try {
      const response = await api.post(`/api/friends/show`, {
        loged_user,
        token,
      })

      const data = response.data
      setFriends(data)
    } catch (error) {
      console.error('Failed to fetch user data:', error)

      toast.error('Erro ao listar amigos', {
        toastId: customId,
        autoClose: 3000,
      })
    }
  }

  const getGroups = async () => {
    try {
      const response = await api.get(`/api/user/groups/${loged_user}`);

      const data = response.data;
      setGroups(data.groups)
    } catch (error) {
      console.error('Failed to fetch groups data:', error);

      toast.error('Erro ao listar grupos', {
        toastId: customId,
        autoClose: 3000,
      })
    }
  }

  const getMessages = async (friendId) => {
    try {
      const response = await api.get(`/api/chat/listMessagesByFriend/${loged_user}/${friendId}`);

      setGroupMessages([])
      setFriendMessages(response.data.messages)

    } catch (error) {
      console.error('Failed to get messages:', error);

      toast.error('Erro ao listar mensagens', {
        toastId: customId,
        autoClose: 3000,
      })
    }
  }

  const deleteMessage = async (messageId, isGroup = false, chatId) => {
    try {
      await api.delete(`/api/chat/delete/${messageId}`);

      setDeletedMessage([...deletedMessage, messageId])
      
      if (isGroup) {
        const updatedChats = groups.map(chat => {
          if (chat.id == chatId) {
            return { ...chat, latest_message: 'Mensagem apagada' };
          }
          return chat;
        });

        setGroups(updatedChats)
      } else {
        const updatedChats = friends.map(chat => {
          if (chat.id == chatId) {
            return { ...chat, latest_message: 'Mensagem apagada' };
          }
          return chat;
        });

        setFriends(updatedChats)
      }
    } catch (error) {
      console.error('Failed to delete message:', error);

      toast.error('Erro ao remover mensagem', {
        toastId: customId,
        autoClose: 3000,
      })
    }
  }

  const blockContact = async (contactId) => {
    try {
      await api.put(`/api/chat/block/${contactId}`);

      if (blocked) {
        setwasBlocked(false)
      }

      setBlocked(!blocked)
    } catch (error) {
      console.error('Failed to block contact:', error);

      toast.error('Erro ao bloquear/desbloquear contato', {
        toastId: customId,
        autoClose: 3000,
      })
    }
  }

  const editMessage = async (messageId , isGroup = false, chatId) => {
    try {
      if (newText === '') {
        toast.error('Por favor escreva a nova mensagem.', { autoClose: 3000 })
      } else {
        await api.put(`/api/chat/edit/${messageId}`, {
          newMessage: newText
        });

        updateEditedMessages({
          id: messageId,
          text: newText
        })

        setNewText('')
        setEditedChatId('')
        setShowEdit(false)

        if (isGroup) {
          const updatedChats = groups.map(chat => {
            if (chat.id == chatId) {
              return { ...chat, latest_message: newText };
            }
            return chat;
          });
  
          setGroups(updatedChats)
        } else {
          const updatedChats = friends.map(chat => {
            if (chat.id == chatId) {
              return { ...chat, latest_message: newText };
            }
            return chat;
          });
  
          setFriends(updatedChats)
        }
      }
    } catch (error) {
      console.error('Failed to edit message:', error);

      toast.error('Erro ao editar mensagem', {
        toastId: customId,
        autoClose: 3000,
      })
    }
  }

  const getGroupMessages = async (groupId) => {
    try {
      const response = await api.get(`/api/chat/listMessagesByGroup/${groupId}`);

      setFriendMessages([])
      setGroupMessages(response.data.messages)

    } catch (error) {
      console.error('Failed to get group messages:', error);

      toast.error('Erro ao listar mensagens de grupo', {
        toastId: customId,
        autoClose: 3000,
      })
    }
  }

  const setLastMessage = (message, chats, isFirstMessage = false, sending = false) => {
    if (Array.isArray(chats)) {
      const moveToTop = (updatedChats, index) => {
        const chatToMove = updatedChats.splice(index, 1)[0];
        return [chatToMove, ...updatedChats];
      };

      if (message.group_id) {
        if (sending) {
          const updatedChats = chats.map(chat => {
            if (chat.id == message.group_id) {
              return { ...chat, latest_message: message.message, latest_message_time: 'Agora' };
            }
            return chat;
          });

          const chatIndex = updatedChats.findIndex(chat => chat.id == message.group_id);
          const reorderedChats = moveToTop(updatedChats, chatIndex);

          setGroups(reorderedChats);
        } else {
          const updatedChats = chats.map(chat => {
            if (chat.id == message.group_id) {
              return { ...chat, latest_message: message.message, latest_message_time: 'Agora', unread_messages: chat.unread_messages + 1 };
            }
            return chat;
          });

          const chatIndex = updatedChats.findIndex(chat => chat.id == message.group_id);
          const reorderedChats = moveToTop(updatedChats, chatIndex);

          setGroups(reorderedChats);
        }

      } else if (!isFirstMessage) {
        if (sending) {
          const updatedChats = chats.map(chat => {
            if (chat.id == message.sender_id || chat.id == message.receiver_id) {
              return { ...chat, latest_message: message.message, latest_message_time: 'Agora' };
            }
            return chat;
          });

          const chatIndex = updatedChats.findIndex(chat =>
            chat.id == message.sender_id || chat.id == message.receiver_id
          );
          const reorderedChats = moveToTop(updatedChats, chatIndex);

          setFriends(reorderedChats);
        } else {
          const updatedChats = chats.map(chat => {
            if (chat.id == message.sender_id || chat.id == message.receiver_id) {
              return { ...chat, latest_message: message.message, latest_message_time: 'Agora', unread_messages: chat.unread_messages + 1 };
            }
            return chat;
          });

          const chatIndex = updatedChats.findIndex(chat =>
            chat.id == message.sender_id || chat.id == message.receiver_id
          );
          const reorderedChats = moveToTop(updatedChats, chatIndex);

          setFriends(reorderedChats);
        }
      }
    }
  }

  const changeStatus = (friendId, friends, status) => {
    if (Array.isArray(friends)) {
      const updatedFriends = friends.map(friend => {
        if (friend.id == friendId) {
          let newFriend = { ...friend, chat_status: status };

          if (selectedFriend && selectedFriend.id == friendId) {
            setSelectedFriend(newFriend)
          }

          return newFriend;
        }
        return friend;
      });

      setFriends(updatedFriends)
    }
  }

  const marcarComoLidas = (currentMessages) => {
    if (Array.isArray(currentMessages)) {
      const mensagensAtualizadas = currentMessages.map(message => {
        if (!message.readed) {
          return { ...message, readed: true };
        }
        return message;
      });

      setFriendMessages(mensagensAtualizadas)
    }
  };

  const sendMessage = async (e, groupId = null) => {
    e.preventDefault()

    if (selectedFriend.id === '0') {
      try {
      const messageToSend = textMessage; // Captura a mensagem do state
      sendMessageToChatGpt(messageToSend); // Envia a mensagem para a rota da API
      setTextMessage(''); // Limpa o campo de texto após o envio
  } catch (error) {
      console.error('Failed to send message:', error);

      toast.error('Erro ao enviar mensagem', {
        toastId: customId,
        autoClose: 3000,
      })
    }
  } else {
    setIsLoading(true);

    try {

      if (file) {
        const formData = new FormData()
        formData.append('message', file)
        formData.append('file', file)
        formData.append('type', 'image')
        formData.append('sender_id', loged_user)
        formData.append('receiver_id', groupId ? null : selectedFriend?.id)
        formData.append('group_id', '')
        formData.append('is_first_message', friendMessages.length === 0 ? true : false)
        
        const response = await api.post(`/api/chat/store`, formData)
          
        if (groupId) {
          setTextMessage('')
          setFriendMessages([...groupMessages, response.data.message])

          setLastMessage(response.data.message, groups, false, true)
        } else {
          if (friendMessages.length === 0) {
            getFriends()
          }

          setTextMessage('')
          setFile('')
          setFriendMessages([...friendMessages, response.data.message])

          setLastMessage(response.data.message, friends, friendMessages.length === 0, true)
          getMessages(groupId ? null : selectedFriend?.id)

        }
        
      }
      
      if (textMessage !== '') {
        const response = await api.post(`/api/chat/store`, {
          message: textMessage,
          type: 'text',
          sender_id: loged_user,
          receiver_id: groupId ? null : selectedFriend?.id,
          group_id: groupId,
          is_first_message: friendMessages.length === 0 ? true : false
        });

        if (groupId) {
          setTextMessage('')
          setFriendMessages([...groupMessages, response.data.message])

          setLastMessage(response.data.message, groups, false, true)
        } else {
          if (friendMessages.length === 0) {
            getFriends()
          }

          setTextMessage('')
          setFriendMessages([...friendMessages, response.data.message])

          setLastMessage(response.data.message, friends, friendMessages.length === 0, true)
        }
      }

    } catch (error) {
      setTextMessage('')

      if (error.response.data.error === 'Você bloqueou este usuário' || error.response.data.error === 'Você foi bloqueado por este usuário') {
        setwasBlocked(true)
      }

      console.error('Failed to send message:', error);

      toast.error(error.response.data.error, {
        toastId: customId,
        autoClose: 3000,
      })
    }
    setIsLoading(false);

  }
  }

  const minisidebar = () => {
    document.getElementsByTagName('ASIDE')[0].classList.toggle('sidebar-mini')
  }

  useEffect(() => {
    getFriends()
    getGroups()
  }, [])

  const filteredFriends = useMemo(() => {
    const lowerSearch = search.toLowerCase()

    if (search === '') {
      return friends
    } else {
      return friends.filter((friend) => friend.name.toLowerCase().includes(lowerSearch) || friend.email.toLowerCase().includes(lowerSearch) || friend.id.toString().toLowerCase().includes(lowerSearch))
    }
  }, [search, friends])

  const filteredGroups = useMemo(() => {
    const lowerSearch = search.toLowerCase()

    if (search === '') {
      return groups
    } else {
      return groups.filter((group) => group.name.toLowerCase().includes(lowerSearch))
    }
  }, [search, groups])

  useEffect(() => {
    const chatBody = chatBodyRef.current;
    chatBody.addEventListener("scroll", handleScroll);

    const chatBodyGroup = chatBodyGroupsRef.current;
    chatBodyGroup.addEventListener("scroll", handleScroll);

    return () => {
      chatBody.removeEventListener("scroll", handleScroll);
      chatBodyGroup.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    socket.current = io(process.env.REACT_APP_CHAT_SOCKET_URL, {
      transports: ['polling', 'websocket'],
      withCredentials: true,
      query: {
        token: token,
        loged_user: loged_user,
        userStatus: userStatus
      },
    });

    socket.current.on('connect', () => {
      console.log('Connected to WebSocket');
      socket.current.emit('subscribe', `chat_workplace_user_${loged_user}`);
    });

    socket.current.on('disconnect', () => {
      console.log('Disconnected from WebSocket');
    });

    return () => {
      if (socket.current) {
        socket.current.disconnect();
      }
    };
  }, [socket, loged_user]);

  useEffect(() => {
    const handleMessage = ({ friend_id, message, groupId, isFirstMessage }) => {
      console.log('mensagem recebida')
      dispatch(set_receive_new_messages(true))

      const selectedFriendId = selectedFriend ? String(selectedFriend.id) : null;
      const selectedGroupId = selectedGroup ? String(selectedGroup.id) : null;
      const messageFriendId = String(friend_id);
      const messageGroupId = String(groupId);

      if (groupId) {
        if (selectedGroupId === messageGroupId) {
          setGroupMessages([...groupMessages, message])

          scrollToBottom()
        }

        setLastMessage(message, groups)
      } else if (!isFirstMessage) {
        if (selectedFriendId === messageFriendId) {
          readMessages(messageFriendId)
          setFriendMessages([...friendMessages, message])

          scrollToBottom()
        }

        setLastMessage(message, friends)
      } else if (isFirstMessage) {
        setFriendMessages([...friendMessages, message])
        getFriends()
      }
    }

    if (socket.current) {
      socket.current.on('newMessage', handleMessage);
    }

    return () => {
      if (socket.current) {
        socket.current.off('newMessage', handleMessage);
      }
    };
  }, [selectedFriend, friendMessages, selectedGroup, groupMessages, friends, groups])

  useEffect(() => {
    const handleNewStatus = ({ friend_id, status }) => {
      changeStatus(friend_id, friends, status)
    }

    if (socket.current) {
      socket.current.on('newStatus', handleNewStatus);
    }

    return () => {
      if (socket.current) {
        socket.current.off('newStatus', handleNewStatus);
      }
    };
  }, [friends])

  useEffect(() => {
    const handleRemoveMessage = ({ message_id, is_group, chat_id }) => {
      setDeletedMessage([...deletedMessage, message_id])

      if (is_group) {
        const updatedChats = groups.map(chat => {
          if (chat.id == chat_id) {
            return { ...chat, latest_message: 'Mensagem apagada' };
          }
          return chat;
        });

        setGroups(updatedChats)
      } else {
        const updatedChats = friends.map(chat => {
          if (chat.id == chat_id) {
            return { ...chat, latest_message: 'Mensagem apagada' };
          }
          return chat;
        });

        setFriends(updatedChats)
      }
    }

    if (socket.current) {
      socket.current.on('removedMessage', handleRemoveMessage);
    }

    return () => {
      if (socket.current) {
        socket.current.off('removedMessage', handleRemoveMessage);
      }
    };
  }, [deletedMessage, friends, groups])

  useEffect(() => {
    const handleEditMessage = ({ message_id, new_message, is_group, chat_id }) => {
      updateEditedMessages({
        id: message_id,
        text: new_message
      })

      if (is_group) {
        const updatedChats = groups.map(chat => {
          if (chat.id == chat_id) {
            return { ...chat, latest_message: new_message };
          }
          return chat;
        });

        setGroups(updatedChats)
      } else {
        const updatedChats = friends.map(chat => {
          if (chat.id == chat_id) {
            return { ...chat, latest_message: new_message };
          }
          return chat;
        });

        setFriends(updatedChats)
      }
    }

    if (socket.current) {
      socket.current.on('editedMessage', handleEditMessage);
    }

    return () => {
      if (socket.current) {
        socket.current.off('editedMessage', handleEditMessage);
      }
    };
  }, [editedMessages, friends, groups])

  useEffect(() => {
    const handleReadMessage = () => {
      marcarComoLidas(friendMessages)
      console.log('mensagens lidas')
    }

    if (socket.current) {
      socket.current.on('readedMessage', handleReadMessage);
    }

    return () => {
      if (socket.current) {
        socket.current.off('readedMessage', handleReadMessage);
      }
    };
  }, [friendMessages])

  useEffect(() => {
    const handleUserWasBlocked = ({ blocker_id }) => {
      if (selectedFriend?.id === blocker_id) {
        checkIfUserWasBlocked(blocker_id)
      }
    }

    if (socket.current) {
      socket.current.on('wasBlocked', handleUserWasBlocked);
    }

    return () => {
      if (socket.current) {
        socket.current.off('wasBlocked', handleUserWasBlocked);
      }
    };
  }, [wasBlocked, selectedFriend])

  useEffect(() => {
    scrollToBottom()
  }, [friendMessages])

  const [file, setFile] = useState(null);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
    scrollToBottom()

  };

  const removeImage = () => {
    setFile(null)
  }


  const sendMessageToChatGpt = async (message) => {
    setIsLoading(true);
    try {
        if (selectedMode === 'text') {

          const formData = new FormData();
          formData.append('message', message || '');
          if (file) {
              formData.append('file', file);
          }
  
          const response = await api.post('/api/chatgpt', formData, {
              headers: {
                  'Content-Type': 'multipart/form-data',
              },
          });
  
          setFile(null);

            const newMessage = await api.post(`/api/chat/store`, {
                message: textMessage,
                type: 'text',
                sender_id: loged_user,
                receiver_id: 0,
                is_first_message: friendMessages.length === 0 ? true : false,
                readed: true, // Simulando que foi lida
              });
    
         const chatStore =  await api.post(`/api/chat/store`, {
            message: response.data.message,
            type: 'text',
            sender_id: 0,
            receiver_id: loged_user,
            is_first_message: friendMessages.length === 0 ? true : false,
            readed: true, // Simulando que foi lida
            });
            getMessages(0)
            
        } else if (selectedMode === 'image') {

            const response = await api.post('/api/chatgpt/generateImage', { prompt: message });

            const newMessage = await api.post(`/api/chat/store`, {
                message: textMessage,
                type: 'text',
                sender_id: loged_user,
                receiver_id: 0,
                is_first_message: friendMessages.length === 0 ? true : false,
                readed: true, // Simulando que foi lida
              });

            await api.post('/api/chat/store', {
                message: response.data.image_url,
                type: 'image', // Define como imagem
                sender_id: 0,
                receiver_id: loged_user,
                is_first_message: false,
                readed: true,
            });
            getMessages(0)

        }
    } catch (error) {
        console.error('Failed to send message:', error);
    }
    setIsLoading(false);
}

const handleDownload = (src) => {
    saveAs(src, 'image.png');
  };

  return (
    <>
      <Tab.Container id='left-tabs-example' defaultActiveKey='first'>
        <aside className='sidebar sidebar-chat sidebar-base border-end shadow-none gereral-chat-sidebar' data-sidebar='responsive' style={{ zIndex: '11' }}>
          <div className='chat-search pt-4 px-4'>
            <div className='d-flex align-items-center justify-content-between'>
              <div className='d-flex align-items-center gap-2'>
                <span class="material-symbols-outlined">
                  mark_unread_chat_alt
                </span>
                <h5 className='fw-500'>Conversas</h5>
              </div>
              <Button className='rounded-2 background-alert d-flex align-items-center hide-onbutton' onClick={() => history('/')}>
                <span className='material-symbols-outlined'>undo</span>
              </Button>
              <div className='d-block d-xl-none'>
                <Button
                  size='sm'
                  className='rounded p-1 d-flex'
                  data-toggle='sidebar'
                  data-active='true'
                  onClick={minisidebar}
                >
                  <span class="material-symbols-outlined fs-1">
                    chevron_right
                  </span>
                </Button>
              </div>
            </div>
            <div className='chat-searchbar mt-3 pt-1 mb-4'>
              <Form.Group className='form-group chat-search-data m-0'>
                <input
                  type='text'
                  className='form-control round'
                  id='chat-search'
                  placeholder='Procure por amigos ou grupos...'
                  onChange={(e) => setSearch(e.target.value)}
                />
                <i className='material-symbols-outlined'>search</i>
              </Form.Group>
            </div>
            <Swiper
              slidesPerView={7.1}
              pagination={{
                clickable: true,
              }}
              className='swiper-general messenger-swiper overflow-hidden mb-3 swiper-container-pointer-events border-bottom border-1'
              breakpoints={{
                320: { slidesPerView: 4.1 },
                550: { slidesPerView: 4.3 },
                760: { slidesPerView: 5.3 },
                1200: { slidesPerView: 7.2 },
              }}

            >
              {friends.map((friend, index) => {
                let currentUserStatus = friend.chat_status

                return (
                  <SwiperSlide key={index}>
                    <div className='swiper-slide text-center' role='group' aria-label='9 / 9' style={{ width: '47.3151px', marginRight: '12px' }} key={index}>
                      <div className='messanger-box position-relative d-inline-block'>
                        <div className={`iq-profile-avatar ${currentUserStatus === 'online' ? 'status-online' : currentUserStatus === 'away' ? 'status-away' : 'status-offline'}`}>
                          <img src={friend.user_about.image_profile ?? userGenericImage} className='avatar-48 object-cover rounded-circle' alt='messanger-image' />
                        </div>
                      </div>
                      <p className='mt-2 mb-0 font-size-14 custom-ellipsis text-body'>{friend.name}</p>
                    </div>
                  </SwiperSlide>
                )
              })}
            </Swiper>
          </div>
          <div className="px-4">
            <Table size="sm" striped bordered hover>
              <thead>
                <tr>
                  <th style={{ width: '3rem', textAlign: 'center' }}>
                    <span className="material-symbols-outlined fs-1 d-flex justify-content-center">filter_alt</span>
                  </th>
                  <th>
                    <ButtonGroup>
                      <Button
                        size='sm'
                        className='d-flex align-items-center gap-1'
                        variant={showFriends ? "primary" : "outline-primary"}
                        onClick={() => setShowFriends((prev) => !prev)}
                      >
                        <span class="material-symbols-outlined fs-6">
                          chat
                        </span>
                        <span className="me-2">Amigos</span>
                      </Button>
                      <Button
                        size='sm'
                        className='d-flex align-items-center gap-1'
                        variant={showGroups ? "primary" : "outline-primary"}
                        onClick={() => setShowGroups((prev) => !prev)}
                      >
                        <span class="material-symbols-outlined fs-6">
                          group
                        </span>
                        <span className="me-2">Grupos</span>
                      </Button>
                    </ButtonGroup>
                  </th>
                </tr>
              </thead>
            </Table>
          </div>
          <div
            className='sidebar-body pt-0 data-scrollbar mb-5 pb-5 px-4'
            tabIndex='-1'
            style={{ overflow: 'hidden', outline: 'none', maxHeight: '65vh' }}>
            <div
              className='scroll-content'
              style={{ transform: 'translate3d(0px, 0px, 0px)' }}
            >
              <ul
                className='nav navbar-nav iq-main-menu mb-5 pb-5'
                id='sidebar-menu'
                role='tablist'
              >

                <div className='border-top border-bottom border-1 rounded-1' style={{ maxHeight: '33rem', overflowY: 'scroll', scrollbarWidth: 'none', msOverflowStyle: 'none', maxWidth: '-webkit-fill-available' }}>
                        <Nav.Item as='li'
                          className='iq-chat-list mb-3 ps-0'
                          role='presentation'
                          key={`chat_gpt-0`}
                          onClick={() => {
                            setSelectedFriend({ id: '0', name: 'ATMO AI' })
                            setwasBlocked(false)
                            setTextMessage('')
                            getMessages(0)
                            readMessages(0)
                            minisidebar('')
                          }}
                        >
                          <Nav.Link
                            className={`d-flex gap-3 rounded-2 zoom-in`}
                            eventKey='first'
                            onClick={() => setActive('first')}
                          >
                            <div className='position-relative'>
                              <div className={`iq-profile-avatar status-online`}>
                                <img
                                    src={logoAtmoAi}
                                    alt='status-101'
                                    className='avatar-48 object-cover rounded-circle'
                                    loading='lazy'
                                />
                              </div>
                            </div>
                            <div className='d-flex align-items-top w-100 iq-userlist-data'>
                              <div className='d-flex flex-grow-1 flex-column'>
                                <div className='d-flex align-items-center gap-1'>
                                  <h6 className='mb-0 iq-userlist-name font-size-14 fw-semibold mb-0 text-ellipsis short-1 flex-grow-1'>
                                    ATMO AI  <MdAssistant style={{fontSize: "20px", color:" RGBA(var(--bs-primary-rgb), var(--bs-bg-opacity, 1))" }} />
                                  </h6>
                                </div>
                              </div>
                            </div>
                          </Nav.Link>
                        </Nav.Item>
                  {
                    showFriends && filteredFriends.map((friend, index) => {
                      let currentUserStatus = friend.chat_status

                      return (
                        <Nav.Item as='li'
                          className='iq-chat-list mb-3 ps-0'
                          role='presentation'
                          key={`chat_${index}`}
                          onClick={() => {
                            setTextMessage('')
                            setSelectedFriend(friend)
                            checkIfUserWasBlocked(friend.id)
                            setSelectedGroup('')
                            getMessages(friend.id)
                            readMessages(friend.id)
                            readFriendMessages(friend.id, friends)
                            minisidebar('')
                          }}
                        >
                          <Nav.Link
                            className={`d-flex gap-3 rounded-2 zoom-in`}
                            eventKey='first'
                            onClick={() => setActive('first')}
                          >
                            {
                              friend.unread_messages > 0 ? (
                                <div className="counter-container-general-chat bg-primary">
                                  <span className="counter-number">{friend.unread_messages}</span>
                                </div>
                              ) : ''
                            }
                            <div className='position-relative'>
                              <div className={`iq-profile-avatar ${currentUserStatus === 'online' ? 'status-online' : currentUserStatus === 'away' ? 'status-away' : 'status-offline'}`}>
                                <img
                                    src={friend.user_about.image_profile ?? userGenericImage}
                                    alt='status-101'
                                    className='avatar-48 object-cover rounded-circle'
                                    loading='lazy'
                                />
                              </div>
                            </div>
                            <div className='d-flex align-items-top w-100 iq-userlist-data'>
                              <div className='d-flex flex-grow-1 flex-column'>
                                <div className='d-flex align-items-center gap-1'>
                                  <h6 className='mb-0 iq-userlist-name font-size-14 fw-semibold mb-0 text-ellipsis short-1 flex-grow-1'>
                                    {friend.name}
                                  </h6>
                                  <span className='mb-0 font-size-12'>
                                    {friend.latest_message_time ?? ''}
                                  </span>
                                </div>
                                <div className='d-flex align-items-center gap-2'>
                                  <p className='text-ellipsis short-1 flex-grow-1 font-size-14 mb-0'>
                                    {friend.latest_message && /\.(jpg|jpeg|png|gif|bmp|webp)$/i.test(friend.latest_message) ? (
                                      <span className="image-text fst-italic d-flex align-items-center">
                                        <span class="material-symbols-outlined fs-6 me-1">
                                          image
                                        </span> Imagem
                                      </span>
                                    ) : (
                                      friend.latest_message ?? "Nova conversa..."
                                    )}
                                  </p>

                                </div>
                              </div>
                            </div>
                          </Nav.Link>
                        </Nav.Item>
                      )
                    })
                  }

                  {
                    showGroups && filteredGroups.map((group, index) => {
            
                      return (
                        <Nav.Item as='li'
                          className='iq-chat-list mb-3 ps-0'
                          role='presentation'
                          key={`chat_group_${index}`}
                          onClick={() => {
                            setTextMessage('')
                            setSelectedGroup(group)
                            setSelectedFriend('')
                            setwasBlocked(false)
                            setBlocked(false)
                            getGroupMessages(group.id)
                            minisidebar('')
                          }}
                        >
                          <Nav.Link
                            className={`d-flex gap-3 rounded-2 zoom-in`}
                            eventKey='second'
                            onClick={() => setActive('second')}
                          >
                            <div className='position-relative'>
                                <img
                                    src={group.photo ?? userGenericImage}
                                    alt='status-101'
                                    className='avatar-48 object-cover rounded-circle'
                                    loading='lazy'
                                />
                            </div>
                            <div className='d-flex align-items-top w-100 iq-userlist-data'>
                              <div className='d-flex flex-grow-1 flex-column'>
                                <div className='d-flex align-items-center gap-1'>
                                  <h6 className='mb-0 iq-userlist-name font-size-14 fw-semibold mb-0 text-ellipsis short-1 flex-grow-1'>
                                    {group.name}
                                  </h6>
                                  <span className='mb-0 font-size-12'>
                                    {group.latest_message_time ?? ''}
                                  </span>
                                </div>
                                <div className='d-flex align-items-center gap-2'>
                                  <p className='text-ellipsis short-1 flex-grow-1 font-size-14 mb-0'>
                                    {group.latest_message ?? "Nova conversa..."}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </Nav.Link>
                        </Nav.Item>
                      )
                    })
                  }
                </div>
            
              </ul>
            </div>
          </div>
          <div className='sidebar-footer'></div>
        </aside>
        <main className={selectedFriend || selectedGroup  ? 'main-content' : 'main-content d-none'}>
          <div className='container-fluid content-inner p-0' id='page_layout'>
            <Tab.Content id='myTabContent'>

              <Tab.Pane
                eventKey='first'
                className='card mb-0 fade'
                id='user-content-101'
                role='tabpanel'
              >
                <div className='chat-head'>
                  <header
                    className='d-flex justify-content-between align-items-center pt-1 ps-3 pe-3 pb-1'
                  >
                    <div className='d-flex align-items-center gap-3'>
                      <div className='d-block d-xl-none me-2'>
                        <Button
                          size='sm'
                          className='d-flex rounded p-1'
                          data-toggle='sidebar'
                          data-active='true'
                          onClick={minisidebar}
                        >
                          <span class="material-symbols-outlined fs-1">
                            chevron_left
                          </span>
                        </Button>
                      </div>
                      <div className='avatar chat-user-profile m-0'>
                          {selectedFriend && selectedFriend.id == '0' ?
                            <div className={`iq-profile-avatar status-online`}>
                              <img
                              src={selectedFriend?.user_about?.image_profile ?? logoAtmoAi}
                              alt='avatar'
                              className='avatar-50 rounded-pill'
                              loading='lazy'
                              /> 
                            </div>
                            : (
                             <div className={`iq-profile-avatar ${selectedFriend?.chat_status === 'online' ? 'status-online' : selectedFriend?.chat_status === 'away' ? 'status-away' : 'status-offline'}`}>
                                <img
                                src={selectedFriend?.user_about?.image_profile ?? userGenericImage}
                                alt='avatar'
                                className='avatar-50 rounded-pill'
                                loading='lazy'
                                />
                            </div>
                          )}
                      </div>
                      <div>
                        <h5 className='mb-0'>{selectedFriend ? selectedFriend.name : ''}</h5>
                        {selectedFriend && selectedFriend.id == '0' ?
                        <small className='text-capitalize fw-500'>Online</small>
                        : (
                        <small className='text-capitalize fw-500'>{selectedFriend && selectedFriend.chat_status === 'online' ? 'Online' : selectedFriend && selectedFriend.chat_status === 'away' ? 'Ocupado' : 'Offline'}</small>
                        )}
                      </div>
                    </div>

                    {selectedFriend && selectedFriend.id == '0' ?
                     <div className='chat-header-icons d-inline-flex ms-auto'>
                     <Dropdown
                      onSelect={handleSelect}
                       className='bg-primary-subtle d-flex align-items-center justify-content-center'
                       as='span'
                     >
                       <Dropdown.Toggle as='svg' bsPrefix=' '
                         className='icon-20 nav-hide-arrow cursor-pointer pe-0'
                         id='dropdownMenuButton-09'
                         data-bs-toggle='dropdown'
                         aria-haspopup='true'
                         aria-expanded='false'
                         role='menu'
                         width='24'
                         xmlns='http://www.w3.org/2000/svg'
                         viewBox='0 0 24 24'
                         fill='none'
                       >
                         <path
                           fillRule='evenodd'
                           clipRule='evenodd'
                           d='M10 20.6788C10 21.9595 11.0378 23 12.3113 23C13.5868 23 14.6265 21.9595 14.6265 20.6788C14.6265 19.3981 13.5868 18.3576 12.3113 18.3576C11.0378 18.3576 10 19.3981 10 20.6788ZM10 12.0005C10 13.2812 11.0378 14.3217 12.3113 14.3217C13.5868 14.3217 14.6265 13.2812 14.6265 12.0005C14.6265 10.7198 13.5868 9.67929 12.3113 9.67929C11.0378 9.67929 10 10.7198 10 12.0005ZM12.3113 5.64239C11.0378 5.64239 10 4.60192 10 3.3212C10 2.04047 11.0378 1 12.3113 1C13.5868 1 14.6265 2.04047 14.6265 3.3212C14.6265 4.60192 13.5868 5.64239 12.3113 5.64239Z'
                           fill='currentColor'
                         ></path>
                       </Dropdown.Toggle>
                       <Dropdown.Menu
                         className='dropdown-menu-right'
                         aria-labelledby='dropdownMenuButton-09'
                       >
                          <Dropdown.Item eventKey="text">Tipo de chat: Texto</Dropdown.Item>
                          <Dropdown.Item eventKey="image">Tipo de chat: Imagem</Dropdown.Item>
                       </Dropdown.Menu>
                     </Dropdown>
                   </div>  
                     : (
                      <div className='chat-header-icons d-inline-flex ms-auto'>
                      <Dropdown
                        className='bg-primary-subtle d-flex align-items-center justify-content-center'
                        as='span'
                      >
                        <Dropdown.Toggle as='svg' bsPrefix=' '
                          className='icon-20 nav-hide-arrow cursor-pointer pe-0'
                          id='dropdownMenuButton-09'
                          data-bs-toggle='dropdown'
                          aria-haspopup='true'
                          aria-expanded='false'
                          role='menu'
                          width='24'
                          xmlns='http://www.w3.org/2000/svg'
                          viewBox='0 0 24 24'
                          fill='none'
                        >
                          <path
                            fillRule='evenodd'
                            clipRule='evenodd'
                            d='M10 20.6788C10 21.9595 11.0378 23 12.3113 23C13.5868 23 14.6265 21.9595 14.6265 20.6788C14.6265 19.3981 13.5868 18.3576 12.3113 18.3576C11.0378 18.3576 10 19.3981 10 20.6788ZM10 12.0005C10 13.2812 11.0378 14.3217 12.3113 14.3217C13.5868 14.3217 14.6265 13.2812 14.6265 12.0005C14.6265 10.7198 13.5868 9.67929 12.3113 9.67929C11.0378 9.67929 10 10.7198 10 12.0005ZM12.3113 5.64239C11.0378 5.64239 10 4.60192 10 3.3212C10 2.04047 11.0378 1 12.3113 1C13.5868 1 14.6265 2.04047 14.6265 3.3212C14.6265 4.60192 13.5868 5.64239 12.3113 5.64239Z'
                            fill='currentColor'
                          ></path>
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                          className='dropdown-menu-right'
                          aria-labelledby='dropdownMenuButton-09'
                        >
                          <Dropdown.Item
                            className='d-flex align-items-center'
                            href='#'
                            onClick={() => blockContact(selectedFriend.id)}
                          >
                            <i className='material-symbols-outlined md-18 me-1'>block</i>
                            {
                              blocked ? (
                                'desbloquear'
                              ) : (
                                'bloquear'
                              )
                            }
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>    
                      )
                    }
                  </header>
                </div>
                {file && (
                    <div className="card filePreview w-100 z-3">
                            <img
                                    className='rounded mx-3 my-3'
                                    src={URL.createObjectURL(file)}
                                    alt="Imagem escolhida"
                                    style={{ maxWidth: '15%'}}
                                  />
                                  <CloseButton
                                    onClick={removeImage}
                                    style={{ position: 'absolute', top: '10px', right: '10px', backgroundColor:'white', color: '#ff0000' }}
                                  />
                        </div>
                        
                    )}
                <div className='card-body chat-body bg-body' ref={chatBodyRef}>
                  {
                    friendMessages?.map((msg, index) =>  {
                      if (msg.sender_id == loged_user) {
                        return (
                          <div className='iq-message-body iq-current-user'  ref={index === friendMessages.length - 1 ? ref : null} key={`message_${msg.id}`}>
                            {isVisible && (
                              <div className='chat-profile text-center'>
                                <img
                                  src={msg.sender_photo ?? userGenericImage}
                                  alt='chat-user'
                                  className='rounded-pill img-invisible-chat'
                                  loading='lazy'
                                />
                                <small className='iq-chating p-0 mb-0 d-block'>{msg.formatted_created_at}</small>
                              </div>
                            )}
                            <div 
                              className='iq-chat-text'
                              
                            >
                              <div
                                className='d-flex align-items-center justify-content-end gap-1 gap-md-2'
                              >
                                {
                                  (!msg.user_removed && !deletedMessage.includes(msg.id)) && (
                                    <Dropdown
                                      className='dropdown cursor-pointer more'
                                      data-bs-toggle='tooltip'
                                      data-bs-placement='top'
                                      data-bs-original-title='More'
                                    >
                                      <Dropdown.Toggle
                                        className='lh-1'
                                        id='post-option'
                                        data-bs-toggle='dropdown'
                                        as='span'
                                        bsPrefix=' '
                                      >
                                        <span className='material-symbols-outlined text-dark'>
                                          more_vert
                                        </span>
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu
                                        className='dropdown-menu dropdown-menu-right'
                                        aria-labelledby='post-option'
    
                                      >
                                        <Dropdown.Item
                                          className=''
                                          href='#'
                                          data-bs-toggle='modal'
                                          data-bs-target='#post-modal'
                                          onClick={() => copyMessage(editedMessages.find(message => message.id === msg.id)?.text ?? msg.message)}
                                        >
                                        <span
                                          className='material-symbols-outlined align-middle font-size-20 me-1'>
                                          content_copy
                                        </span>
                                        copiar texto
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          className=''
                                          href='#'
                                          data-bs-toggle='modal'
                                          data-bs-target='#post-modal'
                                          onClick={() => {
                                            setShowEdit(true)
                                            setEditedChatId(msg.id)
                                            setNewText(msg.message)
                                          }}
                                        >
                                          <span
                                            className='material-symbols-outlined align-middle font-size-20 me-1'
                                          >edit</span>editar</Dropdown.Item>
                                        <Dropdown.Item
                                          className=''
                                          href='#'
                                          data-bs-toggle='modal'
                                          data-bs-target='#post-modal'
                                          onClick={toggleVisibility}
                                        >
                                          <span className='material-symbols-outlined align-middle font-size-20 me-1'>
                                            shelf_auto_hide
                                          </span>
                                          {isVisible ? 'esconder data' : 'exibir data'}
                                          </Dropdown.Item>
                                        <Dropdown.Item
                                          className=''
                                          href='#'
                                          data-bs-toggle='modal'
                                          data-bs-target='#post-modal'
                                          onClick={() => deleteMessage(msg.id, false, msg.receiver_id)}
                                        >
                                          <span
                                            className='material-symbols-outlined align-middle font-size-20 me-1'
                                          >delete</span>deletar</Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  )
                                }
                                
                                <div 
                                  className='iq-chating-content d-flex align-items-center'
                                  draggable
                                  onDragStart={handleDragStart}
                                  onDrag={handleDrag}
                                  onDragEnd={handleDragEnd}
                                  onTouchStart={handleDragStart}
                                  onTouchMove={handleDrag}
                                  onTouchEnd={handleDragEnd}
                                  style={{
                                    cursor: "grab",
                                    transition: "transform 0.3s ease", // Transição suave
                                    transform: `translateX(${translateX}px)`, // Movimento horizontal
                                  }}
                                >
                                  {
                                    (msg.user_removed || deletedMessage.includes(msg.id)) ? (
                                      <i className='mr-2 mb-0'>
                                        Mensagem apagada
                                      </i>
                                    ) : showEdit && editedChatId === msg.id ? (
                                      <div>
                                        <input
                                          type='text'
                                          className='form-control rounded px-0 mb-3'
                                          style={{ border: 'none' }}
                                          value={newText}
                                          onChange={(e) => setNewText(e.target.value)}
                                        />

                                        <button type='button' className='btn btn-primary px-4' style={{ marginRight: '5px' }} onClick={() => editMessage(msg.id, false, msg.receiver_id)}>
                                          <div className='fw-semibold'>Salvar</div>
                                        </button>

                                        <button type='button' className='btn btn-danger px-4 ml-5' onClick={() => {
                                          setShowEdit(false)
                                          setNewText(msg.message)
                                          setEditedChatId('')
                                        }}>
                                          Cancelar
                                        </button>
                                      </div>
                                    ) : editedMessages && editedMessages.some(message => message.id === msg.id) ? (
                                      <p className='mr-2 mb-0'>
                                        {editedMessages.find(message => message.id === msg.id).text}
                                      </p>
                                    ) : ( msg.type === 'text' ? (

                                      <p className='mr-2 mb-0'>
                                        {msg.message}
                                      </p>
                                    ) : (
                                      <div className="d-inline-block py-2 px-3 bg-light chat-popup-message message-right font-size-12 fw-medium" style={{ marginRight: '5px' }}>
                                      <PhotoProvider toolbarRender={({ rotate, onRotate, scale, onScale }) => (
                                              <div>
                                                  <Button onClick={() => onRotate(rotate + 90)} style={{ cursor: 'pointer',  marginLeft: '10px', display: 'contents' }}>
                                                      <span className="material-symbols-outlined font-size-18 icon-rtl" style={{ margin: '10px' }}>
                                                      rotate_right
                                                      </span>
                                                  </Button>

                                                  <Button onClick={() => onRotate(rotate - 90)} style={{ cursor: 'pointer',  marginLeft: '10px', display: 'contents' }}>
                                                      <span className="material-symbols-outlined font-size-18 icon-rtl" style={{ margin: '10px' }} >
                                                      rotate_left
                                                      </span>
                                                  </Button>

                                                  <Button className="d-contents" onClick={() => onScale(scale - 0.1)} style={{ marginLeft: '10px', display: 'contents' }}>
                                                  <span className="material-symbols-outlined font-size-18 icon-rtl" style={{ margin: '10px' }}>
                                                      zoom_out
                                                      </span>
                                                  </Button>

                                                  <Button onClick={() => onScale(scale + 0.1)} style={{ marginLeft: '10px', display: 'contents' }}>
                                                  <span className="material-symbols-outlined font-size-18 icon-rtl" style={{ margin: '10px' }}>
                                                      zoom_in
                                                      </span>
                                                  </Button>

                                                  <Button className="d-contents" onClick={() => handleDownload(msg.message)} style={{ marginLeft: '10px', marginRight: '10px', display: 'contents' }}>
                                                  <span className="material-symbols-outlined font-size-18 icon-rtl" style={{ margin: '10px' }}>
                                                      file_download
                                                      </span>
                                                  </Button>
                                              </div>
                                      )}>
                                          <PhotoView src={msg.message} >
                                              <img src={msg.message} style={{ maxWidth: '100%', maxHeight: '200px', borderRadius: '8px' }} alt="" />
                                          </PhotoView>
                                          </PhotoProvider>
                                  </div>
                                    ))}
                                </div>
                                <IoCheckmarkDoneSharp style={{ color: msg.readed ? '#50B5FF' : '' }} />
                              </div>
                            </div>
                          </div>
                        )
                      } else {
                        return (
                          <div className='iq-message-body iq-other-user' key={`message_${msg.id}`}>
                            {isVisible && (
                              <div className='chat-profile text-center'>
                              {selectedFriend && selectedFriend.id == '0' ?
                                <img
                                  src={logoAtmoAi}
                                  alt='chat-user'
                                  className='avatar-40 rounded-pill'
                                  loading='lazy'
                                />
                                :(
                                  <img
                                  src={msg.sender_photo ?? userGenericImage}
                                  alt='chat-user'
                                  className='img-invisible-chat rounded-pill'
                                  loading='lazy'
                                />
                                )}
                                <small className='iq-chating p-0 mb-0 d-block'>{msg.formatted_created_at}</small>
                              </div>
                            )}
                            <div 
                              className='iq-chat-text'
                              
                            >
                              <div
                                className='d-flex align-items-center justify-content-start gap-md-2'
                              >
                                <div 
                                  className='iq-chating-content d-flex align-items-center'
                                  draggable
                                  onDragStart={handleDragStart}
                                  onDrag={handleDrag}
                                  onDragEnd={handleDragEndOtherUser}
                                  onTouchStart={handleDragStart}
                                  onTouchMove={handleDrag}
                                  onTouchEnd={handleDragEndOtherUser}
                                  style={{
                                    cursor: "grab",
                                    transition: "transform 0.3s ease", // Transição suave
                                    transform: `translateX(${translateX}px)`, // Movimento horizontal
                                  }}
                                  >
                                  {
                                    (msg.user_removed || deletedMessage.includes(msg.id)) ? (
                                      <i className='mr-2 mb-0'>
                                        Mensagem apagada
                                      </i>
                                    ) : editedMessages && editedMessages.some(message => message.id === msg.id) ? (
                                      <p className='mr-2 mb-0'>
                                        {editedMessages.find(message => message.id === msg.id).text}
                                      </p>
                                    ) : msg.type === 'text' && (
                                      <p className='mr-2 mb-0'>
                                        {msg.message}
                                      </p>
                                    )
                                  }
                                  {msg.type === 'image' && (
                                          <div className="d-inline-block py-2 px-3 bg-light chat-popup-message message-right font-size-12 fw-medium" style={{ marginRight: '5px' }}>
                                              {/* <img src={msg.message} alt="Generated" style={{ maxWidth: '100%', maxHeight: '200px', borderRadius: '8px' }} /> */}
                                              <PhotoProvider toolbarRender={({ rotate, onRotate, scale, onScale }) => (
                                                      <div>
                                                          <Button onClick={() => onRotate(rotate + 90)} style={{ cursor: 'pointer',  marginLeft: '10px', display: 'contents' }}>
                                                              <span className="material-symbols-outlined font-size-18 icon-rtl" style={{ margin: '10px' }}>
                                                              rotate_right
                                                              </span>
                                                          </Button>

                                                          <Button onClick={() => onRotate(rotate - 90)} style={{ cursor: 'pointer',  marginLeft: '10px', display: 'contents' }}>
                                                              <span className="material-symbols-outlined font-size-18 icon-rtl" style={{ margin: '10px' }} >
                                                              rotate_left
                                                              </span>
                                                          </Button>

                                                          <Button className="d-contents" onClick={() => onScale(scale - 0.1)} style={{ marginLeft: '10px', display: 'contents' }}>
                                                          <span className="material-symbols-outlined font-size-18 icon-rtl" style={{ margin: '10px' }}>
                                                              zoom_out
                                                              </span>
                                                          </Button>

                                                          <Button onClick={() => onScale(scale + 0.1)} style={{ marginLeft: '10px', display: 'contents' }}>
                                                          <span className="material-symbols-outlined font-size-18 icon-rtl" style={{ margin: '10px' }}>
                                                              zoom_in
                                                              </span>
                                                          </Button>

                                                          <Button className="d-contents" onClick={() => handleDownload(msg.message)} style={{ marginLeft: '10px', marginRight: '10px', display: 'contents' }}>
                                                          <span className="material-symbols-outlined font-size-18 icon-rtl" style={{ margin: '10px' }}>
                                                              file_download
                                                              </span>
                                                          </Button>
                                                      </div>
                                              )}>
                                                  <PhotoView src={msg.message} >
                                                      <img src={msg.message} style={{ maxWidth: '100%', maxHeight: '200px', borderRadius: '8px' }} alt="" />
                                                  </PhotoView>
                                                  </PhotoProvider>
                                          </div>
                                      )}
                                </div>

                                {
                                  (!msg.user_removed && !deletedMessage.includes(msg.id)) && (
                                    <Dropdown
                                      className='dropdown cursor-pointer more'
                                      data-bs-toggle='tooltip'
                                      data-bs-placement='top'
                                      data-bs-original-title='More'
                                    >
                                      <Dropdown.Toggle
                                        className='lh-1'
                                        id='post-option'
                                        data-bs-toggle='dropdown'
                                        bsPrefix=' '
                                        as='span'
                                      >
                                        <span className='material-symbols-outlined text-dark'>
                                          more_vert
                                        </span>
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu
                                        className='dropdown-menu dropdown-menu-right'
                                        aria-labelledby='post-option'

                                      >
                                        <Dropdown.Item
                                          className=''
                                          href='#'
                                          data-bs-toggle='modal'
                                          data-bs-target='#post-modal'
                                          onClick={() => copyMessage(editedMessages.find(message => message.id === msg.id)?.text ?? msg.message)}
                                        ><span
                                          className='material-symbols-outlined align-middle font-size-20 me-1'
                                        >content_copy</span
                                          >copiar texto</Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  )
                                }
                              </div>
                            </div>
                          </div>
                        )
                      }
                    })
                  }

                  {isLoading && (
                    <div className="text-primary" role="status">
                        <span className="sr-only">Carregando...</span>
                    </div>
                  )}

                  <button
                    className={`scroll-to-bottom-btn-general-chat btn btn-primary ${showScrollButton ? 'visible' : 'invisible'}`}
                    onClick={scrollToBottom}
                  >
                    <FaArrowDown />
                  </button>
                </div>

                <div className='card-footer chat-footer px-3 py-3 border-top rounded-0'>
                  {showPicker && (
                    <div>
                      <EmojiPicker className="w-50" onEmojiClick={onEmojiClick} />
                    </div>
                  )}

                  <form className='d-flex align-items-center' action='#' 
                    onSubmit={(e) => {
                      e.preventDefault();
                      sendMessage(e, selectedGroup?.id);
                    }}
                  >
                  <div className="chat-attachment d-flex align-items-center pe-2" style={{ position: 'relative', cursor: 'pointer' }}>
                      <span className="material-symbols-outlined text-dark" style={{ fontSize: '24px' }}>
                        attach_file
                      </span>

                      <Form.Control
                        type="file"
                        accept="image/*"
                        onChange={handleFileChange}
                        className="opacity-0 position-absolute top-0 start-0"
                        style={{
                          width: '100%',
                          height: '100%',
                          cursor: 'pointer',
                        }}
                      />
                  </div>
                    <div className='chat-attagement d-flex'>
                      <Link to='#' className='d-flex align-items-center pe-3' onClick={(e) => {
                        e.preventDefault();
                        setShowPicker((val) => !val);
                      }}>
                        <svg
                          className='icon-24'
                          width='24'
                          viewBox='0 0 24 25'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <g clipPath='url(#clip0_156_599)'>
                            <path
                              d='M20.4853 4.01473C18.2188 1.74823 15.2053 0.5 12 0.5C8.79469 0.5 5.78119 1.74823 3.51473 4.01473C1.24819 6.28119 0 9.29469 0 12.5C0 15.7053 1.24819 18.7188 3.51473 20.9853C5.78119 23.2518 8.79469 24.5 12 24.5C15.2053 24.5 18.2188 23.2518 20.4853 20.9853C22.7518 18.7188 24 15.7053 24 12.5C24 9.29469 22.7518 6.28119 20.4853 4.01473ZM12 23.0714C6.17091 23.0714 1.42856 18.3291 1.42856 12.5C1.42856 6.67091 6.17091 1.92856 12 1.92856C17.8291 1.92856 22.5714 6.67091 22.5714 12.5C22.5714 18.3291 17.8291 23.0714 12 23.0714Z'
                              fill='currentcolor'
                            ></path>
                            <path
                              d='M9.40398 9.3309C8.23431 8.16114 6.33104 8.16123 5.16136 9.3309C4.88241 9.60981 4.88241 10.0621 5.16136 10.3411C5.44036 10.62 5.89266 10.62 6.17157 10.3411C6.78432 9.72836 7.78126 9.7284 8.39392 10.3411C8.53342 10.4806 8.71618 10.5503 8.89895 10.5503C9.08171 10.5503 9.26457 10.4806 9.40398 10.3411C9.68293 10.0621 9.68293 9.60986 9.40398 9.3309Z'
                              fill='currentcolor'
                            ></path>
                            <path
                              d='M18.8384 9.3309C17.6688 8.16123 15.7655 8.16114 14.5958 9.3309C14.3169 9.60981 14.3169 10.0621 14.5958 10.3411C14.8748 10.62 15.3271 10.62 15.606 10.3411C16.2187 9.72836 17.2156 9.72831 17.8284 10.3411C17.9679 10.4806 18.1506 10.5503 18.3334 10.5503C18.5162 10.5503 18.699 10.4806 18.8384 10.3411C19.1174 10.0621 19.1174 9.60986 18.8384 9.3309Z'
                              fill='currentcolor'
                            ></path>
                            <path
                              d='M18.3335 13.024H5.6668C5.2723 13.024 4.95251 13.3438 4.95251 13.7383C4.95251 17.6243 8.11409 20.7859 12.0001 20.7859C15.8862 20.7859 19.0477 17.6243 19.0477 13.7383C19.0477 13.3438 18.728 13.024 18.3335 13.024ZM12.0001 19.3573C9.14366 19.3573 6.77816 17.215 6.42626 14.4525H17.574C17.2221 17.215 14.8566 19.3573 12.0001 19.3573Z'
                              fill='currentcolor'
                            ></path>
                          </g>
                          <defs>
                            <clipPath>
                              <rect
                                width='24'
                                height='24'
                                fill='white'
                                transform='translate(0 0.5)'
                              ></rect>
                            </clipPath>
                          </defs>
                        </svg>
                      </Link>
                    </div>
                    <input
                      disabled={wasBlocked}
                      type='text'
                      className='form-control me-3'
                      placeholder={
                        selectedFriend?.id === '0' 
                            ? (selectedMode === "text" 
                                ? "Mensagem Atmo Ai.." 
                                : selectedMode === "image" 
                                    ? "Crie uma imagem.." 
                                    : "Digite aqui...")
                            : "Digite aqui..."
                    }
                      onChange={(e) => setTextMessage(e.target.value)}
                      value={textMessage}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          e.preventDefault();
                          sendMessage(e, selectedGroup?.id);
                        }
                      }}
                    />
                      {isLoading ? 
                <div className="text-primary" role="status">
                    <button
                  className='btn btn-primary d-flex align-items-center'
                  disabled={true}
                >
                  <div className="spinner-border spinner-border-sm" role="status"></div>
                </button>

                </div>
                : (
                  <>
                  <button
                  type='submit'
                  className='btn btn-primary d-flex align-items-center'
                  disabled={wasBlocked}
                >
                  <svg
                    className='icon-20'
                    width='18'
                    viewBox='0 0 20 21'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M13.8325 6.67463L8.10904 12.4592L1.59944 8.38767C0.66675 7.80414 0.860765 6.38744 1.91572 6.07893L17.3712 1.55277C18.3373 1.26963 19.2326 2.17283 18.9456 3.142L14.3731 18.5868C14.0598 19.6432 12.6512 19.832 12.0732 18.8953L8.10601 12.4602'
                      stroke='currentcolor'
                      strokeWidth='2'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    ></path></svg
                  ><span className='d-none d-lg-block ms-1'>Enviar</span>
                </button>
                </>
                )}
                    
                  </form>
                </div>
              </Tab.Pane>

              <Tab.Pane
                eventKey='second'
                className='card mb-0 fade'
                id='user-content-101'
                role='tabpanel'
              >
                <div className='chat-head'>
                  <header
                    className='d-flex justify-content-between align-items-center pt-3 ps-3 pe-3 pb-3'
                  >
                    <div className='d-flex align-items-center gap-3'>
                      <div className='d-block d-xl-none me-2'>
                        <Button
                          size='sm'
                          className='d-flex rounded p-1'
                          data-toggle='sidebar'
                          data-active='true'
                          onClick={minisidebar}
                        >
                          <span class="material-symbols-outlined fs-1">
                            chevron_left
                          </span>
                        </Button>
                      </div>
                      <div className='avatar chat-user-profile m-0'>
                          <img
                            src={selectedGroup?.photo ?? userGenericImage}
                            alt='avatar'
                            className='avatar-50 rounded-pill'
                            loading='lazy'
                          />
                      </div>
                      <div>
                        <h5 className='mb-0'>{selectedGroup ? selectedGroup.name : ''}</h5>
                      </div>
                    </div>
                  </header>
                </div>
                <div className='card-body chat-body bg-body' ref={chatBodyGroupsRef}>
                  {
                    groupMessages?.map(msg => {
                      if (msg.sender_id == loged_user) {
                        return (
                          <div className='iq-message-body iq-current-user' key={`message_${msg.id}`}>
                            {isVisible && (
                              <div className='chat-profile text-center'>
                                {msg.name}
                                <small className='iq-chating p-0 mb-0 d-block'>{msg.formatted_created_at}</small>
                              </div>
                            )}
                            <div 
                              className='iq-chat-text'
                              
                            >
                              <div
                                className='d-flex align-items-center justify-content-end gap-1 gap-md-2'
                              >
                                {
                                  (!msg.user_removed && !deletedMessage.includes(msg.id)) && (
                                    <Dropdown
                                      className='dropdown cursor-pointer more'
                                      data-bs-toggle='tooltip'
                                      data-bs-placement='top'
                                      data-bs-original-title='More'
                                    >
                                      <Dropdown.Toggle
                                        className='lh-1'
                                        id='post-option'
                                        data-bs-toggle='dropdown'
                                        as='span'
                                        bsPrefix=' '
                                      >
                                        <span className='material-symbols-outlined text-dark'>
                                          more_vert
                                        </span>
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu
                                        className='dropdown-menu dropdown-menu-right'
                                        aria-labelledby='post-option'

                                      >
                                        <Dropdown.Item
                                          className=''
                                          href='#'
                                          data-bs-toggle='modal'
                                          data-bs-target='#post-modal'
                                          onClick={() => copyMessage(editedMessages.find(message => message.id === msg.id)?.text ?? msg.message)}
                                        ><span
                                          className='material-symbols-outlined align-middle font-size-20 me-1'
                                        >content_copy</span
                                          >copiar texto</Dropdown.Item>
                                        <Dropdown.Item
                                          className=''
                                          href='#'
                                          data-bs-toggle='modal'
                                          data-bs-target='#post-modal'
                                          onClick={() => {
                                            setShowEdit(true)
                                            setEditedChatId(msg.id)
                                            setNewText(msg.message)
                                          }}
                                        >
                                          <span
                                            className='material-symbols-outlined align-middle font-size-20 me-1'
                                          >edit</span>editar</Dropdown.Item>
                                        <Dropdown.Item
                                          className=''
                                          href='#'
                                          data-bs-toggle='modal'
                                          data-bs-target='#post-modal'
                                          onClick={toggleVisibility}
                                        >
                                          <span className='material-symbols-outlined align-middle font-size-20 me-1'>
                                            shelf_auto_hide
                                          </span>
                                          {isVisible ? 'esconder data' : 'exibir data'}
                                          </Dropdown.Item>
                                        <Dropdown.Item
                                          className=''
                                          href='#'
                                          data-bs-toggle='modal'
                                          data-bs-target='#post-modal'
                                          onClick={() => deleteMessage(msg.id, true, msg.group_id)}
                                        >
                                          <span
                                            className='material-symbols-outlined align-middle font-size-20 me-1'
                                          >delete</span>deletar</Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>   
                                  )
                                }
                                <div 
                                  className='iq-chating-content d-flex align-items-center'
                                  draggable
                                  onDragStart={handleDragStart}
                                  onDrag={handleDrag}
                                  onDragEnd={handleDragEnd}
                                  onTouchStart={handleDragStart}
                                  onTouchMove={handleDrag}
                                  onTouchEnd={handleDragEnd}
                                  style={{
                                    cursor: "grab",
                                    transition: "transform 0.3s ease", // Transição suave
                                    transform: `translateX(${translateX}px)`, // Movimento horizontal
                                  }}
                                >
                                  {
                                    (msg.user_removed || deletedMessage.includes(msg.id)) ? (
                                      <i className='mr-2 mb-0'>
                                        Mensagem apagada
                                      </i>
                                    ) : showEdit && editedChatId === msg.id ? (
                                      <div>
                                        <input
                                          type='text'
                                          className='form-control rounded px-0 mb-3'
                                          style={{ border: 'none' }}
                                          value={newText}
                                          onChange={(e) => setNewText(e.target.value)}
                                        />

                                        <button type='button' className='btn btn-primary px-4' style={{ marginRight: '5px' }} onClick={() => editMessage(msg.id, true, msg.group_id)}>
                                          <div className='fw-semibold'>Salvar</div>
                                        </button>

                                        <button type='button' className='btn btn-danger px-4 ml-5' onClick={() => {
                                          setShowEdit(false)
                                          setNewText(msg.message)
                                          setEditedChatId('')
                                        }}>
                                          Cancelar
                                        </button>
                                      </div>
                                    ) : editedMessages && editedMessages.some(message => message.id === msg.id) ? (
                                      <p className='mr-2 mb-0'>
                                        {editedMessages.find(message => message.id === msg.id).text}
                                      </p>
                                    ) : (
                                      <p className='mr-2 mb-0'>
                                        {msg.message}
                                      </p>
                                    )
                                  }
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      } else {
                        return (
                          <div className='iq-message-body iq-other-user' key={`message_${msg.id}`}>
                            {isVisible && (
                              <div className='chat-profile text-center'>
                                {msg.name}
                                <small className='iq-chating p-0 mb-0 d-block'>{msg.formatted_created_at}</small>
                              </div>
                            )}
                            <div 
                              className='iq-chat-text'
                              
                            >
                              <div
                                className='d-flex align-items-center justify-content-start gap-md-2'
                              >
                                <div 
                                  className='iq-chating-content d-flex align-items-center'
                                  draggable
                                  onDragStart={handleDragStart}
                                  onDrag={handleDrag}
                                  onDragEnd={handleDragEndOtherUser}
                                  onTouchStart={handleDragStart}
                                  onTouchMove={handleDrag}
                                  onTouchEnd={handleDragEndOtherUser}
                                  style={{
                                    cursor: "grab",
                                    transition: "transform 0.3s ease", // Transição suave
                                    transform: `translateX(${translateX}px)`, // Movimento horizontal
                                  }}
                                >
                                  {
                                    (msg.user_removed || deletedMessage.includes(msg.id)) ? (
                                      <i className='mr-2 mb-0'>
                                        Mensagem apagada
                                      </i>
                                    ) : editedMessages && editedMessages.some(message => message.id === msg.id) ? (
                                      <p className='mr-2 mb-0'>
                                        {editedMessages.find(message => message.id === msg.id).text}
                                      </p>
                                    ) : (
                                      <p className='mr-2 mb-0'>
                                        {msg.message}
                                      </p>
                                    )
                                  }
                                </div>

                                {
                                  (!msg.user_removed && !deletedMessage.includes(msg.id)) && (
                                    <Dropdown
                                      className='dropdown cursor-pointer more'
                                      data-bs-toggle='tooltip'
                                      data-bs-placement='top'
                                      data-bs-original-title='More'
                                    >
                                      <Dropdown.Toggle
                                        className='lh-1'
                                        id='post-option'
                                        data-bs-toggle='dropdown'
                                        bsPrefix=' '
                                        as='span'
                                      >
                                        <span className='material-symbols-outlined text-dark'>
                                          more_vert
                                        </span>
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu
                                        className='dropdown-menu dropdown-menu-right'
                                        aria-labelledby='post-option'
                                      >
                                        <Dropdown.Item
                                          className=''
                                          href='#'
                                          data-bs-toggle='modal'
                                          data-bs-target='#post-modal'
                                          onClick={() => copyMessage(editedMessages.find(message => message.id === msg.id)?.text ?? msg.message)}
                                        ><span
                                          className='material-symbols-outlined align-middle font-size-20 me-1'
                                        >content_copy</span
                                          >copiar texto</Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  )
                                }
                              </div>
                            </div>
                          </div>
                        )
                      }
                    })
                  }

                  <button
                    className={`scroll-to-bottom-btn-general-chat btn btn-primary ${showScrollButton ? 'visible' : 'invisible'}`}
                    onClick={scrollToBottom}
                  >
                    <FaArrowDown />
                  </button>
                </div>

                <div className='card-footer px-3 py-3 border-top rounded-0'>
                  {showPicker && (
                    <div>
                      <EmojiPicker className="w-50" onEmojiClick={onEmojiClick} />
                    </div>
                  )}

                  <form className='d-flex align-items-center' action='#' 
                    onSubmit={(e) => {
                      e.preventDefault();
                      sendMessage(e, selectedGroup?.id);
                    }}
                  >
                    <div className='chat-attagement d-flex'>
                      <Link to='#' className='d-flex align-items-center pe-3' onClick={(e) => {
                        e.preventDefault();
                        setShowPicker((val) => !val);
                      }}>
                        <svg
                          className='icon-24'
                          width='24'
                          viewBox='0 0 24 25'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <g clipPath='url(#clip0_156_599)'>
                            <path
                              d='M20.4853 4.01473C18.2188 1.74823 15.2053 0.5 12 0.5C8.79469 0.5 5.78119 1.74823 3.51473 4.01473C1.24819 6.28119 0 9.29469 0 12.5C0 15.7053 1.24819 18.7188 3.51473 20.9853C5.78119 23.2518 8.79469 24.5 12 24.5C15.2053 24.5 18.2188 23.2518 20.4853 20.9853C22.7518 18.7188 24 15.7053 24 12.5C24 9.29469 22.7518 6.28119 20.4853 4.01473ZM12 23.0714C6.17091 23.0714 1.42856 18.3291 1.42856 12.5C1.42856 6.67091 6.17091 1.92856 12 1.92856C17.8291 1.92856 22.5714 6.67091 22.5714 12.5C22.5714 18.3291 17.8291 23.0714 12 23.0714Z'
                              fill='currentcolor'
                            ></path>
                            <path
                              d='M9.40398 9.3309C8.23431 8.16114 6.33104 8.16123 5.16136 9.3309C4.88241 9.60981 4.88241 10.0621 5.16136 10.3411C5.44036 10.62 5.89266 10.62 6.17157 10.3411C6.78432 9.72836 7.78126 9.7284 8.39392 10.3411C8.53342 10.4806 8.71618 10.5503 8.89895 10.5503C9.08171 10.5503 9.26457 10.4806 9.40398 10.3411C9.68293 10.0621 9.68293 9.60986 9.40398 9.3309Z'
                              fill='currentcolor'
                            ></path>
                            <path
                              d='M18.8384 9.3309C17.6688 8.16123 15.7655 8.16114 14.5958 9.3309C14.3169 9.60981 14.3169 10.0621 14.5958 10.3411C14.8748 10.62 15.3271 10.62 15.606 10.3411C16.2187 9.72836 17.2156 9.72831 17.8284 10.3411C17.9679 10.4806 18.1506 10.5503 18.3334 10.5503C18.5162 10.5503 18.699 10.4806 18.8384 10.3411C19.1174 10.0621 19.1174 9.60986 18.8384 9.3309Z'
                              fill='currentcolor'
                            ></path>
                            <path
                              d='M18.3335 13.024H5.6668C5.2723 13.024 4.95251 13.3438 4.95251 13.7383C4.95251 17.6243 8.11409 20.7859 12.0001 20.7859C15.8862 20.7859 19.0477 17.6243 19.0477 13.7383C19.0477 13.3438 18.728 13.024 18.3335 13.024ZM12.0001 19.3573C9.14366 19.3573 6.77816 17.215 6.42626 14.4525H17.574C17.2221 17.215 14.8566 19.3573 12.0001 19.3573Z'
                              fill='currentcolor'
                            ></path>
                          </g>
                          <defs>
                            <clipPath>
                              <rect
                                width='24'
                                height='24'
                                fill='white'
                                transform='translate(0 0.5)'
                              ></rect>
                            </clipPath>
                          </defs>
                        </svg>
                      </Link>
                    </div>
                    <input
                      type='text'
                      className='form-control me-3'
                      placeholder='digite sua mensagem'
                      onChange={(e) => setTextMessage(e.target.value)}
                      value={textMessage}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          e.preventDefault();
                          sendMessage(e, selectedGroup?.id);
                        }
                      }}
                    />
                    <button
                      type='submit'
                      className='btn btn-primary d-flex align-items-center'
                    >
                      <svg
                        className='icon-20'
                        width='18'
                        viewBox='0 0 20 21'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M13.8325 6.67463L8.10904 12.4592L1.59944 8.38767C0.66675 7.80414 0.860765 6.38744 1.91572 6.07893L17.3712 1.55277C18.3373 1.26963 19.2326 2.17283 18.9456 3.142L14.3731 18.5868C14.0598 19.6432 12.6512 19.832 12.0732 18.8953L8.10601 12.4602'
                          stroke='currentcolor'
                          strokeWidth='2'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        ></path></svg
                      ><span className='d-none d-lg-block ms-1'>Enviar</span>
                    </button>
                  </form>
                </div>
              </Tab.Pane>

            </Tab.Content>
          </div>
        </main>
      </Tab.Container>
    </>
  )
}
export default Chat
