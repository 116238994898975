import React, { useEffect, useState } from "react";
import { Nav, Form, Card, Container, Image, Dropdown, Navbar, Button, Modal } from "react-bootstrap";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { toast } from 'react-toastify'
import AnimationWarningLottie from '../../../AnimationWarningDeleteConfim/AnimationWarningLottie'
import AnimationWarningApproveLottie from '../../../AnimationWarningApproveConfim/AnimationWarningApproveConfimLottie'
import { useFriendRequests } from '../../../../context/FriendRequestContext';
import { useFriendStatus } from '../../../../context/FriendStatusContext';
import { useNotification } from '../../../../context/NotificationContext';
import { useUserAbout } from '../../../../context/UserAboutContext';
import { formatDistanceToNow, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';

import loader from "../../../../assets/images/page-img/page-load-loader.gif";
import io from 'socket.io-client';
import './style.css'

//image
import userGerericImage from '../../../../assets/images/generic_user.png'
import atmoLogo from '../../../../assets/images/atmo logo.png'

// Import selectors & action from setting store
import * as SettingSelector from '../../../../store/setting/selectors'
import SettingOffCanvas from "../../../../components/setting/SettingOffCanvas";

// Redux Selector / Action
import { useSelector, useDispatch } from "react-redux";
import { set_receive_new_messages, set_selected_friend, set_friend_messages } from "../../../../store/setting/actions";
import SearchModal from "../../../search-modal";

import Cookies from 'js-cookie'
import api from '../../../../services/api'

const Header = () => {
    const location = useLocation();
    const appName = useSelector(SettingSelector.app_name);
    const receiveNewMessages = useSelector((state) => state.setting.setting.receive_new_messages);
    const userId = Cookies.get('userId');
    const token = Cookies.get('token');
    const loged_user = Cookies.get('userId');
    const customId = 'success-toast-id'
    const [modalShow, setModalShow] = useState(false)
    const [modalAcceptShow, setModalAccpetShow] = useState(false)
    const [userIdToAction, setUserIdToAction] = useState('')
    const [userNameToAction, setUserNameToAction] = useState('')
    const { friendRequests, fetchFriendRequests } = useFriendRequests();
    const [isLoading, setIsLoading] = useState(false);
    const [notificationFriendRequestCount, setNotificationFriendRequestCount] = useState(0);
    const { fetchFriendStatus } = useFriendStatus();
    const { notifications, isNewNotification, unreadCount, fetchNotification } = useNotification();
    const { userAbout, fetchUserAbout } = useUserAbout();


    const handleNotificationIconClick = () => {
        fetchNotification();
    }; const userStatus = Cookies.get('userStatus');
    const [chatStatus, setChatStatus] = useState(userStatus);
    const [conversations, setConversations] = useState([]);
    const dispatch = useDispatch();

    const changeStatus = async (status) => {
        try {
            await api.put(`/api/users/changeStatus`, {
                logged_user: userId,
                status,
            })

            Cookies.set('userStatus', status)
            setChatStatus(status)
        } catch (error) {
            toast.error('Erro mudar status', {
                toastId: customId,
                autoClose: 3000,
            })

            console.error('Failed to fetch user data:', error)
        }
    }

    const getMessages = async (friendId) => {
        try {
            const response = await api.get(`/api/chat/listMessagesByFriend/${userId}/${friendId}`);

            dispatch(set_friend_messages(response.data.messages))

        } catch (error) {
            console.error('Failed to get messages:', error);

            toast.error('Erro ao listar mensagens', {
                toastId: customId,
                autoClose: 3000,
            })
        }
    }

    const getConversations = async () => {
        try {
            const response = await api.post(`api/chat/getLast4Messages`);

            const data = response.data;
            setConversations(data)
        } catch (error) {
            console.error('Failed to get conversations:', error);

            toast.error('Erro ao listar últimas conversas', {
                toastId: customId,
                autoClose: 3000,
            })
        }
    }

    useEffect(() => {
        fetchNotification(); 
        fetchUserAbout()
    }, []);

    const handleNotificationRequestFriendClick = () => {
        if (notificationFriendRequestCount.length > 0) {
            deleteFriendRequestNotification();
        }
        fetchFriendRequests()
    };

    const readNotification = async (index, event) => {
        event.preventDefault();
        try {
            await api.post(`/api/notification/markAsRead`, { index, loged_user }, {
                headers: { Authorization: `Bearer ${token}` },
            })
            fetchNotification();
        } catch (error) {
            console.error('Failed to remove friend:', error);
        }
    };

    const readAllNotification = async (event) => {
        event.preventDefault();
        try {
            await api.post(`/api/notification/markAllAsRead`, { loged_user }, {
                headers: { Authorization: `Bearer ${token}` },
            })
            toast.info(`Notificações marcadas como lidas.`, { autoClose: 1000 });
            fetchNotification();
        } catch (error) {
            console.error('Failed to remove friend:', error);
        }
    };


    const socket = io(process.env.REACT_APP_API_SOCKET_URL, {
        autoConnect: true,
        withCredentials: true,
    });

    let cancel = false;

    async function getFriendRequestNotification() {
        try {
            const response = await api.post(`/api/notification/getNotificationsFriendRequest/`, {
                loged_user,
                token,
            });

            if (!cancel) {
                const data = response;
                setNotificationFriendRequestCount(data.data)
            }
        } catch (error) {
            console.error('Failed to fetch notifications data:', error);
        }
    }

    async function deleteFriendRequestNotification() {
        try {
            await api.post(`/api/notification/deleteNotificationsFriendRequest/`, {
                loged_user,
                token,
            });

            getFriendRequestNotification()

        } catch (error) {
            console.error('Failed to fetch user data:', error);
        }
    }

    useEffect(() => {
        getFriendRequestNotification(true)
    }, []);


    useEffect(() => {
        setIsLoading(true);
        fetchFriendRequests().then(() => {
            setIsLoading(false);
        }).catch(() => {
            setIsLoading(false);
        });
    }, [fetchFriendRequests]);

    useEffect(() => {
        socket.on('connect', () => {
            if (userId) {
                socket.emit('register', userId);
            }
        });

        socket.on('new-friend-request', (data) => {
            toast.info(`Você recebeu uma nova solicitação de amizade`, { autoClose: 1000 });
            fetchFriendRequests()
            fetchFriendStatus(data.from)
            getFriendRequestNotification()
            fetchNotification()
        });

        socket.on('new-group-member-request', (data) => {
            toast.info(`Você recebeu uma nova solicitação de entrada em grupo`, { autoClose: 1000 });
            fetchNotification()
        });

        socket.on('friend-request-cancel', (data) => {
            fetchFriendRequests()
            fetchFriendStatus(data.from)
        });

        socket.on('friend-request-accept', (data) => {
            toast.info(`Solicitação de amizade aceita`, { autoClose: 1000 });
            fetchFriendRequests()
            fetchFriendStatus(data.from)
            fetchNotification()
        });

        socket.on('group-member-request-accept', (data) => {
            toast.info(`Solicitação de entrada em grupo aceita`, { autoClose: 1000 });
            fetchNotification()
        });

        socket.on('friend-request-remove', (data) => {
            toast.info(`Amizade removida`, { autoClose: 1000 });
            fetchFriendRequests()
            fetchFriendStatus(data.from);
        });

        socket.on('friend-request-block', (data) => {
            toast.info(`Pedido de amizade bloqueado`, { autoClose: 1000 });
            fetchFriendRequests()
            fetchFriendStatus(data.from);
        });

        socket.on('group-member-request-block', (data) => {
            toast.info(`Solicitação de entrada em grupo bloqueada`, { autoClose: 1000 });
        });

        socket.on('new-comment-request', (data) => {
            console.log(data)
            toast.info(`Novo comentario em seu post`, { autoClose: 1000 });
            fetchNotification()
        });

        socket.on('new-reaction-request', (data) => {
            console.log(data)
            toast.info(`Nova reação em seu post`, { autoClose: 1000 });
            fetchNotification()
        });

        socket.on('new-reaction-comment-request', (data) => {
            console.log(data)
            toast.info(`Nova reação em seu comentário`, { autoClose: 1000 });
            fetchNotification()
        });

        socket.on('new-share-request', (data) => {
            console.log(data)
            toast.info(`Novo compartilhamento de post`, { autoClose: 1000 });
            fetchNotification()
        });

        return () => {
            socket.off('connect');
            socket.off('new-friend-request');
            socket.off('friend-request-cancel');
            socket.off('friend-request-accept');
            socket.off('friend-request-remove');
            socket.off('friend-request-block');
            socket.off('new-comment-request');
            socket.off('new-reaction-request');
            socket.off('new-reaction-comment-request');
            socket.off('new-share-request');
            socket.disconnect();
        };
    }, [socket]);

    const showDeleteRequestConfirmationModal = (userId, userName) => {
        setModalShow(true)
        setUserIdToAction(userId)
        setUserNameToAction(userName)
    }

    const deleteRequest = async (friendId) => {
        try {
            await api.post(`/api/friends/blocked`, { friendId, loged_user }, {
                headers: { Authorization: `Bearer ${token}` },
            }).then(() => {
                toast.success('Pedido de amizade bloqueado com sucesso!', { autoClose: 1000 });
                fetchFriendRequests()
                setModalShow(false)
            });
        } catch (error) {
            console.error('Falha ao bloquear amigo', error);
            toast.error('Falha ao bloquear amigo.');

        }
    }

    function DeleteRequestConfirmationModal(props) {

        return (
            <Modal
                {...props}
                aria-labelledby='contained-modal-title-vcenter'
                centered
            >
                <Modal.Header id='modalHeader' closeButton>
                    <div id='divModalTitle'>
                        <Modal.Title id='modalTitle'>
                            Recusar pedido de amizade
                        </Modal.Title>
                    </div>
                </Modal.Header>
                <Modal.Body id='modalBody'>
                    <div className="d-flex justify-content-center m-4">
                        <AnimationWarningLottie />
                    </div>
                    <div className="d-flex">
                        <p>
                            Você tem certeza de que deseja recusar o pedido de amizade de {props.username} ?
                        </p>
                    </div>
                </Modal.Body>
                <Modal.Footer id='modalFooter'>
                    <Button id='yesButton' onClick={() => deleteRequest(props.userid)}>Sim</Button>
                    <Button id='noButton' onClick={props.onHide}>Não</Button>
                </Modal.Footer>
            </Modal>
        )
    }

    const showAcceptRequestConfirmationModal = (userId, userName, e) => {
        setModalAccpetShow(true)
        setUserIdToAction(userId)
        setUserNameToAction(userName)
    }


    function AcceptRequestConfirmationModal(props) {

        return (
            <Modal
                {...props}
                aria-labelledby='contained-modal-title-vcenter'
                centered
            >
                <Modal.Header id='modalHeader' closeButton>
                    <div id='divModalTitle'>
                        <Modal.Title id='modalTitle'>
                            Aceitar pedido de amizade
                        </Modal.Title>
                    </div>
                </Modal.Header>
                <Modal.Body id='modalBody'>
                    <div className="d-flex justify-content-center m-4">
                        <AnimationWarningApproveLottie />
                    </div>
                    <div className="d-flex">
                        <p>
                            Deseja aceitar o pedido de amizade de {props.username} ?
                        </p>
                    </div>
                </Modal.Body>
                <Modal.Footer id='modalFooter'>
                    <Button id='yesButton' onClick={(e) => handleFriendAction(props.userid, e)}>Sim</Button>
                    <Button id='noButton' onClick={props.onHide}>Não</Button>
                </Modal.Footer>
            </Modal>
        )
    }

    const handleFriendAction = async (friendId) => {
        try {
            await api.post(`/api/friends/accept`, { friendId, loged_user: loged_user }, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setModalAccpetShow(false)
            toast.success('Pedido de amizade aceito com sucesso!', { toastId: customId, autoClose: 1000 });
            fetchFriendRequests()
            fetchFriendStatus(friendId)
        } catch (error) {
            console.error('Failed to add friend:', error);
            toast.error('Falha ao adicionar amigo.');
        }
    };

    const minisidebar = () => {
        const sidebarMini = document.getElementsByTagName("ASIDE")[0];
        if (sidebarMini.classList.contains('sidebar-mini')) {
            sidebarMini.classList.remove('sidebar-mini')
        }
        else {
            sidebarMini.classList.add('sidebar-mini')
        }
    };

    const dropdownContent = document.querySelectorAll(".sub-drop");
    if (dropdownContent) {
        dropdownContent.forEach((element) => {
            setTimeout(() => {
                element.style = "";
            }, 100);
        });
    }

    const history = useNavigate()

    const handleLogout = async (e) => {
        e.preventDefault()

        try {
            await api.put(`/api/users/changeStatus`, {
                logged_user: userId,
                status: 'disconnected',
            })

            Cookies.remove('userStatus')

            api.post('/api/auth/logout')
                .then(async response => {
                    console.log(response.data.message)
                    Cookies.remove('token')
                    Cookies.remove('userId')
                    Cookies.remove('userName')

                    history('/auth/sign-in')
                    history('/auth/sign-in')

                    history('/auth/sign-in')

                })
                .catch(error => {
                    console.log(error)
                })

        } catch (error) {
            console.error('Erro ao fazer logout:', error)
        }
    }

    return (
        <>
            <DeleteRequestConfirmationModal
                show={modalShow}
                onHide={() => setModalShow(false)}
                userid={userIdToAction}
                username={userNameToAction}
            />

            <AcceptRequestConfirmationModal
                show={modalAcceptShow}
                onHide={() => setModalAccpetShow(false)}
                userid={userIdToAction}
                username={userNameToAction}
            />
            {/* <div className="position-relative"> */}
            <div className="iq-top-navbar border-bottom">
                <Navbar
                    expand="lg"
                    variant="light"
                    className="nav navbar navbar-expand-lg navbar-light iq-navbar p-lg-0"
                >
                    {/* <Navbar className="iq-navbar p-lg-0" sticky="top"> */}
                    <Container fluid className="navbar-inner">
                        <div className="d-flex align-items-center pb-2 pb-lg-0 d-xl-none">
                            <Link
                                to="/"
                                className="d-flex align-items-center iq-header-logo navbar-brand d-block d-xl-none"
                            >
                                <img style={{ width: 50 }} src={atmoLogo} alt='Logo Atmo' ></img>
                                <h3
                                    className="logo-title d-none d-sm-block"
                                    data-setting="app_name"
                                >
                                    {appName}
                                </h3>
                            </Link>
                            <Link
                                className="sidebar-toggle"
                                data-toggle="sidebar"
                                data-active="true"
                                onClick={minisidebar}
                                to="#"
                            >
                                <div className="icon material-symbols-outlined iq-burger-menu">
                                    {" "}
                                    menu{" "}
                                </div>
                            </Link>
                        </div>

                        <div className="d-flex align-items-center pull-rigth">
                            {
                                location.pathname === '/chat/index' ? (
                                    <Link to="/" className="d-flex align-items-center gap-2 iq-header-logo">
                                        <img style={{ width: 50 }} src={atmoLogo} alt='Logo Atmo' className="general-chat-logo" />
                                        <h6 className="logo-title general-chat-logo" data-setting="app_name">{appName}</h6>
                                    </Link>
                                ) : ''
                            } 

                            <Dropdown bsPrefix=" "
                                className="iq-search-bar device-search position-relative d-none d-lg-block"
                            >
                                
                                <Dropdown.Toggle as="form" bsPrefix=" "
                                    action="#"
                                    className="searchbox open-modal-search"
                                >
                                    <Link className="search-link" to="#">
                                        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="7.82491" cy="7.82495" r="6.74142" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></circle>
                                            <path d="M12.5137 12.8638L15.1567 15.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                        </svg>
                                    </Link>
                                    <Link
                                        className="d-lg-none d-flex d-none d-lg-block"
                                        to="/"
                                    >
                                        <span className="material-symbols-outlined">search12</span>
                                    </Link>
                                </Dropdown.Toggle>
                                <SearchModal />
                            </Dropdown>
                        </div>            

                        <ul className="navbar-nav navbar-list">

                            <Nav.Item as="li" className="d-lg-none">
                                <Dropdown bsPrefix=" " className="iq-search-bar device-search ">
                                    <Dropdown.Toggle
                                        as="form"
                                        bsPrefix=" "
                                        className="searchbo open-modal-search"
                                    >
                                        <Link className="d-lg-none d-flex text-body" to="#">
                                            <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="7.82491" cy="7.82495" r="6.74142" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></circle>
                                                <path d="M12.5137 12.8638L15.1567 15.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </Link>
                                        <Form.Control
                                            type="text"
                                            className="text search-input form-control bg-soft-primary  d-none d-lg-block"
                                            placeholder="Search here..."
                                        />

                                    </Dropdown.Toggle>
                                    <SearchModal />
                                </Dropdown>
                            </Nav.Item>

                            <Dropdown className="nav-item " as="li" onClick={handleNotificationRequestFriendClick}>
                                <Dropdown.Toggle as="a" bsPrefix=" "
                                    to=""
                                    className="dropdown-toggle d-flex align-items-center"
                                    id="group-drop"
                                >
                                    <span className="material-symbols-outlined position-relative">
                                        group_add
                                        {notificationFriendRequestCount.length > 0 &&
                                            <span className="bg-primary text-white shopping-badge">
                                                {notificationFriendRequestCount.length}
                                            </span>
                                        }
                                    </span>
                                </Dropdown.Toggle>
                                <Dropdown.Menu
                                    className={`sub-drop sub-drop-large`}
                                    aria-labelledby="group-drop"
                                    data-bs-popper="static"
                                >
                                    <div className="card shadow m-0">
                                        <div className="card-header d-flex justify-content-between px-0 pb-4 mx-5 border-bottom">
                                            <div className="header-title">
                                                <h5 className="fw-semibold">Solicitações de amizade</h5>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="item-header-scroll">
                                                {isLoading ? (
                                                    <div className="col-sm-12 text-center">
                                                        <img src={loader} alt="loader" style={{ height: "100px" }} />
                                                    </div>
                                                ) : friendRequests.data && friendRequests.data.length > 0 ? (
                                                    <ul className="request-list list-inline m-0 p-0">
                                                        {friendRequests.data.map((friend, index) => (
                                                            <li className="d-flex align-items-center justify-content-between" key={index}>
                                                                <div className="d-flex align-items-center">
                                                                    <div className="user-img img-fluid flex-shrink-0">
                                                                        <img
                                                                            src={friend.image_profile}
                                                                            alt="story-img"
                                                                            className="rounded-circle avatar-40"
                                                                        />
                                                                    </div>
                                                                    <div className="flex-grow-1 ms-3">
                                                                        <Link to={`/users/profile/${friend.user_id}`} >
                                                                            {friend.name}
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                                <div className="d-flex align-items-center mt-2 mt-md-0">
                                                                    <div className="confirm-click-btn">
                                                                        <Button onClick={() => showAcceptRequestConfirmationModal(friend.id_user, friend.name)} className="me-2 btn btn-success-subtle rounded confirm-btn p-1 lh-1">
                                                                            <i className="material-symbols-outlined font-size-14">
                                                                                done
                                                                            </i>
                                                                        </Button>
                                                                    </div>
                                                                    <div className="confirm-click-btn">
                                                                        <Button onClick={() => showDeleteRequestConfirmationModal(friend.id_user, friend.name)} className="me-2 btn btn-danger-subtle rounded confirm-btn p-1 lh-1">
                                                                            <i className="material-symbols-outlined font-size-14">
                                                                                close
                                                                            </i>
                                                                        </Button>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                ) : (
                                                    <div className="text-center">
                                                        <p>Não há solicitações de amizade no momento.</p>
                                                    </div>
                                                )}
                                            </div>
                                            {friendRequests.data && friendRequests.data.length > 0 &&
                                                <div className="text-center">
                                                    <Link to={"/friends/friend-request"}
                                                        type="button"
                                                        className="btn btn-primary fw-500 mt-4"
                                                    >
                                                        Ver todas Solicitações
                                                    </Link>
                                                </div>
                                            }

                                        </div>
                                    </div>
                                </Dropdown.Menu>
                            </Dropdown>

                            <Dropdown as="li" className="nav-item"  onClick={() => {
                                getConversations()
                                dispatch(set_receive_new_messages(false))
                            }}>
                                <Dropdown.Toggle as="a"
                                    to="#"
                                    className=" d-flex align-items-center"
                                    id="mail-drop"
                                >
                                    <span className="material-symbols-outlined position-relative">
                                        chat
                                        {
                                            receiveNewMessages ? (
                                                <span className="bg-primary text-white notification-badge"></span>
                                            ) : ''
                                        }
                                    </span>
                                </Dropdown.Toggle>

                                <Dropdown.Menu
                                    className={`sub-drop header-notification`}
                                    data-bs-popper="static"
                                >
                                    <div className="card shadow m-0">
                                        <div className="card-header d-flex justify-content-between px-0 pb-4 mx-5 border-bottom">
                                            <div className="header-title">
                                                <h5 className="fw-semibold">Mensagens não lidas</h5>
                                            </div>
                                        </div>
                                        <Card.Body className="p-0">
                                            <div className="item-header-scroll">
                                                {
                                                    conversations.map(conversation => {
                                                        if (conversation.latest_message) {
                                                            return (
                                                                <Link data-target="chat-popup-modal" to="#" key={`conversation_${conversation.id}`} onClick={() => {
                                                                    dispatch(set_selected_friend(conversation))
                                                                    getMessages(conversation.id)
                                                                }}>
                                                                    <div className="thread d-flex align-items-center justify-content-between rounded-0">
                                                                        <div>
                                                                            <img
                                                                                className="avatar-40 rounded-pill align-top"
                                                                                src={conversation.user_about.image_profile ?? userGerericImage}
                                                                                alt=""
                                                                                loading="lazy"
                                                                            />{" "}
                                                                            <div className="ms-3 d-inline-block">
                                                                                <h6>{conversation.name}</h6>
                                                                                <small className="fw-500 text-body">
                                                                                    {conversation.latest_message}
                                                                                </small>
                                                                            </div>
                                                                        </div>
                                                                        <small className="text-body">{conversation.latest_message_time}</small>
                                                                    </div>
                                                                </Link>
                                                            )
                                                        }
                                                    })
                                                }
                                            </div>
                                            {/* <div className="m-5 mt-4">
                        <button
                          type="button"
                          className="btn btn-primary fw-500 w-100"
                        >
                          todas as conversas
                        </button>
                      </div> */}
                                        </Card.Body>
                                    </div>
                                </Dropdown.Menu>
                            </Dropdown>

                            <Dropdown as="li" className="nav-item" onClick={handleNotificationIconClick}>
                                <Dropdown.Toggle as="a" className="search-toggle d-flex align-items-center">
                                    <span className="material-symbols-outlined position-relative">
                                        notifications
                                        {isNewNotification && <span className="bg-primary text-white shopping-badge">{unreadCount}</span>}
                                    </span>
                                </Dropdown.Toggle>
                                <Dropdown.Menu
                                    className={`sub-drop header-notification `}
                                    aria-labelledby="notification-drop"
                                    data-bs-popper="static"
                                >
                                    <Card className=" m-0 shadow">
                                        <div className="card-header d-flex justify-content-between px-0 pb-4 mx-5 border-bottom">
                                            <div className="header-title">
                                                <h5 className="fw-semibold">Notificações</h5>
                                            </div>
                                            {unreadCount > 0 &&
                                                <Link onClick={(e) => readAllNotification(e)}>
                                                    <i className="material-symbols-outlined d-flex">
                                                        done
                                                    </i>
                                                </Link>
                                            }
                                        </div>
                                        <Card.Body>
                                            {isLoading ? (
                                                <div className="col-sm-12 text-center">
                                                    <img src={loader} alt="loader" style={{ height: "100px" }} />
                                                </div>
                                            ) : notifications && notifications.length > 0 ? (
                                                <div className="item-header-scroll">
                                                    {notifications && notifications.map((notification, index) => (
                                                        <div key={index} onClick={(e) => readNotification(notification.id, e)}>
                                                            <Link to={notification.url}>
                                                                <div className="d-flex gap-3 mb-4">
                                                                    <img
                                                                        className="avatar-32 rounded-pill"
                                                                        src={notification.image_profile}
                                                                        alt="image_profilel"
                                                                    />
                                                                    <div>
                                                                        <h6 className="font-size-14">
                                                                            {notification.user_name} {" "}
                                                                            <span className="text-body fw-normal text-lowercase">
                                                                                {notification.message}
                                                                            </span>
                                                                        </h6>
                                                                        <small className="text-body fw-500">
                                                                            {formatDistanceToNow(parseISO(notification.created_at), { addSuffix: true, locale: ptBR })}
                                                                            {notification.read == false &&
                                                                                <span className="bg-primary text-white notification-badge-read"></span>
                                                                            }
                                                                        </small>
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                        </div>
                                                    ))}
                                                    <Link
                                                        type="button" to={'/notifications/notification'}
                                                        className="btn btn-primary fw-500 w-100"
                                                    >
                                                        Ver todas notificações
                                                    </Link>
                                                </div>
                                            ) : (
                                                <div className="text-center">
                                                    <p>Não há notificações no momento.</p>
                                                </div>
                                            )}
                                        </Card.Body>
                                    </Card>
                                </Dropdown.Menu>
                            </Dropdown>

                            <Nav.Item className="nav-item d-none d-lg-none">
                                <Link
                                    to="#"
                                    className="dropdown-toggle d-flex align-items-center"
                                    id="mail-drop-1"
                                    data-bs-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                >
                                    <i className="material-symbols-outlined">mail</i>
                                    <span className="mobile-text  ms-3">Message</span>
                                </Link>
                            </Nav.Item>
                            <Dropdown as="li" className="nav-item user-dropdown">
                                <Dropdown.Toggle as="a"
                                    to="#"
                                    className="d-flex align-items-center mt-perfil-mobile"
                                    id="drop-down-arrow"
                                >
                                    <Image
                                        src={userAbout ? userAbout.user.image_profile : loader}
                                        className="img-fluid rounded-circle avatar-48 border border-2 me-3"
                                        alt="user"
                                        loading="lazy"
                                    />
                                </Dropdown.Toggle>
                                <Dropdown.Menu
                                    className={`sub-drop caption-menu `}
                                >
                                    <Card className="shadow-none m-0">
                                        <Card.Header>
                                            <div className="header-title">
                                                <h5 className="mb-0 ">{userAbout ? userAbout.user.name : ''}</h5>
                                            </div>
                                        </Card.Header>
                                        <Card.Body className="p-0 ">
                                            <div className="d-flex align-items-center iq-sub-card border-0">
                                                <span className="material-symbols-outlined">
                                                    line_style
                                                </span>
                                                <div className="ms-3">
                                                    <Link
                                                        to="/users/my-profile"
                                                        className="mb-0 h6"
                                                    >
                                                        Meu Perfil
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-center iq-sub-card border-0">
                                                <span className="material-symbols-outlined">
                                                    edit_note
                                                </span>
                                                <div className="ms-3">
                                                    <Link to="/users/user-profile-edit" className="mb-0 h6">
                                                        Editar Perfil
                                                    </Link>
                                                </div>
                                            </div>
                                            {/* <div className="d-flex align-items-center iq-sub-card border-0">
                                                <span className="material-symbols-outlined">
                                                    lock
                                                </span>
                                                <div className="ms-3">
                                                    <Link
                                                        to="/users/user-privacy-setting"
                                                        className="mb-0 h6"
                                                    >
                                                        Privacidade
                                                    </Link>
                                                </div>
                                            </div> */}
                                            <div className="d-flex align-items-center iq-sub-card border-0">
                                                <span className="material-symbols-outlined">
                                                    Settings
                                                </span>
                                                <div className="ms-3">
                                                    <SettingOffCanvas />
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-center iq-sub-card">
                                                <span className="material-symbols-outlined">
                                                    login
                                                </span>
                                                <div className="ms-3">
                                                    <Link onClick={handleLogout} className="mb-0 h6">
                                                        Sair
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className=" iq-sub-card">
                                                <h5>Chat Settings</h5>
                                            </div>
                                            <div className="d-flex align-items-center iq-sub-card border-0" onClick={() => changeStatus('online')}>
                                                <i className={`material-symbols-outlined md-14 text-success ${chatStatus === 'online' ? 'filled' : ''}`}>
                                                    circle
                                                </i>
                                                <div className="ms-3">Online</div>
                                            </div>
                                            <div className="d-flex align-items-center iq-sub-card border-0" onClick={() => changeStatus('away')}>
                                                <i className={`material-symbols-outlined md-14 text-warning ${chatStatus === 'away' ? 'filled' : ''}`}>
                                                    circle
                                                </i>
                                                <div className="ms-3">Ocupado</div>
                                            </div>
                                            <div className="d-flex align-items-center iq-sub-card border-0" onClick={() => changeStatus('invisible')}>
                                                <i className={`material-symbols-outlined md-14 text-gray ${chatStatus === 'invisible' ? 'filled' : ''}`}>
                                                    circle
                                                </i>
                                                <div className="ms-3">Invisível</div>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Dropdown.Menu>
                            </Dropdown>
                        </ul>
                    </Container>
                    {/* </Navbar> */}
                </Navbar>
            </div >
            {/* </div> */}

            {/* <div
        className={`modal-backdrop fade ${show ? "show" : "d-none"}`}
        onClick={handleClose}
      ></div> */}
        </>
    );
};

export default Header;
