import Lottie from "lottie-react";
import animationWarningApproveLottie from "./animationWarningApproveLottie.json";

const style = {
    width: 100,
    height: 100,
    top: -25,
};

const AnimationWarningApproveConfimLottie = () => {
    return <Lottie loop={true} style={style} animationData={animationWarningApproveLottie} />;
};

export default AnimationWarningApproveConfimLottie;