import React, { useState, useEffect } from 'react';
import api from '../../services/api';
import placeholderIMG from '../../assets/images/placeholder.png';
import { Card, Container } from 'react-bootstrap';

const TeamLeaderInfo = ({ user_id }) => {
    const [leaderData, setLeaderData] = useState(null);

    useEffect(() => {
        api.get(`/api/user/${user_id}/team-name`)
            .then(response => {
                // Assumindo que o líder da equipe é o primeiro item da resposta (ajuste conforme necessário)
                const leader = response.data;  // Ajuste o índice conforme necessário para obter o líder correto
                setLeaderData(leader);
            })
            .catch(error => {
                console.error('Erro ao carregar o organograma', error);
            });
    }, []);

    return (
        <Container>
            <Card>
                {leaderData ? (
                    <div className="d-flex flex-row gap-2">
                        <a href={`/users/profile/${leaderData.leader_id}`}>
                            <img
                                alt={leaderData.leader_name}
                                src={leaderData.leader_photo ? leaderData.leader_photo : placeholderIMG}
                                style={{ width: "3rem", height: "3rem", borderRadius: "50%" }}
                            />
                        </a>
                        <div className='f-flex flex-column'>
                            <h5 className="ms-2 fw-bolder fs-6">{leaderData.leader_name}</h5>
                            <div className="ms-2">Lider de:<span className="ms-2 fw-bolder fs-6">{leaderData.team_name}</span></div>
                        </div>
                    </div>
                ) : (
                    <p>Carregando...</p>
                )}
            </Card>
        </Container>
    );
};

export default TeamLeaderInfo;
