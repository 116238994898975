import React, { useState, useEffect } from 'react'
import { Row, Col, Container, Button, Card } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import { Link } from 'react-router-dom'
import api from '../../services/api'
import { toast } from 'react-toastify'
import { Line } from 'react-chartjs-2'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'

const Chart = () => {
    ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend)
    const customId = 'success-toast-id'
    let { id } = useParams()
    const [horas, setHoras] = useState([])
    const [audiencia, setAudiencia] = useState([])

    const data = {
        labels: horas,
        datasets: [
          {
            label: 'Pessoas',
            data: audiencia,
            backgroundColor: 'rgba(75, 192, 192, 0.2)',
            borderColor: 'rgba(75, 192, 192, 1)',
            borderWidth: 1,
          },
        ],
    }
      
    const options = {
        responsive: true,
        plugins: {
            legend: {
            position: 'top',
            },
            title: {
                display: true,
                text: 'Audiência',
            },
        },
        scales: {
            y: {
              beginAtZero: true,
              min: 0,     
            },
        },
    }

    useEffect(() => {
        let cancel = false

        async function fetchData() {
            try {
                const response = await api.post(`/api/viewsChart`, {
                    live_id: id
                })

                if (!cancel) {
                    setHoras(response.data.horas_grafico)
                    setAudiencia(response.data.audiencia)
                }
            } catch (error) {
                console.error('Failed to fetch chart data:', error)
                toast.error('Erro ao recuperar dados do gráfico', {
                    toastId: customId,
                    autoClose: 1000,
                })
            }
        }

        fetchData()

        return () => {
            cancel = true
        }
    }, [])

    return (
        <>
            <div id='content-page' className='content-inner'>
                <Container>
                    <Row>
                        <Col sm='12'>
                            <Card className='position-relative inner-page-bg bg-primary p-5'>
                                <div className='inner-page-title' style={{ display: 'flex', justifyContent: 'space-between', width: '-webkit-fill-available' }}>
                                    <h3 className='text-white'>Lives</h3>
                                    <Link to='/streaming/index'>
                                        <Button><i className='icon material-symbols-outlined d-flex'>
                                            arrow_back
                                        </i></Button>
                                    </Link>
                                </div>
                            </Card>
                        </Col>
                        <Col sm='12' lg='12'>
                            <Col sm='12' lg='12'>
                                <Card>
                                    <Card.Header className='d-flex justify-content-between'>
                                        <div className='header-title'>
                                            <h4 className='card-title'>Gráfico de acessos</h4>
                                        </div>
                                    </Card.Header>
                                    <Card.Body className='pt-0'>
                                        <Line data={data} options={options} />
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default Chart