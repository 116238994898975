import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react'
import { Col, OverlayTrigger, Tooltip, Collapse, Dropdown, Button, Modal, CloseButton, Form, Popover, Badge } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { set_handle_posts } from '../../store/setting/actions'
import Cookies from 'js-cookie'
import ShareOffcanvasNew from '../../components/ShareOffcanvasNew'
import { FaFileUpload } from 'react-icons/fa'
import like_btn from '../../assets/images/icon/like_btn.png'
import love_btn from '../../assets/images/icon/love_btn.png'
import haha_btn from '../../assets/images/icon/haha_btn.png'
import wow_btn from '../../assets/images/icon/wow_btn.png'
import angry_btn from '../../assets/images/icon/angry_btn.png'
import sad_btn from '../../assets/images/icon/sad_btn.png'
import Comment from '../../components/Comment'
import { debounce } from 'lodash'
import { useInView } from 'react-intersection-observer'
import { FaSearch } from 'react-icons/fa'
import ShowReactionModal from '../../components/Reactions/modalShowReactions';

import api from '../../services/api'
import { toast } from 'react-toastify'
import AnimationWarningLottie from '../../components/AnimationWarningDeleteConfim/AnimationWarningLottie'
import { formatDistanceToNow, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { useUserAbout } from '../../context/UserAboutContext';
import Plyr from "plyr-react"
import "plyr-react/plyr.css"
import loader from '../../assets/images/page-img/page-load-loader.gif'
import ShowPostModal from '../Post/modalShowPost'

const Index = ({ user_name, user_id, user_image, type, media, post_time, text, post_id, privacy, group_id, user_reaction, reactions, share_id, share_posts, group_name, view_count, important = false }) => {
  const dispatch = useDispatch()
  const handlePosts = useSelector((state) => state.setting.setting.handle_posts)
  const handleComments = useSelector((state) => state.setting.setting.handle_comments)
  const [open3, setOpen3] = useState(false)
  const [modalShow3, setModalShow3] = useState(false)
  const [modalShow, setModalShow] = useState(false)
  const [reactionsModalShow, setReactionsModalShow] = useState(false)
  const [showEdit, setShowEdit] = useState(false)
  const [showMediaInput, setShowMediaInput] = useState(false)
  const [showSpinner, setShowSpinner] = useState(false)
  const [removeImages, setRemoveImages] = useState(false)
  const [removeVideos, setRemoveVideos] = useState(false)
  const [newText, setNewText] = useState(text ?? '')
  const [newImage, setNewImage] = useState(null)
  const [newVideo, setNewVideo] = useState(null)
  const [newMedia, setNewMedia] = useState(media)
  const [newType, setNewType] = useState(type)
  const [newPrivacy, setNewPrivacy] = useState(privacy)
  const [newReaction, setNewReaction] = useState(user_reaction)
  const [newReactionsCounter, setNewReactionsCounter] = useState(reactions)
  const logged_user = Cookies.get('userId')
  const [comments, setComments] = useState(null)
  const [newComment, setNewComment] = useState('')
  const { userAbout, fetchUserAbout } = useUserAbout()
  const [isLoading, setIsLoading] = useState(true)
  const image_profile = userAbout?.user?.image_profile;
  const [isAdmin, setIsAdmin] = useState(false)

  const getDetailsGroup = async () => {
    const response = await api.get(`/api/group/${group_id}/show`)

    setIsAdmin(response.data.is_adm)
  }

  useEffect(() => {
    fetchUserAbout()
    getDetailsGroup()
  }, []);

  const handleMediaClick = () => {
    if (newImage || newVideo) {
      setNewImage(null)
      setNewVideo(null)
      setShowMediaInput(false)
    } else {
      setShowMediaInput(!showMediaInput)
    }
  }

  const handleFileUpload = (e) => {
    const file = e.target.files[0]
    if (file) {
      const fileType = file.type.split('/')[0]
      if (fileType === 'image') {
        setNewImage(file)
        setShowMediaInput(false)
      } else if (fileType === 'video') {
        setNewVideo(file)
        setShowMediaInput(false)
      }
    }
  }

  const removeImage = () => {
    setNewImage(null)
    setShowMediaInput(true)
  }

  const removeVideo = () => {
    setNewVideo(null)
    setShowMediaInput(true)
  }

  const updatePost = async () => {
    setShowSpinner(true)

    const formData = new FormData()
    formData.append('text', newText)
    formData.append('privacy', newPrivacy)
    if (removeImages) formData.append('remove_image', removeImages)
    if (removeVideos) formData.append('remove_video', removeVideos)
    if (newImage) formData.append('image', newImage)
    if (newVideo) formData.append('video', newVideo)

    await api.post(`/api/postUpdate/${post_id}`, formData).then((res) => {
      setShowEdit(false)
      setShowSpinner(false)
      setShowMediaInput(false)
      setRemoveVideos(false)
      setRemoveImages(false)
      setNewImage(null)
      setNewVideo(null)

      if (res.data.post.image) {
        setNewMedia(res.data.post.image)
        setNewType('image')
      } else if (res.data.post.video) {
        setNewMedia(res.data.post.video)
        setNewType('video')
      } else {
        setNewMedia('')
        setNewType('text')
      }
      toast.success('Postagem editada com sucesso', { autoClose: 3000 })
    }).catch(err => {
      setShowSpinner(false)
      toast.error('Erro ao editar postagem', { autoClose: 3000 })
    })
  }

  const deletePost = async () => {
    await api.delete(`/api/posts/${post_id}`).then(() => {
      dispatch(set_handle_posts(!handlePosts))
      setModalShow(false)
      toast.success('Postagem deletada com sucesso', { autoClose: 3000 })
    }).catch(err => toast.error('Erro ao deletar postagem', { autoClose: 3000 }))
  }

  const handleEditPost = async () => {
    setShowEdit(true)
  }

  const removeAllFiles = () => {
    setShowMediaInput(false)
    setNewImage(null)
    setNewVideo(null)
    setRemoveImages(true)
    setRemoveVideos(true)

    toast.success('Arquivos removidos da postagem', { autoClose: 3000 })
  }

  const getComments = async () => {
    const response = await api.post(`/api/comments/getComments/${post_id}`)
    setIsLoading(false)
    setComments(response.data)
  }

  const commentStore = async (e) => {
    e.preventDefault()
    await api.post(`/api/comments/store/${post_id}`, {
      newComment,
      logged_user,
    }).then(() => {
      toast.success('Comentario feito com sucesso.', { autoClose: 1000 })
      setNewComment('')
      getComments()
    }).catch(err => toast.error('Erro ao criar comentario', { autoClose: 3000 }))
  }

  const playerRef = useRef(null);
  const playerRefNewVideo = useRef(null);
  const playerRefShareVideo = useRef(null);

  const plyrProps = useMemo(() => ({
    source: {
      type: "video",
      sources: [
        {
          src: newMedia,
          type: "video/mp4",
        }
      ]
    }
  }), [newMedia]);

  const plyrPropsShare = useMemo(() => ({
    source: {
      type: "video",
      sources: [
        {
          src: share_posts ? share_posts.video : '',
          type: "video/mp4",
        }
      ]
    }
  }), [share_posts ? share_posts.video : '']);

  const plyrPropsNewVideo = useMemo(() => ({
    source: {
      type: "video",
      sources: [
        {
          src: newVideo ? URL.createObjectURL(newVideo) : "",
          type: "video/mp4",
        }
      ]
    }
  }), [newVideo ? URL.createObjectURL(newVideo) : ""]);

  function DeletePostConfirmationModal(props) {
    return (
      <Modal
        {...props}
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header id='modalHeader' closeButton>
          <div id='divModalTitle'>
            <Modal.Title id='modalTitle'>
              Exclusão de postagem
            </Modal.Title>
          </div>
        </Modal.Header>
        <Modal.Body id='modalBody'>
          <div className='d-flex justify-content-center'>
            <AnimationWarningLottie />
          </div>
          <div className='d-flex justify-content-center'>
            <p>
              Você tem certeza de que deseja remover essa postagem ?
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer id='modalFooter'>
          <Button id='yesButton' onClick={() => deletePost()}>Sim</Button>
          <Button id='noButton' onClick={props.onHide}>Não</Button>
        </Modal.Footer>
      </Modal>
    )
  }

  const [modalShowPost, setModalShowPost] = useState(false);

  const openModal = () => {
    setModalShowPost(true);
  };

  const openModalReaction = () => {
    setReactionsModalShow(true);
  };

  const sendReaction = async (reaction) => {
    await api.post(`/api/reactionPost/store`, {
      post_id,
      reaction
    }).catch(err => toast.error('Erro ao reagir post', { autoClose: 3000 }))
  }

  const removeReaction = async () => {
    setNewReactionsCounter(prev => prev - 1)
    setNewReaction('')
    await api.delete(`/api/reactionPost/destroy/${post_id}`).catch(err => toast.error('Erro ao deletar reação', { autoClose: 3000 }))
  }

  const handleReaction = (reaction) => {
    if (!newReaction) {
      setNewReactionsCounter(prev => prev + 1)
    }

    setNewReaction(reaction)
    sendReaction(reaction)
  }

  useEffect(() => {
    if (post_id === handleComments) {
      getComments()
    }
  }, [handleComments])

  const [viewers, setViewers] = useState([]);
  const [viewersModalShow, setViewersModalShow] = useState(false);
  const [viewCount, setViewCount] = useState(0);
  const [limitedViewers, setLimitedViewers] = useState([]);
  const [search, setSearch] = useState('')
  const [page, setPage] = useState(1)
  const [hasMore, setHasMore] = useState(true)
  const [showMoreButton, setShowMoreButton] = useState(false)
  const { ref, inView } = useInView({
    threshold: 0.1,
  })

  const fetchViewers = useCallback(async (resetPage = false) => {
    setIsLoading(true)
    const currentPage = resetPage ? 1 : page
    try {
      const response = await api.post(`/api/posts/${post_id}/viewers`, {
        search,
        page: currentPage,
        limit: 10
      })

      const data = response.data.data
      if (resetPage) {
        setViewers(data)
        setPage(2)
      } else {
        setViewers(prev => [...prev, ...data])
        setPage(currentPage + 1)
      }
      setLimitedViewers((resetPage ? data : [...viewers, ...data]).slice(0, 10))
      setShowMoreButton(data.length === 10 || viewers.length > 10)
      setHasMore(data.length === 10)
    } catch (error) {
      console.error('Failed to fetch user data:', error)
    } finally {
      setIsLoading(false)
    }
  }, [search, page, post_id])

  const handleSearchChange = useCallback(debounce((newSearch) => {
    setSearch(newSearch)
    fetchViewers(true)
  }, 300), [])

  useEffect(() => {
    fetchViewers(true)
  }, [search])

  useEffect(() => {
    if (inView && hasMore && !isLoading) {
      fetchViewers(false)
    }
  }, [inView, hasMore, isLoading])

  const filteredViewers = useMemo(() => {
    return search ? viewers.filter(f => f.name.toLowerCase().includes(search.toLowerCase())) : viewers;
  }, [search, viewers]);

  const fetchViewCount = useCallback(async () => {
    try {
      const response = await api.get(`/api/posts/${post_id}/view-count`);
      setViewCount(response.data.view_count);
    } catch (error) {
      console.error('Erro ao buscar contagem de visualizações:', error);
    }
  }, [post_id]);

  useEffect(() => {
    fetchViewCount();

    const interval = setInterval(fetchViewCount, 300000);

    return () => clearInterval(interval);
  }, [fetchViewCount]);

  const handleModalShow = () => {
    setViewersModalShow(true);
  };

  const popover = (
    <Popover id="popover-basic">
      <Popover.Body>
        {limitedViewers.length > 0 ? (
          <>
            {limitedViewers.map((viewer) => (
              <div key={viewer.id}>{viewer.name}</div>
            ))}
            {showMoreButton && (
              <Badge pill>
                ...e mais {viewers.length - 10} usuários
              </Badge>
            )}
          </>
        ) : (
          <div>Nenhum visualizador ainda</div>
        )}
      </Popover.Body>
    </Popover>
  );

  return (
    <Col sm={12} className='special-post'>
      <DeletePostConfirmationModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />

      <ShowReactionModal
        post_id={post_id}
        showModalReaction={reactionsModalShow}
        setShowModalReaction={setReactionsModalShow}
        onHide={() => setReactionsModalShow(false)}
      />

      <ShowPostModal
        showModalPost={modalShowPost}
        setModalShowPost={setModalShowPost}
        onHide={() => setModalShowPost(false)}
        user_name={user_name}
        user_id={user_id}
        user_image={user_image}
        type={type}
        media={media}
        post_time={post_time}
        text={text}
        post_id={post_id}
        privacy={privacy}
        group_id={group_id}
        is_admin={isAdmin}
        share_id={share_id}
        share_posts={share_posts}
        newMedia={newMedia}
        newText={newText}
        newType={newType}
        user_reaction={user_reaction}
        reactions={reactions}
        view_count={view_count}
      />

      <div className='card card-block card-stretch card-height'>
        <div className='card-body'>
          <div className='user-post-data'>
            <div className='d-flex justify-content-between align-items-center'>
              <div className='me-3 flex-shrik-0'>
                <Link to={`/users/profile/${user_id}`}>
                  <img
                    className='border border-2 rounded-circle user-post-profile'
                    src={user_image}
                    alt=''
                  />
                </Link>
              </div>
              <div className='w-100'>
                <div className='d-flex justify-content-between align-items-center'>
                  <div>
                    <Link to={`/users/profile/${user_id}`}>
                      <h6 className='mb-0 d-inline-block'>{user_name}</h6>{' '}
                    </Link>
                    {group_name && (
                      <span className="group-name">
                        <Link to={`/groups/group-detail/${group_id}`}>em {group_name}</Link>
                      </span>
                    )}
                    <p className='mb-0'>{formatDistanceToNow(parseISO(post_time), { addSuffix: true, locale: ptBR })}</p>
                  </div>

                  <div className='card-post-toolbar d-flex'>
                    {
                      newPrivacy === 'public' && !important ? (
                        <span className='material-symbols-outlined' style={{ 'marginRight': '10px' }}>
                          public
                        </span>
                      ) : newPrivacy === 'friends' ? (
                        <span className='material-symbols-outlined' style={{ 'marginRight': '10px' }}>
                          group
                        </span>
                      ) : (
                        <span className='material-symbols-outlined' style={{ 'marginRight': '10px' }}>
                          keep
                        </span>
                      )
                    }

                    {
                      logged_user == user_id || isAdmin ? (
                        <Dropdown>
                          <Dropdown.Toggle id='post-option' as='span' >
                            <span className='material-symbols-outlined'>more_horiz</span>
                          </Dropdown.Toggle>
                          <Dropdown.Menu className='m-0 p-0'>
                            <Dropdown.Item className=' p-3' to='#' onClick={() => setModalShow(true)}>
                              <div className='d-flex align-items-top'>
                                <span className='material-symbols-outlined'>
                                  delete
                                </span>
                                <div className='data ms-2'>
                                  <h6>deletar</h6>
                                </div>
                              </div>
                            </Dropdown.Item>

                            <Dropdown.Item className=' p-3' to='#' onClick={handleEditPost}>
                              <div className='d-flex align-items-top'>
                                <span className='material-symbols-outlined'>
                                  edit
                                </span>
                                <div className='data ms-2'>
                                  <h6>editar</h6>
                                </div>
                              </div>
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      ) : ''
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>

          {
            showEdit ? (
              <div className='mt-4'>
                <input
                  type='text'
                  className='form-control rounded px-0'
                  style={{ border: 'none' }}
                  value={newText}
                  onChange={(e) => setNewText(e.target.value)}
                />

                <div className='mt-3'>
                  {newImage && (
                    <div className='position-relative d-inline-block'>
                      <img
                        src={URL.createObjectURL(newImage)}
                        alt='Imagem escolhida'
                        style={{ maxWidth: '100%', maxHeight: '200px', objectFit: 'cover' }}
                      />
                      <CloseButton
                        onClick={removeImage}
                        style={{ position: 'absolute', top: '10px', right: '10px', color: '#ff0000' }}
                      />
                    </div>
                  )}
                  {newVideo && (
                    <div className='position-relative d-inline-block'>
                      <div className='d-flex'>
                        <Plyr ref={playerRefNewVideo} {...plyrPropsNewVideo} />
                      </div>
                      <CloseButton
                        onClick={removeVideo}
                        style={{ position: 'absolute', top: '10px', right: '10px', color: '#ff0000' }}
                      />
                    </div>
                  )}
                </div>

                {showMediaInput && (
                  <div className='upload-area mt-3 position-relative' style={{ border: '1px solid #ccc', padding: '20px', borderRadius: '5px', textAlign: 'center', marginBottom: '5px' }}>
                    <CloseButton
                      onClick={handleMediaClick}
                      style={{ zIndex: 99, position: 'absolute', top: '10px', right: '10px', color: '#ff0000', backgroundColor: '#fff' }}
                    />
                    <FaFileUpload size={48} style={{ color: '#ccc' }} />
                    <p className='mt-2'>Adicionar Fotos/Vídeos</p>
                    <p className='text-muted'>ou arraste e solte</p>
                    <Form.Control
                      type='file'
                      accept='image/*,video/*'
                      onChange={handleFileUpload}
                      className='position-absolute w-100 h-100 opacity-0'
                      style={{ top: 0, left: 0, cursor: 'pointer' }}
                    />
                  </div>
                )}

                <button type='button' className='btn btn-primary px-4' style={{ marginRight: '5px' }} onClick={handleMediaClick}>
                  <span className='material-symbols-outlined align-text-top font-size-20'>
                    photo_camera
                  </span>
                </button>

                <button disabled={removeImages && removeVideos} type='button' className='btn btn-primary px-4' style={{ marginRight: '5px' }} onClick={removeAllFiles}>
                  <span className='material-symbols-outlined align-text-top font-size-20'>
                    no_photography
                  </span>
                </button>

                {
                  group_id ? '' : (
                    <Dropdown className='btn btn-primary px-4' style={{ marginRight: '5px' }}>
                      <Dropdown.Toggle id='post-option' as='span'>
                        {
                          newPrivacy === 'public' ? (
                            <span className='material-symbols-outlined' style={{ 'fontSize': 'large', 'verticalAlign': 'middle' }}>
                              public
                            </span>
                          ) : newPrivacy === 'friends' ? (
                            <span className='material-symbols-outlined' style={{ 'fontSize': 'large', 'verticalAlign': 'middle' }}>
                              group
                            </span>
                          ) : ''
                        }
                      </Dropdown.Toggle>
                      <Dropdown.Menu className='m-0 p-0'>
                        <Dropdown.Item className=' p-3' to='#' onClick={() => setNewPrivacy('public')}>
                          <div className='d-flex align-items-top'>
                            <span className='material-symbols-outlined' style={{ 'fontSize': 'large', 'verticalAlign': 'middle' }}>
                              public
                            </span>
                            <div className='data ms-2'>
                              <h6>pública</h6>
                            </div>
                          </div>
                        </Dropdown.Item>

                        <Dropdown.Item className=' p-3' to='#' onClick={() => setNewPrivacy('friends')}>
                          <div className='d-flex align-items-top'>
                            <span className='material-symbols-outlined' style={{ 'fontSize': 'large', 'verticalAlign': 'middle' }}>
                              group
                            </span>
                            <div className='data ms-2'>
                              <h6>amigos</h6>
                            </div>
                          </div>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  )
                }

                <button disabled={showSpinner} type='button' className='btn btn-primary px-4' style={{ marginRight: '5px' }} onClick={updatePost}>
                  {showSpinner ? <div className="spinner-border spinner-border-sm" role="status"></div> : <div className='fw-semibold'>Salvar</div>}
                </button>

                <button type='button' className='btn btn-danger px-4 ml-5' onClick={() => {
                  setShowEdit(false)
                  setNewText(text)
                  setRemoveVideos(false)
                  setRemoveImages(false)
                  setNewPrivacy(privacy)
                }}>
                  Cancelar
                </button>
              </div>
            ) : (
              <div className='mt-4'>
                <p className='m-0'>
                  {newText}
                </p>
              </div>
            )
          }

          {
            share_id ? (
              <div className='card card-block card-stretch card-height mt-3' style={{ border: '2px solid grey' }}>
                <div className='card-body'>
                  <div className='user-post-data'>
                    <div className='d-flex justify-content-between align-items-center'>
                      <div className='me-3 flex-shrik-0'>
                        <img
                          className='border border-2 rounded-circle user-post-profile'
                          src={share_posts.user_image}
                          alt=''
                        />
                      </div>
                      <div className='w-100'>
                        <div className='d-flex justify-content-between align-items-center'>
                          <div>
                            <h6 className='mb-0 d-inline-block'>{share_posts.user_name}</h6>{' '}
                            <p className='mb-0'>{formatDistanceToNow(parseISO(share_posts.created_at), { addSuffix: true, locale: ptBR })}</p>
                          </div>

                          <div className='card-post-toolbar d-flex'>
                            {
                              share_posts.privacy === 'public' ? (
                                <span className='material-symbols-outlined' style={{ 'marginRight': '10px' }}>
                                  public
                                </span>
                              ) : share_posts.privacy === 'friends' ? (
                                <span className='material-symbols-outlined' style={{ 'marginRight': '10px' }}>
                                  group
                                </span>
                              ) : ''
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='mt-4'>
                      <p className='m-0'>
                        {share_posts.text}
                      </p>
                    </div>
                  </div>

                  {share_posts.image ? (
                    <div className='user-post mt-4 live-container'>
                      <div className='ratio ratio-16x9' style={{ height: '100%' }}>
                        <img
                          src={share_posts.image}
                          alt='post-images'
                          className='img-fluid rounded w-100'
                          loading='lazy'
                        />
                      </div>
                    </div>
                  ) : ''
                  }

                  {share_posts.video ? (
                    <div className='user-post mt-4 live-container'>
                      <div className='ratio ratio-16x9' style={{ height: '100%' }}>
                        <div className='d-flex justify-content-center'>
                          <Plyr ref={playerRefShareVideo} {...plyrPropsShare} />
                        </div>
                      </div>
                    </div>
                  ) : ''
                  }

                  {share_posts.live_iframe ? (
                    <div className=''>
                      <div className='' style={{ height: '100%' }}>
                        <div dangerouslySetInnerHTML={{ __html: share_posts.live_iframe }} style={{ height: '100%' }} />
                      </div>
                    </div>
                  ) : ''
                  }
                </div>
              </div>
            ) : ''
          }

          {
            newType !== 'text' ? (
              <div className='user-post mt-4 live-container' onClick={openModal}>
                <div className='d-flex justify-content-center' >
                  {
                    newType === 'image' ? (
                      <img
                        src={newMedia}
                        alt='post-images'
                        className='img-fluid rounded-2 w-100'
                        loading='lazy'
                      />
                    ) : newType === 'video' ? (
                      <div className='d-flex'>
                        <Plyr ref={playerRef} {...plyrProps} />
                      </div>

                    ) : ''
                  }
                </div>
              </div>
            ) : ''
          }

          <div className='post-meta-likes mt-4'>
            <div className='d-flex align-items-center gap-2 flex-wrap'>
              <div className='d-inline-flex w-100 justify-content-between'>
                <span
                  className='text-capitalize font-size-14 fw-medium'
                  type='button'
                  data-bs-toggle='modal'
                  data-bs-target='#likemodal'
                >
                  {
                    newReactionsCounter > 0 ? (
                      <div>
                        <img src={like_btn} className='img-fluid me-2' alt='' style={{ 'height': '35px', 'width': '35px' }} onClick={openModalReaction} /> {newReactionsCounter}
                      </div>
                    ) : ''
                  }
                </span>

                <OverlayTrigger
                  trigger={['hover', 'focus']}
                  placement="top"
                  overlay={popover}
                  onEnter={fetchViewers}
                >
                  <Button variant="link" className="p-0 m-0 fw-medium" onClick={handleModalShow}>
                    {viewCount} {viewCount === 1 ? 'visualização' : 'visualizações'}
                  </Button>
                </OverlayTrigger>
              </div>
            </div>
          </div>

          <Modal show={viewersModalShow} onHide={() => setViewersModalShow(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Usuários que visualizaram</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Group className='input-group pb-3'>
                <span className='input-group-text' id='basic-addon1'><FaSearch /></span>
                <Form.Control
                  placeholder='Pesquisar'
                  aria-label='Pesquisar'
                  aria-describedby='search'
                  onChange={(e) => handleSearchChange(e.target.value)}
                />
              </Form.Group>
              <div className="item-header-scroll">
                {filteredViewers.map((viewer, index) => (
                  <Col md={6} key={index} ref={index === filteredViewers.length - 1 ? ref : null}>
                    <div key={`${viewer.id}-${index}`} className="d-flex align-items-center gap-3 mb-3">
                      <a href={`/users/profile/${viewer.id}`} className="d-flex align-items-center">
                        <img
                          className="avatar-32 rounded-pill"
                          src={viewer?.image_profile}
                          alt={viewer.name}
                        />
                      </a>
                      <a href={`/users/profile/${viewer.id}`} className="text-decoration-none">
                        <span>{viewer.name}</span>
                      </a>
                    </div>
                  </Col>
                ))}
              </div>
              {isLoading && <div className="col-sm-12 text-center">
                <img src={loader} alt="loader" style={{ height: "50px" }} />
              </div>}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setViewersModalShow(false)}>
                Fechar
              </Button>
            </Modal.Footer>
          </Modal>

          <div className='comment-area mt-4 pt-4 border-top'>
            <div className='d-flex justify-content-between align-items-center flex-wrap'>
              <div className='like-block position-relative d-flex align-items-center flex-shrink-0'>
                <div className='like-data'>
                  <div className='dropdown'>
                    <span
                      className='dropdown-toggle'
                      data-bs-toggle='dropdown'
                      aria-haspopup='true'
                      aria-expanded='false'
                      role='button'
                    >
                      {
                        !newReaction ? (
                          <span className='material-symbols-outlined align-text-top font-size-20'>
                            thumb_up
                          </span>
                        ) : (
                          <img src={newReaction === 'like' ? like_btn : newReaction === 'love' ? love_btn : newReaction === 'haha' ? haha_btn : newReaction === 'wow' ? wow_btn : newReaction === 'sad' ? sad_btn : newReaction === 'angry' ? angry_btn : ''} className='img-fluid me-2' alt='' onClick={() => removeReaction()} style={{ 'height': '35px', 'width': '35px' }} />
                        )
                      }

                      {' '}
                      <span className='fw-medium'>{
                        newReaction === 'like' ? 'Gostei' : newReaction === 'love' ? 'Amei' : newReaction === 'haha' ? 'Haha' : newReaction === 'wow' ? 'Uau' : newReaction === 'sad' ? 'Triste' : newReaction === 'angry' ? 'Grr' : 'Reagir'
                      }</span>
                    </span>
                    <div className='dropdown-menu py-2 shadow'>
                      <OverlayTrigger
                        placement='top'
                        overlay={<Tooltip>Gostei</Tooltip>}
                        className='ms-2 me-2'
                      >
                        <img src={like_btn} className='img-fluid me-2' alt='' onClick={() => handleReaction('like')} style={{ 'height': '35px', 'width': '35px' }} />
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement='top'
                        overlay={<Tooltip>Amei</Tooltip>}
                        className='me-2'
                      >
                        <img src={love_btn} className='img-fluid me-2' alt='' onClick={() => handleReaction('love')} style={{ 'height': '35px', 'width': '35px' }} />
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement='top'
                        overlay={<Tooltip>HaHa</Tooltip>}
                        className='me-2'
                      >
                        <img src={haha_btn} className='img-fluid me-2' alt='' onClick={() => handleReaction('haha')} style={{ 'height': '35px', 'width': '35px' }} />
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement='top'
                        overlay={<Tooltip>Uau</Tooltip>}
                        className='me-2'
                      >
                        <img src={wow_btn} className='img-fluid me-2' alt='' onClick={() => handleReaction('wow')} style={{ 'height': '35px', 'width': '35px' }} />
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement='top'
                        overlay={<Tooltip>Triste</Tooltip>}
                        className='me-2'
                      >
                        <img src={sad_btn} className='img-fluid me-2' alt='' onClick={() => handleReaction('sad')} style={{ 'height': '35px', 'width': '35px' }} />
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement='top'
                        overlay={<Tooltip>Grr</Tooltip>}
                        className='me-2'
                      >
                        <img src={angry_btn} className='img-fluid me-2' alt='' onClick={() => handleReaction('angry')} style={{ 'height': '35px', 'width': '35px' }} />
                      </OverlayTrigger>
                    </div>
                  </div>
                </div>
              </div>
              <div className='share-block d-flex align-items-center feather-icon gap-3 flex-shrink-0'>
                <Link
                  className='total-comment-block'
                  type='button'
                  aria-controls='commentcollapes'
                  aria-expanded={open3}
                  onClick={() => { setOpen3(!open3); getComments(); }}
                >
                  <span className='material-symbols-outlined align-text-top font-size-20'>
                    comment
                  </span>{' '}
                  <span className='fw-medium'> Comentários</span>
                </Link>
                {newPrivacy === 'public' ? (
                  <div className='share-block d-flex align-items-center feather-icon'>
                    <Link
                      to='#'
                      data-bs-toggle='modal'
                      data-bs-target='#share-btn'
                      onClick={() => { setModalShow3(true); }}
                      aria-controls='share-btn'
                      className='d-flex align-items-center'
                    >
                      <span className='material-symbols-outlined align-text-top font-size-20'>
                        share
                      </span>
                      <span className='ms-1 fw-medium'>Compartilhar</span>
                    </Link>
                  </div>
                ) : ''
                }
                <ShareOffcanvasNew user_image={user_image} text={text} user_name={user_name} post_time={post_time} new_privacy={newPrivacy} new_type={newType} new_media={newMedia} post_id={post_id} group_id={group_id}
                  show={modalShow3}
                  onHide={() => setModalShow3(false)}
                />
              </div>
            </div>

            <Collapse in={open3}>
              <div id='commentcollapes' className='border-top mt-4 pt-4'>
                {isLoading && <div className='col-sm-12 text-center'>
                  <img src={loader} alt='loader' style={{ height: '100px' }} />
                </div>}
                {comments && comments.map((comment, index) => (
                  <Comment comment={comment} key={index} />
                ))}
                <div className='add-comment-form-block'>
                  <div className='d-flex align-items-center gap-3'>
                    <div className='flex-shrink-0'>
                      <img
                        src={image_profile}
                        alt='userimg'
                        className='avatar-48 rounded-circle img-fluid'
                        loading='lazy'
                      />
                    </div>
                    <div className='add-comment-form'>
                      <Form onSubmit={commentStore}>
                        <input
                          type='text'
                          className='form-control'
                          placeholder='Escrever comentário...'
                          value={newComment} onChange={(e) => setNewComment(e.target.value)}
                        />
                        <Button type="submit" variant="primary">Comentar</Button>
                      </Form>
                    </div>
                  </div>
                </div>
              </div>
            </Collapse>
          </div>
        </div>
      </div>
    </Col >
  )
}

export default Index
