import { useState } from "react";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

const EventVisibilityDropdown = ({ setSeeEvent, seeEvent }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen(prevState => !prevState);

  const handleSelect = (option) => {
    setSeeEvent(option);
  };

  const options = [
    {
      value: 'Organização',
      label: 'Pública',
      description: 'Todos que trabalham na sua organização',
      icon: '👥',
    },
    {
      value: 'Privado',
      label: 'Privado',
      description: 'Somente para convidados',
      icon: '🔒',
    },
    {
      value: 'Grupo',
      label: 'Grupo',
      description: 'Membros de um grupo do qual você faz parte',
      icon: '👤',
    },
  ];

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle caret>
        {seeEvent ? seeEvent : 'Selecione aqui..'}
      </DropdownToggle>
      <DropdownMenu>
        {options.map((option) => (
          <DropdownItem
            key={option.value}
            onClick={() => handleSelect(option.value)}
            className="d-flex align-items-center"
          >
            <span style={{ fontSize: '1.5rem', marginRight: '10px' }}>{option.icon}</span>
            <div>
              <div><strong>{option.label}</strong></div>
              <small>{option.description}</small>
            </div>
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};

export default EventVisibilityDropdown