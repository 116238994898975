import React, { useEffect, useState } from "react";
import * as SettingSelector from "../../../../store/setting/selectors";
import atmoLogo from '../../../../assets/images/atmo logo.png';
import { useSelector } from "react-redux";
import Verticalnav from "./verticalnav";
import Scrollbar from "smooth-scrollbar";
import { Link } from "react-router-dom";

const Sidebar = () => {
  const sidebarType = useSelector(SettingSelector.sidebar_type); // array
  const sidebarMenuStyle = useSelector(SettingSelector.sidebar_menu_style);
  const appName = useSelector(SettingSelector.app_name);
  const [isSidebarMini, setIsSidebarMini] = useState(false);

  useEffect(() => {
    Scrollbar.init(document.querySelector(".data-scrollbar"));

    const handleResize = () => {
      const sidebarResponsive = document.querySelector('[data-sidebar="responsive"]');

      if (sidebarResponsive !== null) {
        if (window.innerWidth < 1025) {
          if (!sidebarResponsive.classList.contains("sidebar-mini")) {
            sidebarResponsive.classList.add("sidebar-mini", "on-resize");
          }
        } else {
          if (sidebarResponsive.classList.contains("sidebar-mini") && sidebarResponsive.classList.contains("on-resize")) {
            sidebarResponsive.classList.remove("sidebar-mini", "on-resize");
          }
        }

        // Atualizar o estado quando a classe 'sidebar-mini' for detectada
        if (sidebarResponsive.classList.contains("sidebar-mini")) {
          setIsSidebarMini(true);
        } else {
          setIsSidebarMini(false);
        }
      }
    };

    // Monitorar resize da janela
    window.addEventListener("resize", handleResize);

    // Executar a função uma vez para verificar o estado inicial
    handleResize();

    // Cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const toggleMiniSidebar = () => {
    const sidebar = document.getElementsByTagName("ASIDE")[0];
    sidebar.classList.toggle("sidebar-mini");
    setIsSidebarMini(sidebar.classList.contains("sidebar-mini"));
  };

  return (
    <>
      <aside
        className={`${sidebarType.join(" ")} ${sidebarMenuStyle} sidebar sidebar-default sidebar-base navs-rounded-all`}
        id="first-tour"
        data-toggle="main-sidebar"
        data-sidebar="responsive"
      >
        <div className="sidebar-header d-flex align-items-center justify-content-start position-relative">
          <Link to="/" className="d-flex align-items-center gap-2 iq-header-logo">
            <img style={{ width: 50 }} src={atmoLogo} alt='Logo Atmo' />
            <h6 className="logo-title" data-setting="app_name">{appName}</h6>
          </Link>
          <div
            className="sidebar-toggle"
            data-toggle="sidebar"
            data-active="true"
            onClick={toggleMiniSidebar}
          >
            <span className="menu-btn d-inline-block is-active">
              <i className="right-icon material-symbols-outlined icon-rtl">chevron_left</i>
            </span>
          </div>
        </div>
        <div className="sidebar-body pt-4 data-scrollbar">
          <div className="sidebar-list">
            {/* Passando isSidebarMini como prop */}
            <Verticalnav isSidebarMini={isSidebarMini} />
          </div>
        </div>
        <div className="sidebar-footer"></div>
      </aside>
    </>
  );
};

export default Sidebar;
