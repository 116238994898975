import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react'
import { Modal, Form, Button, OverlayTrigger, Tooltip, Col, Badge } from 'react-bootstrap';
import { formatDistanceToNow, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import Plyr from 'plyr-react';
import loader from '../../assets/images/page-img/page-load-loader.gif'
import { useUserAbout } from '../../context/UserAboutContext';
import api from '../../services/api';
import Cookies from 'js-cookie'
import { toast } from 'react-toastify'
import AnimationWarningLottie from '../../components/AnimationWarningDeleteConfim/AnimationWarningLottie'
import "plyr-react/plyr.css"
import Comment from '../Comment'
import placeholderIMG from '../../assets/images/placeholder.png'
import like_btn from '../../assets/images/icon/like_btn.png'
import love_btn from '../../assets/images/icon/love_btn.png'
import haha_btn from '../../assets/images/icon/haha_btn.png'
import wow_btn from '../../assets/images/icon/wow_btn.png'
import angry_btn from '../../assets/images/icon/angry_btn.png'
import sad_btn from '../../assets/images/icon/sad_btn.png'
import './style-modalShowPost.css'
import { debounce } from 'lodash'
import { useInView } from 'react-intersection-observer'
import { FaSearch } from 'react-icons/fa'
import ShowReactionModal from '../../components/Reactions/modalShowReactions';
import LiveChat from '../LiveChat'

function ShowPostModal({ showModalPost, setModalShowPost, post_id, user_image, user_name, post_time, newText, newType, newMedia, user_reaction, reactions, view_count, scheduleShow = false, event = false, is_live = false }) {
    const playerRef = useRef(null);
    const { userAbout } = useUserAbout()
    const [comments, setComments] = useState(null)
    const [isLoading, setIsLoading] = useState(true)
    const logged_user = Cookies.get('userId')
    const [newComment, setNewComment] = useState('')
    const [modalCommentShow, setModalCommentShow] = useState(false)
    const image_profile = userAbout?.user?.image_profile;
    const [newReaction, setNewReaction] = useState(user_reaction)
    const [newReactionsCounter, setNewReactionsCounter] = useState(reactions)
    const [reactionsModalShow, setReactionsModalShow] = useState(false)

    function formatDate(dataString) {
        const diasSemana = ["Domingo", "Segunda-feira", "Terça-feira", "Quarta-feira", "Quinta-feira", "Sexta-feira", "Sábado"];
        const mesesAno = ["janeiro", "fevereiro", "março", "abril", "maio", "junho", "julho", "agosto", "setembro", "outubro", "novembro", "dezembro"];

        const data = new Date(dataString.replace(" ", "T"));

        const diaSemana = diasSemana[data.getDay()];
        const diaMes = data.getDate();
        const mes = mesesAno[data.getMonth()];
        const hora = data.getHours().toString().padStart(2, '0');
        const minutos = data.getMinutes().toString().padStart(2, '0');

        return `${diaSemana}, ${diaMes} de ${mes} às ${hora}:${minutos}`;
    }

    const getComments = async () => {
        const response = await api.post(`/api/comments/getComments/${post_id}`)
        setIsLoading(false)
        setComments(response.data)
    }

    const sendReaction = async (reaction) => {
        await api.post(`/api/reactionPost/store`, {
            post_id,
            reaction
        }).catch(err => toast.error('Erro ao reagir post', { autoClose: 3000 }))
    }

    const removeReaction = async () => {
        setNewReactionsCounter(prev => prev - 1)
        setNewReaction('')
        await api.delete(`/api/reactionPost/destroy/${post_id}`).catch(err => toast.error('Erro ao deletar reação', { autoClose: 3000 }))
    }

    const handleReaction = (reaction) => {
        if (!newReaction) {
            setNewReactionsCounter(prev => prev + 1)
        }

        setNewReaction(reaction)
        sendReaction(reaction)
    }

    const commentStore = async (e) => {
        e.preventDefault()
        await api.post(`/api/comments/store/${post_id}`, {
            newComment,
            logged_user,
        }).then(() => {
            toast.success('Comentario feito com sucesso.', { autoClose: 1000 })
            setNewComment('')
            getComments()
        }).catch(err => toast.error('Erro ao criar comentario', { autoClose: 3000 }))
    }

    const commentDelete = async (comment_id) => {
        await api.post(`/api/comments/delete/${comment_id}`).then(() => {
            setModalCommentShow(false)
            toast.success('Comentario deletado com sucesso.', { autoClose: 1000 })
            getComments()
        }).catch(err => toast.error('Erro ao deletar comentario', { autoClose: 3000 }))
    }

    useEffect(() => {
        if (showModalPost) {
            getComments()
        }
    }, [showModalPost]);

    const plyrProps = useMemo(() => ({
        source: {
            type: "video",
            sources: [
                {
                    src: newMedia,
                    type: "video/mp4",
                }
            ]
        }
    }), [newMedia]);

    function DeleteCommentConfirmationModal(props) {
        return (
            <Modal
                {...props}
                aria-labelledby='contained-modal-title-vcenter'
                centered
            >
                <Modal.Header id='modalHeader' closeButton>
                    <div id='divModalTitle'>
                        <Modal.Title id='modalTitle'>
                            Exclusão de comentário
                        </Modal.Title>
                    </div>
                </Modal.Header>
                <Modal.Body id='modalBody'>
                    <div className='d-flex justify-content-center'>
                        <AnimationWarningLottie />
                    </div>
                    <div className='d-flex justify-content-center'>
                        <p>
                            Você tem certeza de que deseja remover esse comentário ?
                        </p>
                    </div>
                </Modal.Body>
                <Modal.Footer id='modalFooter'>
                    <Button id='yesButton' onClick={() => commentDelete(props.show)}>Sim</Button>
                    <Button id='noButton' onClick={props.onHide}>Não</Button>
                </Modal.Footer>
            </Modal>
        )
    }

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            background: '#fff', // Fundo branco para o modal
            color: '#000', // Texto preto para melhor leitura
            borderRadius: '8px',
            padding: '20px',
            border: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '80%', // Largura baseada na largura da tela
            height: '80vh', // Altura baseada na altura da tela
            maxWidth: '1200px', // Máximo de largura
            overflow: 'hidden' // Evita overflow
        },
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.85)'
        }
    };

    const [isPopoverVisible, setIsPopoverVisible] = useState(false);
    const [viewersFetched, setViewersFetched] = useState(false);
    const [viewers, setViewers] = useState([]);
    const [viewersModalShow, setViewersModalShow] = useState(false);
    const [limitedViewers, setLimitedViewers] = useState([]);
    const [search, setSearch] = useState('')
    const [page, setPage] = useState(1)
    const [hasMore, setHasMore] = useState(true)
    const [showMoreButton, setShowMoreButton] = useState(false)
    const { ref, inView } = useInView({
        threshold: 0.1,
    })

    const openPopover = () => {
        setIsPopoverVisible(true);
        if (!viewersFetched) {
            fetchViewers();
            setViewersFetched(true);
        }
    };

    const closePopover = () => {
        setIsPopoverVisible(false);
    };

    const fetchViewers = useCallback(async (resetPage = false) => {
        const currentPage = resetPage ? 1 : page
        try {
            const response = await api.post(`/api/posts/${post_id}/viewers`, {
                search,
                page: currentPage,
                limit: 10
            })

            const data = response.data.data
            if (resetPage) {
                setViewers(data)
                setPage(2)
            } else {
                setViewers(prev => [...prev, ...data])
                setPage(currentPage + 1)
            }
            setLimitedViewers((resetPage ? data : [...viewers, ...data]).slice(0, 10))
            setShowMoreButton(data.length === 10 || viewers.length > 10)
            setHasMore(data.length === 10)
        } catch (error) {
            console.error('Failed to fetch user data:', error)
        }
    }, [search, page, post_id])

    const handleSearchChange = useCallback(debounce((newSearch) => {
        setSearch(newSearch)
        fetchViewers(true)
    }, 300), [])

    useEffect(() => {
        fetchViewers(true)
    }, [search])

    useEffect(() => {
        if (inView && hasMore && !isLoading) {
            fetchViewers(false)
        }
    }, [inView, hasMore, isLoading])

    const filteredViewers = useMemo(() => {
        return search ? viewers.filter(f => f.name.toLowerCase().includes(search.toLowerCase())) : viewers;
    }, [search, viewers]);

    const handleModalShow = () => {
        setViewersModalShow(true);
    };

    const openModalReaction = () => {
        setReactionsModalShow(true);
    };

    return (

        <Modal
            show={showModalPost}
            onHide={() => setModalShowPost(false)}
            style={customStyles}
            contentlabel="Image Preview"
            fullscreen={true}
        >
            <DeleteCommentConfirmationModal
                show={modalCommentShow}
                onHide={() => setModalCommentShow(false)}
            />

            <ShowReactionModal
                post_id={post_id}
                showModalReaction={reactionsModalShow}
                setShowModalReaction={setReactionsModalShow}
                onHide={() => setReactionsModalShow(false)}
            />

            <Modal show={viewersModalShow} onHide={() => setViewersModalShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Usuários que visualizaram</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className='input-group pb-3'>
                        <span className='input-group-text' id='basic-addon1'><FaSearch /></span>
                        <Form.Control
                            placeholder='Pesquisar'
                            aria-label='Pesquisar'
                            aria-describedby='search'
                            onChange={(e) => handleSearchChange(e.target.value)}
                        />
                    </Form.Group>
                    <div className="item-header-scroll">
                        {filteredViewers.map((viewer, index) => (
                            <Col md={6} key={index} ref={index === filteredViewers.length - 1 ? ref : null}>
                                <div key={`${viewer.id}-${index}`} className="d-flex align-items-center gap-3 mb-3">
                                    <a href={`/users/profile/${viewer.id}`} className="d-flex align-items-center">
                                        <img
                                            className="avatar-32 rounded-pill"
                                            src={viewer.image_profile}
                                            alt={viewer.name}
                                        />
                                    </a>
                                    <a href={`/users/profile/${viewer.id}`} className="text-decoration-none">
                                        <span>{viewer.name}</span>
                                    </a>
                                </div>
                            </Col>
                        ))}
                    </div>
                    {isLoading && <div className="col-sm-12 text-center">
                        <img src={loader} alt="loader" style={{ height: "50px" }} />
                    </div>}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setViewersModalShow(false)}>
                        Fechar
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal.Header closeButton />
            <div className='div-modal-showPost-content' style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                <div className='div-content-modal-onmobile' style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {
                        newType === 'image' ? (
                            <img className='img-modalShowPost' src={newMedia} alt="Preview" />
                        ) : newType === 'video' ? (
                            <div className='video-modalShowPost'>
                                <Plyr ref={playerRef} {...plyrProps} />
                            </div>
                        ) : newType === 'live' ? (
                            <div className='live-modalShowPost' dangerouslySetInnerHTML={{ __html: newMedia }} />
                        ) : ''
                    }
                </div>
                <div className='div-comment-content-modal'>
                    <div className='card user-post-data'>
                        <div className='d-flex justify-content-between align-items-center'>
                            <div className='me-2 flex-shrink-0'>
                                <img
                                    className='border border-2 rounded-circle user-post-profile'
                                    src={user_image}
                                    alt=''
                                    style={{ width: '50px', height: '50px' }}
                                />
                            </div>
                            <div className='w-100'>
                                <div className='d-flex justify-content-between align-items-center'>
                                    <div>
                                        <h6 className='mb-0 d-inline-block'>{user_name}</h6>{' '}
                                        <p className='mb-0'>{formatDistanceToNow(parseISO(post_time), { addSuffix: true, locale: ptBR })}</p>
                                        <div className="popover-container">
                                            <Button
                                                variant="link"
                                                className="popover-button p-0 m-0 fw-medium"
                                                onMouseEnter={openPopover}
                                                onMouseLeave={closePopover}
                                                onClick={handleModalShow}
                                            >
                                                {view_count} {view_count === 1 ? 'visualização' : 'visualizações'}
                                            </Button>

                                            {isPopoverVisible && (
                                                <div className="popover-content">
                                                    {limitedViewers.length > 0 ? (
                                                        limitedViewers.map((viewer) => (
                                                            <div key={viewer.id}>
                                                                {viewer.name}
                                                            </div>
                                                        ))
                                                    ) : (
                                                        <div>Nenhum visualizador ainda</div>
                                                    )}
                                                    {showMoreButton && (
                                                        <Badge pill>
                                                            ...e mais {viewers.length -10} usuários
                                                        </Badge>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {
                        event ? (
                            <>
                                <h6 className='m-0'>
                                    Início: {formatDate(event.data_inicio)}
                                </h6>

                                <h6 className='m-0'>
                                    Fim: {formatDate(event.data_fim)}
                                </h6>
                            </>
                        ) : ''
                    }

                    {
                        scheduleShow ? (
                            <button target='_blank' onClick={() => scheduleShow(true)} type='button' className='btn btn-primary px-4 mt-2'>
                                <div className='fw-semibold'>Programação</div>
                            </button>
                        ) : ''
                    }

                    <div className='mt-4 pb-5'>
                        <p className='m-0'>
                            {event ? event.descricao : newText}
                        </p>
                    </div>
                    <div className='d-flex justify-content-between w-100 border-bottom mb-3'>
                        <div className='comment-area'>
                            <div className='d-flex justify-content-between align-items-center flex-wrap'>
                                <div className='like-block position-relative d-flex align-items-center flex-shrink-0'>
                                    <div className='like-data'>
                                        <div className='dropdown'>
                                            <span
                                                className='dropdown-toggle'
                                                data-bs-toggle='dropdown'
                                                aria-haspopup='true'
                                                aria-expanded='false'
                                                role='button'
                                            >
                                                {
                                                    !newReaction ? (
                                                        <span className='material-symbols-outlined align-text-top font-size-20'>
                                                            thumb_up
                                                        </span>
                                                    ) : (
                                                        <img src={newReaction === 'like' ? like_btn : newReaction === 'love' ? love_btn : newReaction === 'haha' ? haha_btn : newReaction === 'wow' ? wow_btn : newReaction === 'sad' ? sad_btn : newReaction === 'angry' ? angry_btn : ''} className='img-fluid me-2' alt='' onClick={() => removeReaction()} style={{ 'height': '35px', 'width': '35px' }} />
                                                    )
                                                }

                                                {' '}
                                                <span className='fw-medium'>{
                                                    newReaction === 'like' ? 'Gostei' : newReaction === 'love' ? 'Amei' : newReaction === 'haha' ? 'Haha' : newReaction === 'wow' ? 'Uau' : newReaction === 'sad' ? 'Triste' : newReaction === 'angry' ? 'Grr' : 'Reagir'
                                                }</span>
                                            </span>
                                            <div className='dropdown-menu py-2 shadow'>
                                                <OverlayTrigger
                                                    placement='top'
                                                    overlay={<Tooltip>Gostei</Tooltip>}
                                                    className='ms-2 me-2'
                                                >
                                                    <img src={like_btn} className='img-fluid me-2' alt='' onClick={() => handleReaction('like')} style={{ 'height': '35px', 'width': '35px' }} />
                                                </OverlayTrigger>
                                                <OverlayTrigger
                                                    placement='top'
                                                    overlay={<Tooltip>Amei</Tooltip>}
                                                    className='me-2'
                                                >
                                                    <img src={love_btn} className='img-fluid me-2' alt='' onClick={() => handleReaction('love')} style={{ 'height': '35px', 'width': '35px' }} />
                                                </OverlayTrigger>
                                                <OverlayTrigger
                                                    placement='top'
                                                    overlay={<Tooltip>HaHa</Tooltip>}
                                                    className='me-2'
                                                >
                                                    <img src={haha_btn} className='img-fluid me-2' alt='' onClick={() => handleReaction('haha')} style={{ 'height': '35px', 'width': '35px' }} />
                                                </OverlayTrigger>
                                                <OverlayTrigger
                                                    placement='top'
                                                    overlay={<Tooltip>Uau</Tooltip>}
                                                    className='me-2'
                                                >
                                                    <img src={wow_btn} className='img-fluid me-2' alt='' onClick={() => handleReaction('wow')} style={{ 'height': '35px', 'width': '35px' }} />
                                                </OverlayTrigger>
                                                <OverlayTrigger
                                                    placement='top'
                                                    overlay={<Tooltip>Triste</Tooltip>}
                                                    className='me-2'
                                                >
                                                    <img src={sad_btn} className='img-fluid me-2' alt='' onClick={() => handleReaction('sad')} style={{ 'height': '35px', 'width': '35px' }} />
                                                </OverlayTrigger>
                                                <OverlayTrigger
                                                    placement='top'
                                                    overlay={<Tooltip>Grr</Tooltip>}
                                                    className='me-2'
                                                >
                                                    <img src={angry_btn} className='img-fluid me-2' alt='' onClick={() => handleReaction('angry')} style={{ 'height': '35px', 'width': '35px' }} />
                                                </OverlayTrigger>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className='post-meta-likes me-2'>
                            <div className='d-flex align-items-center gap-2 flex-wrap'>
                                <div className='d-inline-flex align-items-center gap-1'>
                                    <span
                                        className='text-capitalize font-size-14 fw-medium'
                                        type='button'
                                        data-bs-toggle='modal'
                                        data-bs-target='#likemodal'
                                    >
                                        {
                                            newReactionsCounter > 0 ? (
                                                <div>
                                                    <img src={like_btn} className='img-fluid me-2' alt='' style={{ 'height': '35px', 'width': '35px' }} onClick={() => openModalReaction()} /> {newReactionsCounter}
                                                </div>
                                            ) : ''
                                        }
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='comment-area'>
                        {
                            !is_live ? (
                                <>
                                    <div className='item-comment-scroll'>
                                        {isLoading && <div className='col-sm-12 text-center'>
                                            <img src={loader} alt='loader' style={{ height: '100px' }} />
                                        </div>}
                                        {comments && comments.map((comment, index) => (
                                            <Comment className="list-inline m-o p-0 comment-list" comment={comment} key={index} />
                                        ))}
                                    </div>
                                    <div className='add-comment-form-block pb-5'>
                                        <div className='d-flex align-items-center gap-3'>
                                            <div className='flex-shrink-0'>
                                                <img
                                                    src={image_profile ? image_profile : placeholderIMG}
                                                    alt='userimg'
                                                    className='avatar-48 rounded-circle img-fluid'
                                                    loading='lazy'
                                                />
                                            </div>
                                            <div className='add-comment-form'>
                                                <Form onSubmit={commentStore}>
                                                    <input
                                                        type='text'
                                                        className='form-control'
                                                        placeholder='Escrever comentário...'
                                                        value={newComment} onChange={(e) => setNewComment(e.target.value)}
                                                    />
                                                    <Button type="submit" variant="primary">
                                                        <i className='icon material-symbols-outlined d-flex align-items-center fs-4'>chat</i>
                                                    </Button>
                                                </Form>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <LiveChat post_id={post_id} is_live={is_live} />
                            )
                        }
                    </div>
                </div>
            </div>
        </Modal>

    );
}

export default ShowPostModal;