import React, { createContext, useState, useContext, useCallback } from 'react';
import api from '../services/api';
import Cookies from 'js-cookie'

const FriendStatusContext = createContext();

export const useFriendStatus = () => useContext(FriendStatusContext);

export const FriendStatusProvider = ({ children }) => {
    const [friendStatus, setFriendStatus] = useState([]);
    const [friendStatusCanAccept, setStatusFriendCanAccept] = useState([]);
    const loged_user = Cookies.get('userId');
    const token = Cookies.get('token');


    const fetchFriendStatus = useCallback(async (id) => {
        try {

            const response = await api.post(`/api/friends/requestStatus`, { loged_user, id }, {
              token,
            });
            setFriendStatus(response.data.status);
            setStatusFriendCanAccept(response.data.can_accept);
          } catch (error) {
            console.error('Failed to add friend:', error);
          }
    }, [loged_user, token]);

    return (
        <FriendStatusContext.Provider value={{ friendStatus, friendStatusCanAccept, fetchFriendStatus }}>
            {children}
        </FriendStatusContext.Provider>
    );
};
