import React, { createContext, useState, useContext, useCallback } from 'react';
import api from '../services/api';
import Cookies from 'js-cookie'

const NotificationContext = createContext();

export const useNotification = () => useContext(NotificationContext);

export const NotificationProvider = ({ children }) => {
  const token = Cookies.get('token');
  const loged_user = Cookies.get('userId');
  const [notifications, setNotification] = useState('');
  const [allNotifications, setAllNotification] = useState('');
  const [isNewNotification, setIsNewNotification] = useState(false);
  const [unreadCount, setUnreadCount] = useState(0);


  const fetchNotification = useCallback(async () => {
    try {
      const response = await api.post(`/api/notification/getNotifications/`, {
         loged_user,
         token,
      });

      const responseAll = await api.post(`/api/notification/getAllNotifications/`, {
        loged_user,
        token,
     });
      const data = response.data;
      const dataAll = responseAll.data;
      setNotification(data);
      setAllNotification(dataAll);
      const hasUnread = data.some(n => !n.read);
      setIsNewNotification(hasUnread);
      const unreadNotificationsCount = data.filter(notification => !notification.read);
      setUnreadCount(unreadNotificationsCount.length);

   } catch (error) {
      console.error('Failed to fetch notifications:', error);
   }
  }, [loged_user, token]);

  return (
    <NotificationContext.Provider value={{ notifications, allNotifications, isNewNotification, unreadCount, fetchNotification }}>
      {children}
    </NotificationContext.Provider>
  );
};
