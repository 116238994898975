import React, { useState, useEffect } from 'react';
import api from '../../services/api';
import { Container, Row, Col, Card, Button, Form } from 'react-bootstrap';
import Select from 'react-select';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';

const EditTeam = () => {
    const { id } = useParams();
    const [teamName, setTeamName] = useState('');
    const [users, setUsers] = useState([]);
    const [selectedMembers, setSelectedMembers] = useState([]);
    const [usersToAssociate, setUsersToAssociate] = useState([]);
    const [creatorId, setCreatorId] = useState(null);
    const history = useNavigate()

    const fetchTeam = async () => {
        try {
            const response = await api.get(`/api/teams/${id}/edit`, {
                nome: teamName,
                membros: usersToAssociate.map(member => member.value),
                lider: creatorId
            });
            const team = response.data.team;
            const users = response.data.users;
            const leader = response.data.leader;

            setTeamName(team.nome);

            setSelectedMembers(team.membros.map(member => ({
                value: member.id,
                label: member.name,
            })));

            setUsers(users.map(user => ({
                value: user.id,
                label: user.name,
            })));

            if (leader && leader.id) {
                setCreatorId(leader.id);
            } else {
                setCreatorId(null);
            }
        } catch (error) {
            console.error('Erro ao carregar equipe:', error);
        }
    };

    const fetchUsers = async () => {
        try {
            const response = await api.post('/api/users/index');
            const usersData = response.data.users;

            if (Array.isArray(usersData)) {
                const formattedUsers = usersData
                    .map(user => ({
                        value: user.id,
                        label: user.name
                    }));

                setSelectedMembers(formattedUsers);
                setUsers(formattedUsers);
            }
        } catch (error) {
            console.error('Erro ao carregar usuários:', error);
        }
    };

    const handleUpdateTeam = async (e) => {
        e.preventDefault()
        try {
            await api.put(`/api/teams/update/${id}`, {
                nome: teamName,
                membros: selectedMembers.map(member => member.value),
                lider: creatorId,
            });

            toast.success('Equipe atualizada com sucesso!', { autoClose: 3000, onClose: history('/equipes/index') });
        } catch (error) {
            console.error('Erro ao atualizar equipe:', error);
            toast.error('Erro ao atualizar equipe.', { autoClose: 3000 });
        }
    };

    useEffect(() => {
        fetchTeam();
    }, [id]);

    return (
        <>
            <ToastContainer />

            <div id='content-page' className='content-inner'>
                <Container>
                    <Row>
                        <Col sm="12">
                            <Card
                                className="position-relative inner-page-bg bg-primary"
                                style={{ height: "100px" }}
                            >
                                <div className="inner-page-title" style={{ display: "flex", justifyContent: "space-between", width: "-webkit-fill-available" }}>
                                    <h3 className="text-white">Equipes</h3>
                                    <Link to='/equipes/index'>
                                        <Button><i className="icon material-symbols-outlined d-flex">
                                            arrow_back
                                        </i></Button>
                                    </Link>
                                </div>
                            </Card>
                        </Col>
                        <Col sm="12" lg="12">
                            <Col sm="12" lg="12">
                                <Card>
                                    <Card.Header className="d-flex justify-content-between">
                                        <div className="header-title">
                                            <h4 className="card-title">Editar equipe</h4>
                                        </div>
                                    </Card.Header>
                                    <Card.Body className="pt-0">
                                        <Form noValidate onSubmit={handleUpdateTeam} className="row g-3">
                                            <Col md="6" className="">
                                                <Form.Label
                                                    className="form-label"
                                                    htmlFor="validationCustom01"
                                                >
                                                    Nome
                                                </Form.Label>
                                                <Form.Control className='input-group-edit' type='text' placeholder='Nome' value={teamName} onChange={(e) => setTeamName(e.target.value)} aria-describedby="inputGroupPrepend" required />
                                                <Form.Control.Feedback>
                                                    Correto!
                                                </Form.Control.Feedback>
                                                <Form.Control.Feedback type="invalid">
                                                    Please choose a permission name.
                                                </Form.Control.Feedback>
                                            </Col>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Selecione os Membros da Equipe</Form.Label>
                                                <Select
                                                    isMulti
                                                    value={selectedMembers}
                                                    options={users}
                                                    onChange={(e) => setSelectedMembers(e)}
                                                />
                                            </Form.Group>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Selecione o Líder da Equipe</Form.Label>
                                                <Select
                                                    value={users.find(user => user.value === creatorId)}
                                                    options={users}
                                                    onChange={(e) => setCreatorId(e ? e.value : null)}
                                                    placeholder="Selecione o líder"
                                                />
                                            </Form.Group>

                                            <div className="col-12">
                                                <Button type="submit" variant="primary">Editar</Button>
                                            </div>
                                        </Form>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};

export default EditTeam;
