import React, { useEffect, useState, useRef } from "react";
import { Row, Col, Form, Container, Button, Card, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useNavigate, useParams, Link } from 'react-router-dom';
import api from '../../services/api';
import { ToastContainer, toast } from 'react-toastify';
import { IoHourglass } from "react-icons/io5";
import Table from 'react-bootstrap/Table';
import InputGroup from 'react-bootstrap/InputGroup';
import EventVisibilityDropdown from '../../components/Dropdown/eventVisibilityDropdown';
import { Modal } from 'react-bootstrap';
import Badge from 'react-bootstrap/Badge';
import { FaCheck } from "react-icons/fa6";
import { FaCheckDouble } from "react-icons/fa6";
import { FaRegCalendarPlus } from "react-icons/fa";
import Cookies from 'js-cookie'
import Select from 'react-select'

const EventsEdit = () => {
    const { id } = useParams();
    const logged_user = Cookies.get('userId')
    const [validated, setValidated] = useState(false);
    const customId = 'success-toast-id';
    const history = useNavigate();
    const [name, setName] = useState('');
    const [imageCapa, setImageCapa] = useState(null);
    const [dataInicio, setDataInicio] = useState('');
    const [horaInicio, setHoraInicio] = useState('');
    const [dataFim, setDataFim] = useState('');
    const [horaFim, setHoraFim] = useState('');
    const [descricao, setDescricao] = useState('');
    const [local, setLocal] = useState('');
    const [externalLink, setExternalLink] = useState('');
    const [eventType, setEventType] = useState('');
    const [userLimit, setUserLimit] = useState(1);
    const [isLimited, setIsLimited] = useState(false);
    const [seeEvent, setSeeEvent] = useState('');
    const [imageSrc, setImageSrc] = useState('');
    const [errors, setErrors] = useState([]);
    const [showSpinner, setShowSpinner] = useState(false)
    const fileInputRef = useRef(null);

    const [showModal, setShowModal] = useState(false);
    const [programacoes, setProgramacoes] = useState([]);
    const [userGroups, setUserGroups] = useState([]);
    const [selectedGroups, setSelectedGroups] = useState([]);
    const [userFriends, setUserFriends] = useState([]);
    const [selectedFriends, setSelectedFriends] = useState([]);
    const [nomeProgramacao, setNomeProgramacao] = useState('');
    const [dataInicioProgramacao, setDataInicioProgramacao] = useState('');
    const [horaInicioProgramacao, setHoraInicioProgramacao] = useState('');
    const [dataFimProgramacao, setDataFimProgramacao] = useState('');
    const [horaFimProgramacao, setHoraFimProgramacao] = useState('');
    const [descricaoProgramacao, setDescricaoProgramacao] = useState('');
    const [mostrarFim, setMostrarFim] = useState(false);

    useEffect(() => {
        const fetchEvent = async () => {
            try {
                const response = await api.get(`/api/event/edit/${id}`);
                const event = response.data.event;

                setName(event.name);
                setDataInicio(event.data_inicio.split(' ')[0]);
                setHoraInicio(event.data_inicio.split(' ')[1]);
                setDataFim(event.data_fim ? event.data_fim.split(' ')[0] : '');
                setHoraFim(event.data_fim ? event.data_fim.split(' ')[1] : '');
                setDescricao(event.descricao);
                
                if (event.local) {
                    setLocal(event.local);
                }

                if (event.external_link) {
                    setExternalLink(event.external_link);
                }

                setEventType(event.event_type);
                setUserLimit(event.user_limit);
                setIsLimited(event.is_limited);
                setSeeEvent(event.see_event);
                setProgramacoes(event.programations);
                setImageSrc(event.image_capa);
                setSelectedGroups(response.data.groups)
                setSelectedFriends(response.data.guests)
            } catch (error) {
                console.error("Erro ao carregar o evento", error);
            }
        };
        fetchEvent();
    }, [id]);

    const updateEvent = async (e) => {
        e.preventDefault();

        setShowSpinner(true)

        if (name === '') {
            setShowSpinner(false)

            toast.error('Preencha o nome do evento', {
                toastId: customId,
                autoClose: 1000,
            });
        } else if (dataInicio === '' || horaInicio === '') {
            setShowSpinner(false)

            toast.error('Selecione a data de início do evento', {
                toastId: customId,
                autoClose: 1000,
            });
        } else if (dataFim === '' || horaFim === '') {
            setShowSpinner(false)

            toast.error('Selecione a data de término do evento', {
                toastId: customId,
                autoClose: 1000,
            });
        } else if (descricao === '') {
            setShowSpinner(false)

            toast.error('Preencha a descrição do evento', {
                toastId: customId,
                autoClose: 1000,
            });
        } else if (eventType === '') {
            setShowSpinner(false)

            toast.error('Selecione o tipo do evento', {
                toastId: customId,
                autoClose: 1000,
            });
        } else if (eventType === 'Ligação de vídeo externa' && externalLink === '') {
            setShowSpinner(false)

            toast.error('Forneça o link da chamada de vídeo externa', {
                toastId: customId,
                autoClose: 3000,
            });
        } else if (eventType === 'Presencial' && local === '') {
            setShowSpinner(false)

            toast.error('Forneça o local do evento', {
                toastId: customId,
                autoClose: 3000,
            });
        } else if (seeEvent === '') {
            setShowSpinner(false)

            toast.error('Selecione quem pode ver o evento', {
                toastId: customId,
                autoClose: 1000,
            });
        } else if (seeEvent === 'Grupo' && selectedGroups.length === 0) {
            setShowSpinner(false)

            toast.error('Selecione ao menos um grupo para participar do evento', {
                toastId: customId,
                autoClose: 1000,
            });
        } else if (seeEvent === 'Privado' && selectedFriends.length === 0) {
            setShowSpinner(false)

            toast.error('Selecione ao menos um convidado para participar do evento', {
                toastId: customId,
                autoClose: 1000,
            });
        } else {
            try {
                const formData = new FormData();
                formData.append('name', name);
                
                if (imageCapa) {
                    formData.append('event_image', imageCapa)
                }
    
                formData.append('data_inicio', `${dataInicio} ${horaInicio}`);
                formData.append('data_fim', dataFim ? `${dataFim} ${horaFim}` : '');
                formData.append('descricao', descricao);
                
                if (local) {
                    formData.append('local', local);
                }

                
                if (externalLink) {
                    formData.append('external_link', externalLink);
                }

                formData.append('event_type', eventType);
                formData.append('user_limit', userLimit);
                formData.append('see_event', seeEvent);
                formData.append('is_limited', isLimited ? 1 : 0);
    
                programacoes.forEach((prog, index) => {
                    formData.append(`programacoes[${index}][nome]`, prog.name);
                    formData.append(`programacoes[${index}][dataInicio]`, prog.data_inicio.split(' ')[0]);
                    formData.append(`programacoes[${index}][horaInicio]`, prog.data_inicio.split(' ')[1]);
                    
                    if (prog.data_fim) {
                        formData.append(`programacoes[${index}][dataFim]`, prog.data_fim.split(' ')[0]);
                        formData.append(`programacoes[${index}][horaFim]`, prog.data_fim.split(' ')[1]);
                    }
    
                    formData.append(`programacoes[${index}][descricao]`, prog.descricao);
                });

                if (selectedGroups.length > 0) {
                    selectedGroups.forEach((group, index) => {
                        formData.append(`selected_groups[${index}][value]`, group.value);
                    });
                }

                if (selectedFriends.length > 0) {
                    selectedFriends.forEach((friend, index) => {
                        formData.append(`selected_friends[${index}][value]`, friend.value);
                    });
                }
    
                await api.post(`/api/event/update/${id}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                }).then(res => {
                    setShowSpinner(false)

                    toast.success('Evento atualizado com sucesso!', {
                        toastId: customId,
                        autoClose: 1000,
                        onClose: () => {
                            history('/events/index');
                        }
                    });
        
                    console.log('Evento atualizado com sucesso', res.data);
                }).catch(err => {
                    setShowSpinner(false)
                    toast.error('Erro ao atualizar evento.', { autoClose: 3000 })
                });;

            } catch (error) {
                if (error.response && error.response.data.errors) {
                    setErrors(error.response.data.errors);
                } else {
                    console.error('Erro ao atualizar o evento', error);
                }
            }     
        }
    };

    const handleButtonClick = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setImageCapa(file);
            const reader = new FileReader();
            reader.onloadend = () => {
                setImageSrc(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleDataInicioChange = (e) => {
        setDataInicio(e.target.value);
    };

    const handleHoraInicioChange = (e) => {
        setHoraInicio(e.target.value);
    };

    const handleDataFimChange = (e) => {
        setDataFim(e.target.value);
    };

    const handleHoraFimChange = (e) => {
        setHoraFim(e.target.value);
    };

    const handleLimitSwitch = () => {
        setIsLimited(!isLimited);
    };

    const handleIncrement = () => {
        setUserLimit(prevCount => parseInt(prevCount) + 1);
    };

    const handleDecrement = () => {
        if (userLimit > 1) {
            setUserLimit(prevCount => parseInt(prevCount) - 1);
        }
    };

    const handleUserLimitChange = (e) => {
        const value = e.target.value;
        if (/^\d+$/.test(value) || value === '') {
            setUserLimit(value === '' ? '' : Number(value));
        }
    };

    const handleShowModal = () => setShowModal(true);
    const handleCloseModal = () => {
        setMostrarTudo(false);
        setShowModal(false);
    };

    const handleAddProgramacao = () => {
        if (nomeProgramacao === '') {
            toast.error('Digite o nome da programação', {
                toastId: customId,
                autoClose: 1000,
            });
        } else if (dataInicioProgramacao === '' || horaInicioProgramacao === '') {
            toast.error('Forneça a data e horário de início da programação', {
                toastId: customId,
                autoClose: 1000,
            });
        } else {
            const novaProgramacao = {
                name: nomeProgramacao,
                data_inicio: `${dataInicioProgramacao} ${horaInicioProgramacao}`,
                data_fim: dataFimProgramacao ? `${dataFimProgramacao} ${horaFimProgramacao}` : '',
                descricao: descricaoProgramacao,
            };

            setProgramacoes([...programacoes, novaProgramacao]);
            setNomeProgramacao('');
            setDataInicioProgramacao('');
            setHoraInicioProgramacao('');
            setDataFimProgramacao('');
            setHoraFimProgramacao('');
            setDescricaoProgramacao('');
            setMostrarFim(false);
        }
    };

    const [mostrarTudo, setMostrarTudo] = useState(false); // Novo estado

    const handleMostrarTudo = () => {
        setMostrarTudo(!mostrarTudo);
    };

    const handleDateField = (date) => {
        if (date && typeof date === 'string' && date.trim() !== '') {
          const [year, month, day] = date.split('-');
          const localDate = new Date(year, month - 1, day);
          return localDate.toLocaleDateString();
        }
        return '';
    };

    const formatDate = (dateString, timeString) => {
        const formattedDate = handleDateField(dateString);
        const [hours, minutes] = timeString.split(':');
        const formattedTime = `${hours}:${minutes}`;

        return (
            <>
                <div className='d-flex align-items-center gap-1'>
                    <span className="material-symbols-outlined fs-5">calendar_today</span> {formattedDate}
                </div>
                <div className='d-flex align-items-center gap-1'>
                    <span className="material-symbols-outlined fs-5">schedule</span> {formattedTime}
                </div>
            </>
        );
    };

    useEffect(() => {
        const fetchGroups = async () => {
            try {
                const response = await api.get(`/api/groupsByUser/${logged_user}`);
                response.data.groups.shift()
                setUserGroups(response.data.groups)
            } catch (error) {
                console.error("Erro ao carregar grupos", error);
                toast.error('Erro ao carregar grupos do usuário', { autoClose: 3000 })
            }
        };

        fetchGroups();
    }, [])

    useEffect(() => {
        const fetchFriends = async () => {
            try {
                const response = await api.get(`/api/user/friends/${logged_user}`);
                setUserFriends(response.data.friends)
            } catch (error) {
                console.error("Erro ao carregar convidados", error);
                toast.error('Erro ao carregar grupos do usuário', { autoClose: 3000 })
            }
        };

        fetchFriends();
    }, [])

    return (
        <>
            <ToastContainer />

            <div id='content-page' className='content-inner'>
                <Container>
                    <Row>
                        <Col sm="12">
                            <Card className="position-relative inner-page-bg bg-primary p-5">
                                <div className="inner-page-title" style={{ display: "flex", justifyContent: "space-between", width: "-webkit-fill-available" }}>
                                    <h3 className="text-white">Editar Evento</h3>
                                    <Link to='/events/index'>
                                        <Button>
                                            <i className="icon material-symbols-outlined d-flex">
                                                arrow_back
                                            </i>
                                        </Button>
                                    </Link>
                                </div>
                            </Card>
                        </Col>
                        <Col sm="12" lg="12">
                            <Card>
                                <Card.Header className="d-flex justify-content-between">
                                    <div className="header-title">
                                        <h4 className="card-title">Editar Evento</h4>
                                    </div>
                                </Card.Header>
                                <Card.Body className="pt-0">
                                    <Form noValidate onSubmit={updateEvent} validated={validated} className="row g-3">
                                        <Col md="6">
                                            <Form.Label
                                                className="form-label"
                                                htmlFor="validationCustom01"
                                            >
                                                Nome
                                            </Form.Label>
                                            <Form.Control className='input-group-edit' type='text' placeholder='Nome' value={name} onChange={(e) => setName(e.target.value)} aria-describedby="inputGroupPrepend" id="validationCustom01" required />
                                            <Form.Control.Feedback>
                                                Correto!
                                            </Form.Control.Feedback>
                                            <Form.Control.Feedback type="invalid">
                                                Please choose a username.
                                            </Form.Control.Feedback>
                                        </Col>
                                        <Col md="6">
                                            <div className="w-100 h-100 d-flex align-items-end">
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={<Tooltip id="tooltip-top">Adicionar imagem do evento</Tooltip>}
                                                >
                                                    <Button
                                                        variant="outline-primary"
                                                        id="button-addon2"
                                                        className="d-flex align-items-center justify-content-center position-absolute"
                                                        style={{ height: "2.8rem" }}
                                                    >
                                                        <span onClick={handleButtonClick} className="material-symbols-outlined">add_a_photo</span>
                                                        <input
                                                            ref={fileInputRef}
                                                            className="file-upload"
                                                            onChange={handleFileChange}
                                                            type="file"
                                                            accept="image/*" />
                                                    </Button>
                                                </OverlayTrigger>

                                                {imageSrc && (
                                                    <div className="image-preview">
                                                        <img
                                                            src={imageSrc}
                                                            alt="Preview"
                                                            style={{ maxWidth: "100%", borderRadius: "6px" }} />
                                                    </div>
                                                )}
                                            </div>
                                        </Col>
                                        <Table striped bordered className='shadowCustom'>
                                            <thead className='bg-dark'>
                                                <tr>
                                                    <th colSpan={2}>
                                                        <h6 className="text-white text-center">
                                                            <IoHourglass className='mb-1' /> Periodo
                                                        </h6>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr className='rowGenForm'>
                                                    <td>
                                                        <Col>
                                                            <h5 className="bg-success text-white p-1 mb-3 rounded text-center">
                                                                Inicio
                                                            </h5>
                                                            <InputGroup>
                                                                <InputGroup.Text className='bg-dark text-white' id="basic-addon1">Data</InputGroup.Text>
                                                                <input
                                                                    type="date"
                                                                    name="data_inicio"
                                                                    className="text-center form-control valid fw-semibold"
                                                                    id="data_inicio"
                                                                    aria-describedby="data_inicio"
                                                                    aria-invalid="false"
                                                                    value={dataInicio}
                                                                    onChange={handleDataInicioChange}
                                                                    required
                                                                />
                                                            </InputGroup>
                                                            <InputGroup className='mt-3'>
                                                                <InputGroup.Text className='bg-dark text-white' id="basic-addon1">Hora</InputGroup.Text>
                                                                <input
                                                                    type="time"
                                                                    name="hora_inicio"
                                                                    step="1"
                                                                    className="text-center form-control inteiro fw-semibold"
                                                                    id="hora_inicio"
                                                                    aria-describedby="hora_inicio"
                                                                    value={horaInicio}
                                                                    onChange={handleHoraInicioChange}
                                                                    required
                                                                />
                                                            </InputGroup>
                                                        </Col>
                                                    </td>
                                                    <td>
                                                        <Col>
                                                            <h5 className="bg-danger text-white p-1 mb-3 rounded text-center">
                                                                Final
                                                            </h5>
                                                            <InputGroup>
                                                                <InputGroup.Text className='bg-dark text-white' id="basic-addon1">Data</InputGroup.Text>
                                                                <input
                                                                    type="date"
                                                                    name="data_fim"
                                                                    className="text-center form-control valid fw-semibold"
                                                                    id="data_fim"
                                                                    aria-describedby="data_fim"
                                                                    aria-invalid="false"
                                                                    value={dataFim}
                                                                    onChange={handleDataFimChange}
                                                                    required
                                                                />
                                                            </InputGroup>
                                                            <InputGroup className='mt-3'>
                                                                <InputGroup.Text className='bg-dark text-white' id="basic-addon1">Hora</InputGroup.Text>
                                                                <input
                                                                    type="time"
                                                                    name="hora_fim"
                                                                    step="1"
                                                                    className="text-center form-control inteiro fw-semibold"
                                                                    id="hora_fim"
                                                                    value={horaFim}
                                                                    onChange={handleHoraFimChange}
                                                                    aria-describedby="hora_fim"
                                                                />
                                                            </InputGroup>
                                                        </Col>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                        <Col md="6" className="d-flex flex-column row-gap-4">
                                            <Row>
                                                <Form.Label
                                                    className="form-label"
                                                >
                                                    Descrição
                                                </Form.Label>
                                                <div className="input-group">
                                                    <Form.Control className='input-group-edit' type='text' placeholder='Descrição' value={descricao} onChange={(e) => setDescricao(e.target.value)} />
                                                </div>
                                            </Row>
                                            <Row>
                                                <div className="d-flex align-items-center gap-2">
                                                    <Row>
                                                        <Form.Label className="mb-0">Quem pode ver o evento</Form.Label>
                                                        <EventVisibilityDropdown setSeeEvent={setSeeEvent} seeEvent={seeEvent} />

                                                        {seeEvent === 'Grupo' && (
                                                            <Form.Group className="mt-2">
                                                                <Form.Label>Selecione os grupos</Form.Label>
                                                                <Select className='input-group-edit' value={selectedGroups} onChange={(e) => setSelectedGroups(e)} options={userGroups} isMulti={true} />
                                                            </Form.Group>
                                                        )}

                                                        {seeEvent === 'Privado' && (
                                                            <Form.Group className="mt-2">
                                                                <Form.Label>Selecione os convidados</Form.Label>
                                                                <Select className='input-group-edit' value={selectedFriends} onChange={(e) => setSelectedFriends(e)} options={userFriends} isMulti={true} />
                                                            </Form.Group>
                                                        )}
                                                    </Row>
                                                </div>
                                            </Row>
                                        </Col>

                                        <Col md="6" className="d-flex flex-column row-gap-4">
                                            <Row>
                                                <Form.Label>Tipo de evento</Form.Label>
                                                <Form.Select
                                                    value={eventType}
                                                    onChange={(e) => setEventType(e.target.value)}
                                                    required
                                                >
                                                    <option value="">Selecione o tipo</option>
                                                    <option value="No Workplace">No Workplace</option>
                                                    <option value="Ligação de vídeo externa">Ligação de vídeo externa</option>
                                                    <option value="Presencial">Presencial</option>
                                                </Form.Select>

                                                {eventType === 'Presencial' && (
                                                    <div>
                                                        <Form.Label>Localização</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="local do evento"
                                                            value={local}
                                                            onChange={(e) => setLocal(e.target.value)}
                                                            required
                                                        />
                                                    </div>
                                                )}

                                                {eventType === 'Ligação de vídeo externa' && (
                                                    <div>
                                                        <Form.Label>Link</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="link da chamada"
                                                            value={externalLink}
                                                            onChange={(e) => setExternalLink(e.target.value)}
                                                            required
                                                        />
                                                    </div>
                                                )}
                                            </Row>
                                            <Row>
                                                <div className="d-flex gap-3">
                                                    {isLimited && (
                                                        <div className="d-flex align-items-center">
                                                            <Button className="d-flex align-items-center fs-3" style={{ height: "2rem" }} variant="secondary" onClick={handleDecrement}>-</Button>
                                                            <Form.Control
                                                                type="text"
                                                                value={userLimit}
                                                                className="text-center mx-2"
                                                                style={{ width: "4rem", height: "2rem" }}
                                                                onChange={handleUserLimitChange}
                                                            />
                                                            <Button className="d-flex align-items-center fs-3" style={{ height: "2rem" }} variant="secondary" onClick={handleIncrement}>+</Button>
                                                        </div>
                                                    )}
                                                    <Form.Check
                                                        className="d-flex align-items-center gap-2"
                                                        type="switch"
                                                        id="custom-switch"
                                                        label="Limitar quantidade de usuários ?"
                                                        checked={isLimited}
                                                        onChange={handleLimitSwitch}
                                                    />
                                                </div>
                                            </Row>
                                            <Row>
                                                <Button variant="primary" onClick={handleShowModal}>Adicionar Programação</Button>
                                            </Row>
                                        </Col>

                                        <div className="col-12">
                                            <Button disabled={showSpinner} type="submit" variant="primary">
                                                {showSpinner ? <div className="spinner-border spinner-border-sm" role="status"></div> : 'Atualizar'}
                                            </Button>
                                        </div>

                                        <Modal show={showModal} onHide={handleCloseModal}>
                                            <Modal.Header closeButton>
                                                <Modal.Title>Adicionar Programação ao Evento</Modal.Title>
                                            </Modal.Header>

                                            <Modal.Body>
                                                {programacoes.length > 0 && (
                                                    <div className="mt-3">
                                                        <h5>Programações Adicionadas:</h5>
                                                        <ul>
                                                            {programacoes.map((prog, index) => (
                                                                <div className="mt-3" key={index}>
                                                                    <Badge bg="secondary" className="fs-6">{prog.name}</Badge>
                                                                    <div className="ms-3 mt-2 d-flex gap-4">
                                                                        <div>
                                                                            <span className="fw-bold">Início: </span>
                                                                            {formatDate(prog.data_inicio.split(' ')[0], prog.data_inicio.split(' ')[1])}
                                                                        </div>
                                                                        {prog.data_fim && (
                                                                            <div>
                                                                                <span className="fw-bold">Término: </span>
                                                                                {formatDate(prog.data_fim.split(' ')[0], prog.data_fim.split(' ')[1])}
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                    <div className="ms-3 mt-1">Descrição:<span className="fw-bold ms-1">{prog.descricao}</span></div>
                                                                </div>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                )}
                                                {mostrarTudo && (
                                                    <div>
                                                        <Form.Group>
                                                            <Form.Label>Nome</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                value={nomeProgramacao}
                                                                onChange={(e) => setNomeProgramacao(e.target.value)}
                                                            />
                                                        </Form.Group>
                                                        <Row className="mt-2">
                                                            <Col lg="6">
                                                                <Form.Group>
                                                                    <Form.Label>Data de Início</Form.Label>
                                                                    <Form.Control
                                                                        type="date"
                                                                        value={dataInicioProgramacao}
                                                                        onChange={(e) => setDataInicioProgramacao(e.target.value)}
                                                                    />
                                                                </Form.Group>
                                                                <Form.Group>
                                                                    <Form.Label>Hora de Início</Form.Label>
                                                                    <Form.Control
                                                                        type="time"
                                                                        value={horaInicioProgramacao}
                                                                        onChange={(e) => setHoraInicioProgramacao(e.target.value)}
                                                                    />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col lg="6">
                                                                {mostrarFim && (
                                                                    <>
                                                                        <Form.Group>
                                                                            <Form.Label>Data de Término</Form.Label>
                                                                            <Form.Control
                                                                                type="date"
                                                                                value={dataFimProgramacao}
                                                                                onChange={(e) => setDataFimProgramacao(e.target.value)}
                                                                            />
                                                                        </Form.Group>
                                                                        <Form.Group>
                                                                            <Form.Label>Hora de Término</Form.Label>
                                                                            <Form.Control
                                                                                type="time"
                                                                                value={horaFimProgramacao}
                                                                                onChange={(e) => setHoraFimProgramacao(e.target.value)}
                                                                            />
                                                                        </Form.Group>
                                                                    </>
                                                                )}
                                                                {!mostrarFim && (
                                                                    <Button style={{ marginTop: '2.1rem', height: '2.9rem' }} className="w-100" variant="secondary" onClick={() => setMostrarFim(true)}>
                                                                        <FaRegCalendarPlus className="fs-5 me-1 mb-1" />Adicionar data de término
                                                                    </Button>
                                                                )}
                                                            </Col>
                                                        </Row>
                                                        <Form.Group className="mt-2">
                                                            <Form.Label>Descrição</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                value={descricaoProgramacao}
                                                                placeholder="Opcional"
                                                                onChange={(e) => setDescricaoProgramacao(e.target.value)}
                                                            />
                                                        </Form.Group>
                                                        <Button variant="primary" className="mt-3 w-100" onClick={handleAddProgramacao}>
                                                            <FaCheck className="me-1 fs-5" />Adicionar Programação
                                                        </Button>
                                                    </div>
                                                )}
                                            </Modal.Body>

                                            <Modal.Footer>
                                                <Button variant="secondary" onClick={handleMostrarTudo}>
                                                    {mostrarTudo ? 'Ocultar' : 'Adicionar Programação'}
                                                </Button>
                                                <Button variant="primary" onClick={handleCloseModal}>
                                                    <FaCheckDouble className="me-1 fs-5" />Concluir
                                                </Button>
                                            </Modal.Footer>

                                        </Modal>

                                    </Form>
                                </Card.Body>
                            </Card>

                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};

export default EventsEdit;
