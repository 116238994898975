import React from 'react'
import { useNavigate } from 'react-router-dom'
import Modal from 'react-modal'
import api from '../services/api'

const GroupDeleteModal = ({ isOpen, onClose, groupId }) => {
  const navigate = useNavigate()

  const handleDeleteGroup = async () => {
    try {
      const response = await api.delete(`/api/group/${groupId}`)
      if (response.status === 201) {
        navigate('/groups/groups')
      } else {
        console.error('Erro ao excluir o grupo')
      }
    } catch (error) {
      console.error('Erro ao excluir o grupo:', error)
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentlabel="Confirmar Exclusão"
      ariaHideApp={false}
      style={{
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          width: '400px',
          textAlign: 'center',
        },
      }}
    >
      <h2>Você tem certeza que deseja excluir o grupo?</h2>
      <div style={{ marginTop: '20px' }}>
        <button onClick={handleDeleteGroup} style={{ backgroundColor: 'red', color: 'white', padding: '10px 20px' }}>
          Sim, excluir
        </button>
        <button onClick={onClose} style={{ marginLeft: '10px', padding: '10px 20px' }}>
          Cancelar
        </button>
      </div>
    </Modal>
  )
}

export default GroupDeleteModal
