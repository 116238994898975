import React, { useEffect, useState, useMemo } from 'react'
import { Container, Row, Col, Card, Button, Modal, Form } from "react-bootstrap";
import { useNavigate } from 'react-router-dom'
import { Link } from "react-router-dom";
import api from '../../services/api'
import { ToastContainer, toast } from 'react-toastify'
import AnimationWarningLottie from '../../components/AnimationWarningDeleteConfim/AnimationWarningLottie'
import { FaSearch } from 'react-icons/fa'

const RolesIndex = () => {
    const history = useNavigate()
    const [roles, setRoles] = useState([])
    const [modalShow, setModalShow] = useState(false)
    const [roleIdToDelete, setRoleIdToDelete] = useState('')
    const [roleNameToDelete, setRoleNameToDelete] = useState('')
    const [search, setSearch] = useState('')

    const [currentPage, setCurrentPage] = useState(1)
    const [rolesPerPage] = useState(10)
    const indexOfLastRole = currentPage * rolesPerPage
    const indexOfFirstRole = indexOfLastRole - rolesPerPage
    const currentRoles = roles.slice(indexOfFirstRole, indexOfLastRole)
    const nPages = Math.ceil(roles.length / rolesPerPage)

    const Pagination = ({ nPages, currentPage, setCurrentPage }) => {
        if (nPages <= 1) return null;

        const range = (start, end) => {
            return Array(end - start + 1)
                .fill()
                .map((_, idx) => start + idx);
        };

        let pageNumbers = [];
        if (currentPage <= 3) {
            pageNumbers = [...range(1, Math.min(5, nPages))];
            if (nPages > 5) pageNumbers.push('...', nPages);
        } else if (currentPage > 3 && currentPage < nPages - 2) {
            pageNumbers = [1, '...', ...range(currentPage - 1, currentPage + 1), '...', nPages];
        } else {
            pageNumbers = [1, '...'];
            pageNumbers.push(...range(nPages - 4, nPages));
        }

        return (

            <nav className='d-flex justify-content-center align-items-center'>
                <ul className="pagination mb-4">
                    <li className="page-item"><Link className="page-link" onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}>
                        <i className="icon material-symbols-outlined d-flex">
                            arrow_back
                        </i></Link></li>
                    {pageNumbers.map((number, index) => (
                        <li key={index} className={`page-item ${currentPage === number ? 'active' : ''}`}>
                            <Link className="page-link" onClick={() => typeof number === 'number' && setCurrentPage(number)}> {number}</Link></li>
                    ))}
                    <li className="page-item"><Link className="page-link" onClick={() => setCurrentPage(prev => Math.min(prev + 1, nPages))}>
                        <i className="icon material-symbols-outlined d-flex">
                            arrow_forward
                        </i></Link></li>
                </ul>
            </nav>
        );
    };

    const getRoles = async () => {
        try {
            const [rolesResponse] = await Promise.all([
                api.get('/api/role/index')
            ])

            setRoles(rolesResponse.data.roles);
        } catch (error) {
            console.error('Erro')
        }
    }

    const showCreateRolePage = () => {
        history(`/roles/create`)
    }

    const showEditRolePage = (roleId) => {
        history(`/roles/edit/${roleId}`)
    }

    const showDeleteConfirmationModal = (roleId, roleName) => {
        setModalShow(true)
        setRoleIdToDelete(roleId)
        setRoleNameToDelete(roleName)
    }

    const deleteRole = async (roleId) => {
        await api.delete(`/api/role/${roleId}`).then(() => {
            getRoles()
            setModalShow(false)
            toast.success('Perfil deletado com sucesso!', { autoClose: 3000 })
        })
    }

    function DeleteRoleConfirmaitonModal(props) {

        return (
            <Modal
                {...props}
                aria-labelledby='contained-modal-title-vcenter'
                centered
            >
                <Modal.Header id='modalHeader' closeButton>
                    <div id='divModalTitle'>
                        <Modal.Title id='modalTitle'>
                            Exclusão de Role
                        </Modal.Title>
                    </div>
                </Modal.Header>
                <Modal.Body id='modalBody'>
                    <div className="d-flex justify-content-center">
                        <AnimationWarningLottie />
                    </div>
                    <div className="d-flex justify-content-center">
                        <p>
                            Você tem certeza de que deseja remover esta role "{props.rolename}" ?
                        </p>
                    </div>
                </Modal.Body>
                <Modal.Footer id='modalFooter'>
                    <Button id='yesButton' onClick={() => deleteRole(props.roleid)}>Sim</Button>
                    <Button id='noButton' onClick={props.onHide}>Não</Button>
                </Modal.Footer>
            </Modal>
        )
    }

    useEffect(() => {
        getRoles()
    }, [])

    const filteredRoles = useMemo(() => {
        const lowerSearch = search.toLowerCase()

        if (search === '') {
            return currentRoles
        } else {
            return roles.filter((role) => role.name.toLowerCase().includes(lowerSearch) || role.id.toString().toLowerCase().includes(lowerSearch))
        }
    }, [search, roles, currentRoles])

    return (
        <>
            <ToastContainer />
            <div id='content-page' className='content-inner'>
                <Container>
                    <DeleteRoleConfirmaitonModal
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                        roleid={roleIdToDelete}
                        rolename={roleNameToDelete}
                    />
                    <Row>
                        <Col sm="12">
                            <Card
                                className="position-relative inner-page-bg bg-primary p-5"
                                
                            >
                                <div className="inner-page-title">
                                    <h3 className="text-white">Perfis</h3>
                                </div>
                            </Card>
                        </Col>
                        <Col sm="12" lg="12">
                            <Card>
                                <Card.Body className="pt-0">
                                    <div className="mb-4 mt-4">
                                        <Form.Group className="input-group">
                                            <span className="input-group-text" id="basic-addon1">
                                                <FaSearch />
                                            </span>
                                            <Form.Control
                                                placeholder='Pesquisar'
                                                aria-label='pesquisar'
                                                aria-describedby='search'
                                                onChange={(e) => setSearch(e.target.value)}
                                            />
                                            <Button onClick={() => showCreateRolePage()} id='buttonNew' className='borderbtnCreateEdit'>
                                                <i className="icon material-symbols-outlined d-flex">
                                                add
                                                </i>
                                            </Button>
                                        </Form.Group>
                                    </div>
                                    <table className="table d-table">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">Nome</th>
                                                <th scope="col">Ações</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {filteredRoles.map((role, index) => (
                                                <tr key={index}>
                                                    <th scope="row">{role.id}</th>
                                                    <td>{role.name}</td>
                                                    <td>
                                                        <Button style={{ marginRight: "5px" }} onClick={() => showEditRolePage(role.id)} >
                                                            <i  className="material-symbols-outlined d-flex"> mode_edit </i>
                                                        </Button>
                                                        <Button onClick={() => showDeleteConfirmationModal(role.id, role.name)}>
                                                            <i className="material-symbols-outlined d-flex"> delete </i>
                                                        </Button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </Card.Body>
                            </Card>
                            <Pagination
                                nPages={nPages}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                            />
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default RolesIndex