import React, { useEffect, useState, useMemo, useCallback } from 'react'
import { Container, Row, Col, Card, Button, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import api from '../../services/api'
import { ToastContainer } from 'react-toastify'
import { FaSearch } from 'react-icons/fa'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Popover from 'react-bootstrap/Popover'
import { debounce } from 'lodash';

const ActivityLog = () => {
    const [activityLogs, setActivityLogs] = useState([]);
    const [details, setDetails] = useState([])

    const [search, setSearch] = useState('')
    const [currentPage, setCurrentPage] = useState(1)
    const [logsPerPage] = useState(10)

    const fetchActivityLogs = async () => {
        try {
            const response = await api.get('/api/activity-logs', {
                search
            });
            setActivityLogs(response.data.logsActivity);
        } catch (error) {
            console.error('Erro ao carregar logs de atividades:', error);
        }
    };

    const indexOfLastLogs = currentPage * logsPerPage
    const indexOfFirstLogs = indexOfLastLogs - logsPerPage
    const currentLogs = activityLogs.slice(indexOfFirstLogs, indexOfLastLogs)
    const nPages = Math.ceil(activityLogs.length / logsPerPage)

    const handleSearchChange = useCallback(debounce((newSearch) => {
        setSearch(newSearch)
        setCurrentPage(1)
        fetchActivityLogs(true)
    }, 300), [])

    const Pagination = ({ nPages, currentPage, setCurrentPage }) => {
        if (nPages <= 1) return null;

        const range = (start, end) => {
            return Array(end - start + 1)
                .fill()
                .map((_, idx) => start + idx);
        };

        let pageNumbers = [];
        if (currentPage <= 3) {
            pageNumbers = [...range(1, Math.min(5, nPages))];
            if (nPages > 5) pageNumbers.push('...', nPages);
        } else if (currentPage > 3 && currentPage < nPages - 2) {
            pageNumbers = [1, '...', ...range(currentPage - 1, currentPage + 1), '...', nPages];
        } else {
            pageNumbers = [1, '...'];
            pageNumbers.push(...range(nPages - 4, nPages));
        }

        return (

            <nav className='d-flex justify-content-center align-items-center'>
                <ul className="pagination mb-4">
                    <li className="page-item"><Link className="page-link" onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}>
                        <i className="icon material-symbols-outlined d-flex">
                            arrow_back
                        </i></Link></li>
                    {pageNumbers.map((number, index) => (
                        <li key={index} className={`page-item ${currentPage === number ? 'active' : ''}`}>
                            <Link className="page-link" onClick={() => typeof number === 'number' && setCurrentPage(number)}> {number}</Link></li>
                    ))}
                    <li className="page-item"><Link className="page-link" onClick={() => setCurrentPage(prev => Math.min(prev + 1, nPages))}>
                        <i className="icon material-symbols-outlined d-flex">
                            arrow_forward
                        </i></Link></li>
                </ul>
            </nav>
        );
    };

    useEffect(() => {
        fetchActivityLogs();
    }, [])

    const filteredLogs = useMemo(() => {

        if (search === '') {
            return currentLogs
        } else {
            return search ? activityLogs.filter(f => f.logName.toLowerCase().includes(search.toLowerCase()) || f.email.toLowerCase().includes(search.toLowerCase()) || f.description.toLowerCase().includes(search.toLowerCase()) || f.subjectId.toString().includes(search.toLowerCase())) : activityLogs
        }
    }, [search, activityLogs, currentLogs])

    const popover = (
        <Popover id="popover-basic">
            <Popover.Header as="h3">Logs</Popover.Header>
            <Popover.Body>
                <div style={{ display: 'flex' }}>
                    <p className='mr-2'>Tipo de log:</p>
                    <p>{details.description}</p>
                </div>
                <div style={{ display: 'flex' }}>
                    <p className='mr-2'>IP:</p>
                    <p>{details.ip}</p>
                </div>
                <div style={{ display: 'flex' }}>
                    <p className='mr-2'>Navegador:</p>
                    <p>{details.userAgent}</p>
                </div>
                {details.email ?
                    <div style={{ display: 'flex' }}>
                        <p className='mr-2'>Email:</p>
                        <p>{details.email}</p>
                    </div> : ''

                }
                {details.password ?
                    <div style={{ display: 'flex' }}>
                        <p>Senha digitada:</p>
                        <p className='mr-2'>{details.password}</p>
                    </div> : ''

                }
                {details.descricao ?
                    <div style={{ display: 'flex' }}>
                        <p>Descrição:</p>
                        <p className='mr-2'>{details.descricao}</p>
                    </div> : ''

                }
            </Popover.Body>
        </Popover>
    );

    return (
        <>
            <ToastContainer />
            <div id='content-page' className='content-inner'>
                <Container>
                    <Row>
                        <Col sm="12">
                            <Card
                                className="position-relative inner-page-bg bg-primary p-5"

                            >
                                <div className="inner-page-title">
                                    <h3 className="text-white">Activity Log</h3>
                                </div>
                            </Card>
                        </Col>
                        <Col sm="12" lg="12">
                            <Card>
                                <Card.Body className="pt-0">
                                    <div className="mb-4 mt-4">
                                        <Form.Group className="input-group">
                                            <span className="input-group-text" id="basic-addon1">
                                                <FaSearch />
                                            </span>
                                            <Form.Control
                                                placeholder='Pesquisar'
                                                aria-label='pesquisar'
                                                aria-describedby='search'
                                                onChange={(e) => handleSearchChange(e.target.value)}
                                            />
                                        </Form.Group>
                                    </div>
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">Log</th>
                                                <th scope="col">Email causador</th>
                                                <th scope="col">Id afetado</th>
                                                <th scope="col">Ações</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {filteredLogs.map((log, index) => (
                                                <tr key={index}>
                                                    <th scope="row">{log.logId}</th>
                                                    <td>{log.logName}</td>
                                                    <td>{log.email}</td>
                                                    <td>{log.subjectId}</td>
                                                    <td>
                                                        <OverlayTrigger trigger="click" placement="right" overlay={popover}>
                                                            <Button onClick={() => setDetails(log.logsAttributes)} variant='primary'>Detalhes</Button>
                                                        </OverlayTrigger>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </Card.Body>
                            </Card>
                            <Pagination
                                nPages={nPages}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                            />
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default ActivityLog;