import React from "react";

//layoutpages
import Default from "../layouts/dashboard/default";
import Error404 from "../views/errors/error404";

import { DefaultRouter } from "./default-router";

export const IndexRouters = [
  {
    path: "/",
    element: <Default />,
    children: [...DefaultRouter],
  },{
    path: "*",
    element: <Error404 />,
  },
];
