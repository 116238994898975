import React, { useEffect, useState, useMemo } from 'react'
import { Container, Row, Col, Card, Button, Modal, Form, Dropdown } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom'
import api from '../../services/api'
import { toast } from 'react-toastify'
import AnimationWarningLottie from '../../components/AnimationWarningDeleteConfim/AnimationWarningLottie'
import { FaSearch } from 'react-icons/fa'
import { format } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import Cookies from 'js-cookie'

const StreamingsIndex = () => {
    const history = useNavigate()
    const logged_user = Cookies.get('userId')
    const [streamings, setStreamings] = useState([])
    const [modalShow, setModalShow] = useState(false)
    const [streamingIdToDelete, setStreamingIdToDelete] = useState('')
    const [streamingNameToDelete, setStreamingNameToDelete] = useState('')
    const [search, setSearch] = useState('')
    const [currentPage, setCurrentPage] = useState(1)
    const [streamingsPerPage] = useState(10)
    const indexOfLastStreaming = currentPage * streamingsPerPage
    const indexOfFirstStreaming = indexOfLastStreaming - streamingsPerPage
    const currentStreamings = streamings.slice(indexOfFirstStreaming, indexOfLastStreaming)
    const nPages = Math.ceil(streamings.length / streamingsPerPage)

    const Pagination = ({ nPages, currentPage, setCurrentPage }) => {
        if (nPages <= 1) return null

        const range = (start, end) => {
            return Array(end - start + 1)
                .fill()
                .map((_, idx) => start + idx)
        }

        let pageNumbers = []

        if (currentPage <= 3) {
            pageNumbers = [...range(1, Math.min(5, nPages))]
            if (nPages > 5) pageNumbers.push('...', nPages)
        } else if (currentPage > 3 && currentPage < nPages - 2) {
            pageNumbers = [1, '...', ...range(currentPage - 1, currentPage + 1), '...', nPages]
        } else {
            pageNumbers = [1, '...']
            pageNumbers.push(...range(nPages - 4, nPages))
        }

        return (
            <nav className='d-flex justify-content-center align-items-center'>
                <ul className='pagination mb-4'>
                    <li className='page-item'><Link className='page-link' onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}>
                        <i className='icon material-symbols-outlined d-flex'>
                            arrow_back
                        </i></Link></li>
                    {pageNumbers.map((number, index) => (
                        <li key={index} className={`page-item ${currentPage === number ? 'active' : ''}`}>
                            <Link className='page-link' onClick={() => typeof number === 'number' && setCurrentPage(number)}> {number}</Link></li>
                    ))}
                    <li className='page-item'><Link className='page-link' onClick={() => setCurrentPage(prev => Math.min(prev + 1, nPages))}>
                        <i className='icon material-symbols-outlined d-flex'>
                            arrow_forward
                        </i></Link></li>
                </ul>
            </nav>
        )
    }

    const handleCopy = (textToCopy, message) => {
        if (navigator.clipboard) {
            navigator.clipboard.writeText(textToCopy)
                .then(() => {
                    toast.success(message + ' copiado com sucesso', { autoClose: 1000 })
                })
                .catch((err) => {
                    console.error('Erro ao copiar o texto: ', err)
                    toast.error('Erro ao copiar ' + message, { autoClose: 1000 })
                })
        } else {
            console.warn('A API Clipboard não é suportada por este navegador.')
            toast.error('A API Clipboard não é suportada por este navegador.', { autoClose: 1000 })
        }
    }

    const formatDate = (dateString) => {
        return format(new Date(dateString), 'dd/MM/yyyy HH:mm:ss', { locale: ptBR })
    }

    const getStreamings = async () => {
        try {
            const response = await api.get('/api/streaming/index')
            setStreamings(response.data.streamings)
        } catch (error) {
            console.error('Erro')
            toast.error('Erro ao carregar lives.', { autoClose: 3000 })
        }
    }

    const showEditStreamingPage = (streamingId) => {
        history(`/streaming/edit/${streamingId}`)
    }

    const showCreateStreamingPage = () => {
        history(`/streaming/create`)
    }

    const showDeleteStreamingConfirmationModal = (streamingId, streamingName) => {
        setModalShow(true)
        setStreamingIdToDelete(streamingId)
        setStreamingNameToDelete(streamingName)
    }

    const deleteStreaming = async (streamingId) => {
        await api.delete(`/api/streaming/destroy/${streamingId}`).then(() => {
            getStreamings()
            setModalShow(false)
            toast.success('Live deletada com sucesso', { autoClose: 3000 })
        })
    }

    const startLiveStream = async (streamId) => {
        await api.post(`/api/streaming/startLive`, {
            streaming_id: streamId,
            logged_user
        }).then(() => {
            toast.success('Live iniciada com sucesso', { autoClose: 3000 })
        }).catch((err) => {
            if (err.response.data.message === 'Live já iniciada') {
                toast.error('Live já iniciada', { autoClose: 3000 })
            } else {
                toast.error('Erro ao iniciar live', { autoClose: 3000 })
            }

            console.error(err)
        })
    }

    const endLiveStream = async (streamId) => {
        await api.post(`/api/streaming/endLive`, {
            streaming_id: streamId,
            logged_user,
        }).then((response) => {
            toast.success('Live finalizada com sucesso', { autoClose: 3000 });
        }).catch((err) => {
            toast.error('Erro ao finalizar live', { autoClose: 3000 });
            console.error(err);
        });
    };

    const downloadViewsReport = async (live_id) => {
        try {
            const response = await api({
                url: `/api/streamingReport`,
                method: 'post',
                data: { live_id },
                responseType: 'blob',
            })

            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', 'logs.xlsx')
            document.body.appendChild(link)
            link.click()
            link.remove()

            toast.success('Relatório gerado com sucesso', { autoClose: 1000 })
        } catch (err) {
            toast.error('Erro ao gerar relatório', { autoClose: 1000 })
            console.error(err)
        }
    }

    const downloadChatReport = async (live_id) => {
        try {
            const response = await api({
                url: `/api/chatReport`,
                method: 'post',
                data: { live_id },
                responseType: 'blob',
            })

            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', 'chats.xlsx')
            document.body.appendChild(link)
            link.click()
            link.remove()

            toast.success('Relatório gerado com sucesso', { autoClose: 1000 })
        } catch (err) {
            toast.error('Erro ao gerar relatório', { autoClose: 1000 })
            console.error(err)
        }
    }

    function DeleteStreamingConfirmationModal(props) {

        return (
            <Modal
                {...props}
                aria-labelledby='contained-modal-title-vcenter'
                centered
            >
                <Modal.Header id='modalHeader' closeButton>
                    <div id='divModalTitle'>
                        <Modal.Title id='modalTitle'>
                            Exclusão de live
                        </Modal.Title>
                    </div>
                </Modal.Header>
                <Modal.Body id='modalBody'>
                    <div className='d-flex justify-content-center'>
                        <AnimationWarningLottie />
                    </div>
                    <div className='d-flex justify-content-center'>
                        <p>
                            Você tem certeza de que deseja remover a live '{props.streamingname}' ?
                        </p>
                    </div>
                </Modal.Body>
                <Modal.Footer id='modalFooter'>
                    <Button id='yesButton' onClick={() => deleteStreaming(props.streamingid)}>Sim</Button>
                    <Button id='noButton' onClick={props.onHide}>Não</Button>
                </Modal.Footer>
            </Modal>
        )
    }

    useEffect(() => {
        getStreamings()
    }, [])

    const filteredStreamings = useMemo(() => {
        const lowerSearch = search.toLowerCase()

        if (search === '') {
            return currentStreamings
        } else {
            return streamings.filter((streaming) => streaming.nome.toLowerCase().includes(lowerSearch) || streaming.id.toString().toLowerCase().includes(lowerSearch))
        }
    }, [search, streamings, currentStreamings])

    return (
        <>
            <div id='content-page' className='content-inner'>
                <Container>
                    <DeleteStreamingConfirmationModal
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                        streamingid={streamingIdToDelete}
                        streamingname={streamingNameToDelete}
                    />
                    <Row>
                        <Col sm='12'>
                            <Card
                                className='position-relative inner-page-bg bg-primary p-5'
                            >
                                <div className='inner-page-title'>
                                    <h3 className='text-white'>Lives</h3>
                                </div>
                            </Card>
                        </Col>
                        <Col sm='12' lg='12'>
                            <Card>
                                <Card.Body className='pt-0'>
                                    <div className='mb-4 mt-4'>
                                        <Form.Group className='input-group'>
                                            <span className='input-group-text' id='basic-addon1'>
                                                <FaSearch />
                                            </span>
                                            <Form.Control
                                                placeholder='Pesquisar'
                                                aria-label='pesquisar'
                                                aria-describedby='search'
                                                onChange={(e) => setSearch(e.target.value)}
                                            />
                                            <Button onClick={() => showCreateStreamingPage()} id='buttonNew' className='borderbtnCreateEdit'>
                                                <i className='icon material-symbols-outlined d-flex'>
                                                    video_call
                                                </i>
                                            </Button>
                                        </Form.Group>
                                    </div>
                                    <div style={{ overflowX: 'auto' }}>
                                        <table className='table'>
                                            <thead>
                                                <tr>
                                                    <th scope='col' className='text-center'>#</th>
                                                    <th scope='col' className='text-center'>Nome</th>
                                                    <th scope='col' className='text-center'>Data início</th>
                                                    <th scope='col' className='text-center'>Data fim</th>
                                                    <th scope='col' className='text-center'>Idioma</th>
                                                    <th scope='col' className='text-center'>Link</th>
                                                    <th scope='col' className='text-center'>Stream Key</th>
                                                    <th scope='col' className='text-center'>Fixa no feed</th>
                                                    <th scope='col' className='text-center'>Ativo</th>
                                                    <th scope='col' className='text-center'>Ações</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {filteredStreamings.map((streaming, index) => (
                                                    <tr key={index}>
                                                        <th scope='row' className='text-center'>{streaming.id}</th>
                                                        <td className='text-center'>{streaming.nome}</td>
                                                        <td className='text-center'>{formatDate(streaming.data_inicio)}</td>
                                                        <td className='text-center'>{formatDate(streaming.data_fim)}</td>
                                                        <td className='text-center'>{streaming.idioma}</td>
                                                        <td className='text-center'>
                                                            <Button onClick={() => handleCopy(streaming.rtmps_link, 'Link')}>
                                                                <i className='material-symbols-outlined d-flex'> content_copy </i>
                                                            </Button>
                                                        </td>
                                                        <td className='text-center'>
                                                            <Button onClick={() => handleCopy(streaming.stream_key, 'Stream key')}>
                                                                <i className='material-symbols-outlined d-flex'> content_copy </i>
                                                            </Button>
                                                        </td>
                                                        <td className='text-center'>{streaming.permanent ? 'Sim' : 'Não'}</td>
                                                        <td className='text-center'>{streaming.active ? 'Sim' : 'Não'}</td>
                                                        <td className='text-center'>
                                                            <Dropdown>
                                                                <Dropdown.Toggle
                                                                    className="btn btn-primary"
                                                                    data-bs-toggle="dropdown"
                                                                    aria-haspopup="true"
                                                                    aria-expanded="false"
                                                                    role="button"
                                                                >
                                                                    Opções
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu className="dropdown-menu m-0 p-0">
                                                                    <Dropdown.Item className="dropdown-item p-3" href="#" onClick={() => startLiveStream(streaming.id)}>
                                                                        <div className="d-flex align-items-top">
                                                                            <i className='material-symbols-outlined d-flex'> radio_button_checked </i>
                                                                            <div className="data ms-2">
                                                                                <p className="mb-0">
                                                                                    Iniciar evento
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </Dropdown.Item>

                                                                    <Dropdown.Item className="dropdown-item p-3" href="#" onClick={() => showEditStreamingPage(streaming.id)}>
                                                                        <div className="d-flex align-items-top">
                                                                            <i className='material-symbols-outlined d-flex'> mode_edit </i>
                                                                            <div className="data ms-2">
                                                                                <p className="mb-0">
                                                                                    Editar
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </Dropdown.Item>

                                                                    <Dropdown.Item className="dropdown-item p-3" href="#" onClick={() => downloadViewsReport(streaming.id)}>
                                                                        <div className="d-flex align-items-top">
                                                                            <i className='material-symbols-outlined d-flex'> download </i>
                                                                            <div className="data ms-2">
                                                                                <p className="mb-0">
                                                                                    Download Planilha
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </Dropdown.Item>

                                                                    <Dropdown.Item className="dropdown-item p-3" href="#" onClick={() => history(`/streaming/chart/${streaming.id}`)}>
                                                                        <div className="d-flex align-items-top">
                                                                            <i className='material-symbols-outlined d-flex'> monitoring </i>
                                                                            <div className="data ms-2">
                                                                                <p className="mb-0">
                                                                                    Gráfico
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </Dropdown.Item>

                                                                    <Dropdown.Item className="dropdown-item p-3" href="#" onClick={() => downloadChatReport(streaming.id)}>
                                                                        <div className="d-flex align-items-top">
                                                                            <i className='material-symbols-outlined d-flex'> chat </i>
                                                                            <div className="data ms-2">
                                                                                <p className="mb-0">
                                                                                    Relatório chat
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </Dropdown.Item>

                                                                    <Dropdown.Item className="dropdown-item p-3" href="#" onClick={() => showDeleteStreamingConfirmationModal(streaming.id, streaming.nome)}>
                                                                        <div className="d-flex align-items-top">
                                                                            <i className='material-symbols-outlined d-flex'> delete </i>
                                                                            <div className="data ms-2">
                                                                                <p className="mb-0">
                                                                                    Deletar
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </Dropdown.Item>

                                                                    <Dropdown.Item className="dropdown-item p-3" href="#" onClick={() => endLiveStream(streaming.id)}>
                                                                        <div className="d-flex align-items-top">
                                                                            <i className='material-symbols-outlined d-flex'> stop </i>
                                                                            <div className="data ms-2">
                                                                                <p className="mb-0">
                                                                                    Finalizar evento
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </Dropdown.Item>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </Card.Body>
                            </Card>
                            <Pagination
                                nPages={nPages}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                            />
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default StreamingsIndex