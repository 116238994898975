import React, { useState } from 'react'
import Modal from 'react-modal'

import api from '../services/api'

const ProfilePhotoModal = ({ isOpen, onClose, onSave, type, id }) => {
  const [selectedImage, setSelectedImage] = useState(null)

  const handleFileChange = (event) => {
    const file = event.target.files[0]
    if (file) {
      setSelectedImage(file)
    }
  }

  const handleSaveImage = async () => {
    if (selectedImage) {
      const formData = new FormData()
      formData.append('_method', 'PUT')
      if (type === 'profile') formData.append('photo', selectedImage)
      if (type === 'bg') formData.append('background', selectedImage)

      try {
        const response = await api.post(`/api/group/${id}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
  
        if (response.status === 201) {
          onSave(URL.createObjectURL(selectedImage))
          onClose()
          setSelectedImage(null)
        } else {
          console.error('Erro no upload da imagem')
        }
      } catch (error) {
        console.error('Erro ao enviar a imagem:', error)
      }
    }
  }
  

  const handleCancel = () => {
    onClose()
    setSelectedImage(null)
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleCancel}
      contentlabel="Selecionar Imagem"
      ariaHideApp={false}
      style={{
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          width: '400px',
          textAlign: 'center',
        },
      }}
    >
      <div>
        {selectedImage ? (
          <img
            src={URL.createObjectURL(selectedImage)}
            alt="Preview"
            style={{ maxWidth: '100%', maxHeight: '300px' }}
          />
        ) : (
          <input
            type="file"
            accept="image/*"
            onChange={handleFileChange}
          />
        )}
      </div>
      <div style={{ marginTop: '20px' }}>
        <button onClick={handleSaveImage} disabled={!selectedImage}>
          Salvar
        </button>
        <button onClick={handleCancel} style={{ marginLeft: '10px' }}>
          Cancelar
        </button>
      </div>
    </Modal>
  )
}

export default ProfilePhotoModal
