import React, { useEffect, useState, useMemo } from 'react'
import { Container, Row, Col, Card, Button, Modal, Form } from "react-bootstrap";
import { useNavigate } from 'react-router-dom'
import { Link } from "react-router-dom";
import api from '../../services/api'
import { toast } from 'react-toastify'
import AnimationWarningLottie from '../../components/AnimationWarningDeleteConfim/AnimationWarningLottie'
import { FaSearch } from 'react-icons/fa'


const KnowledgeIndex = () => {
    const history = useNavigate()

    const [knowledge, setKnowledge] = useState([])
    const [modalShow, setModalShow] = useState(false)
    const [knowIdToDelete, setKnowIdToDelete] = useState('')
    const [knowNameToDelete, setKnowNameToDelete] = useState('')
    const [search, setSearch] = useState('')
    const [currentPage, setCurrentPage] = useState(1)
    const [knowledgePerPage] = useState(10)
    const indexOfLastKnow = currentPage * knowledgePerPage
    const indexOfFirstKnow = indexOfLastKnow - knowledgePerPage
    const currentKnowledge = knowledge.slice(indexOfFirstKnow, indexOfLastKnow)
    const nPages = Math.ceil(knowledge.length / knowledgePerPage)


    const Pagination = ({ nPages, currentPage, setCurrentPage }) => {
        if (nPages <= 1) return null;

        const range = (start, end) => {
            return Array(end - start + 1)
                .fill()
                .map((_, idx) => start + idx);
        };

        let pageNumbers = [];
        if (currentPage <= 3) {
            pageNumbers = [...range(1, Math.min(5, nPages))];
            if (nPages > 5) pageNumbers.push('...', nPages);
        } else if (currentPage > 3 && currentPage < nPages - 2) {
            pageNumbers = [1, '...', ...range(currentPage - 1, currentPage + 1), '...', nPages];
        } else {
            pageNumbers = [1, '...'];
            pageNumbers.push(...range(nPages - 4, nPages));
        }

        return (

            <nav className='d-flex justify-content-center align-items-center'>
                <ul className="pagination mb-4">
                    <li className="page-item"><Link className="page-link" onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}>
                        <i className="icon material-symbols-outlined d-flex">
                            arrow_back
                        </i></Link></li>
                    {pageNumbers.map((number, index) => (
                        <li key={index} className={`page-item ${currentPage === number ? 'active' : ''}`}>
                            <Link className="page-link" onClick={() => typeof number === 'number' && setCurrentPage(number)}> {number}</Link></li>
                    ))}
                    <li className="page-item"><Link className="page-link" onClick={() => setCurrentPage(prev => Math.min(prev + 1, nPages))}>
                        <i className="icon material-symbols-outlined d-flex">
                            arrow_forward
                        </i></Link></li>
                </ul>
            </nav>
        );
    };



    const getKnowledges = async () => {

        try {
            const response = await api.post('/api/knowledge/index')
            setKnowledge(response.data.knowledge)

        } catch (error) {
            console.error('Erro')
            toast.error('Erro ao carregar dados.', { autoClose: 3000 });
        }

    }

    const showEditKnowledgePage = (knowId) => {
        history(`/knowledge/edit/${knowId}`)
    }

    const showCreateKnowledgePage = () => {
        history(`/knowledge/create`)
    }

    const showDeleteKnowConfirmationModal = (knowId, knowName) => {
        setModalShow(true)
        setKnowIdToDelete(knowId)
        setKnowNameToDelete(knowName)
    }

    const deleteKnowledge = async (knowId) => {
        await api.delete(`/api/knowledge/destroy/${knowId}`).then(() => {
            getKnowledges()
            setModalShow(false)
            toast.success('Biblioteca de conhecimento deletada com sucesso!', { autoClose: 3000 })
        })
    }

    function DeleteKnowledgeConfirmationModal(props) {

        return (
            <Modal
                {...props}
                aria-labelledby='contained-modal-title-vcenter'
                centered
            >
                <Modal.Header id='modalHeader' closeButton>
                    <div id='divModalTitle'>
                        <Modal.Title id='modalTitle'>
                            Exclusão de Biblioteca de Conhecimento
                        </Modal.Title>
                    </div>
                </Modal.Header>
                <Modal.Body id='modalBody'>
                    <div className="d-flex justify-content-center">
                        <AnimationWarningLottie />
                    </div>
                    <div className="d-flex justify-content-center">
                        <p>
                            Você tem certeza de que deseja remover a biblioteca "{props.knowname}" ?
                        </p>
                    </div>
                </Modal.Body>
                <Modal.Footer id='modalFooter'>
                    <Button id='yesButton' onClick={() => deleteKnowledge(props.knowid)}>Sim</Button>
                    <Button id='noButton' onClick={props.onHide}>Não</Button>
                </Modal.Footer>
            </Modal>
        )
    }

    useEffect(() => {
        getKnowledges()
    }, [])

    const filteredKnowledges = useMemo(() => {
        const lowerSearch = search.toLowerCase()
        if (search === '') {
            return currentKnowledge
        } else {
            return knowledge.filter((k) => k.title.toLowerCase().includes(lowerSearch))
        }
    }, [search, knowledge, currentKnowledge])

    return (
        <>
            <div id='content-page' className='content-inner'>
                <Container>
                    <DeleteKnowledgeConfirmationModal
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                        knowid={knowIdToDelete}
                        knowname={knowNameToDelete}
                    />
                    <Row>
                        <Col sm="12">
                            <Card
                                className="position-relative inner-page-bg bg-primary p-5"

                            >
                                <div className="inner-page-title">
                                    <h3 className="text-white">Conhecimentoteca</h3>
                                </div>
                            </Card>
                        </Col>
                        <Col sm="12" lg="12">
                            <Card>
                                <Card.Body className="pt-0">
                                    <div className="mb-4 mt-4">
                                        <Form.Group className="input-group">
                                            <span className="input-group-text" id="basic-addon1">
                                                <FaSearch />
                                            </span>
                                            <Form.Control
                                                placeholder='Pesquisar'
                                                aria-label='pesquisar'
                                                aria-describedby='search'
                                                onChange={(e) => setSearch(e.target.value)}
                                            />
                                            <Button onClick={() => showCreateKnowledgePage()} id='buttonNew' className='borderbtnCreateEdit d-flex align-items-center'>
                                                <i className="icon material-symbols-outlined d-flex">
                                                    school
                                                </i>
                                                <i className="icon material-symbols-outlined d-flex">
                                                    add
                                                </i>
                                            </Button>
                                        </Form.Group>
                                    </div>
                                    <table className="table d-table">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">Titulo</th>
                                                <th scope="col" className='d-flex justify-content-end'>Ações</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {filteredKnowledges.map((know, index) => (
                                                <tr key={index}>
                                                    <th scope="row">{know.id}</th>
                                                    <td>{know.title}</td>
                                                    <td className='d-flex justify-content-end'>
                                                        <Button style={{ marginRight: "5px" }} onClick={() => showEditKnowledgePage(know.id)} >
                                                            <i onClick={() => showEditKnowledgePage(know.id)} className="material-symbols-outlined d-flex"> mode_edit </i>
                                                        </Button>
                                                        <Button onClick={() => showDeleteKnowConfirmationModal(know.id, know.title)}>
                                                            <i className="material-symbols-outlined d-flex"> delete </i>
                                                        </Button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </Card.Body>
                            </Card>
                            <Pagination
                                nPages={nPages}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                            />
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default KnowledgeIndex