import React, { createContext, useState, useContext, useCallback } from 'react';
import api from '../services/api';
import Cookies from 'js-cookie'

const FriendRequestContext = createContext();

export const useFriendRequests = () => useContext(FriendRequestContext);

export const FriendRequestProvider = ({ children }) => {
    const [friendRequests, setFriendRequests] = useState([]);
    const loged_user = Cookies.get('userId');
    const token = Cookies.get('token');


    const fetchFriendRequests = useCallback(async (page = 1, limit = 10) => {
        try {
            const response = await api.post(`/api/friends/requestFriends`, {
                loged_user,
                page,
                limit
            });
            setFriendRequests(response.data);
        } catch (error) {
            console.error('Failed to fetch friend requests:', error);
        }
    }, [loged_user, token]);

    return (
        <FriendRequestContext.Provider value={{ friendRequests, fetchFriendRequests }}>
            {children}
        </FriendRequestContext.Provider>
    );
};
