import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { Container, Row, Col, Card, Button, Form, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import Cookies from 'js-cookie';
import api from '../../services/api';
import { toast } from 'react-toastify';
import AnimationWarningLottie from '../../components/AnimationWarningDeleteConfim/AnimationWarningLottie'
import AnimationWarningApproveLottie from '../../components/AnimationWarningApproveConfim/AnimationWarningApproveConfimLottie'
import { useFriendRequests } from '../../context/FriendRequestContext';
import loader from "../../assets/images/page-img/page-load-loader.gif";
import io from 'socket.io-client';

const FriendRequest = () => {
  const token = Cookies.get('token');
  const loged_user = Cookies.get('userId');
  const { friendRequests, fetchFriendRequests } = useFriendRequests();
  const [modalShow, setModalShow] = useState(false)
  const [modalAcceptShow, setModalAccpetShow] = useState(false)
  const [userIdToAction, setUserIdToAction] = useState('')
  const [userNameToAction, setUserNameToAction] = useState('')
  const [usersPerPage] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const nPages = Math.ceil(friendRequests.total / usersPerPage)
  const [isLoading, setIsLoading] = useState(false);

  const socket = io(process.env.REACT_APP_API_SOCKET_URL, {
    autoConnect: true,
    withCredentials: true,
  });

  useEffect(() => {
    setIsLoading(true);
    fetchFriendRequests(currentPage, usersPerPage).then(() => {
      setIsLoading(false);
    }).catch(() => {
      setIsLoading(false);
    });
  }, [currentPage, usersPerPage, fetchFriendRequests]);


  const handleFriendAction = async (friendId, event) => {
    event.preventDefault();
    try {
      await api.post(`/api/friends/accept`, { friendId, loged_user }, {
        headers: { Authorization: `Bearer ${token}` },
      }).then(() => {
        toast.success('Pedido de amizade aceito com sucesso!', { autoClose: 1000 });
        setModalAccpetShow(false)
        fetchFriendRequests()
      });
    } catch (error) {
      console.error('Falha ao adicionar amigo', error);
      toast.error('Falha ao adicionar amigo.');

    }
  };

  const showDeleteRequestConfirmationModal = (userId, userName) => {
    setModalShow(true)
    setUserIdToAction(userId)
    setUserNameToAction(userName)
  }

  const deleteRequest = async (friendId) => {
    try {
      await api.post(`/api/friends/blocked`, { friendId, loged_user }, {
        headers: { Authorization: `Bearer ${token}` },
      }).then(() => {
        toast.success('Pedido de amizade bloqueado com sucesso!', { autoClose: 1000 });
        fetchFriendRequests()
        setModalShow(false)
      });
    } catch (error) {
      console.error('Falha ao bloquear amigo', error);
      toast.error('Falha ao bloquear amigo.');

    }
  }

  function DeleteRequestConfirmationModal(props) {

    return (
      <Modal
        {...props}
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header id='modalHeader' closeButton>
          <div id='divModalTitle'>
            <Modal.Title id='modalTitle'>
              Recusar pedido de amizade
            </Modal.Title>
          </div>
        </Modal.Header>
        <Modal.Body id='modalBody'>
          <div className="d-flex justify-content-center m-4">
            <AnimationWarningLottie />
          </div>
          <div className="d-flex">
            <p>
              Você tem certeza de que deseja recusar o pedido de amizade de {props.username} ?
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer id='modalFooter'>
          <Button id='yesButton' onClick={() => deleteRequest(props.userid)}>Sim</Button>
          <Button id='noButton' onClick={props.onHide}>Não</Button>
        </Modal.Footer>
      </Modal>
    )
  }

  const showAcceptRequestConfirmationModal = (userId, userName, e) => {
    setModalAccpetShow(true)
    setUserIdToAction(userId)
    setUserNameToAction(userName)
  }


  function AcceptRequestConfirmationModal(props) {

    return (
      <Modal
        {...props}
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header id='modalHeader' closeButton>
          <div id='divModalTitle'>
            <Modal.Title id='modalTitle'>
              Aceitar pedido de amizade
            </Modal.Title>
          </div>
        </Modal.Header>
        <Modal.Body id='modalBody'>
          <div className="d-flex justify-content-center m-4">
            <AnimationWarningApproveLottie />
          </div>
          <div className="d-flex">
            <p>
              Deseja aceitar o pedido de amizade de {props.username} ?
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer id='modalFooter'>
          <Button id='yesButton' onClick={(e) => handleFriendAction(props.userid, e)}>Sim</Button>
          <Button id='noButton' onClick={props.onHide}>Não</Button>
        </Modal.Footer>
      </Modal>
    )
  }


  const Pagination = () => {
    const pageNumbers = [];
    if (nPages <= 1) return null;

    for (let i = 1; i <= Math.ceil(friendRequests.total / usersPerPage); i++) {
      pageNumbers.push(i);
    }

    return (

      <nav className='d-flex justify-content-center align-items-center'>
        <ul className="pagination mb-4">
          <li className="page-item"><Link className="page-link" onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}>
            <i className="icon material-symbols-outlined d-flex">
              arrow_back
            </i></Link></li>
          {pageNumbers.map((number, index) => (
            <li key={index} className={`page-item ${currentPage === number ? 'active' : ''}`}>
              <Link className="page-link" onClick={() => typeof number === 'number' && setCurrentPage(number)}> {number}</Link></li>
          ))}
          <li className="page-item"><Link className="page-link" onClick={() => setCurrentPage(prev => Math.min(prev + 1, nPages))}>
            <i className="icon material-symbols-outlined d-flex">
              arrow_forward
            </i></Link></li>
        </ul>
      </nav>
    );
  }

  return (
    <>
      <div id="content-page" className="content-inner">
        <Container>
          <Row>
            <Col sm="12">
              <Card>
                <Card.Header>
                  <div className="header-title">
                    <h4 className="card-title">Pedidos de amizade</h4>
                  </div>
                </Card.Header>
                <Card.Body className="pt-0">
                  {isLoading ? (
                    <div className="col-sm-12 text-center">
                      <img src={loader} alt="loader" style={{ height: "100px" }} />
                    </div>
                  ) : friendRequests.data && friendRequests.data.length > 0 ? (
                    <ul className="request-list list-inline m-0 p-0">
                      {friendRequests.data.map((friend, index) => (
                        <li className="d-flex align-items-center justify-content-between" key={index}>
                          <div className="d-flex align-items-center">
                            <div className="user-img img-fluid flex-shrink-0">
                              <img
                                src={friend.image_profile}
                                alt="story-img"
                                className="rounded-circle avatar-40"
                              />
                            </div>
                            <div className="flex-grow-1 ms-3">
                              <Link to={`/users/profile/${friend.user_id}`} >
                                {friend.name}
                              </Link>
                            </div>
                          </div>
                          <div className="d-flex align-items-center mt-2 mt-md-0">
                            <div className="confirm-click-btn">
                              <Button onClick={() => showAcceptRequestConfirmationModal(friend.id_user, friend.name)} className="me-2 btn btn-success-subtle rounded confirm-btn p-1 lh-1">
                                <i className="material-symbols-outlined font-size-14">
                                  done
                                </i>
                              </Button>
                            </div>
                            <div className="confirm-click-btn">
                              <Button onClick={() => showDeleteRequestConfirmationModal(friend.id_user, friend.name)} className="me-2 btn btn-danger-subtle rounded confirm-btn p-1 lh-1">
                                <i className="material-symbols-outlined font-size-14">
                                  close
                                </i>
                              </Button>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <div className="text-center">
                      <p>Não há solicitações de amizade no momento.</p>
                    </div>
                  )}
                  <Pagination
                    nPages={nPages}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                  />
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <DeleteRequestConfirmationModal
            show={modalShow}
            onHide={() => setModalShow(false)}
            userid={userIdToAction}
            username={userNameToAction}
          />

          <AcceptRequestConfirmationModal
            show={modalAcceptShow}
            onHide={() => setModalAccpetShow(false)}
            userid={userIdToAction}
            username={userNameToAction}
          />
        </Container>
      </div>
    </>
  );
};

export default FriendRequest;
