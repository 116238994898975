import React, { useState, useEffect, useRef, useCallback, useLayoutEffect } from 'react'
import api from '../../services/api'
import { Container, Row, Col, Card, Button } from 'react-bootstrap'
import CreatePost from '../../components/create-post'
import Post from '../../components/Post'
import LivePost from '../../components/LivePost'
import EventPost from '../../components/EventPost'
import QuizPost from '../../components/QuizPost'
import PollPost from '../../components/PollPost'
import { useParams, useNavigate } from 'react-router-dom'
import { useInView } from 'react-intersection-observer'
import { useSelector } from 'react-redux'
import './style-add.css'
import ProfilePhotoModal from '../../components/SendImage'
import GroupDeleteModal from '../../components/DeleteGroupModal'
import loader from '../../assets/images/page-img/page-load-loader.gif'
import img1 from '../../assets/images/page-img/gi-1.jpg'
import genericBackground from "../../assets/images/page-img/profile-bg1.jpg"
import './style-group-detail.css'

const GroupDetail = () => {
  let { id } = useParams()
  const cardRef = useRef(null)
  const navigate = useNavigate()
  const [group, setGroup] = useState([])
  const [groupJoined, setGroupJoined] = useState([])
  const [membersImageWithId, setMembersImageWithId] = useState([])
  const [membersNumber, setMembersNumber] = useState([])
  const handlePosts = useSelector((state) => state.setting.setting.handle_posts)
  const [isEditing, setIsEditing] = useState(false)
  const [groupType, setGroupType] = useState(group.type)
  const [groupName, setGroupName] = useState(group.name)
  const [description, setDescription] = useState(group.description)
  const [photoProfileSrc, setPhotoProfileSrc] = useState(null)
  const [photoBgSrc, setPhotoBgSrc] = useState(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalType, setModalType] = useState('')
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [posts, setPosts] = useState([])
  const [lives, setLives] = useState([])
  const [page, setPage] = useState(1)
  const [hasMore, setHasMore] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [isAdmin, setIsAdmin] = useState(false)
  const { ref, inView } = useInView({
    threshold: 0.1,
    triggerOnce: false,
    skip: !hasMore || isLoading
  })

  const getDetailsGroup = async () => {
    const response = await api.get(`/api/group/${id}/show`)
    setGroup(response.data.group)
    setGroupJoined(response.data.group_joined)
    setMembersImageWithId(response.data.image_members_with_id)
    setMembersNumber(response.data.members_number)
    setDescription(response.data.group.description)
    setGroupType(response.data.group.type)
    setPhotoProfileSrc(response.data.group.photo)
    setPhotoBgSrc(response.data.group.background)
    setGroupName(response.data.group.name)
    setIsAdmin(response.data.is_adm)
  }

  const leaveGroup = async (group_id) => {
    await api.post(`/api/group/leaveGroup/${group_id}`)
    getDetailsGroup()
  }

  const joinGroup = async (group_id) => {
    await api.post(`/api/group/joinGroup/${group_id}`)
    getDetailsGroup()
  }

  useEffect(() => {
    getDetailsGroup()
  }, [])

  const handleEditClick = () => {
    setIsEditing(true)
  }

  const handleSaveClick = async () => {
    const formData = new FormData()
    formData.append('description', description)
    formData.append('name', groupName)
    formData.append('type', groupType)

    setIsEditing(false)
    try {
      const response = await api.put(`/api/group/${id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      console.log(response.data)
    } catch (error) {
      console.error('Error creating group:', error)
    }
  }

  const handleCancelClick = () => {
    setIsEditing(false)
    setGroupType(group.type)
    setDescription(group.description)
  }

  const handleTypeChange = (e) => {
    setGroupType(e.target.value)
  }

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value)
  }

  const handleNameChange = (e) => {
    setGroupName(e.target.value)
  }

  const coverContainerRef = useRef(null);
  const profileImageRef = useRef(null);

  const handleClickOutside = (event) => {
    if (
      coverContainerRef.current &&
      profileImageRef.current &&
      !coverContainerRef.current.contains(event.target) &&
      !profileImageRef.current.contains(event.target)
    ) {
      setIsEditing(false);
    }
  };

  useEffect(() => {
    if (isEditing) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isEditing, isModalOpen])

  const handleSendBgImg = () => {
    if (isEditing) {
      setModalType('bg');
      setIsModalOpen(true);
    }
  };

  const handleSendProfileImg = () => {
    if (isEditing) {
      setModalType('profile');
      setIsModalOpen(true);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false)
  }

  const handleSaveProfileImg = (newImage) => {
    if (modalType === 'profile') {
      setPhotoProfileSrc(newImage)
    }
    if (modalType === 'bg') {
      setPhotoBgSrc(newImage)
    }
  }

  const handleSaveBgImg = (newImage) => {
    if (modalType === 'profile') {
      setPhotoProfileSrc(newImage)
    }
    if (modalType === 'bg') {
      setPhotoBgSrc(newImage)
    }
    setIsModalOpen(false);
  }

  const handleOpenDeleteModal = () => {
    setIsDeleteModalOpen(true)
  }

  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false)
  }

  const loadingRef = useRef(false)

  const fetchPostsData = useCallback(async (resetPage = false) => {
    if (loadingRef.current) return
    loadingRef.current = true
    setIsLoading(true)
    const currentPage = resetPage ? 1 : page
    try {
      const response = await api.post(`/api/postsListGroup/${id}`, {
        page: currentPage,
        limit: 2
      })
      const data = response.data
      if (data.length === 0) {
        setHasMore(false)
        return
      }
      if (resetPage) {
        setPosts(data)
        setPage(2)
      } else {
        setPosts(prev => {
          const newPosts = data.filter(post => !prev.some(prevPost => prevPost.id === post.id))
          return [...prev, ...newPosts]
        })
        setPage(currentPage + 1)
      }
    } catch (error) {
      console.error('Failed to fetch posts data:', error)
    } finally {
      setIsLoading(false)
      loadingRef.current = false
    }
  }, [page])

  useEffect(() => {
    fetchPostsData(true)
  }, [])

  useEffect(() => {
    fetchPostsData(true)
  }, [handlePosts])

  useLayoutEffect(() => {
    if (inView && hasMore && !loadingRef.current) {
      fetchPostsData(false)
    }
  }, [inView, hasMore])

  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      const [entry] = entries
      if (entry.isIntersecting && hasMore && !loadingRef.current) {
        fetchPostsData(false)
      }
    }, { threshold: 0.1 })

    if (ref.current) {
      observer.observe(ref.current)
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current)
      }
    }
  }, [hasMore, fetchPostsData])

  useEffect(() => {
    let cancel = false

    async function fetchData() {
      try {
        const response = await api.get(`/api/livePostsByGroup/${id}`)

        if (!cancel) {
          const data = response.data
          setLives(data.posts)
        }
      } catch (error) {
        console.error('Failed to fetch live posts:', error)
      }
    }

    fetchData()

    return () => {
      cancel = true
    }
  }, [])

  return (
    <>
      <div id='content-page' className='content-inner'>
        <Container>
          <Row>
            <Col sm='12'>
              <div className='profile-header profile-info'>
                <div className='cover-container'>
                  <div className='d-flex align-items-center justify-content-between mb-3 flex-wrap'
                    onClick={handleSendBgImg}
                    style={{ cursor: isEditing ? 'pointer' : 'default' }}
                  >
                    <img
                      loading='lazy'
                      src={photoBgSrc ?? genericBackground}
                      alt='profile-bg'
                      className='rounded'
                      width={'100%'}
                      height={'300vh'}
                    />
                  </div>
                  <ProfilePhotoModal
                    isOpen={isModalOpen}
                    onClose={handleCloseModal}
                    onSave={handleSaveBgImg}
                    type={modalType}
                    id={id}
                  />
                </div>
              </div>
              <div className='d-flex align-items-center justify-content-between mb-3 flex-wrap'>
                <div className='group-info d-flex align-items-center'>
                  <div className='me-3'>
                    <img
                      className='rounded-circle img-fluid avatar-100'
                      src={photoProfileSrc ?? img1}
                      alt=''
                      onClick={handleSendProfileImg}
                      style={{ cursor: isEditing ? 'pointer' : 'default' }}
                      width={'100%'}
                      height={'250vh'}
                    />

                    <ProfilePhotoModal
                      isOpen={isModalOpen}
                      onClose={handleCloseModal}
                      onSave={handleSaveBgImg}
                      type={modalType}
                      id={id}
                    />

                  </div>
                  <div className='info'>
                    <h4>{groupName}</h4>
                    {
                      isAdmin ? (
                        <p className='mb-0' onClick={() => navigate(`/groups/members/${id}`)} style={{ 'cursor': 'pointer' }}>
                          <i className='ri-lock-fill pe-2'></i>{membersNumber === 1 ? membersNumber + ' Membro' : membersNumber + ' Membros'}
                        </p>
                      ) : (
                        <p className='mb-0'>
                          <i className='ri-lock-fill pe-2'></i>{membersNumber === 1 ? membersNumber + ' Membro' : membersNumber + ' Membros'}
                        </p>
                      )
                    }
                  </div>
                </div>
                <div
                  mt-md='0'
                  mt='2'
                  className='group-member d-flex align-items-center div-buttons-group'
                >
                  {
                    isAdmin ? (
                      <Button onClick={() => navigate(`/groups/membersRequests/${id}`)} variant='primary' className='mb-2 mr-5' style={{ 'marginRight': '5px' }}>
                        <i className='ri-add-line me-1'></i>Solicitações
                      </Button>
                    ) : ''
                  }
                  {groupJoined ?
                    <button
                      onClick={() => leaveGroup(id)}
                      className='btn btn-danger-subtle fw-semibold ml-2 mb-2'
                      style={{ 'marginRight': '5px' }}
                    >
                      Sair
                    </button>
                    :
                    <button
                      onClick={() => joinGroup(id)}
                      className='btn btn-primary-subtle fw-semibold ml-2 mb-2'
                    >
                      Entrar {id === groupJoined}
                    </button>
                  }
                  <div>
                    {
                      isAdmin ? (
                        <button onClick={handleOpenDeleteModal} className='btn btn-primary-subtle fw-semibold ml-2 mb-2' style={{ backgroundColor: 'red', color: 'white' }}>
                          Excluir grupo
                        </button>
                      ) : ''
                    }

                    <GroupDeleteModal
                      isOpen={isDeleteModalOpen}
                      onClose={handleCloseDeleteModal}
                      groupId={group?.id}
                    />
                  </div>
                </div>
              </div>
            </Col>

            <Col lg='8'>
              <CreatePost group_id={id} />

              {
                lives.map(live => (
                  <LivePost key={`live_post_${live.id}`} event_id={live.event_id} user_name={live.user_name} user_image={live.user_image} live_iframe={live.live_iframe} live_name={live.live_name} post_time={live.created_at} privacy={live.privacy} post_id={live.id} user_reaction={live.user_reaction} reactions={live.reaction_counter} share_id={live.share_id} share_posts={live.sharePosts} is_live={true} />
                ))
              }

              {
                posts.map((post, index) => (
                  <div ref={index === posts.length - 1 ? ref : null} key={`post_${post.id}`}>
                    {
                      post.live_id && !post.event_id ? (
                        <LivePost user_name={post.user_name} user_image={post.user_image} live_iframe={post.live_iframe} live_name={post.live_name} post_time={post.created_at} privacy={post.privacy} post_id={post.id} user_reaction={post.user_reaction} reactions={post.reaction_counter} share_id={post.share_id} share_posts={post.sharePosts} />
                      ) : post.event_id ? (
                        <EventPost event_id={post.event_id} event_place={post.event_place} event_external_link={post.event_external_link} event_start_date={post.event_start_date} event_end_date={post.event_end_date} event_name={post.event_name} user_id={post.user_id} post_id={post.id} user_name={post.user_name} user_image={post.user_image} type={post.event_image ? 'image' : 'text'} media={post.event_image} post_time={post.created_at} text={post.event_description} privacy={post.privacy} group_id={post.group_id} is_admin={false} user_reaction={post.user_reaction} reactions={post.reaction_counter} share_id={post.share_id} share_posts={post.sharePosts} />
                      ) : post.quiz_id ? (
                        <QuizPost user_answer={post.user_answer} user_id={post.user_id} post_id={post.id} user_name={post.user_name} user_image={post.user_image} type={post.image ? 'image' : post.video ? 'video' : 'text'} media={post.image ?? post.video} post_time={post.created_at} text={post.text} privacy={post.privacy} group_id={post.group_id} is_admin={false} user_reaction={post.user_reaction} reactions={post.reaction_counter} share_id={post.share_id} share_posts={post.sharePosts} view_count={post.view_count} options={post.quiz_options} right_answer={post.quiz_right_answer} />
                      ) : post.poll_id ? (
                        <PollPost poll_id={post.poll_id} group_name={post.group_name} user_id={post.user_id} post_id={post.id} user_name={post.user_name} user_image={post.user_image} type={post.image ? 'image' : post.video ? 'video' : 'text'} media={post.image ?? post.video} post_time={post.created_at} text={post.text} privacy={post.privacy} group_id={post.group_id} is_admin={false} user_reaction={post.user_reaction} reactions={post.reaction_counter} share_id={post.share_id} share_posts={post.sharePosts} view_count={post.view_count} />
                      ) : (
                        <Post user_id={post.user_id} post_id={post.id} user_name={post.user_name} user_image={post.user_image} type={post.image ? 'image' : post.video ? 'video' : 'text'} media={post.image ?? post.video} post_time={post.created_at} text={post.text} privacy={post.privacy} group_id={post.group_id} is_admin={false} user_reaction={post.user_reaction} reactions={post.reaction_counter} share_id={post.share_id} share_posts={post.sharePosts} view_count={post.view_count} />
                      )
                    }
                  </div>
                ))
              }

              {isLoading && <div className='col-sm-12 text-center'>
                <img src={loader} alt='loader' style={{ height: '100px' }} />
              </div>}
            </Col>

            <Col lg='4'>
              <Card ref={cardRef}>
                <Card.Header className='card-header d-flex justify-content-between'>
                  <div className='header-title'>
                    <h4 className='card-title'>Sobre</h4>
                  </div>
                  {!isEditing && isAdmin && (
                    <button className='btn btn-link' onClick={handleEditClick}>
                      Editar
                    </button>
                  )}
                </Card.Header>
                <Card.Body>
                  <ul className='list-inline p-0 m-0'>
                    <li className='mb-3'>
                      {isEditing ? (
                        <>
                          <label>Nome do Grupo</label>
                          <textarea
                            className='form-control'
                            value={groupName}
                            onChange={handleNameChange}
                            placeholder='Nome do grupo'
                          />

                          <label>Descrição de Grupo</label>
                          <textarea
                            className='form-control'
                            value={description}
                            onChange={handleDescriptionChange}
                            placeholder='Digite a descrição do grupo'
                          />
                        </>
                      ) : (
                        <p className='mb-0'>{description}</p>
                      )}
                    </li>

                    <li className='mb-3'>
                      {isEditing ? (
                        <div className='d-flex flex-column'>
                          <label htmlFor='groupType'>Tipo de Grupo</label>
                          <select
                            id='groupType'
                            value={groupType}
                            onChange={handleTypeChange}
                            className='form-control'
                          >
                            <option value='1'>
                              Privado (Somente membros podem ver quem está no grupo e o que é publicado nele.)
                            </option>
                            <option value='2'>
                              Público (Qualquer pessoa pode ver quem está no grupo e o que é publicado nele.)
                            </option>
                          </select>
                        </div>
                      ) : groupType === '1' ? (
                        <div className='d-flex'>
                          <div className='flex-shrink-0'>
                            <i className='material-symbols-outlined'>lock</i>
                          </div>
                          <div className='flex-grow-1 ms-3'>
                            <h6>Privado</h6>
                            <p className='mb-0'>
                              Somente membros podem ver quem está no grupo e o que é publicado nele.
                            </p>
                          </div>
                        </div>
                      ) : (
                        <div className='d-flex'>
                          <div className='flex-shrink-0'>
                            <i className='material-symbols-outlined'>public</i>
                          </div>
                          <div className='flex-grow-1 ms-3'>
                            <h6>Público</h6>
                            <p className='mb-0'>
                              Qualquer pessoa pode ver quem está no grupo e o que é publicado nele.
                            </p>
                          </div>
                        </div>
                      )}
                    </li>

                    <li className='mb-3'>
                      <div className='d-flex'>
                        <div className='flex-shrink-0'>
                          <i className='material-symbols-outlined'>visibility</i>
                        </div>
                        <div className='flex-grow-1 ms-3'>
                          <h6>Visível</h6>
                          <p className='mb-0'>Qualquer pessoa pode encontrar o grupo.</p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </Card.Body>
                {isEditing && (
                  <Card.Footer>
                    <div className='d-flex justify-content-end'>
                      <button className='btn btn-secondary mr-2' onClick={handleCancelClick}>
                        Cancelar
                      </button>
                      <button className='btn btn-primary' onClick={handleSaveClick}>
                        Salvar
                      </button>
                    </div>
                  </Card.Footer>
                )}
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default GroupDetail
