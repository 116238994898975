import React, { useEffect, useState, useMemo } from 'react'
import { Container, Row, Col, Card, Button, Modal, Form } from "react-bootstrap";
import { useNavigate } from 'react-router-dom'
import { Link } from "react-router-dom";
import api from '../../services/api'
import { toast } from 'react-toastify'
import AnimationWarningLottie from '../../components/AnimationWarningDeleteConfim/AnimationWarningLottie'
import { FaSearch } from 'react-icons/fa'

const QuizIndex = () => {
    const history = useNavigate()
    const [quiz, setQuiz] = useState([])
    const [modalShow, setModalShow] = useState(false)
    const [quizIdToDelete, setQuizIdToDelete] = useState('')
    const [quizNameToDelete, setQuizNameToDelete] = useState('')
    const [search, setSearch] = useState('')
    const [currentPage, setCurrentPage] = useState(1)
    const [quizPerPage] = useState(10)
    const indexOfLastQuiz = currentPage * quizPerPage
    const indexOfFirstQuiz = indexOfLastQuiz - quizPerPage
    const currentQuiz = quiz.slice(indexOfFirstQuiz, indexOfLastQuiz)
    const nPages = Math.ceil(quiz.length / quizPerPage)

    const Pagination = ({ nPages, currentPage, setCurrentPage }) => {
        if (nPages <= 1) return null;

        const range = (start, end) => {
            return Array(end - start + 1)
                .fill()
                .map((_, idx) => start + idx);
        };

        let pageNumbers = [];
        if (currentPage <= 3) {
            pageNumbers = [...range(1, Math.min(5, nPages))];
            if (nPages > 5) pageNumbers.push('...', nPages);
        } else if (currentPage > 3 && currentPage < nPages - 2) {
            pageNumbers = [1, '...', ...range(currentPage - 1, currentPage + 1), '...', nPages];
        } else {
            pageNumbers = [1, '...'];
            pageNumbers.push(...range(nPages - 4, nPages));
        }

        return (
            <nav className='d-flex justify-content-center align-items-center'>
                <ul className="pagination mb-4">
                    <li className="page-item"><Link className="page-link" onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}>
                        <i className="icon material-symbols-outlined d-flex">
                            arrow_back
                        </i></Link></li>
                    {pageNumbers.map((number, index) => (
                        <li key={index} className={`page-item ${currentPage === number ? 'active' : ''}`}>
                            <Link className="page-link" onClick={() => typeof number === 'number' && setCurrentPage(number)}> {number}</Link></li>
                    ))}
                    <li className="page-item"><Link className="page-link" onClick={() => setCurrentPage(prev => Math.min(prev + 1, nPages))}>
                        <i className="icon material-symbols-outlined d-flex">
                            arrow_forward
                        </i></Link></li>
                </ul>
            </nav>
        );
    };

    const getQuiz = async () => {

        try {
            const response = await api.get('/api/quiz/index')
            setQuiz(response.data.quizzes)

        } catch (error) {
            console.error('Erro')
            toast.error('Erro ao carregar dados.', { autoClose: 3000 });
        }

    }

    const showEditQuizPage = (quizId) => {
        history(`/quiz/edit/${quizId}`)
    }

    const showCreateQuizPage = () => {
        history(`/quiz/create`)
    }

    const showDeleteQuizConfirmationModal = (quizId, quizName) => {
        setModalShow(true)
        setQuizIdToDelete(quizId)
        setQuizNameToDelete(quizName)
    }

    const deleteQuiz = async (quizId) => {
        await api.delete(`/api/quiz/destroy/${quizId}`).then(() => {
            getQuiz()
            setModalShow(false)
            toast.success('Quiz deletado com sucesso!', { autoClose: 3000 })
        })
    }

    const downloadQuizReport = async (quiz_id) => {
        try {
            const response = await api({
                url: `/api/quizReport`,
                method: 'post',
                data: { quiz_id },
                responseType: 'blob',
            })
    
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `quiz_${quiz_id}_report.xlsx`)
            document.body.appendChild(link)
            link.click()
            link.remove()
            
            toast.success('Relatório gerado com sucesso', { autoClose: 1000 })
        } catch (err) {
            toast.error('Erro ao gerar relatório', { autoClose: 1000 })
            console.error(err)
        }
    }

    function DeleteQuizConfirmationModal(props) {
        return (
            <Modal
                {...props}
                aria-labelledby='contained-modal-title-vcenter'
                centered
            >
                <Modal.Header id='modalHeader' closeButton>
                    <div id='divModalTitle'>
                        <Modal.Title id='modalTitle'>
                            Exclusão de Quiz
                        </Modal.Title>
                    </div>
                </Modal.Header>
                <Modal.Body id='modalBody'>
                    <div className="d-flex justify-content-center">
                        <AnimationWarningLottie />
                    </div>
                    <div className="d-flex justify-content-center">
                        <p>
                            Você tem certeza de que deseja remover o quiz "{props.quizname}" ?
                        </p>
                    </div>
                </Modal.Body>
                <Modal.Footer id='modalFooter'>
                    <Button id='yesButton' onClick={() => deleteQuiz(props.quizid)}>Sim</Button>
                    <Button id='noButton' onClick={props.onHide}>Não</Button>
                </Modal.Footer>
            </Modal>
        )
    }

    useEffect(() => {
        getQuiz()
    }, [])

    const filteredQuiz = useMemo(() => {
        const lowerSearch = search.toLowerCase()

        if (search === '') {
            return currentQuiz
        } else {
            return quiz.filter((quiz) => quiz.question.toLowerCase().includes(lowerSearch) || quiz.id.toString().toLowerCase().includes(lowerSearch))
        }
    }, [search, quiz, currentQuiz])

    return (
        <>
            <div id='content-page' className='content-inner'>
                <Container>
                    <DeleteQuizConfirmationModal
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                        quizid={quizIdToDelete}
                        quizname={quizNameToDelete}
                    />
            
                    <Row>
                        <Col sm="12">
                            <Card
                                className="position-relative inner-page-bg bg-primary p-5"

                            >
                                <div className="inner-page-title">
                                    <h3 className="text-white">Quiz</h3>
                                </div>
                            </Card>
                        </Col>
                
                        <Col sm="12" lg="12">
                            <Card>
                                <Card.Body className="pt-0">
                                    <div className="mb-4 mt-4">
                                        <Form.Group className="input-group">
                                            <span className="input-group-text" id="basic-addon1">
                                                <FaSearch />
                                            </span>
                                            <Form.Control
                                                placeholder='Pesquisar'
                                                aria-label='pesquisar'
                                                aria-describedby='search'
                                                onChange={(e) => setSearch(e.target.value)}
                                            />
                                            <Button onClick={() => showCreateQuizPage()} id='buttonNew' className='borderbtnCreateEdit'>
                                                <i className="icon material-symbols-outlined d-flex">
                                                    person_add
                                                </i>
                                            </Button>
                                        </Form.Group>
                                    </div>
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">Pergunta</th>
                                                <th scope="col">Ativo</th>
                                                <th scope="col">Ações</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {filteredQuiz.map((quiz, index) => (
                                                <tr key={index}>
                                                    <th scope="row">{quiz.id}</th>
                                                    <td>{quiz.question}</td>
                                                    <td>{quiz.active ? 'Sim' : 'Não'}</td>
                                                    <td>
                                                        <Button style={{ marginRight: "5px" }} onClick={() => history(`/quiz/chart/${quiz.id}`)}>
                                                            <i className='material-symbols-outlined d-flex'> monitoring </i>
                                                        </Button>
                                                        <Button style={{ marginRight: "5px" }} onClick={() => downloadQuizReport(quiz.id)}>
                                                            <i className='material-symbols-outlined d-flex'> download </i>
                                                        </Button>
                                                        <Button style={{ marginRight: "5px" }} onClick={() => showEditQuizPage(quiz.id)}>
                                                            <i className="material-symbols-outlined d-flex"> mode_edit </i>
                                                        </Button>
                                                        <Button onClick={() => showDeleteQuizConfirmationModal(quiz.id, quiz.id)}>
                                                            <i className="material-symbols-outlined d-flex"> delete </i>
                                                        </Button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </Card.Body>
                            </Card>
                            <Pagination
                                nPages={nPages}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                            />
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default QuizIndex