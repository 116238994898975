import React, { useEffect, useState, useMemo } from 'react'
import { Container, Row, Col, Card, Button, Modal, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import api from '../../services/api'
import { toast } from 'react-toastify'
import loader from "../../assets/images/page-img/page-load-loader.gif";

const KnowledgeIndex = () => {

    const [knowledge, setKnowledge] = useState([])
    const [isLoading, setIsLoading] = useState(false);

    const getKnowledges = async () => {

        try {
            const response = await api.post('/api/knowledge/index')
            setKnowledge(response.data.knowledge)

        } catch (error) {
            console.error('Erro')
            toast.error('Erro ao carregar dados.', { autoClose: 3000 });
        }

    }

    useEffect(() => {
        setIsLoading(true);
        getKnowledges().then(() => {
            setIsLoading(false);
        }).catch(() => {
            setIsLoading(false);
        });
    }, []);

    return (
        <>
            <div id='content-page' className='content-inner'>
                <Container>
                    <Row>
                        <Col sm="12" lg="12">
                            <Card>
                            <Card.Header className="d-flex justify-content-between">
                                    <h4 className="card-title">Conhecimentoteca</h4>
                                    <Link to='/knowledge/index'>
                                        <Button><i className="icon material-symbols-outlined d-flex">
                                        settings
                                        </i></Button>
                                    </Link>
                                </Card.Header>
                                <Card.Body className="pt-0">
                                    {isLoading ? (
                                        <div className="col-sm-12 text-center">
                                            <img src={loader} alt="loader" style={{ height: "100px" }} />
                                        </div>
                                    ) : knowledge && knowledge.length > 0 ? (
                                        <ul className="request-list list-inline m-0 p-0">
                                            {knowledge.map((know, index) => (
                                                <li className="d-flex align-items-center justify-content-between" key={index}>
                                                    <div className="d-flex align-items-center">
                                                        <div className="flex-grow-1 ms-3">
                                                            <Link to={`/knowledge/show/${know.id}`} >
                                                                {know.title}
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </li>
                                            ))}
                                        </ul>
                                    ) : (
                                        <div className="text-center">
                                            <p>Não há biblioteca de conhecimento no momento.</p>
                                        </div>
                                    )}
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default KnowledgeIndex