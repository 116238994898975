import React, { useState, useRef, useEffect } from 'react'
import { Row, Col, Form, Container, Button, Card, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom'
import api from '../../services/api'
import { toast } from 'react-toastify'
import Cookies from 'js-cookie'

const QuizCreate = () => {
    const userId = Cookies.get('userId')
    const [validated, setValidated] = useState(false)
    const customId = 'success-toast-id'
    const history = useNavigate()
    const [question, setQuestion] = useState('')
    const [options, setOptions] = useState([])
    const [image, setImage] = useState(null)
    const [imageSrc, setImageSrc] = useState('')
    const [selectedOption, setSelectedOption] = useState('')
    const fileInputRef = useRef(null)
    const [fixed, setFixed] = useState(false)
    const [fixedLimitDate, setFixedLimitDate] = useState('')
    const [fixedLimitTime, setFixedLimitTime] = useState('')
    const [showSpinner, setShowSpinner] = useState(false)
    const [groups, setGroups] = useState([])
    const [selectedGroup, setSelectedGroup] = useState('')

    const handleButtonClick = () => {
        fileInputRef.current.click()
    }

    const removeLastOption = (index) => {
        setOptions((prevOptions) => prevOptions.filter((_, i) => i !== index))
        setSelectedOption('')
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        setShowSpinner(true)
    
        if (question === '') {
            setShowSpinner(false)

            toast.error('Por favor preencha a pergunta.', { autoClose: 3000 })
        } else if (selectedOption === '') {
            setShowSpinner(false)

            toast.error('Por favor selecione a resposta correta da pergunta.', { autoClose: 3000 })
        } else if (fixed && fixedLimitDate === '') {
            setShowSpinner(false)

            toast.error('Por favor selecione a data limite pra manter o quiz fixado no feed.', { autoClose: 3000 })
        } else if (fixed && fixedLimitTime === '') {
            setShowSpinner(false)

            toast.error('Por favor selecione a hora limite pra manter o quiz fixado no feed.', { autoClose: 3000 })
        } else {
            setValidated(true)
    
            const formData = new FormData()
            formData.append('question', question)
            formData.append('right_answer', selectedOption)
            formData.append('fixed', fixed)
            formData.append('fixed_limit_date', fixedLimitDate)
            formData.append('fixed_limit_time', fixedLimitTime)
            formData.append('group_id', selectedGroup && selectedGroup !== 'Sem grupo' ? selectedGroup : null)
    
            options.forEach(option => {
                formData.append('options[]', option)
            })
    
            if (image) formData.append('image', image)
    
            await api.post('/api/quiz/store', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }).then(() => {
                setShowSpinner(false)

                toast.success('Quiz criado com sucesso!', {
                    toastId: customId,
                    autoClose: 300,
                    onClose: () => {
                        history('/quiz/index')
                    }
                })
            })
        }
    }    

    const handleFileChange = (event) => {
        const file = event.target.files[0]
        if (file) {
            setImage(file)
            const reader = new FileReader()
            reader.onloadend = () => {
                setImageSrc(reader.result)
            }
            reader.readAsDataURL(file)
        }
    }

    const handleOptionChange = (value, index) => {
        setOptions((prevOptions) => {
            const newOptions = [...prevOptions]
            newOptions[index] = value
            return newOptions
        })

        setSelectedOption('')
    }

    useEffect(() => {
        let cancel = false

        async function fetchData() {
            try {
            const response = await api.get(`/api/groupsByUser/${userId}`)

            if (!cancel) {
                const data = response.data
                setGroups(data.groups)
            }
            } catch (error) {
                console.error('Failed to fetch user groups:', error)
                toast.error('Erro ao recuperar grupos', { autoClose: 3000 })
            }
        }

        fetchData()

        return () => {
            cancel = true
        }
    }, [])

    return (
        <>
            <div id='content-page' className='content-inner'>
                <Container>
                    <Row>
                        <Col sm='12'>
                            <Card
                                className='position-relative inner-page-bg bg-primary p-5'
                            >
                                <div className='inner-page-title' style={{ display: 'flex', justifyContent: 'space-between', width: '-webkit-fill-available' }}>
                                    <h3 className='text-white'>Quiz</h3>
                                    <Link to='/quiz/index'>
                                        <Button><i className='icon material-symbols-outlined d-flex'>
                                            arrow_back
                                        </i></Button>
                                    </Link>
                                </div>
                            </Card>
                        </Col>
                        <Col sm='12' lg='12'>
                            <Col sm='12' lg='12'>
                                <Card>
                                    <Card.Header className='d-flex justify-content-between'>
                                        <div className='header-title'>
                                            <h4 className='card-title'>Criar quiz</h4>
                                        </div>
                                    </Card.Header>
                                    <Card.Body className='pt-0'>
                                        <Form noValidate onSubmit={handleSubmit} validated={validated} className='row g-3'>
                                            <Col md='6' className=''>
                                                <Form.Label
                                                    className='form-label'
                                                    htmlFor='validationCustom01'
                                                >
                                                    Pergunta
                                                </Form.Label>
                                                <Form.Control className='input-group-edit' type='text' placeholder='Pergunta' value={question} onChange={(e) => setQuestion(e.target.value)} aria-describedby='inputGroupPrepend' id='validationCustom01' required />
                                                <Form.Control.Feedback>
                                                    Correto!
                                                </Form.Control.Feedback>
                                                <Form.Control.Feedback type='invalid'>
                                                    Por favor preencha a pergunta corretamente.
                                                </Form.Control.Feedback>
                                            </Col>

                                            <Col md='6'>
                                                <div className='w-100 h-100 d-flex align-items-end'>
                                                    <OverlayTrigger
                                                        placement='top'
                                                        overlay={<Tooltip id='tooltip-top'>Adicionar imagem para o quiz</Tooltip>}
                                                    >
                                                        <Button
                                                            variant='outline-primary'
                                                            id='button-addon2'
                                                            className='d-flex align-items-center justify-content-center position-absolute button-position-img'
                                                            style={{ height: '2.8rem' }}
                                                        >
                                                            <span onClick={handleButtonClick} className='material-symbols-outlined'>add_a_photo</span>
                                                            <input
                                                                ref={fileInputRef}
                                                                className='file-upload'
                                                                onChange={handleFileChange}
                                                                type='file'
                                                                accept='image/*'
                                                            />
                                                        </Button>
                                                    </OverlayTrigger>

                                                    {imageSrc && (
                                                        <div className='image-preview'>
                                                            <img
                                                                src={imageSrc}
                                                                alt='Preview'
                                                                style={{ maxWidth: '100%', borderRadius: '6px' }}
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                            </Col>
                        
                                            <div className='col-12'>
                                                <Button type='button' variant='success' onClick={() => setOptions([...options, ''])}>Nova opção 
                                                    <span className='material-symbols-outlined' style={{ 'fontSize': 'large', 'verticalAlign': 'middle' }}>
                                                        add
                                                    </span>
                                                </Button>
                                            </div>

                                            {
                                                options.map((option, index) => (
                                                    <Col md='6' key={`option_${index}`}>
                                                        <Form.Label
                                                            className='form-label'
                                                        >
                                                            {`Opção ${index + 1}`}
                                                        </Form.Label>
                                                        
                                                        <div className='d-flex'>
                                                            <Form.Control 
                                                                className='input-group-edit' 
                                                                type='text' 
                                                                placeholder='Opção' 
                                                                value={option}
                                                                onChange={(e) => handleOptionChange(e.target.value, index)} 
                                                                style={{ marginRight: '5px' }} 
                                                            />

                                                            <Button type='button' variant='danger' onClick={() => removeLastOption(index)}> 
                                                                <span className='material-symbols-outlined' style={{ 'fontSize': 'large', 'verticalAlign': 'middle' }}>
                                                                    delete
                                                                </span>
                                                            </Button>
                                                        </div>
                                                    </Col>
                                                ))
                                            }

                                            <Col md='6'>
                                                <label htmlFor='right-answer' className='form-label'>Resposta certa</label>
                                                <select
                                                    id='right-answer'
                                                    value={selectedOption}
                                                    onChange={(e) => setSelectedOption(e.target.value)}
                                                    className='form-control'
                                                >
                                                    <option value=''>
                                                        Selecione...
                                                    </option>
                                                    {
                                                        options.map((option, index) => (
                                                            <option value={option} key={`right_answer_option_${index}`}>
                                                                {`Opção ${index + 1}`}
                                                            </option>
                                                        ))
                                                    }
                                                </select>
                                            </Col>

                                            <Col md='6'>
                                                <label htmlFor='groups' className='form-label'>Grupo</label>
                                                <select
                                                    id='groups'
                                                    value={selectedGroup}
                                                    onChange={(e) => setSelectedGroup(e.target.value)}
                                                    className='form-control'
                                                >
                                                    {
                                                        groups.map((group, index) => (
                                                            <option value={group.value} key={`group_${index}`}>
                                                                {group.label}
                                                            </option>
                                                        ))
                                                    }
                                                </select>
                                            </Col>

                                            <Col md='12' className=''>
                                                <Form.Check
                                                    type="switch"
                                                    label="Fixar no feed"
                                                    checked={fixed}
                                                    onChange={(e) => setFixed(e.target.checked)}
                                                    required
                                                />
                                            </Col>

                                            {
                                                fixed ? (
                                                    <>
                                                        <Col md='6' className=''>
                                                            <Form.Label
                                                                className='form-label'
                                                            >
                                                                Data limite
                                                            </Form.Label>
                                                            <Form.Control className='input-group-edit' placeholder='Data limite' type='date' value={fixedLimitDate} onChange={(e) => setFixedLimitDate(e.target.value)} />
                                                        </Col>

                                                        <Col md='6' className=''>
                                                            <Form.Label
                                                                className='form-label'
                                                            >
                                                                Hora limite
                                                            </Form.Label>
                                                            <Form.Control className='input-group-edit' placeholder='Hora limite' type='time' value={fixedLimitTime} onChange={(e) => setFixedLimitTime(e.target.value)} />
                                                        </Col>
                                                    </>
                                                ) : ''
                                            }

                                            <div className="col-12">
                                                <Button disabled={showSpinner} type="submit" variant="primary">
                                                    {showSpinner ? <div className="spinner-border spinner-border-sm" role="status"></div> : 'Criar'}
                                                </Button>
                                            </div>
                                        </Form>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default QuizCreate
