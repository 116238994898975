import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { Col, Modal, Form } from 'react-bootstrap'
import like_btn from '../../assets/images/icon/like_btn.png'
import love_btn from '../../assets/images/icon/love_btn.png'
import haha_btn from '../../assets/images/icon/haha_btn.png'
import wow_btn from '../../assets/images/icon/wow_btn.png'
import angry_btn from '../../assets/images/icon/angry_btn.png'
import sad_btn from '../../assets/images/icon/sad_btn.png'
import { debounce } from 'lodash'
import { useInView } from 'react-intersection-observer'
import { FaSearch } from 'react-icons/fa'
import api from '../../services/api'
import loader from '../../assets/images/page-img/page-load-loader.gif'

function ShowReactionCommentsModal({ comment_id, showModalReactionComments, setShowModalReactionComments }) {
    const [commentReactions, setCommentReactions] = useState([])
    const [likesCounter, setLikesCounter] = useState(0)
    const [lovesCounter, setLovesCounter] = useState(0)
    const [hahaCounter, setHahaCounter] = useState(0)
    const [wowsCounter, setWowsCounter] = useState(0)
    const [sadCounter, setSadCounter] = useState(0)
    const [angryCounter, setAngryCounter] = useState(0)
    const [search, setSearch] = useState('')
    const [page, setPage] = useState(1)
    const [hasMore, setHasMore] = useState(true)
    const [isLoading, setIsLoading] = useState(true)
    const { ref, inView } = useInView({
        threshold: 0.1,
    })

    const getCommentReactions = useCallback(async (resetPage = false) => {
        setIsLoading(true)
        const currentPage = resetPage ? 1 : page
        try {
          const response = await api.post(`/api/reactionComment/index/${comment_id}`, {
            search,
            page: currentPage,
            limit: 10
          })
    
          const data = response.data.reactions.data
    
          if (resetPage) {
            setCommentReactions(data)
            setPage(2)
          } else {
            setCommentReactions(prev => [...prev, ...data])
            setPage(currentPage + 1)
          }
          setHasMore(data.length === 10)
          setLikesCounter(response.data.likes)
          setLovesCounter(response.data.loves)
          setHahaCounter(response.data.hahas)
          setWowsCounter(response.data.wows)
          setSadCounter(response.data.sads)
          setAngryCounter(response.data.angries)
        } catch (error) {
          console.error('Failed to fetch user data:', error)
        } finally {
          setIsLoading(false)
        }
      }, [search, page, comment_id])
    
      const handleSearchChangeReactions = useCallback(debounce((newSearch) => {
        setSearch(newSearch)
        getCommentReactions(true)
      }, 300), [])
    
      useEffect(() => {
        if (showModalReactionComments){
            getCommentReactions(true)
        }
      }, [search, showModalReactionComments])
    
      useEffect(() => {
        if (inView && hasMore && !isLoading) {
          getCommentReactions(false)
        }
      }, [inView, hasMore, isLoading])
    
      const filteredReactions = useMemo(() => {
        return search ? commentReactions.filter(f => f.user_name.toLowerCase().includes(search.toLowerCase())) : commentReactions;
      }, [search, commentReactions]);

    return (
        <Modal
            show={showModalReactionComments}
            onHide={() => setShowModalReactionComments(false)}
            aria-labelledby='contained-modal-title-vcenter'
            centered
        >
            <Modal.Header id='modalHeader' closeButton>
                <div id='divModalTitle'>
                    <Modal.Title className='modalReactionPost'>
                        Pessoas que reagiram
                    </Modal.Title>
                </div>
            </Modal.Header>
            <Modal.Body id='modalBody'>
                <Form.Group className='input-group pb-1' style={{ marginBottom: '5px' }}>
                    <span className='input-group-text' id='basic-addon1'><FaSearch /></span>
                    <Form.Control
                        placeholder='Pesquisar'
                        aria-label='Pesquisar'
                        aria-describedby='search'
                        onChange={(e) => handleSearchChangeReactions(e.target.value)}
                    />
                </Form.Group>
                <Modal.Header id='modalTitle' style={{ marginBottom: '5px', paddingBottom: '0' }}>
                    <div style={{ display: 'flex', gap: '10px', flexWrap: 'wrap', marginTop: '-10px', marginBottom: '12px' }}>
                        {
                            likesCounter > 0 ? (
                                <div style={{ marginRight: '10px', marginBottom: '0' }}>
                                    <img src={like_btn} className='img-fluid me-1' alt='' style={{ 'height': '25px', 'width': '25px' }} />{likesCounter}
                                </div>
                            ) : ''
                        }

                        {
                            lovesCounter > 0 ? (
                                <div style={{ marginRight: '10px', marginBottom: '0' }}>
                                    <img src={love_btn} className='img-fluid me-1' alt='' style={{ 'height': '25px', 'width': '25px' }} />{lovesCounter}
                                </div>
                            ) : ''
                        }

                        {
                            hahaCounter > 0 ? (
                                <div style={{ marginRight: '10px', marginBottom: '0' }}>
                                    <img src={haha_btn} className='img-fluid me-1' alt='' style={{ 'height': '25px', 'width': '25px' }} />{hahaCounter}
                                </div>
                            ) : ''
                        }

                        {
                            wowsCounter > 0 ? (
                                <div style={{ marginRight: '10px', marginBottom: '0' }}>
                                    <img src={wow_btn} className='img-fluid me-1' alt='' style={{ 'height': '25px', 'width': '25px' }} />{wowsCounter}
                                </div>
                            ) : ''
                        }

                        {
                            sadCounter > 0 ? (
                                <div style={{ marginRight: '10px', marginBottom: '0' }}>
                                    <img src={sad_btn} className='img-fluid me-1' alt='' style={{ 'height': '25px', 'width': '25px' }} />{sadCounter}
                                </div>
                            ) : ''
                        }

                        {
                            angryCounter > 0 ? (
                                <div style={{ marginRight: '10px', marginBottom: '0' }}>
                                    <img src={angry_btn} className='img-fluid me-1' alt='' style={{ 'height': '25px', 'width': '25px' }} />{angryCounter}
                                </div>
                            ) : ''
                        }
                    </div>

                </Modal.Header>

                <div className="item-header-scroll" style={{ marginTop: '10px'}}>
                    {
                        filteredReactions.map((reaction, index) => (
                            <Col md={6} key={index} ref={index === filteredReactions.length - 1 ? ref : null}>
                                <div key={`reaction_${reaction.id}`}>
                                    <div className="d-flex gap-3 mb-4">
                                        <img
                                            className="avatar-32 rounded-pill"
                                            src={reaction?.user_image}
                                            alt="image_profilel"
                                        />
                                        <div>
                                            <h6 className="font-size-14">
                                                <img src={reaction.reaction === 'like' ? like_btn : reaction.reaction === 'love' ? love_btn : reaction.reaction === 'haha' ? haha_btn : reaction.reaction === 'wow' ? wow_btn : reaction.reaction === 'sad' ? sad_btn : reaction.reaction === 'angry' ? angry_btn : ''} className='img-fluid me-2' alt='' style={{ 'height': '25px', 'width': '25px' }} />
                                                {reaction.user_name} {" "}
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        ))
                    }
                </div>
                {isLoading && <div className="col-sm-12 text-center">
                    <img src={loader} alt="loader" style={{ height: "50px" }} />
                </div>}
            </Modal.Body>
        </Modal>
    );
}

export default ShowReactionCommentsModal;