import React, { useState, useEffect, useRef } from 'react'
import { Button, Form } from 'react-bootstrap'
import api from '../../services/api';
import { toast } from 'react-toastify'
import loader from '../../assets/images/page-img/page-load-loader.gif'
import { FaArrowDown } from "react-icons/fa"
import { io } from 'socket.io-client'
import './style.css'

const Index = ({ post_id, is_live = false }) => {
  const [messages, setMessages] = useState(null)
  const [newMessage, setNewMessage] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [showScrollButton, setShowScrollButton] = useState(false)
  const chatBodyRef = useRef(null)
  const socket = useRef(null)

  const handleScroll = () => {
    const chatBody = chatBodyRef.current;

    if ((chatBody.scrollTop < chatBody.scrollHeight - chatBody.clientHeight - 100)) {
      setShowScrollButton(true);
    } else {
      setShowScrollButton(false);
    }
  };

  const scrollToBottom = () => {
    if (chatBodyRef.current) {
      chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
    }
  }

  const getMessages = async () => {
    const response = await api.get(`/api/liveChat/${post_id}`)
    setIsLoading(false)
    setMessages(response.data.messages)
    scrollToBottom()
  }

  const messageStore = async (e) => {
    e.preventDefault()
    await api.post(`/api/liveChat/store`, {
      message: newMessage,
      type: 'text',
      post_id
    }).then(() => {
      setNewMessage('')
      getMessages()
    }).catch(err => toast.error('Erro ao enviar mensagem', { autoClose: 3000 }))
  }

  useEffect(() => {
    getMessages()
  }, [])

  useEffect(() => {
    scrollToBottom()
  }, [messages])

  useEffect(() => {
    const chatBody = chatBodyRef.current
    chatBody.addEventListener("scroll", handleScroll)

    return () => {
      chatBody.removeEventListener("scroll", handleScroll)
    }
  }, [])

  useEffect(() => {
    socket.current = io(process.env.REACT_APP_CHAT_SOCKET_URL, {
      transports: ['polling', 'websocket'],
      withCredentials: true
    });

    socket.current.on('connect', () => {
      console.log('Connected to live chat WebSocket');
      socket.current.emit('subscribe', `chat_workplace_live_${post_id}`);
    });

    socket.current.on('disconnect', () => {
      console.log('Disconnected from WebSocket');
    });

    return () => {
      if (socket.current) {
        socket.current.disconnect();
      }
    };
  }, [socket, post_id]);

  useEffect(() => {
    const handleMessage = ({ message }) => {
      console.log('mensagem de live recebida')

      setMessages([...messages, message])
      scrollToBottom()
    }

    if (socket.current) {
      socket.current.on('newLiveMessage', handleMessage);
    }

    return () => {
      if (socket.current) {
        socket.current.off('newLiveMessage', handleMessage);
      }
    };
  }, [messages])

  return (
    <div className='border-top mt-4 pt-4 live-chat-container'>
      {isLoading && 
        <div className='col-sm-12 text-center'>
          <img src={loader} alt='loader' style={{ height: '100px' }} />
        </div>
      }

      <div className='messages-container' ref={chatBodyRef}>
        {messages && messages.map((message) => (
          <div key={`live_message_${message.id}`} className='comment-list-user-img flex-shrink-0 d-flex mb-3'>
            <img
                src={message?.user_image ?? ''}
                alt='userimg'
                className='avatar-48 rounded-circle img-fluid me-3'
                loading='lazy'
            />

            <p><b className='me-2'>{message?.user_name}</b> <b>{message.formatted_created_at}</b>: {message.message}</p>
          </div>
        ))}

        <button
          className={`scroll-to-bottom-btn-live-chat btn btn-primary ${showScrollButton ? 'visible' : 'invisible'}`}
          onClick={scrollToBottom}
        >
          <FaArrowDown />
        </button>
      </div>

      <div className='add-comment-form-block'>
        <div className='d-flex align-items-center gap-3'>
          <div className='add-comment-form'>
            <Form onSubmit={messageStore}>
              <input
                  type='text'
                  className='form-control'
                  disabled={!is_live}
                  placeholder='Escrever mensagem...'
                  value={newMessage} onChange={(e) => setNewMessage(e.target.value)}
              />
              <Button disabled={!is_live} type="submit" variant="primary">Enviar</Button>
            </Form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Index
