import React from "react";

//header
import Header from "../../components/partials/dashboard/HeaderStyle/header";

//sidebar
import RightSidebar from "../../components/partials/dashboard/SidebarStyle/rightsidebar";

//sidebar
import Sidebar from "../../components/partials/dashboard/SidebarStyle/sidebar";

//footer
import Footer from "../../components/partials/dashboard/FooterStyle/footer";

import { Outlet, useLocation } from "react-router-dom";

const Default = () => {
  const location = useLocation();

  return (
    <>
      {
        location.pathname !== '/chat/index' ? (
          <Sidebar />
        ) : ''
      }
      {/* <HeaderOne/> */}
      <div className="main-content">
        <div className="position-relative">
          <Header />
          {/* <div id="content-page" className="content-inner"> */}
          {/* <DefaultRouter/> */}
          <Outlet />
          {/* </div> */}
        </div>
      </div>

      {
        location.pathname !== '/chat/index' ? (
          <>
            <RightSidebar />
            <Footer />
          </>
        ) : ''
      }
    </>
  );
};

export default Default;
