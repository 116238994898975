import React, { useEffect, useState, useMemo } from "react";
import { Container, Row, Col, Card, Button, Modal, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import api from "../../services/api";
import { toast } from "react-toastify";
import { FaSearch } from "react-icons/fa";
import AnimationWarningLottie from '../../components/AnimationWarningDeleteConfim/AnimationWarningLottie'

const PollIndex = () => {
  const navigate = useNavigate();
  const history = useNavigate();
  const [polls, setPolls] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [poll_idToDelete, setPollIdToDelete] = useState("");
  const [pollQuestionToDelete, setPollQuestionToDelete] = useState("");
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pollsPerPage] = useState(10);
  const indexOfLastPoll = currentPage * pollsPerPage;
  const indexOfFirstPoll = indexOfLastPoll - pollsPerPage;
  const currentPolls = polls.slice(indexOfFirstPoll, indexOfLastPoll);
  const nPages = Math.ceil(polls.length / pollsPerPage);

  const Pagination = ({ nPages, currentPage, setCurrentPage }) => {
    if (nPages <= 1) return null;

    const range = (start, end) => {
      return Array(end - start + 1)
        .fill()
        .map((_, idx) => start + idx);
    };

    let pageNumbers = [];
    if (currentPage <= 3) {
      pageNumbers = [...range(1, Math.min(5, nPages))];
      if (nPages > 5) pageNumbers.push("...", nPages);
    } else if (currentPage > 3 && currentPage < nPages - 2) {
      pageNumbers = [1, "...", ...range(currentPage - 1, currentPage + 1), "...", nPages];
    } else {
      pageNumbers = [1, "..."];
      pageNumbers.push(...range(nPages - 4, nPages));
    }

    return (
      <nav className="d-flex justify-content-center align-items-center">
        <ul className="pagination mb-4">
          <li className="page-item">
            <Link className="page-link" onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}>
              <i className="icon material-symbols-outlined d-flex">arrow_back</i>
            </Link>
          </li>
          {pageNumbers.map((number, index) => (
            <li key={index} className={`page-item ${currentPage === number ? "active" : ""}`}>
              <Link className="page-link" onClick={() => typeof number === "number" && setCurrentPage(number)}>
                {number}
              </Link>
            </li>
          ))}
          <li className="page-item">
            <Link className="page-link" onClick={() => setCurrentPage((prev) => Math.min(prev + 1, nPages))}>
              <i className="icon material-symbols-outlined d-flex">arrow_forward</i>
            </Link>
          </li>
        </ul>
      </nav>
    );
  };

  const getPolls = async () => {
    try {
      const response = await api.get("/api/polls/index");
      setPolls(response.data.polls);
    } catch (error) {
      console.error("Erro ao carregar enquetes:", error);
      toast.error("Erro ao carregar dados.", { autoClose: 3000 });
    }
  };

  const showEditPollPage = (poll_id) => {
    navigate(`/polls/edit/${poll_id}`);
  };

  const showCreatePollPage = () => {
    navigate(`/polls/create`);
  };

  const showDeletePollConfirmationModal = (poll_id, pollQuestion) => {
    setModalShow(true);
    setPollIdToDelete(poll_id);
    setPollQuestionToDelete(pollQuestion);
  };

  const deletePoll = async (poll_id) => {
    try {
      await api.delete(`/api/polls/destroy/${poll_id}`);
      getPolls();
      setModalShow(false);
      toast.success("Enquete deletada com sucesso!", { autoClose: 3000 });
    } catch (error) {
      console.error("Erro ao excluir a enquete:", error);
      toast.error("Erro ao excluir a enquete.", { autoClose: 3000 });
    }
  };

  const downloadPollReport = async (poll_id) => {
    try {
        const response = await api({
            url: `/api/pollReport`,
            method: 'post',
            data: { poll_id },
            responseType: 'blob',
        })

        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `poll_${poll_id}_report.xlsx`)
        document.body.appendChild(link)
        link.click()
        link.remove()
        
        toast.success('Relatório gerado com sucesso', { autoClose: 1000 })
    } catch (err) {
        toast.error('Erro ao gerar relatório', { autoClose: 1000 })
        console.error(err)
    }
}


  function DeletePollConfirmationModal(props) {
    return (
      <Modal {...props} aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton>
          <Modal.Title>Exclusão de Enquete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-center">
            <AnimationWarningLottie />
          </div>
          <div className="d-flex justify-content-center">
            <p>Você tem certeza de que deseja remover a enquete "{props.pollQuestion}"?</p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => deletePoll(props.poll_id)}>Sim</Button>
          <Button onClick={props.onHide}>Não</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  useEffect(() => {
    getPolls();
  }, []);

  const filteredPolls = useMemo(() => {
    const lowerSearch = search.toLowerCase();
    return search === ""
      ? currentPolls
      : polls.filter(
        (poll) =>
          poll.question.toLowerCase().includes(lowerSearch) ||
          poll.id.toString().toLowerCase().includes(lowerSearch)
      );
  }, [search, polls, currentPolls]);

  return (
    <div id="content-page" className="content-inner">
      <Container>
        <DeletePollConfirmationModal
          show={modalShow}
          onHide={() => setModalShow(false)}
          poll_id={poll_idToDelete}
          pollQuestion={pollQuestionToDelete}
        />
        <Row>
          <Col sm="12">
            <Card className="position-relative inner-page-bg bg-primary p-5">
              <div className="inner-page-title">
                <h3 className="text-white">Enquetes</h3>
              </div>
            </Card>
          </Col>
          <Col sm="12" lg="12">
            <Card>
              <Card.Body>
                <div className="mb-4 mt-4">
                  <Form.Group className="input-group">
                    <span className="input-group-text">
                      <FaSearch />
                    </span>
                    <Form.Control
                      placeholder="Pesquisar"
                      onChange={(e) => setSearch(e.target.value)}
                    />
                    <Button onClick={showCreatePollPage}>
                      <i className="icon material-symbols-outlined d-flex">
                        post_add
                      </i>
                    </Button>
                  </Form.Group>
                </div>
                <table className="table">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Enquete</th>
                      <th scope="col">Ativo</th>
                      <th>Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredPolls.map((poll, index) => (
                      <tr key={index}>
                        <th>{poll.id}</th>
                        <td>{poll.question}</td>
                        <td>{poll.active ? 'Sim' : 'Não'}</td>
                        <td className="d-flex gap-2">
                          <Button onClick={() => history(`/poll/chart/${poll.id}`)}>
                            <i className='material-symbols-outlined d-flex'> monitoring </i>
                          </Button>
                          <Button onClick={() => downloadPollReport(poll.id)}>
                            <i className='material-symbols-outlined d-flex'> download </i>
                          </Button>
                          <Button onClick={() => showEditPollPage(poll.id)}>
                            <i onClick={() => showEditPollPage(poll.id)} className="material-symbols-outlined d-flex"> mode_edit </i>
                          </Button>
                          <Button onClick={() => showDeletePollConfirmationModal(poll.id, poll.question)}>
                            <i className="material-symbols-outlined d-flex"> delete </i>
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </Card.Body>
            </Card>
            <Pagination
              nPages={nPages}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default PollIndex;
