import React from 'react';
import { Navigate } from 'react-router-dom';
import Cookies from 'js-cookie';

const ProtectedRoute = ({ children }) => {
  const isAuthenticated = Cookies.get('token');

  return isAuthenticated ? children : <Navigate to="/auth/sign-in" replace />;
};

export default ProtectedRoute;
