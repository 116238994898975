import React, { useState, useEffect } from 'react'
import { Row, Col, Form, Container, Button, Card } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom'
import { Link } from 'react-router-dom'
import api from '../../services/api'
import { toast } from 'react-toastify'

const StreamingsEdit = () => {
    const [validated, setValidated] = useState(false)
    const customId = 'success-toast-id'
    const history = useNavigate()
    const [nome, setNome] = useState('')
    const [idioma, setIdioma] = useState('pt-br')
    const [dataInicio, setDataInicio] = useState('')
    const [horaInicio, setHoraInicio] = useState('')
    const [dataFim, setDataFim] = useState('')
    const [horaFim, setHoraFim] = useState('')
    const [ativo, setAtivo] = useState(false)
    const [permanent, setPermanent] = useState(false)
    let { id } = useParams()

    const handleSubmit = async (e) => {
        e.preventDefault()

        if (nome === '' || idioma === '' || dataInicio === '' || horaInicio === '' || dataFim === '' || horaFim === '') {
            toast.error('Por favor preencha todos os campos.', { autoClose: 3000 })
        } else {
            setValidated(true)

            await api.post(`/api/streaming/update/${id}`, {
                nome,
                idioma,
                data_inicio: dataInicio,
                hora_inicio: horaInicio,
                data_fim: dataFim,
                hora_fim: horaFim,
                permanent,
                ativo
            }).then(() => {
                toast.success('Live editada com sucesso!', {
                    toastId: customId,
                    autoClose: 300,
                    onClose: () => {
                        history('/streaming/index')
                    }
                })
            })
        }

    }

    useEffect(() => {
        let cancel = false

        async function fetchData() {
            try {
                const response = await api.get(`/api/streaming/edit/${id}`)

                if (!cancel) {
                    setNome(response.data.streaming.nome)
                    setIdioma(response.data.streaming.idioma)
                    setDataInicio(response.data.streaming.data_inicio.split(' ')[0])
                    setHoraInicio(response.data.streaming.data_inicio.split(' ')[1])
                    setDataFim(response.data.streaming.data_fim.split(' ')[0])
                    setHoraFim(response.data.streaming.data_fim.split(' ')[1])
                    setAtivo(response.data.streaming.active)
                    setPermanent(response.data.streaming.permanent)
                }
            } catch (error) {
                console.error('Failed to fetch streaming data:', error)
                toast.error('Erro ao recuperar dados da live', {
                    toastId: customId,
                    autoClose: 1000,
                })
            }
        }

        fetchData()

        return () => {
            cancel = true
        }
    }, [])

    return (
        <>
            <div id='content-page' className='content-inner'>
                <Container>
                    <Row>
                        <Col sm='12'>
                            <Card className='position-relative inner-page-bg bg-primary p-5'>
                                <div className='inner-page-title' style={{ display: 'flex', justifyContent: 'space-between', width: '-webkit-fill-available' }}>
                                    <h3 className='text-white'>Lives</h3>
                                    <Link to='/streaming/index'>
                                        <Button><i className='icon material-symbols-outlined d-flex'>
                                            arrow_back
                                        </i></Button>
                                    </Link>
                                </div>
                            </Card>
                        </Col>
                        <Col sm='12' lg='12'>
                            <Col sm='12' lg='12'>
                                <Card>
                                    <Card.Header className='d-flex justify-content-between'>
                                        <div className='header-title'>
                                            <h4 className='card-title'>Editar live</h4>
                                        </div>
                                    </Card.Header>
                                    <Card.Body className='pt-0'>
                                        <Form noValidate onSubmit={handleSubmit} validated={validated} className='row g-3'>
                                            <Col md='6' className=''>
                                                <Form.Label
                                                    className='form-label'
                                                    htmlFor='validationCustom01'
                                                >
                                                    Nome
                                                </Form.Label>
                                                <Form.Control className='input-group-edit' type='text' placeholder='Nome' value={nome} onChange={(e) => setNome(e.target.value)} aria-describedby='inputGroupPrepend' id='validationCustom01' required />
                                            </Col>
                                            
                                            <Col md='6' className=''>
                                                <Form.Label
                                                    className='form-label'
                                                    htmlFor='validationCustom02'
                                                >
                                                    Idioma
                                                </Form.Label>
                                                <Form.Select className='input-group-edit' placeholder='Idioma' value={idioma} onChange={(e) => setIdioma(e.target.value)} aria-describedby='inputGroupPrepend' id='validationCustom02' required>
                                                    <option>pt-br</option>
                                                    <option>en</option>
                                                    <option>es</option>
                                                </Form.Select>
                                            </Col>

                                            <Col md='6' className=''>
                                                <Form.Label
                                                    className='form-label'
                                                    htmlFor='validationCustom03'
                                                >
                                                    Data início
                                                </Form.Label>
                                                <Form.Control className='input-group-edit' placeholder='Data início' type='date' value={dataInicio} onChange={(e) => setDataInicio(e.target.value)} aria-describedby='inputGroupPrepend' id='validationCustom03' required />
                                            </Col>

                                            <Col md='6' className=''>
                                                <Form.Label
                                                    className='form-label'
                                                    htmlFor='validationCustom04'
                                                >
                                                    Hora início
                                                </Form.Label>
                                                <Form.Control className='input-group-edit' placeholder='Hora início' type='time' value={horaInicio} onChange={(e) => setHoraInicio(e.target.value)} aria-describedby='inputGroupPrepend' id='validationCustom04' required />
                                            </Col>

                                            <Col md='6' className=''>
                                                <Form.Label
                                                    className='form-label'
                                                    htmlFor='validationCustom05'
                                                >
                                                    Data fim
                                                </Form.Label>
                                                <Form.Control className='input-group-edit' placeholder='Data fim' type='date' value={dataFim} onChange={(e) => setDataFim(e.target.value)} aria-describedby='inputGroupPrepend' id='validationCustom05' required />
                                            </Col>

                                            <Col md='6' className=''>
                                                <Form.Label
                                                    className='form-label'
                                                    htmlFor='validationCustom06'
                                                >
                                                    Hora fim
                                                </Form.Label>
                                                <Form.Control className='input-group-edit' placeholder='Hora fim' type='time' value={horaFim} onChange={(e) => setHoraFim(e.target.value)} aria-describedby='inputGroupPrepend' id='validationCustom06' required />
                                            </Col>

                                            <Col md='6' className=''>
                                                <Form.Check
                                                    type="switch"
                                                    label="Live fixa no feed"
                                                    checked={permanent}
                                                    onChange={(e) => setPermanent(e.target.checked)}
                                                    required
                                                />
                                            </Col>

                                            <Col md='6' className=''>
                                                <Form.Check
                                                    type="switch"
                                                    label="Ativo"
                                                    checked={ativo}
                                                    onChange={(e) => setAtivo(e.target.checked)}
                                                    required
                                                />
                                            </Col>

                                            <div className='col-12'>
                                                <Button type='submit' variant='primary'>Salvar</Button>
                                            </div>
                                        </Form>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default StreamingsEdit