import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

// img
import error500Light from "../../assets/images/atmo logo.png";
import error505 from "../../assets/images/atmo logo.png";

const Error500 = () => {
  return (
    <>
      <Container className="p-0">
        <Row className=" no-gutters vh-100">
          <Col sm={12} className="text-center align-self-center">
            <div className="iq-error position-relative mt-5">
              <img
                src={error500Light}
                style={{ width: '250px' }}
                className="img-fluid iq-error-img img-light center"
                alt="light img"
              />
              <img
                src={error505}
                style={{ width: '250px' }}
                className="img-fluid iq-error-img img-dark center"
                alt="dark img"
              />
              <h2 className="mb-0 text-center mt-5">
                Ops! Esta página não está funcionando.
              </h2>
              <p className="text-center">
                Entre em contato com administrador ou tente novamente mais tarde.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Error500;
