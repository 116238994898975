import React, { createContext, useState, useContext, useCallback } from 'react';
import api from '../services/api';
import Cookies from 'js-cookie'

const UserAboutContext = createContext();

export const useUserAbout = () => useContext(UserAboutContext);

export const UserAboutProvider = ({ children }) => {
  const token = Cookies.get('token');
  const userId = Cookies.get('userId');
  const [userAbout, setUserAbout] = useState('');

  async function fetchUserAbout() {
    try {
      const response = await api.post(`/api/user/about/`, {
        userId,
        token,
      });

      const data = response.data[0];
      setUserAbout(data);
    } catch (error) {
      console.error('Failed to fetch user data:', error);
    }
  }

  return (
    <UserAboutContext.Provider value={{ userAbout, fetchUserAbout }}>
      {children}
    </UserAboutContext.Provider>
  );
};
