import React, { createContext, useState, useContext, useCallback } from 'react'
import api from '../services/api'
import Cookies from 'js-cookie'

const MemberRequestContext = createContext()

export const useMemberRequests = () => useContext(MemberRequestContext)

export const MemberRequestProvider = ({ children }) => {
    const [memberRequests, setMemberRequests] = useState([])
    const token = Cookies.get('token')

    const fetchMemberRequests = useCallback(async (page = 1, limit = 10, group_id) => {
        try {
            const response = await api.post(`/api/groups/requestMembers`, {
                group_id,
                page,
                limit
            })
            setMemberRequests(response.data)
        } catch (error) {
            console.error('Failed to fetch member requests:', error)
        }
    }, [token])

    return (
        <MemberRequestContext.Provider value={{ memberRequests, fetchMemberRequests }}>
            {children}
        </MemberRequestContext.Provider>
    )
}
